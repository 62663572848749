import { ChangeDetectorRef, Component, EventEmitter, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Subject } from "rxjs";
import { SubSink } from "subsink";
import { AuthService } from "../../../../../auth/auth.service";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { GridColumnDefinition } from "../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../shared/grid/models/grid-configuration.model";
import { GridRequest } from "../../../../../shared/grid/models/grid-request.model";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { AuditQueryGridFilters } from "../audit-query-grid-filters/audit-query-grid-filter-model";
import { AuditQueryGridService } from "../audit-query-grid/audit-query-grid.service";
import { AuditPackageGenerateItem } from "./audit-package-generate-item.model";
import { AuditPackageGenerateRequest } from "./audit-package-generate-request.model";
import { AuditPackageGenerateService } from "./audit-package-generate.service";

@Component({
  selector: "app-audit-package-generate",
  templateUrl: "./audit-package-generate.component.html",
  styleUrls: ["./audit-package-generate.component.scss"],
})

export class AuditPackageGenerateComponent implements OnInit, OnDestroy  {
  private unsubscribe = new Subject();
  auditPackageGridConfiguration = new GridConfiguration();
  auditPackageGridSearchRequest: GridRequest;
  refreshGrid = new EventEmitter<GridConfiguration>(true);
  auditPackageGridData: AuditPackageGenerateItem[];
  auditPackageGridSelection: AuditPackageGenerateItem;
  packageCreationInitiated = false;
  projectId: number;
  isFileNameEntryVisible = false;
  filename: string;
  showConfirmationForPackageCreation = false;
  showNotificationForAuditGeneration = false;
  private sink = new SubSink();
  gridChase: any = [];
  complianceChangeStatus: string;
  @ViewChild("actionItem", { static: true }) actionItem: TemplateRef<any>;

  constructor(private auditPackageGenerateService: AuditPackageGenerateService,
              private auditQueryGridService: AuditQueryGridService,
              private readonly authService: AuthService,
              private changeDetector: ChangeDetectorRef,
              @Inject(BASE_API_URL) private readonly baseApiUrl: string,
              private messagingService: MessagingService
  ) { }

  ngOnInit() {
    this.createGrid();

    this.auditPackageGenerateService.refreshAuditPackageGrid(null);
    this.sink.add(
      this.auditPackageGenerateService.projectId
        .subscribe(data => {
          this.projectId = data;
          this.loadGrid();
        }),

      this.auditPackageGenerateService.autoDeleteUserAuditEntry
        .subscribe(data => {
          const auditEntry = new AuditPackageGenerateItem({
            auditPackageItemId: data,
          });
          this.deletePackageItem(auditEntry);

        })
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  private createGrid(): void {
    this.auditPackageGridConfiguration = new GridConfiguration({
      columns: [
        new GridColumnDefinition({ field: "chaseId", header: "Chase ID" }),
        new GridColumnDefinition({ field: "measureCode", header: "Measure" }),
        new GridColumnDefinition({ field: "numeratorName", header: "Numerator" }),
        new GridColumnDefinition({ field: "pageRanges", header: "Pages" }),
        new GridColumnDefinition({ header: "", template: this.actionItem, isSortableColumn: false }),
      ],
      pageSize: 10,
      pageSizeOptions: [5, 10, 15, 20],
      selectionMode: "none",
      showMenu: false,
    });

    this.auditPackageGridConfiguration.showExportAction = false;
    this.auditPackageGridConfiguration.showActionColumn = false;
  }

  private loadGrid(): void {
    this.packageCreationInitiated = true;
    const packagefilters = new AuditQueryGridFilters({
      projectId: this.projectId,
      measureId: null,
      numeratorId: null,
    });
    const filtersSerialized = JSON.stringify(packagefilters);
    this.auditPackageGridSearchRequest = new GridRequest({
      url: `${this.baseApiUrl}audit/generatePackageSelection/list?auditQuerySearchCriteria=${filtersSerialized}`,
      filters: [],
    });
    this.refreshGrid.emit();
  }

  gridData($event: AuditPackageGenerateItem[]): void {
    this.auditPackageGridData = $event;
  }

  get auditPackageDataAvailable(): boolean {
    return ArrayHelper.isAvailable(this.auditPackageGridData);
  }

  get displayPackageGridText(): boolean {
    return !this.packageCreationInitiated;
  }

  deletePackageItem(data: AuditPackageGenerateItem): void {
    if (NumberHelper.isGreaterThan(Number(data.auditPackageItemId), 0)) {
      this.auditPackageGenerateService.deleteAuditPackageItem(Number(data.auditPackageItemId)).subscribe(
        () => {
          this.messagingService.showToast("Package Item deleted successfully.", SeverityType.SUCCESS);
          this.auditPackageGenerateService.refreshAuditPackageGrid(this.projectId);
          this.auditQueryGridService.reloadAuditGridFromState();
          this.changeDetector.markForCheck();
        },
        () => {
          this.messagingService.showToast("Failed to delete Package Item.", SeverityType.ERROR);
          this.changeDetector.markForCheck();
        });
    }
  }

  private deletePackageItemIds(data: any): void {
    if (ArrayHelper.isAvailable(data)) {
      this.auditPackageGenerateService.deleteAuditPackageItemIds(data).subscribe(
        () => {
          this.messagingService.showToast("Package Item removed successfully.", SeverityType.SUCCESS);
          this.auditPackageGenerateService.refreshAuditPackageGrid(this.projectId);
          this.changeDetector.markForCheck();
        },
        () => {
          this.messagingService.showToast("Failed to remove Package Item.", SeverityType.ERROR);
          this.changeDetector.markForCheck();
        });
    }
  }

  promptForFileName(): void {
    this.filename = "";

    if (NumberHelper.isAvailable(this.projectId)) {
      this.isFileNameEntryVisible = true;
    }
  }

  get fileNameAvailable(): boolean {
    return StringHelper.isAvailable(this.filename);
  }

  verifyIfAssignedToOtherUser(): void {
    if (this.auditPackageGridData.findIndex(data => data.auditReadyUserId !== this.authService.user.userId) !== -1) {
      this.showConfirmationForPackageCreation = true;

      return;
    }

    this.promptForFileName();
  }

  createPackage(): void {
    const projectIds = [];
    projectIds.push(this.projectId);
    const packageItemIds = [];
    const auditPackageIds = [];

    this.auditPackageGridData.forEach(data => {
      packageItemIds.push(Number(data.auditPackageItemId));
      auditPackageIds.push(Number(data.auditPackageId));
    });

    const auditPackageRequest = new AuditPackageGenerateRequest({
      projectIds,
      auditPackageItemIds: packageItemIds,
      auditPackageIds,
      packageName: this.filename,
    });

    this.bindGridChaseWithCompliance();
    const selectedChaseId: any = [];
    this.auditPackageGridData.map(x => selectedChaseId.push(x.chaseId));
    const chaseList = {chaseList : selectedChaseId};

    if (ArrayHelper.isAvailable(projectIds)) {

      this.auditPackageGenerateService.chaseListForComplianceCode(chaseList).subscribe(
        chaseItems => {

          const sortedGridChase = this.gridChase.slice().sort((a, b) => a.chaseId - b.chaseId);
          const sortedChaseItems = chaseItems.slice().sort((a, b) => a.chaseId - b.chaseId)
            .reduce((acc, current) => {
              const chase = acc.find(item => item.chaseId === current.chaseId);
              if (!chase) { acc.push(current); }
              return acc;
            },      []);

          const changedComplainceList = this.checkComplianceChanges(sortedGridChase, sortedChaseItems);
          this.gridChase = [];

          if (ArrayHelper.isAvailable(changedComplainceList)) {

            const packageItemId: any = [];
            let ncToCmrCount = 0;
            let cmrToNcCount = 0;

            sortedChaseItems.forEach(sortChase => {

              const gridChase = sortedGridChase.find(item => item.chaseId === sortChase.chaseId);
              if (sortChase) {
                if (gridChase.chaseComplianceCode === "C/MR" && sortChase.chaseComplianceCode !== "C/MR") {
                  cmrToNcCount = cmrToNcCount + 1;
                  packageItemId.push(Number(gridChase.auditPackageItemIds));
                }
                if (gridChase.chaseComplianceCode === "NC" && sortChase.chaseComplianceCode !== "NC") {
                   ncToCmrCount = ncToCmrCount + 1;
                   packageItemId.push(Number(gridChase.auditPackageItemIds));
                }
              }
            });

            if (sortedChaseItems.length !== (cmrToNcCount + ncToCmrCount) && (cmrToNcCount !== 0 || ncToCmrCount !== 0)) {
              this.showNotificationForAuditGeneration = true;
              this.complianceChangeStatus = "Chase(s) have been removed from the Audit Packet as compliance has been changed";
            } else if (sortedChaseItems.length === (cmrToNcCount + ncToCmrCount) && (cmrToNcCount !== 0 || ncToCmrCount !== 0)) {
              this.showNotificationForAuditGeneration = true;
              this.complianceChangeStatus = "No Audit Packet will be created as compliance of all Chase(s) have changed";
            }

            this.deletePackageItemIds(packageItemId);
            auditPackageRequest.auditPackageItemIds = auditPackageRequest.auditPackageItemIds.filter(itemId => !packageItemId.includes(Number(itemId)));
          }
          if (ArrayHelper.isAvailable(auditPackageRequest.auditPackageItemIds)) {
            this.auditPackageGenerateService.generatePackage(auditPackageRequest).subscribe(
              data => {
                if (data) {
                  this.messagingService.showToast("Items Queued For Packaging.", SeverityType.SUCCESS);
                  this.auditPackageGenerateService.refreshAuditPackageGrid(this.projectId);
                  this.auditQueryGridService.reloadAuditGridFromState();
                  this.changeDetector.markForCheck();
                }},
              () => {
                this.messagingService.showToast("Failed to generate Package.", SeverityType.ERROR);
                this.changeDetector.markForCheck();
            });
          }

      },
        () => {
        this.messagingService.showToast("Didn't get Compliance Code", SeverityType.ERROR);
        this.changeDetector.markForCheck();
      }

    );

    }

    this.isFileNameEntryVisible = false;
    this.changeDetector.markForCheck();

  }

  private bindGridChaseWithCompliance(): void {
    this.auditPackageGridData.forEach(element => {
        this.gridChase.push({
          chaseId : element.chaseId,
          chaseComplianceCode : element.chaseComplianceCode,
          auditPackageItemIds : element.auditPackageItemId,
        });
    });
  }

  private checkComplianceChanges(sortedGridChase, sortedChaseItems): any {
    return sortedGridChase.filter((element, index) =>
      sortedChaseItems[index]?.chaseId === element.chaseId &&
      sortedChaseItems[index].chaseComplianceCode !== element.chaseComplianceCode
    );
  }


  onConfirmPackageCreation(): void {
    this.promptForFileName();
  }

  closePackageWarning(): void {
    this.showNotificationForAuditGeneration = false;
  }

}
