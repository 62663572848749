import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output, ViewChild } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "../../../../../../auth/auth.service";
import { BASE_API_URL } from "../../../../../../core/environment.tokens";
import { MessagingService } from "../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../core/messaging/severity-type.enum";
import { UserService } from "../../../../../../core/user/user.service";
import { FormService } from "../../../../../../dynamic-forms/form.service";
import { Resize } from "../../../../../../dynamic-forms/inputs/textarea/resize.enum";
import { Textarea } from "../../../../../../dynamic-forms/inputs/textarea/textarea.model";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";
import { InternalPendsDetailInfoService } from "../../internal-pends-detail-info/internal-pends-detail-info.service";
import { InternalPendsDetailDocumentsService } from "../internal-pends-detail-documents.service";

export interface IOnUpload {
  formData: FormData;
  usePresignedURLs: boolean;
  presignedURLList: string[];
  originalFilenameList: string[];
  success(): void;
  fail(): void;
}

export interface IUploadObject {
  presignedURL: string;
  fileName: string;
}

@Component({
  selector: "app-internal-pends-upload-document",
  templateUrl: "./internal-pends-upload-document.component.html",
  styleUrls: ["./internal-pends-upload-document.component.scss"],
})
export class InternalPendsUploadDocumentComponent {
  chasePendId: number;
  @Input() isUploadDocumentVisible = false;
  @Output() formClose = new EventEmitter();
  disableUpload = false;
  readonly descriptionText: Textarea;
  formGroup: FormGroup;
  uploadedFiles: File[] = [];

  private usePresignedURLs: boolean;
  private presignedURLArray = new Array();
  private fileNameArray = new Array();

  @ViewChild("form", { static: true }) input;

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly formService: FormService,
    private service: InternalPendsDetailDocumentsService,
    private readonly route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private authService: AuthService,
    private messagingService: MessagingService,
    private internalPendsDetailInfoService: InternalPendsDetailInfoService,
    private userService: UserService,
    private http: HttpClient
  ) {
    this.chasePendId = this.route.snapshot.parent.params.pendGd;
    this.descriptionText =
      new Textarea({
        key: "description",
        label: "Description",
        placeholder: "Long form text...",
        rows: 6,
        resize: Resize.VERTICAL,
        validators: [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(255),
        ],
        errorMessages: {
          required: "Write a description between 4 - 255 characters.",
          minlength: "Write a description between 4 - 255 characters.",
          maxlength: "Write a description between 4 - 255 characters.",
        },

      });

    this.formGroup = this.formService.createFormGroup([this.descriptionText]);
    this.usePresignedURLs = this.userService.getUserToken().isPreSignedUrlUploadEnabled;
  }

  get isEnabled(): boolean {
    return this.formGroup.valid && ArrayHelper.isAvailable(this.uploadedFiles);
  }

  visibleChange($event) {
    this.isUploadDocumentVisible = $event;
    this.formClose.emit($event);
    this.formGroup.reset();

    this.input.clear();
  }

  async onUpload(event) {
    this.fileNameArray = [];
    this.presignedURLArray = [];
    this.uploadedFiles = [];
    for (const file of event.files) {
      this.uploadedFiles.push(file);
    }

    if (this.usePresignedURLs) {
      const obj = {
        name: event.currentFiles[0].name,
        type: event.currentFiles[0].type,
        fileType: "PendFile",
      };
      await this.http.post(`${this.baseApiUrl}presignedurl`, JSON.stringify(obj), { headers: new HttpHeaders({ "Content-Type": "application/json" }) })
        .toPromise().then(async (x: IUploadObject) => {
          this.presignedURLArray.push(x.presignedURL);
          this.fileNameArray.push(x.fileName);
        });
    }
  }

  async uploadDocument(): Promise<void> {
    const notes = this.formGroup.value?.description;
    if (!StringHelper.isAvailable(notes) || (StringHelper.isAvailable(notes) && (!notes?.replace(/^\s+|\s+$/g, "")) || ((notes?.replace(/ /g, "").length < 4)))) {
      this.formGroup.get(this.descriptionText.key).setErrors({ "server-error": "Write a description between 4 - 255 characters." });
    } else {
      this.formGroup.get(this.descriptionText.key).setErrors(null);
    }

    if (this.formGroup.valid && ArrayHelper.isAvailable(this.uploadedFiles)) {
      this.disableUpload = true;
      const formData = new FormData();
      const description = this.formGroup.get("description").value;

      formData.append("Description", description);
      formData.append("CallUserId", this.authService.userId.toString());
      formData.append("ChasePendId", this.chasePendId.toString());

      if (this.usePresignedURLs) {
        formData.append("OFN0", this.fileNameArray[0]);
        const filePromises = this.uploadedFiles.map((file, index) => {
          return this.uploadFiles(file, index);
        });
        await Promise.all(filePromises).catch(res => {
          this.disableUpload = false;
          return false;
        });

        this.service.uploadPresigned(formData).subscribe(data => {
          this.refreshUI(data);
          this.fileNameArray = [];
          this.presignedURLArray = [];
        });
      } else {
        this.uploadedFiles.forEach(file => {
          formData.append("Document", file);
        });
        this.service.upload(formData).subscribe(data => {
          this.refreshUI(data);
        });
      }
    } else {
      this.formService.markAllAsTouched(this.formGroup);
      this.disableUpload = false;
    }
    this.changeDetector.markForCheck();
  }

  uploadFiles(file, j): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        this.http.put(this.presignedURLArray[j], file).toPromise().then(() => {
          resolve(true);
        });
      } catch (e) {
        reject(e);
      }
    });
  }

  refreshUI(response: number): void {
    if (response > 0) {
      this.service.refreshFunction();
      this.internalPendsDetailInfoService.refreshFunction();
      this.messagingService.showToast("Document Uploaded successfully.", SeverityType.SUCCESS);
      this.uploadedFiles = [];
      this.formGroup.reset();
      this.visibleChange(false);
      this.disableUpload = false;
    } else {
      this.messagingService.showToast("Error while Document uploading, please try again.", SeverityType.ERROR);
      this.disableUpload = false;
    }
  }
}

