<div class="dashboard-clinical-container">
  <app-detail-main-template>
    <div container-header>
      <h3>My Chases</h3>
      <div class="chase-land-stats">
        <div class="ui-g">
          <div class="ui-g-8">
            <div class="chase-land-stats_keyItem"
              *ngFor="let item of headerStatistics; let i = index, trackBy: trackByIndex"
              [ngClass]="getStatisticsClass(item)">
              <span>{{item.key}}</span><br>
              <a class="chase-land-stats-item bold" [ngClass]="getHeaderItemClass(item.key)">
                <span (click)="applyFilter(item.key)">{{item.value}}</span>
              </a>
            </div>
            <div *ngIf="isOverreadFeedbackEnabled" class="chase-land-stats_keyItem">
              <span>Overread Feedback\Acknowledgements</span><br>
              <a class="chase-land-stats-item bold" routerLink="/dashboard/clinical/orfeedback">{{ overreadFeedbackCount
                }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div container-body>
      <div class="ui-g ui-fluid">
        <!-- [ngClass]="getClassForColumn()" -->
        <div class="ui-g-12 ui-md-12 ui-lg-12">

          <app-server-grid 
          [configuration]="clinicalGridConfiguration" 
          [(request)]="serverRequest"
          [filterTemplate]="filterForm">

            
            <ng-template #filterForm let-form>
              <app-tab-menu orientation="left">
                <app-tab-panel header="Date Range for Date Completed">
                  <div class="ui-g-12 ui-md-12 ui-lg-12">

                    <div class="ui-g-6 ui-md-6 ui-lg-6">
                      <form-factory [formGroup]="form" [model]="startDate"></form-factory>
                    </div>
                    <div class="ui-g-6 ui-md-6 ui-lg-6">
                      <form-factory [formGroup]="form" [model]="endDate"></form-factory>
                    </div>
                   
                  </div>
                </app-tab-panel>

              </app-tab-menu>
            </ng-template>

          </app-server-grid>

        </div>
      </div>
    </div>
  </app-detail-main-template>
</div>