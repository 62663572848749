import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-detailed-audit-report",
  templateUrl: "./detailed-audit-report.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailedAuditReportComponent {
  filters: string[] = ["Projects", "LOB", "Products", "RetrievalOwner", "QaDate"];
  dashboardType = LookerRiskDashboardType.DETAILED_AUDIT_REPORT;
  hideFilters: string[] = [HideFilters.PROJECT_ID, HideFilters.PROJECT_NAME, HideFilters.USER_ID, HideFilters.ORG_ID, "Qa Date" , HideFilters.REPORT_TYPE];
}
