import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { List } from "immutable";
import { SubSink } from "subsink";
import { AuthService } from "../../../../../../auth/auth.service";
import { DynamicFormEvent } from "../../../../../../dynamic-forms/dynamic-form-event.model";
import { SaveGroup } from "../../../../../../dynamic-forms/form-groups/save-group/save-group.model";
import { FormService } from "../../../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../../../../../dynamic-forms/inputs/selectable-input.model";
import { DocumentViewerSessionService } from "../../../../../../shared/document/document-page-viewer/document-viewer-session.service";
import { ListItem } from "../../../../../../shared/list/list-item";
import { NumberHelper } from "../../../../../../utilities/contracts/number-helper";
import { DynamicEntityAttribute } from "../../../../../api/member-validation/dynamic-entity-attribute.model";
import { MemberValidation } from "../../../../../api/member-validation/member-validation.model";
import { MemberValidationService } from "../../../../../api/member-validation/member-validation.service";
import { WorkflowStatusDb } from "../../../../../api/workflow/workflow-status-db.enum";
import { CodingReviewMode } from "../../../../project/project-config/coding-review-mode.enum";
import { ChartService } from "../../../chase-detail/chase-detail-chart/chart.service";
import { DobReasonType } from "../../../chase-detail/chase-detail-chart/member-dob-gen/dob-reason-type.enum";
import { RiskService } from "../../../chase-detail/chase-detail-chart/risk/risk.service";
import { ChaseDetailState } from "../../../chase-detail/chase-detail-state.model";
import { ChaseDetailStateService } from "../../../chase-detail/chase-detail-state.service";
import { ChaseDetailService } from "../../../chase-detail/chase-detail.service";

@Component({
  selector: "app-member-dob-gen-v2",
  templateUrl: "./member-dob-gen-v2.component.html",
  styleUrls: ["./member-dob-gen-v2.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberDobGenV2Component implements OnInit {
  private sink = new SubSink();

  @Input() set enabled(value: boolean) {
    this.isMemberPresent = value;
    this.isNextBtnEnabled = value;
    this.changeDetector.markForCheck();

    this.setDisabled(value);
    this.getDobAndGenAttributes(value);
  }

  @Input() set isMemberValidate(value: boolean) {
    this.isNextBtnEnabled = value;
    this.changeDetector.markForCheck();
  }

  @Input() chaseId: number;
  @Input() configuration: MemberValidation;
  @Output() onChange = new EventEmitter<DynamicFormEvent>(true);

  showMemberDob = false;

  form: FormGroup;
  memberDobReasonSaveGroup: SaveGroup;
  memberDobReasonInput: Dropdown;
  memberGenderReasonSaveGroup: SaveGroup;
  memberGenderReasonInput: Dropdown;
  memberValidationData = new MemberValidation();
  chaseDetailState: ChaseDetailState;
  chaseDetails = List<ListItem>();

  isMemberPresent = false;

  yesBtnToggle = false;
  noBtnToggle = false;
  noBtnToggle2 = false;

  yesGenderPresent = false;
  noGenderPresent = false;

  textYes = "Yes";
  textDobMissing = "No, DOB Missing";
  textDobInCorrect = "No, DOB Incorrect";

  textYesGenderPresent = "Yes";
  textNoGenderPresent = "No";

  textNext = "Next";

  isNextBtnEnabled = false;

  memberName = false;
  memberDOB = false;
  memberGender = false;

  constructor(
    private readonly chartService: ChartService,
    private readonly formService: FormService,
    private readonly memberValidationService: MemberValidationService,
    private chaseDetailStateService: ChaseDetailStateService,
    private changeDetector: ChangeDetectorRef,
    private documentViewerSessionService: DocumentViewerSessionService,
    private readonly authService: AuthService,
    private readonly chaseDetailService: ChaseDetailService,
    private readonly riskService: RiskService,
    private readonly router: Router
  ) { }


  get yesBtnClass(): string {
    return this.yesBtnToggle ? "yes-btn yes-btn--active" : "yes-btn";
  }
  get noBtnClass(): string {
    return this.noBtnToggle ? "no-btn no-btn--active" : "no-btn";
  }
  get noBtnClass2(): string {
    return this.noBtnToggle2 ? "no-btn no-btn--active" : "no-btn";
  }
  get yesGenderClass(): string {
    return this.yesGenderPresent ? "yes-btn yes-btn--active" : "yes-btn";
  }
  get noGenderClass(): string {
    return this.noGenderPresent ? "no-btn no-btn--active" : "no-btn";
  }


  get assignedToId(): number {
    const assignedToIdItem = this.chaseDetails.find(item => item.key === "Assigned To Id");
    if (assignedToIdItem == null) {
      return null;
    }
    return +assignedToIdItem.value;
  }

  get assignedToCurrentUser(): boolean {
    if (this.assignedToId == null) {
      return true;
    }
    return this.assignedToId === this.authService.userId;
  }

  get isCodingReviewModeDX(): boolean {
    return (
        this.chaseDetailState?.projectConfiguration?.codingReviewMode === CodingReviewMode.DIAGNOSIS ||
        this.chaseDetailState?.projectConfiguration?.codingReviewMode === CodingReviewMode.DIAGNOSIS_V2_2
    );
}


  ngOnInit() {
    this.initializeForm();
    this.sink.add(
      this.chaseDetailStateService.state.subscribe(state => {
        this.chaseDetailState = state;
      }),

      this.riskService.data
        .subscribe(riskState => {
          if (!this.isMemberPresent) {
            if (riskState.memberVerified) {

              this.memberName = riskState.memberVerified.memberName;
              this.memberDOB = riskState.memberVerified.memberDOB;
              this.memberGender = riskState.memberVerified.memberGender;

              this.yesBtnToggle = this.memberDOB;
              this.yesGenderPresent = this.memberGender;

              this.changeDetector.markForCheck();
            }
            if (this.yesBtnToggle) {
              this.noBtnToggle = false;
              this.noBtnToggle2 = false;
            }
            if (this.yesGenderPresent) {
              this.noGenderPresent = false;
            }
          }
          if (!riskState.assignedToCurrentUser) {
            this.yesBtnToggle = false;
            this.noBtnToggle = false;
            this.noBtnToggle2 = false;
            this.yesGenderPresent = false;
            this.noGenderPresent = false;
          }
        })
    );

    this.chaseDetailService.chaseDetailsChange.subscribe(chaseDetails => {
      this.chaseDetails = List(chaseDetails);
    });

    this.riskService.isMemberValidate.subscribe(data => {
      if (data) {
        this.isMemberPresent = data;
        this.isNextBtnEnabled = data;
        this.getDobDetail();
        this.getGenderDetail();
      }
      this.changeDetector.markForCheck();
    });

    this.changeDetector.markForCheck();
  }

  handleChange(event): void {
    if (this.form.valid) {
      this.onChange.emit(event);
    }
  }

  showMemberDobInput(event): void {
    this.showMemberDob = event.value.memberDobReason === DobReasonType.DOB_INCORRECT;
  }

  getDobAndGenAttributes(enable): void {
    if (enable) {
      this.getDobDetail();
      this.getGenderDetail();
    }
  }

  memberValidationSubmit(memberValidation: MemberValidation, showToast: boolean = false): void {
    this.memberValidationService.submit(memberValidation, WorkflowStatusDb[this.chaseDetailState.workflowStatus]).subscribe();
  }

  private get hasForm(): boolean {
    return this.form != null;
  }

  private initializeForm(): void {
    const dobOptions = [
      new SelectableInput({ text: "Yes", value: DobReasonType.YES }),
      new SelectableInput({ text: "No -DOB is Missing", value: DobReasonType.DOB_MISSING }),
      new SelectableInput({ text: "No -DOB is Incorrect", value: DobReasonType.DOB_INCORRECT }),
    ];

    const genderOptions = [
      new SelectableInput({ text: "Yes", value: "1" }),
      new SelectableInput({ text: "No", value: "0" }),
    ];

    this.memberDobReasonInput = new Dropdown({
      key: "memberDobReason",
      label: "Is DOB present/valid?",
      options: dobOptions,
      validators: [
        Validators.required,
      ],
      errorMessages: {
        required: "Enter a page number greater than 1",
      },
    });

    this.memberGenderReasonInput = new Dropdown({
      key: "memberGenderReason",
      label: "Is Gender Present?",
      options: genderOptions,
    });

    // SAVE GROUPS
    this.memberDobReasonSaveGroup = new SaveGroup({
      key: "memberDobReasonSaveGroup",
      isHideClearButton: true,
      isShowHighlighterClass: false,
    });
    this.setupSaveGroups({
      saveGroup: this.memberDobReasonSaveGroup,
      inputs: [this.memberDobReasonInput],
    });

    this.memberGenderReasonSaveGroup = new SaveGroup({
      key: "memberGenderReasonSaveGroup",
      isHideClearButton: true,
      isShowHighlighterClass: false,
    });
    this.setupSaveGroups({
      saveGroup: this.memberGenderReasonSaveGroup,
      inputs: [this.memberGenderReasonInput],
    });

    this.form = this.formService.createFormGroup([
      this.memberDobReasonSaveGroup,
      this.memberGenderReasonSaveGroup,
    ]);
  }

  private getDobDetail(): void {
    if (NumberHelper.isGreaterThan(this.chaseId, 0)) {
      this.chartService.dobValidationChangeObserver$.subscribe(dobReason => {
        if (dobReason.value) {
          this.setSaveInfo(dobReason, this.memberDobReasonInput);
          this.form.get(this.memberDobReasonInput.getMasterKey()).setValue(dobReason.value);

          this.yesBtnToggle = dobReason.value === "1";
          this.noBtnToggle = dobReason.value === "2";
          this.noBtnToggle2 = dobReason.value === "3";

        } else {
          this.form.get(this.memberDobReasonInput.getMasterKey()).setValue(DobReasonType.YES);
          dobReason.value = DobReasonType.YES;
          this.setSaveInfo(dobReason, this.memberDobReasonInput);
          this.chartService.save([this.memberDobReasonInput.saveInfo]);
          this.yesBtnToggle = dobReason.value === "1";
          if (this.yesBtnToggle) {
            this.noBtnToggle = false;
            this.noBtnToggle2 = false;
          }
        }
        this.chaseDetailStateService.setData({
          isCorrectDob: dobReason.value === "1" ? true : false,
        });
        this.showMemberDob = dobReason.value === DobReasonType.DOB_INCORRECT;
        this.changeDetector.markForCheck();
      });
    }
  }

  private getGenderDetail(): void {
    if (NumberHelper.isGreaterThan(this.chaseId, 0)) {
      this.chartService.genderFoundChangeObserver$.subscribe(genderReason => {
        if (genderReason.value) {
          this.setSaveInfo(genderReason, this.memberGenderReasonInput);
          this.form.get(this.memberGenderReasonInput.getMasterKey()).setValue(genderReason.value);

          this.yesGenderPresent = genderReason.value === "1";
          this.noGenderPresent = genderReason.value === "0";

        } else {
          this.form.get(this.memberGenderReasonInput.getMasterKey()).setValue("1");
          genderReason.value = "1";

          this.yesGenderPresent = genderReason.value === "1";
          if (this.yesGenderPresent) {
            this.noGenderPresent = false;
          }

          this.setSaveInfo(genderReason, this.memberGenderReasonInput);
          this.chartService.save([this.memberDobReasonInput.saveInfo]);
        }
        this.chaseDetailStateService.setData({
          isCorrectGender: genderReason.value === "1" ? true : false,
        });
        this.changeDetector.markForCheck();
      });
    }

  }

  private setDisabled(enable: boolean): void {
    // HACK: Wait for the form to exist before enabling/disabling.
    if (!this.hasForm) {
      setTimeout(() => this.setDisabled(enable), 100);
      return;
    }

    Object.keys(this.form.controls).forEach(key => {
      const control = this.form.get(key);
      if (enable) {
        control.enable();
      } else {
        control.disable();
      }
    });


    this.formService.updateDom.next();
  }

  private setupSaveGroups({
    saveGroup,
    inputs,
  }: any): void {
    saveGroup.controls = inputs;
    inputs.forEach(a => a.parent = saveGroup);
  }

  private setSaveInfo(medicalRecordDob: DynamicEntityAttribute, input): void {
    const saveInfo = { ...medicalRecordDob };
    input.saveInfo = saveInfo;
  }


  onMemberDobReasonSelection(value: string) {

    if (value === "yes") {
      this.form.get(this.memberDobReasonInput.getMasterKey()).setValue(DobReasonType.YES);

      this.chaseDetailStateService.setData({
        isCorrectDob: true,
      });
      this.showMemberDob = false;

      this.yesBtnToggle = true;
      this.noBtnToggle = false;
      this.noBtnToggle2 = false;
    }

    if (value === "dobMissing") {
      this.form.get(this.memberDobReasonInput.getMasterKey()).setValue(DobReasonType.DOB_MISSING);

      this.chaseDetailStateService.setData({
        isCorrectDob: false,
      });
      this.showMemberDob = false;

      this.yesBtnToggle = false;
      this.noBtnToggle = true;
      this.noBtnToggle2 = false;
    }

    if (value === "dobInCorrect") {
      this.form.get(this.memberDobReasonInput.getMasterKey()).setValue(DobReasonType.DOB_INCORRECT);

      this.chaseDetailStateService.setData({
        isCorrectDob: false,
      });

      this.showMemberDob = true;

      this.yesBtnToggle = false;
      this.noBtnToggle = false;
      this.noBtnToggle2 = true;
    }

    const event = new DynamicFormEvent({
      key: this.memberDobReasonSaveGroup.key,
      type: "save",
      value: this.form.value.memberDobReasonSaveGroup,
      control: {} as any,
      model: this.memberDobReasonSaveGroup as any,
    });

    this.handleChange(event);

  }

  onMemberGenderReasonSelection(value: string) {

    if (value === "yes") {
      this.form.get(this.memberGenderReasonInput.getMasterKey()).setValue("1");
      this.yesGenderPresent = true;
      this.noGenderPresent = false;
    }

    if (value === "no") {
      this.form.get(this.memberGenderReasonInput.getMasterKey()).setValue("0");

      this.yesGenderPresent = false;
      this.noGenderPresent = true;
    }

    const event = new DynamicFormEvent({
      key: this.memberGenderReasonSaveGroup.key,
      type: "save",
      value: this.form.value.memberGenderReasonSaveGroup,
      control: {} as any,
      model: this.memberGenderReasonSaveGroup as any,
    });

    this.handleChange(event);
  }

  moveToNextTab() {
    const pathUrl = this.router.routerState.snapshot.url;
    const url = this.isCodingReviewModeDX ? `${pathUrl}/dx-review` : `${pathUrl}/encounter`;
    this.router.navigate([url]);
    this.riskService.nextButton.next();
  }
}
