import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map, tap } from "rxjs/operators";
import { SubSink } from "subsink";
import { AuthService } from "../../auth/auth.service";
import { UserToken } from "../../auth/user-token.model";
import { AutomapperService } from "../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../core/environment.tokens";
import { MessagingService } from "../../core/messaging/messaging.service";
import { SeverityType } from "../../core/messaging/severity-type.enum";
import { LocalService } from "../../core/storage/local.service";
import { UserService } from "../../core/user/user.service";
import { FormService } from "../../dynamic-forms/form.service";
import { Autocomplete } from "../../dynamic-forms/inputs/autocomplete/autocomplete.model";
import { CheckboxGroup } from "../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { Dropdown } from "../../dynamic-forms/inputs/dropdown/dropdown.model";
import { DynamicInput } from "../../dynamic-forms/inputs/dynamic-input.model";
import { Radiobutton } from "../../dynamic-forms/inputs/radiobutton/radiobutton.model";
import { SelectableInput } from "../../dynamic-forms/inputs/selectable-input.model";
import { TagSearchMultiselect } from "../../dynamic-forms/inputs/tag-search-multiselect/tag-search-multiselect.model";
import { Textbox } from "../../dynamic-forms/inputs/textbox/textbox.model";
import { ChaseAssignService } from "../../platform/api/unassign-chase/chase-assign.service";
import { ClinicalPageService } from "../../platform/modules/clinical/clinical-page/clinical-page.service";
import { CLINICAL_MRR_GRID, CLINICAL_OR2_GRID, CLINICAL_OR_GRID, DASHBOARD_MY_CHASES_GRID, PLAN_LIST, PRODUCT_LIST, PROJECT_CHASE_QUERY_GRID, RETRIEVAL_DOCUMENT_QA_GRID } from "../../platform/modules/member/chase-detail/chase-detail-chart/attributes";
import { ChartService } from "../../platform/modules/member/chase-detail/chase-detail-chart/chart.service";
import { ChaseQueryService } from "../../platform/modules/project/chase-query/chase-query.service";
import { RouteFilters } from "../../platform/modules/project/chase-query/route-filters.model";
import { NewProjectService } from "../../platform/modules/project/new-project/new-project.service";
import { DirectoryUserRole } from "../../platform/modules/retrieval/directory-user-role";
import { RetrievalDocumentServiceService } from "../../platform/modules/retrieval/retrieval-document-service.service";
import { RetrievalPageService } from "../../platform/modules/retrieval/retrieval-page/retrieval-page.service";
import { ServiceOrgAttribute } from "../../platform/modules/service-org-admin/service-org-config/model/service-org-attribute.model";
import { ServiceOrgConfigurationService } from "../../platform/modules/service-org-admin/service-org-config/service-org-config.service";
import { ArrayHelper } from "../../utilities/contracts/array-helper";
import { DateHelper } from "../../utilities/contracts/date-helper";
import { DateFormats } from "../../utilities/contracts/helper-types";
import { NumberHelper } from "../../utilities/contracts/number-helper";
import { StringHelper } from "../../utilities/contracts/string-helper";
import { ActionButton } from "../../zdevcontrols/action-button/action-button.model";
import { DevControllerService } from "../../zdevcontrols/dev-controller/dev-controller.service";
import { GridCommentsComponent } from "../comments/grid-comments/grid-comments.component";
import { BulkAction } from "../grid/bulk-actions/bulk-action.model";
import { GridView } from "../grid/grid-menu/grid-views/grid-view.model";
import { GridViewsState } from "../grid/grid-menu/grid-views/grid-views-state.model";
import { GridViewsService } from "../grid/grid-menu/grid-views/grid-views.service";
import { GridPipeName } from "../grid/grid-pipe.enum";
import { GridStateService } from "../grid/grid-state.service";
import { GridColumnDefinition } from "../grid/models/grid-column-definition.model";
import { GridConfiguration } from "../grid/models/grid-configuration.model";
import { GridFilter } from "../grid/models/grid-filter.model";
import { GridRequest } from "../grid/models/grid-request.model";
import { ServerGridComponent } from "../grid/server-grid/server-grid.component";
import { LandingFilterStateService } from "../kpi/kpi-filter/landing-filter/landing-filter-state.service";
import { CreatePendService } from "../pend/create-pend.service";
import { TagType } from "../tags/model/tag-type.enum";
import { TagService } from "../tags/tag.service";
import { ChaseIdComponent } from "./chase-id.component";
import { RetrieverUpdateModel } from "./update-retriever-modal/update-retriever-model.model";

@Component({
  selector: "app-chase-grid",
  templateUrl: "./chase-grid.component.html",
  styleUrls: ["./chase-grid.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseGridComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  private user: UserToken;
  @ViewChild(ServerGridComponent, { static: true }) serverGridComponent: ServerGridComponent;
  @ViewChild("chaseIdColumn", { static: true }) chaseIdColumn: TemplateRef<ChaseIdComponent>;
  @ViewChild("commentsColumn", { static: true }) commentsColumn: TemplateRef<GridCommentsComponent>;
  @ViewChild("chaseForm") chaseForm;
  private readonly possiblePageTypes = [
    CLINICAL_MRR_GRID.attributeCode,
    CLINICAL_OR_GRID.attributeCode,
    CLINICAL_OR2_GRID.attributeCode,
    RETRIEVAL_DOCUMENT_QA_GRID.attributeCode,
    "clinical_queue_chases",
    DASHBOARD_MY_CHASES_GRID.attributeCode,
  ];
  @Input() stateName = "";
  @Input() showNextFilters = false;
  @Input() url = `${this.baseApiUrl}chase/query`;
  @Input() additionalFilters: GridFilter[] = [];
  @Input() additionalColumns: GridColumnDefinition[] = [];
  @Input() additionalBulkActions: BulkAction[] = [];
  @Input() sortDirection = 1; // -1 or 1
  @Input() sortField = "";
  @Input() chaseIdRouteUrl = "/members/chase/:chaseId";
  @Input() viewAttributeId = 0;
  @Input() saveQueryAttributeId = 0;
  @Input() selection: any[];
  @Output() selectionChange = new EventEmitter<any>();
  @Input() clinical: boolean | null;
  @Input() pageType = "";
  @Input() showGridHeader = false;
  refreshGrid = new EventEmitter<GridConfiguration>(true);
  private readonly pageTypes = ["docqa", "mrr", "or1", "clientoverread"];

  @Input() isUnassignEnabled = false;
  @Input() showViews = false;
  @Input() isMyQueryPage = false;
  @Input() routeParameters: RouteFilters;
  @Input() isOverreadFeedbackEmpty = true;
  refreshViews = new EventEmitter<GridView>(true);

  // Note: selectedChases is used for actions / bulk actions.
  isRetrieverModalVisible = false;
  selectedChases: any[];
  isAssignToChaseModalVisible = false;
  isPendModalVisible = false;
  isCopyChartModelVisible = false;
  isLandingFilterApplied = false;
  configuration: GridConfiguration;
  request: GridRequest;
  views: GridViewsState;
  actions: BulkAction[];
  threeDotActions: BulkAction[];
  tagType = TagType.CHASE;
  isManageTagModalVisible = false;
  totalEntityCount: number;
  entityName: string;
  allAvailableTags: SelectableInput[];
  selectedObjectId: number;
  filterHeaderText = "Chase Query";
  overlayPanelVisible = false;
  isShowCloseIcon = false;
  defaultTagSearchOperator = "OR";
  selectedTagSearchOperator: string;
  selectedChasesStatus: any[];
  hasClinicalNonClinicalChases = false;
  retrieverUpdateModel: RetrieverUpdateModel;
  productSelected = false;
  searchTextChanged = new Subject<string>();
  selectedChaseIdsForAssignment: number[];
  @Input() showSaveQuery = false;
  @Output() isSavedQueryDeleted = new EventEmitter<boolean>();
  @Output() isSavedQueryUpdated = new EventEmitter<number>();
  get totalChases(): number {
    return ArrayHelper.isAvailable(this.serverGridComponent.data) ? this.serverGridComponent.data[0].chaseCount : 0;
  }

  get chaseIdInput(): Textbox {
    return this.getInput("ChaseIdAndClientChaseKey");
  }

  get providersInput(): Textbox {
    return this.getInput("ServiceProviders");
  }

  get measuresInput(): CheckboxGroup {
    return this.getInput("MeasuresCodes");
  }
  set measuresInput(value: CheckboxGroup) {
    this.setInput("MeasuresCodes", value);
  }

  get clientInput(): Autocomplete {
    return this.getInput("ClientId");
  }
  set clientInput(value: Autocomplete) {
    this.setInput("ClientId", value);
  }

  get projectsInput(): CheckboxGroup {
    return this.getInput("Projects");
  }
  set projectsInput(value: CheckboxGroup) {
    this.setInput("Projects", value);
  }

  get plansInput(): CheckboxGroup {
    return this.getInput("Plans");
  }
  set plansInput(value: CheckboxGroup) {
    this.setInput("Plans", value);
  }

  get productsInput(): TagSearchMultiselect {
    return this.getInput("Product");
  }
  set productsInput(value: TagSearchMultiselect) {
    this.setInput("Product", value);
  }

  get lobInput(): Dropdown {
    return this.getInput("Lob");
  }
  set lobInput(value: Dropdown) {
    this.setInput("Lob", value);
  }

  get memberFirstNameInput(): Textbox {
    return this.getInput("MemberFirstName");
  }

  get memberLastNameInput(): Textbox {
    return this.getInput("MemberLastName");
  }

  get memberDobInput(): Textbox {
    return this.getInput("MemberDob");
  }

  get memberKeyInput(): Textbox {
    return this.getInput("MemberSourceAliasID");
  }

  get addressIdInput(): Textbox {
    return this.getInput("AddressId");
  }

  get addressGroupIdInput(): Textbox {
    return this.getInput("AddressGroupId");
  }

  get pendCodesInput(): CheckboxGroup {
    return this.getInput("PendCodes");
  }
  set pendCodesInput(value: CheckboxGroup) {
    this.setInput("PendCodes", value);
  }

  get pendStatusInput(): CheckboxGroup {
    return this.getInput("PendsStatus");
  }
  set pendStatusInput(value: CheckboxGroup) {
    this.setInput("PendsStatus", value);
  }

  get complianceInput(): CheckboxGroup {
    return this.getInput("ComplianceCodes");
  }
  set complianceInput(value: CheckboxGroup) {
    this.setInput("ComplianceCodes", value);
  }

  get sampleComplianceInput(): CheckboxGroup {
    return this.getInput("SampleComplianceCodes");
  }
  set sampleComplianceInput(value: CheckboxGroup) {
    this.setInput("SampleComplianceCodes", value);
  }

  get assignedToFilter(): GridFilter {
    return this.request.getFilter("AssignedToUserId");
  }
  get assignedToInput(): Autocomplete {
    return this.request.getInput("AssignedToUserId");
  }
  set assignedToInput(value: Autocomplete) {
    this.request.setInput("AssignedToUserId", value);
  }

  get lastCoderFilter(): GridFilter {
    return this.request.getFilter("LastCoderUserID");
  }
  get chartAcquiredFilter(): GridFilter {
    return this.request.getFilter("ChartAcquired");
  }
  get lastCoderInput(): Autocomplete {
    return this.request.getInput("LastCoderUserID");
  }
  set lastCoderInput(value: Autocomplete) {
    this.request.setInput("LastCoderUserID", value);
  }

  get chartAcquiredInput(): Textbox {
    return this.request.getInput("ChartAcquired");
  }

  set chartAcquiredInput(value: Textbox) {
    this.request.setInput("ChartAcquired", value);
  }

  get statusesInput(): CheckboxGroup {
    return this.getInput("Statuses");
  }
  set statusesInput(value: CheckboxGroup) {
    this.setInput("Statuses", value);
  }

  get dateAssignedInput(): Textbox {
    return this.getInput("DateAssigned");
  }

  // TODO: Need to create a method to get a list of filters for filters that have more than one input.
  get showRiskFilter(): boolean {
    const filter = this.request.getFilter("encounterFound");
    return filter.show;
  }
  get showCoRetrievalOwnerFilter(): boolean {
    const filter = this.request.getFilter("coRetrievalOwner");
    return filter.show;
  }


  get encounterFoundInput(): Radiobutton {
    return this.getInput("encounterFound");
  }
  set encounterFoundInput(value: Radiobutton) {
    this.setInput("encounterFound", value);
  }

  get hccDiscrepencyInput(): Radiobutton {
    return this.getInput("hccDiscrepency");
  }
  set hccDiscrepencyInput(value: Radiobutton) {
    this.setInput("hccDiscrepency", value);
  }

  get pursuitInput(): Radiobutton {
    return this.getInput("pursuit");
  }
  get tagsInputFilter(): GridFilter {
    return this.request.getFilter("TagIdsAsCsv");
  }
  get tagsInput(): TagSearchMultiselect {
    return this.getInput("TagIdsAsCsv");
  }

  set tagsInput(value: TagSearchMultiselect) {
    this.setInput("TagIdsAsCsv", value);
  }

  get tagsSearchOperatorInput(): Dropdown {
    return this.getInput("TagSearchOperator");
  }

  set tagsSearchOperatorInput(value: Dropdown) {
    this.setInput("TagSearchOperator", value);
  }
  get coRetrievalOwnerInput(): Radiobutton {
    return this.getInput("coRetrievalOwner");
  }
  get dvaEventLoadedInput(): Radiobutton {
    return this.getInput("DvaEventLoaded");
  }
  get eveHitsInput(): Dropdown {
    return this.getInput("NlpEventFound");
  }

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly automapper: AutomapperService,
    private readonly devService: DevControllerService,
    private readonly chaseQueryService: ChaseQueryService,
    private readonly clinicalPageService: ClinicalPageService,
    private readonly retrievalPageService: RetrievalPageService,
    private readonly createPendService: CreatePendService,
    private readonly userService: UserService,
    private readonly messagingService: MessagingService,
    private readonly gridStateService: GridStateService,
    private readonly chartService: ChartService,
    private readonly retrievalDocumentService: RetrievalDocumentServiceService,
    private readonly formService: FormService,
    private readonly landingFilterStateService: LandingFilterStateService,
    private readonly chaseAssignService: ChaseAssignService,
    private gridViewsService: GridViewsService,
    private cd: ChangeDetectorRef,
    private authService: AuthService,
    private serviceOrgConfigurationService: ServiceOrgConfigurationService,
    private readonly tagService: TagService,
    private newProjectService: NewProjectService,
    private session: LocalService,
    private router: Router
  ) { }

  ngOnInit() {
    this.user = this.userService.getUserToken();
    this.devService.push([new ActionButton({ name: "Refresh Grid", action: () => this.refreshGrid.emit() })]);
    this.createGrid();
    this.getAllSelectableInputs();
    if (!this.isMyQueryPage) {
      this.showFiltersOrGetData();
    }
    this.sink.add(
      this.refreshViews.subscribe(gridView => this.getViews(gridView)),
      this.searchTextChanged
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          tap(searchText => this.getAllTagsList(searchText)))
        .subscribe()

    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  openAssignToChaseModal = (rowData: any): void => {
    this.setSelectedChases(rowData);
    this.isAssignToChaseModalVisible = true;
  }

  openPendModal = (rowData: any): void => {
    this.setSelectedChases(rowData);
    this.checkChasesStatus();
    this.isPendModalVisible = true;
  }

  exportChases = (rowData: any): void => {
    this.setSelectedChases(rowData);
    this.serverGridComponent.exportGridData();
  }
  submitChartDownloadRequest = (rowData: any): void => {
    const rowChase = ArrayHelper.isAvailable(rowData) ? rowData : [rowData];
    rowChase.forEach((data: any) => {
      this.downloadSelectedChaseChart(data);
    });
    this.closeModal();
  }

  downloadSelectedChaseChart = (rowData: any): void => {
    if (rowData.chaseDocumentPageCount > 0) {
      this.retrievalDocumentService.submitChartDownloadRequest(rowData.chaseId)
        .subscribe((result: boolean) => {
          if (result) {
            this.messagingService.showToast("Your request is being processed and you will be alerted when the file is ready for download", SeverityType.SUCCESS);
          } else {
            this.messagingService.showMessage("Your Chart download request has failed.", SeverityType.ERROR);
          }
        },         err => {
          this.messagingService.showToast("Document Pages are not available for download.", SeverityType.WARN);
        });
    } else {
      this.messagingService.showToast("Chart not available for download.", SeverityType.ERROR);
    }
  }

  closeModal(): void {
    this.selectedChases = [];
    this.selectedChaseIdsForAssignment = [];
    this.selection = [];
  }

  openCopyChartModel = (rowData: any): void => {
    this.setSelectedChases(rowData);
    this.isCopyChartModelVisible = true;
  }

  getInput<T extends DynamicInput>(key: string): T {
    if (this.request == null) {
      return null;
    }

    return this.request.getInput<T>(key);
  }

  setInput<T extends DynamicInput>(key: string, value: T): void {
    if (this.request == null) {
      return null;
    }

    this.request.setInput<T>(key, value);
  }

  getValue(key: string): string {
    if (this.request == null) {
      return "";
    }

    const filter = this.request.getFilter(key);
    return filter.value;
  }

  setValue(key: string, value: string): void {
    if (this.request == null) {
      return;
    }

    const filter = this.request.getFilter(key);
    filter.value = value;
  }

  formatMemberDobDate(): void {
    const dobFilter = this.request.getFilter("MemberDob");
    const formattedValue = DateHelper.format(dobFilter.value);
    dobFilter.value = formattedValue;
    this.formService.updateDom.next();
  }

  formatDateAssigned(): void {
    const dateAssignedFilter = this.request.getFilter("DateAssigned");
    const formattedValue = DateHelper.format(dateAssignedFilter.value);
    dateAssignedFilter.value = formattedValue;
    this.formService.updateDom.next();
  }

  updateGridConfiguration(alreadyInitialized = true) {
    // HACK: Chase Query uses this method to update the columns
    this.configuration = new GridConfiguration({
      isInit: alreadyInitialized,
      stateName: this.stateName,
      isStateDisabled: this.hasRouteParamaters,
      columns: this.getColumns(),
      pageSize: 25,
      pageSizeOptions: [10, 25, 50, 100],
      selectionMode: "multiple",
      showViews: this.showViews,
      viewAttributeId: this.viewAttributeId,
      showSaveQuery: this.showSaveQuery,
      saveQueryAttributeId: this.saveQueryAttributeId,
      showGridHeader: this.showGridHeader,
    });
  }

  createGrid(): void {
    this.updateGridConfiguration(false);
    this.configuration.isStateDisabled = this.hasRouteParamaters;

    this.request = new GridRequest({
      url: this.url,
      sortDirection: this.sortDirection,
      sortField: this.sortField,
      filters: this.getFilters(),
    });

    this.getViews();

    this.actions = this.getActions();
    this.threeDotActions = this.getActions();
    const index = this.threeDotActions.findIndex(x => x.name === "Export Chase(s)");
    this.threeDotActions.splice(index, 1);
  }

  private getColumns(): GridColumnDefinition[] {
    const defaultColumns = [
      new GridColumnDefinition({ field: "chaseId", template: this.chaseIdColumn, header: "Chase ID" }),
      new GridColumnDefinition({ field: "chaseSourceAliasId", header: "Chase Key" }),
      new GridColumnDefinition({ field: "measureCode", header: "Measure" }),
      new GridColumnDefinition({ field: "clientName", header: "Client" }),
      new GridColumnDefinition({ field: "projectName", header: "Project" }),
      new GridColumnDefinition({ field: "plan", header: "Health Plan" }),
      new GridColumnDefinition({ field: "product", header: "Product" }),
      new GridColumnDefinition({ field: "memberSourceAliasId", header: "Member Key" }),
      new GridColumnDefinition({ field: "memberFirstName", header: "First Name" }),
      new GridColumnDefinition({ field: "memberLastName", header: "Last Name" }),
      new GridColumnDefinition({ field: "memberDateOfBirth", header: "DOB" }),
      new GridColumnDefinition({ field: "masterDocumentSourceId", header: "AID", routeUrl: "/retrieval/addressdetail/:masterDocumentSourceId" }),
      new GridColumnDefinition({ field: "addressGrouping", header: "AID Group" }),
      new GridColumnDefinition({ field: "groupName", header: "Group Name" }),
      new GridColumnDefinition({ field: "serviceProviders", header: "Provider" }),
      new GridColumnDefinition({ field: "tin", header: "TIN" }),
      new GridColumnDefinition({ field: "tagsText", header: "Tags" }),
      new GridColumnDefinition({ field: "assignedTo", header: "Assigned To" }),
      new GridColumnDefinition({ field: "reportingStatusName", header: "Status" }),
      new GridColumnDefinition({ field: "chaseDocumentPageCount", header: "Pages" }),
      new GridColumnDefinition({ field: "chartAcquired", header: "Chart Acquired", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
      new GridColumnDefinition({ field: "pendCode", header: "Pend Code" }),
      new GridColumnDefinition({ field: "pendStatus", header: "Pend Status" }),
      new GridColumnDefinition({ field: "lastCoderUserName", header: "Last Coded By" }),
      new GridColumnDefinition({
        field: "dateAssigned", header: "Date Assigned", pipeName: GridPipeName.Date,
        format: this.showSaveQuery ? DateFormats.GRID_DATE_FORMAT : "MM-dd-yyyy hh:mm a", timeZone: "local",
      }),
      new GridColumnDefinition({ field: "chaseComplianceCode", header: "Chase Compliance", show: false }),
      new GridColumnDefinition({ field: "sampleComplianceCode", header: "Sample Compliance" }),
      new GridColumnDefinition({ field: "encounterFound", header: "DOS Confirmed", show: false }),
      new GridColumnDefinition({ field: "hccDiscrepency", header: "HCC Discrepancy", show: false }),
      new GridColumnDefinition({ field: "enrollmentDate", header: "Enrollment Date" }),
      new GridColumnDefinition({ field: "chaseCommentFormatted", header: "Comments", template: this.commentsColumn, showTitle: false }),
      new GridColumnDefinition({ field: "imageName", header: "Image Name" }),
      new GridColumnDefinition({ field: "dvaEventLoaded", header: "DVA Event Loaded"}),
      new GridColumnDefinition({ field: "nlpEventFound ", header: "Eve Hits"}),
      new GridColumnDefinition({ field: "hccValueCombined", header: "RAF Score"}),
      new GridColumnDefinition({ field: "openHccCount", header: "Open HCCs"}),
    ];

    this.additionalColumns.forEach(a => {
      const matchedIndex = defaultColumns.findIndex(b => b.field === a.field);
      if (matchedIndex < 0) {
        defaultColumns.push(a); // add
      } else {
        defaultColumns.splice(matchedIndex, 1, a); // replace
      }
    });

    return defaultColumns;
  }

  private getFilters(): GridFilter[] {
    const memberValidator = [
      Validators.minLength(2),
      Validators.maxLength(100),
    ];
    const memberErrorMessage = {
      minlength: `Minimum 2 characters and Maximum 100 characters allowed`,
      maxlength: `Minimum 2 characters and Maximum 100 characters allowed`,
    };

    const landingFilter = this.landingFilterStateService.get();
    if (NumberHelper.isAvailable(landingFilter.clientId) || NumberHelper.isAvailable(landingFilter.projectId)) {

      this.isLandingFilterApplied = true;
    }
    this.landingFilterStateService.clear();
    const totalFilters = [
      ...this.additionalFilters,
      new GridFilter({
        input: new Textbox(),
        key: "ChaseIdAndClientChaseKey",
        name: "Chase ID / Client Chase Key",
      }),
      new GridFilter({
        input: new CheckboxGroup(),
        key: "MeasuresCodes",
        name: "Measures",
        value: this.getRouteParamterValue("measures"),
      }),
      new GridFilter({
        input: new Autocomplete({ placeholder: "Select..." }),
        key: "ClientId",
        name: "Client",
        value: (landingFilter.clientId || "").toString(),
      }),
      new GridFilter({
        input: new CheckboxGroup(),
        key: "Projects",
        value: (landingFilter.projectId || this.getRouteParamterValue("projectIds")).toString(),
      }),
      new GridFilter({
        input: new Dropdown({ placeholder: "Select Line Of Business..." }),
        key: "Lob",
        name: "Line Of Business",
      }),
      new GridFilter({
        input: new TagSearchMultiselect({ placeholder: "Select Product list..." }),
        key: "Product",
        name: "Products",
        value: landingFilter.product,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "ServiceProviders",
        name: "Providers",
      }),
      new GridFilter({
        input: new Textbox(),
        key: "MemberFirstName",
        name: "First Name",
        validators: memberValidator,
        errorMessages: memberErrorMessage,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "MemberLastName",
        name: "Last Name",
        validators: memberValidator,
        errorMessages: memberErrorMessage,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "MemberDob",
        name: "DOB",
      }),
      new GridFilter({
        input: new Textbox(),
        key: "MemberSourceAliasID",
        name: "Member Key",
      }),
      new GridFilter({
        input: new Textbox(),
        key: "AddressId",
        name: "AID",
      }),
      new GridFilter({
        input: new Textbox(),
        key: "AddressGroupId",
        name: "AID Group",
      }),
      new GridFilter({
        input: new CheckboxGroup({showTooltip: true}),
        key: "PendCodes",
        name: "Pend Codes",
      }),
      new GridFilter({
        input: new CheckboxGroup(),
        key: "PendsStatus",
        name: "Pend Status",
        value: this.getRouteParamterValue("pendStatuses"),
      }),
      new GridFilter({
        input: new CheckboxGroup(),
        key: "ComplianceCodes",
        name: "Chase Compliance",
        value: this.getRouteParamterValue("chaseCompliances"),
      }),
      new GridFilter({
        input: new CheckboxGroup(),
        key: "SampleComplianceCodes",
        name: "Sample Compliance",
        value: this.getRouteParamterValue("sampleCompliances"),
      }),
      new GridFilter({
        input: new Autocomplete({ placeholder: "Select User..." }),
        key: "AssignedToUserId",
        name: "Assigned To",
      }),
      new GridFilter({
        input: new Autocomplete({ placeholder: "Select User..." }),
        key: "LastCoderUserID",
        name: "Last Coded By",
        value: this.getRouteParamterValue("lastCodedBy"),
      }),
      new GridFilter({
        input: new CheckboxGroup(),
        key: "Statuses",
        name: "Status",
        value: this.getRouteParamterValue("chaseStatuses"),
      }),
      new GridFilter({
        input: new Textbox(),
        key: "DateAssigned",
        name: "Date Assigned",
      }),
      new GridFilter({
        input: new Radiobutton(),
        key: "encounterFound",
        name: "DOS Confirmed",
        show: false,
      }),
      new GridFilter({
        input: new Radiobutton(),
        key: "hccDiscrepency",
        name: "Hcc Discrepency",
        show: false,
      }),
      new GridFilter({
        input: new Radiobutton({
          options: [
            new SelectableInput({ text: "Parent Chases", value: "ParentChase" }),
            new SelectableInput({ text: "Pursuit Chases", value: "PursuitChase" }),
            new SelectableInput({ text: "Show All", value: "All" }),
            new SelectableInput({ text: "Exclude", value: "" }),
          ],
        }),
        key: "pursuit",
        name: "Pursuits",
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "ChartAcquired",
        value: null,
        name: "Chart Acquired Date",
        show: false,
      }),
      new GridFilter({
        input: new TagSearchMultiselect({ placeholder: "Tags" }),
        key: "TagIdsAsCsv",
        name: "Tags",
        show: true,
      }),
      new GridFilter({
        input: new Dropdown(),
        key: "TagSearchOperator",
        value: StringHelper.isAvailable(this.selectedTagSearchOperator) && ArrayHelper.isAvailable(this.tagsInput.selectedOptions)
          ? this.selectedTagSearchOperator : null,
        showChip: false,
      }),
      new GridFilter({
        input: new Radiobutton({
          options: [
            new SelectableInput({ text: "Reveleer", value: "Reveleer" }),
            new SelectableInput({ text: "Client", value: "Client" }),
          ],
        }),
        key: "coRetrievalOwner",
        name: "Co Retrieval Owner",
        show: false,
      }),
      new GridFilter({
        input: new CheckboxGroup(),
        key: "Plans",
        name: "Plans List",
      }),
      new GridFilter({
        input: new Radiobutton({
          options: [
            new SelectableInput({ text: "Yes", value: "Yes" }),
            new SelectableInput({ text: "No", value: "No" }),
            new SelectableInput({ text: "All", value: "All" }),
          ],
        }),
        key: "DvaEventLoaded",
        name: "Data Validation Audit Event Loaded",
      }),
      new GridFilter({
        input: new Dropdown({
        key: "auditReady",
        appendTo: "body",
        autoDisplayFirst: false,
        placeholder: "Select...",
        options: [
        new SelectableInput({ text: "Yes", value: "yes", extra: true }),
        new SelectableInput({ text: "No", value: "no", extra: false }),
      ],
        }),
        key: "NlpEventFound",
        name: "Show Eve Hits",
      }),
    ];
    const distinctFilters = totalFilters.filter((filter, i, filters) => {
      return filters.findIndex(a => a.key === filter.key) === i;
    });

    distinctFilters.map(filter => {
      if (filter.key === "hccDiscrepency" || filter.key === "encounterFound") {
        return new Radiobutton({
          ...filter.input,
          options: (filter.input as Radiobutton).options.map(option => {
            option.value = option.text === "Yes" ? "Yes" : option.text === "No" ? "No" : "All";
            return option;
          }),
        });
      }
    });
    return distinctFilters;
  }

  private getViews(gridView: GridView | null = null): void {
    if (this.configuration?.showViews) {
      this.gridViewsService.get(this.configuration.viewAttributeId).subscribe(views => {
        this.views = views;

        if (gridView != null) {
          this.serverGridComponent.onViewSelect.emit(gridView);
        }

        this.cd.markForCheck();
      });
    }
  }

  private getActions(): BulkAction[] {
    const totalBulkActions = [
      ...this.additionalBulkActions,
      new BulkAction({
        name: "Export Chase(s)",
        action: this.exportChases,
        alwaysEnabled: true,
        showBulkAction: false,
      }),
      new BulkAction({
        name: "Pend Chase(s)",
        action: this.openPendModal,
      }),
      new BulkAction({
        name: "Assign Chase(s)",
        action: this.openAssignToChaseModal,
      }),
      new BulkAction({
        name: "Unassign Chase(s)",
        action: this.unassignToChases.bind(this),
      }),
      new BulkAction({
        name: "Download Chart",
        action: this.submitChartDownloadRequest,
        showBulkAction: false,
      }),
      new BulkAction({
        name: "Copy Chart",
        action: this.openCopyChartModel,
        showBulkAction: false,
      }),
      // new BulkAction({
      //  name: "Manage Tags",
      //  action: this.openManageTagModal,  /*TODO: This will be a part of next release*/
      //  disabled: this.hasTagManagement,
      // }),
    ];
    if (this.user.isCoRetrievalEnabled && this.user.isAdminRole && StringHelper.isAvailable(this.stateName) && this.stateName === PROJECT_CHASE_QUERY_GRID.attributeCode) {
      totalBulkActions.push(
        new BulkAction({
          name: "Change Retriever",
          action: this.openChangeRetrieverModal.bind(this),
        })
      );
    }
    const distinctBulkActions = totalBulkActions.filter((filter, i, filters) => {
      return filters.findIndex(a => a.name === filter.name) === i;
    });

    return distinctBulkActions;
  }


  isActionDisabled(rowData: any, actionName: string): boolean {
    if (this.isClientLeadRole && (actionName === "Assign Chase(s)" || actionName === "Unassign Chase(s)")) {
      return rowData.workflowStatusName !== "Overread2";
    }

    return false;
  }

  hasValidPageType(value: string): boolean {
    return StringHelper.isAvailable(value) && this.possiblePageTypes.includes(value);
  }

  showFiltersOrGetData(): void {
    let filters = [];
    if (this.isLandingFilterApplied) {
      this.gridStateService.delete(this.stateName);
    }

    if (StringHelper.isAvailable(this.stateName) && this.gridStateService.hasKey(this.stateName) && !this.hasRouteParamaters) {
      const gridState = this.gridStateService.get(this.stateName);
      filters = gridState.request.filters.filter(filter => filter.input.value);
    } else {
      filters = this.request.filters.filter(filter => StringHelper.isAvailable(filter.input.value));
    }

    if (this.hasValidPageType(this.stateName)) {
      if (filters.length > 1) {
        setTimeout(() => this.refreshGrid.emit());
      } else {
        this.serverGridComponent.showFilters();
        this.serverGridComponent.data = [];
      }
    } else {
      if (filters.length > 0) {
        setTimeout(() => this.refreshGrid.emit());
      }

      if (history.state.isNavigationLink) {
        this.serverGridComponent.showFilters();
      }
    }
    const navigated = this.session.get("isFromMyQueriesPage", false);
    if (navigated) {
      this.session.delete("isFromMyQueriesPage");
      this.serverGridComponent.showFilters();
    }
  }

  getAllSelectableInputs(): void {
    this.getProjects();
    this.getMeasures();
    this.getStatuses();
    this.getComplianceCodes();
    this.getClients();
    this.getProducts();
    this.getLineOfBusiness();
    this.getPendCodes();
    this.getPlans();
    this.getTagSearchOperator();
    this.getUsersToFilter();
    if (this.isMyQueryPage) {
      this.getAllTagsList("");
    }
  }
  getLineOfBusiness() {
    this.newProjectService
      .getLineOfBusiness()
      .subscribe(options => {
        this.lobInput = new Dropdown({ ...this.lobInput, options } as any);
      });


  }
  private getProjects(): void {
    this.retrievalPageService
      .getProjectList()
      .pipe(map(this.automapper.curryMany("LookupModel", "SelectableInput")))
      .subscribe(options => {
        this.projectsInput = { ...this.projectsInput, options } as any;
        this.setProjectsInput();
        this.setControlValue(this.projectsInput, false);
        this.formService.updateDom.next();
      });
  }

  private setProjectsInput(): void {
    const projectFilter = this.request.getFilter(this.projectsInput.key);
    if (StringHelper.isAvailable(projectFilter.value)) {
      const optionsValue = this.projectsInput.options.find(a => a.value === +projectFilter.value);
      projectFilter.inputValue = [optionsValue];
    }
  }

  private getMeasures(): void {
    this.clinicalPageService
      .getMeasuresList()
      .pipe(map(this.automapper.curryMany("ClinicalMeasureListItem", "SelectableInput")))
      .subscribe(options => {
        this.measuresInput = { ...this.measuresInput, options } as any;
        this.setControlValue(this.measuresInput, false);
        this.formService.updateDom.next();
      });
  }

  private getStatuses(): void {
    this.chaseQueryService
      .getReportingStatusesList()
      .pipe(map(this.automapper.curryMany("ChaseReportingStatusModel", "SelectableInput")))
      .subscribe(options => {
        this.statusesInput = { ...this.statusesInput, options } as any;
        this.setControlValue(this.statusesInput);
        this.formService.updateDom.next();
      });
  }

  private getPendCodes(): void {
    const isAllPendCodeFilterRequired = this.isPageTypeExist || this.isAssignmentPage ? null : this.clinical;
    this.createPendService
      .getPendDropdown(isAllPendCodeFilterRequired)
      .subscribe(options => {
        const pendCodes = options?.pendCodes.map(item => new SelectableInput({
          text: `${item.displayName.split("-")[0]} - ${item.displayName.split("-")[1]}`,
          value: item.displayName.split("-")[0],
          extra: item.description,
        }));
        this.pendCodesInput = { ...this.pendCodesInput, options: pendCodes } as any;

        const pendStatuses = options.pendStatus.map(item => new SelectableInput({
          text: item.description,
          value: item.description,
        }));
        this.pendStatusInput = { ...this.pendStatusInput, options: pendStatuses } as any;
        this.setControlValue(this.pendStatusInput);

        this.formService.updateDom.next();
      });
  }

  private setControlValue(control: CheckboxGroup, isStringValue = true): void {
    const filter = this.request.getFilter(control.key);

    if (StringHelper.isAvailable(filter.input.value)) {
      const statuses = filter.input.value.split(",");
      statuses.forEach(item => {
        if (ArrayHelper.isAvailable(control.options)) {
          filter.inputValue.push(control.options.find(a => a.value === (isStringValue ? item : +item)));
        }
      });
      control.selectedOptions = filter.inputValue;
    }
  }

  private getUsersToFilter(): void {
    this.userService
      .getUsersWithQuickOptions()
      .pipe(
        map(this.automapper.curryMany("UserModel", "SelectableInput")),
        tap(clients => clients.unshift(new SelectableInput({ text: "*clear filter", value: "" })))
      )
      .subscribe(options => {
        this.assignedToInput = { ...this.assignedToInput, options } as any;
        this.lastCoderInput = { ...this.lastCoderInput, options } as any;
        this.setLastCoderInput();
        this.formService.updateDom.next();
      });
  }

  private setLastCoderInput(): void {
    const clientFilter = this.request.getFilter(this.lastCoderInput.key);
    if (StringHelper.isAvailable(clientFilter.value)) {
      const optionsValue = this.lastCoderInput.options.find(a => a.value === +clientFilter.value);
      clientFilter.inputValue = [optionsValue];
    }
    this.chaseForm.form.get(this.lastCoderInput.key).setValue(clientFilter.inputValue[0]);
  }

  private getClients(): void {
    this.userService
      .getClients()
      .pipe(
        map(this.automapper.curryMany("ClientOrgModel", "SelectableInput")),
        tap(clients => clients.unshift(new SelectableInput({ text: "*clear filter", value: "" })))
      )
      .subscribe(options => {
        this.clientInput = { ...this.clientInput, options } as any;
        this.setClientInput();
        this.formService.updateDom.next();
      });
  }


  private setClientInput(): void {

    const clientFilter = this.request.getFilter(this.clientInput.key);
    if (StringHelper.isAvailable(clientFilter.value)) {
      const optionsValue = this.clientInput.options.find(a => a.value === +clientFilter.value);
      clientFilter.inputValue = [optionsValue];

      this.formService.updateDom.next();
    }
  }

  private getComplianceCodes(): void {
    this.chartService.getComplianceCodes()
      .subscribe(options => {
        this.complianceInput = { ...this.complianceInput, options } as any;
        this.sampleComplianceInput = { ...this.sampleComplianceInput, options } as any;
        this.setControlValue(this.complianceInput);
        this.setControlValue(this.sampleComplianceInput);
        this.formService.updateDom.next();
      });
  }

  private setSelectedChases(rowData: any): void {
    this.selectedChases = ArrayHelper.isAvailable(rowData) ? rowData : [rowData];
    this.selectedChaseIdsForAssignment = this.chaseAssignService.getSelectedChaseIds(this.selectedChases);
  }

  unassignToChases(rowData: any): void {
    this.setSelectedChases(rowData);

    const chaseStatus = this.selectedChases.map(item => item.workflowStatusName)
      .filter((value, index, self) => self.indexOf(value) === index);
    if (chaseStatus.length > 1 && this.stateName === "project_chasequery") {
      this.messagingService.showToast("Chases for this assignment may not be at different workflow statuses", SeverityType.ERROR);
      this.selectedChases = [];
    } else {
      const unassignedUserId = 0;
      this.chaseAssignService.assignChasesToUser(this.chaseAssignService.getSelectedChaseIds(this.selectedChases), unassignedUserId, null)
        .subscribe(() => {
          this.messagingService.showToast("Unassigned Successfully.", SeverityType.SUCCESS);
          this.closeModal();
          this.refreshGrid.emit();
        });
    }
  }

  get isClientLeadRole(): boolean {
    return this.authService.user.directoryRoleIds.indexOf(DirectoryUserRole.ClientLead) > -1;
  }

  get isClinicalLeadRole(): boolean {
    return this.authService.user.directoryRoleIds.indexOf(DirectoryUserRole.ClinicalLead) > -1;
  }

  get hasRouteParamaters(): boolean {
    if (this.routeParameters == null) {
      return false;
    }
    const parametersWithValues = Object.values(this.routeParameters).filter(x => StringHelper.isAvailable(x));
    return ArrayHelper.isAvailable(parametersWithValues);
  }

  private getRouteParamterValue(name: string): string {
    return this.hasRouteParamaters ? this.routeParameters[name] : "";
  }

  private getProducts(): void {
    this.serviceOrgConfigurationService
      .getServiceOrgConfigurationByAttribute(PRODUCT_LIST.attributeId)
      .subscribe(configuration => {
        this.getProductsInput(configuration);
        this.setProductsInput();
        this.formService.updateDom.next();
        this.cd.markForCheck();
      });
  }

  private getProductsInput(configuration: ServiceOrgAttribute): void {
    const productAttribute = JSON.parse(configuration.attributeValue);
    const productOptions = productAttribute.Products.map(item => new SelectableInput({
      text: item.Name,
      value: item.Name,
    }));
    this.productsInput = new TagSearchMultiselect({ ...this.productsInput, options: productOptions } as any);
  }

  private setProductsInput(): void {
    const productFilter = this.request.getFilter(this.productsInput.key);
    if (StringHelper.isAvailable(productFilter.value)) {
      const optionsValue = this.productsInput.options.find(a => a.value === productFilter.value);
      productFilter.inputValue = [optionsValue];
      this.formService.updateDom.next();
    }
  }

  private getPlans(): void {
    this.serviceOrgConfigurationService
      .getServiceOrgConfigurationByAttribute(PLAN_LIST.attributeId)
      .subscribe(configuration => {
        this.getPlansInput(configuration);
        this.setPlansInput();
        this.formService.updateDom.next();
        this.cd.markForCheck();
      });
   }

  private getPlansInput(configuration: ServiceOrgAttribute): void {
    const plansAttribute = JSON.parse(configuration.attributeValue);
    const plansOptions = plansAttribute.Plans.map(item => new SelectableInput({
      text: item.Name,
      value: item.Name,
    }));
    this.plansInput = { ...this.plansInput, options: plansOptions } as any;
  }

  private setPlansInput(): void {
    const plansFilter = this.request.getFilter(this.plansInput.key);
    if (StringHelper.isAvailable(plansFilter.value)) {
      const optionsValue = this.plansInput.options.find(a => a.value === +plansFilter.value);
      plansFilter.inputValue = [optionsValue];
      this.formService.updateDom.next();
    }
  }

  trackByIndex(index, item) {
    return index;
  }

  openManageTagModal = (rowData: any): void => {
    this.setSelectedChases(rowData);
    if (ArrayHelper.isAvailable(this.selectedChases)) {
      this.totalEntityCount = this.selectedChases.length;
      this.entityName = "CHASE";
      this.isManageTagModalVisible = true;
    } else {
      this.messagingService.showToast("Chase(s) not in right status to be actioned upon.", SeverityType.WARN);
    }
  }

  onKeyUp(event): void {
    if (!this.isMyQueryPage) {
      this.searchTextChanged.next(event.target.value);
    }
  }

  getAllTagsList(searchText: string): void {
    this.allAvailableTags = [];
    this.tagService.getAllTagsList(this.tagType, null, searchText)
      .subscribe(data => {
        this.allAvailableTags = data;
        this.tagsInput.useFilter = false;
        this.tagsInput.options = [...this.allAvailableTags];
        this.cd.markForCheck();
      });
  }

  productSelection(): void {
    this.productSelected = StringHelper.isAvailable(this.request.getFilter("Product").value);
  }

  onChangeObject(event): void {
    this.selectedObjectId = event;
  }

  formatChartAcquiredDate(): void {
    const chartAcquiredFilter = this.request.getFilter("ChartAcquired");
    const formattedValue = DateHelper.format(chartAcquiredFilter.value);
    chartAcquiredFilter.value = formattedValue;
    this.formService.updateDom.next();
  }

  onShowEvent(panelValue: boolean): void {
    this.overlayPanelVisible = panelValue;
    if (ArrayHelper.isAvailable(this.tagsInput.selectedOptions)) {
      this.isShowCloseIcon = true;
    }
  }

  resetTagSearchInput(): void {
    this.checkIfTagFilterValueAvailable();
    this.cd.markForCheck();
  }

  checkIfTagFilterValueAvailable(): void {
    if (ArrayHelper.isAvailable(this.tagsInput.selectedOptions)) {
      this.tagsInput.selectedOptions = [];
      this.selectedTagSearchOperator = this.defaultTagSearchOperator;
      this.tagsInput.filterPlaceHolder = "Search";
      this.isShowCloseIcon = false;
    }
  }

  getTagSearchOperator(): void {
    this.tagService
      .getTagSearchOperator()
      .subscribe(options => {
        this.tagsSearchOperatorInput = new Dropdown({ ...this.tagsSearchOperatorInput, options } as any);
        this.cd.markForCheck();
      });
  }

  getSearchOperatorValue(event: any): void {
    this.selectedTagSearchOperator = event.value;
  }


  checkChasesStatus(): void {
    this.selectedChasesStatus = this.selectedChases.map(item => item.workflowStatusId)
      .filter((value, index, self) => self.indexOf(value) === index);

    if (this.selectedChasesStatus.length > 1 && (this.selectedChasesStatus.indexOf(2) > -1 || this.selectedChasesStatus.indexOf(3) > -1)) {
      this.hasClinicalNonClinicalChases = true;
    } else if (this.selectedChasesStatus.length === 1 && (this.selectedChasesStatus.indexOf(2) > -1 || this.selectedChasesStatus.indexOf(3) > -1)) {
      this.hasClinicalNonClinicalChases = false;
      this.clinical = false;
    } else {
      this.clinical = true;
    }
  }

  openChangeRetrieverModal(rowData?: any): void {
    this.setSelectedChases(rowData);
    this.isRetrieverModalVisible = true;
    this.totalEntityCount = this.selectedChases.length;
    this.retrieverUpdateModel = new RetrieverUpdateModel({
      chaseIds: this.chaseAssignService.getSelectedChaseIds(this.selectedChases),
    });
  }

  deletedSavedQuery(value: boolean) {
    this.isSavedQueryDeleted.emit(value);
  }

  updatedSavedQuery(value: number) {
    this.isSavedQueryUpdated.emit(value);
  }

  get isPageTypeExist(): boolean {
    return StringHelper.isAvailable(this.pageType) && this.pageTypes.includes(this.pageType);
  }

  get isAssignmentPage(): boolean {
    const pathUrl = window.location.href;
    const activePath = pathUrl.substr(pathUrl.lastIndexOf("/") + 1).trim().toLowerCase();
    return activePath === "assignment";
  }
}
