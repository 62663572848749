<member-risk-read-only></member-risk-read-only>
<div class="diagnoses" *ngIf="isCodingReviewModeDX; else riskType22" >
  <div *ngIf="showAdmin">
    <div class="diagnoses-header">
      <div class="diagnoses-header__title">Admin Dx</div>
    </div>
    <div *ngFor="let icdGroup of riskData.admin; trackBy: trackByIndex; let i = index">
      <app-risk-dx-card [chaseId]="chaseId" [isReadOnly]="isReadOnly" [(icdGroup)]="icdGroup"
                        (icdGroupChange)="refreshAdminState()" [riskChaseState]="chaseDetailState" [masterEveEncounterId]="masterEveEncounterId"></app-risk-dx-card>
    </div>
  </div>
  <div class="diagnoses-eve" *ngIf="showEve">
    <div *ngFor="let icdGroup of riskData.eve; trackBy: trackByIndex; let i = index">
      <app-risk-dx-card [chaseId]="chaseId" [isReadOnly]="isReadOnly" [(icdGroup)]="icdGroup"
                        (icdGroupChange)="refreshEveState()" [riskChaseState]="chaseDetailState" [masterEveEncounterId]="masterEveEncounterId"></app-risk-dx-card>
    </div>
  </div>

  <div class="diagnoses-eve" *ngIf="showCoder || addNewCoder" >
    <div class="diagnoses-header">
      <app-icon iconName="user"></app-icon>&nbsp;
      <div class="diagnoses-header__title">Add New HCC</div>
    </div>
    <div *ngIf="addNewCoder" >
      <app-risk-dx-card [chaseId]="chaseId" [masterEveEncounterId]="masterEveEncounterId" 
      [isReadOnly]="isReadOnly" [encounterId]="encounterId" [addNewCoder]="addNewCoder"
       (hideNetNewHccCard)="hideNetNewHccCard()"    (icdGroupChange)="refreshEveState()" [riskChaseState]="chaseDetailState"></app-risk-dx-card>
    </div>
  </div>



  
</div>
<ng-template #riskType22>
<div class="diagnoses" >
  <div *ngIf="showAdmin">
    <div class="diagnoses-header">
      <div class="diagnoses-header__title">Admin Dx</div>
    </div>
    <div *ngFor="let icdGroup of riskData.admin; trackBy: trackByIndex; let i = index">
      <app-risk-dx-admin-card [chaseId]="chaseId" [isReadOnly]="isReadOnly" [(icdGroup)]="icdGroup"
                        (icdGroupChange)="refreshAdminState()" [riskChaseState]="chaseDetailState" [masterEveEncounterId]="masterEveEncounterId"></app-risk-dx-admin-card>
    </div>
  </div>
  <div class="diagnoses-eve" *ngIf="showEve">
    <div *ngFor="let icdGroup of riskData.eve; trackBy: trackByIndex; let i = index">
      <app-risk-dx-admin-card [chaseId]="chaseId" [isReadOnly]="isReadOnly" [(icdGroup)]="icdGroup"
                        (icdGroupChange)="refreshEveState()" [riskChaseState]="chaseDetailState" [masterEveEncounterId]="masterEveEncounterId"></app-risk-dx-admin-card>
    </div>
  </div>

  <div class="diagnoses-eve" *ngIf="showCoder || addNewCoder" >
    <div class="diagnoses-header">
      <app-icon iconName="user"></app-icon>&nbsp;
      <div class="diagnoses-header__title">Add New HCC</div>
    </div>
    <div *ngIf="addNewCoder" >
      <app-risk-dx-admin-card [chaseId]="chaseId" [masterEveEncounterId]="masterEveEncounterId" 
      [isReadOnly]="isReadOnly" [encounterId]="encounterId" [addNewCoder]="addNewCoder"
       (hideNetNewHccCard)="hideNetNewHccCard()"    (icdGroupChange)="refreshEveState()" [riskChaseState]="chaseDetailState"></app-risk-dx-admin-card>
    </div>
  </div>



  
</div>
</ng-template>
