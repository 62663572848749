import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { SubmissionRequest } from "./iva-submission.model";
import { RequestFile } from "./request-file.model";

@Injectable({
  providedIn: "root",
})
export class IvaSubmissionService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService) {
  }

  getRequestTypes(): Observable<SelectableInput[]> {
    const url = `${this.baseApiUrl}ivasubmission/request-types`;
    return this.http.get<SelectableInput[]>(url).pipe(map(this.automapper.curryMany("RequestType", "SelectableInput")));
  }

  getResultsGrid(projectId: number): Observable<SubmissionRequest[]> {
    let params = new HttpParams();
    if (NumberHelper.isAvailable(projectId)) {
      params = params.append("projectId", projectId.toString());
    }
    const url = `${this.baseApiUrl}ivasubmission/results`;
    return this.http.get<SubmissionRequest[]>(url, {params});
  }

  getProjects(): Observable<SelectableInput[]> {
    const url = `${this.baseApiUrl}ivasubmission/projects`;
    return this.http.get<SelectableInput[]>(url)
      .pipe(map(this.automapper.curryMany("ProjectList", "SelectableInput")));
  }

  submitRequest(request: SubmissionRequest): Observable<SubmissionRequest> {
    const url = `${this.baseApiUrl}ivasubmission/submission-request`;
    return this.http.post<SubmissionRequest>(url, request);
  }

  getPreSignedUrl(requestId: number, auditRequestFileId: number): Observable<string> {
    const url = `${this.baseApiUrl}ivasubmission/${requestId}/presigned-url/${auditRequestFileId}`;
    return this.http.get<string>(url);
  }

  updateRequest(requestId: number, auditStatusId: number): Observable<SubmissionRequest> {
    const params = new HttpParams().append("requestId", requestId.toString()).append("auditStatusId", auditStatusId.toString());
    const url = `${this.baseApiUrl}ivasubmission/submission-request`;
    return this.http.patch<SubmissionRequest>(url, null, {params});
  }

  getRequestDownloads(requestId: number): Observable<RequestFile[]> {
    const url = `${this.baseApiUrl}ivasubmission/${requestId}/downloads`;
    return this.http.get<RequestFile[]>(url).pipe(map(arr => arr.sort((a, b) => {
      return a?.updateDate?.localeCompare(b?.updateDate) || a.requestFileTypeDesc.localeCompare(b.requestFileTypeDesc) || a.fileName.localeCompare(b.fileName);
    })));
  }

  getRequestFileTypes(): Observable<SelectableInput[]> {
    const url = `${this.baseApiUrl}ivasubmission/request-file-types`;
    return this.http.get<SelectableInput[]>(url).pipe(map(this.automapper.curryMany("RequestFileType", "SelectableInput")));
  }

  uploadResponseFile(requestId: number, fileTypeId: number, statusId: number, formData: FormData): Observable<void> {
    let params = new HttpParams();
    if (NumberHelper.isAvailable(statusId)) {
      params = params.append("statusId", statusId.toString());
    }
    const url = `${this.baseApiUrl}ivasubmission/${requestId}/upload/${fileTypeId}`;
    return this.http.post<void>(url, formData, {params});
  }
}
