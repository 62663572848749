import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { SubscriptionLike } from "rxjs";
import { filter, map } from "rxjs/operators";
import { SubSink } from "subsink";
import { ArrayHelper } from "../../../../../../../utilities/contracts/array-helper";
import { ObjectHelper } from "../../../../../../../utilities/contracts/object-helper";
import { CodingReviewMode } from "../../../../../project/project-config/coding-review-mode.enum";
import { ChaseDetailState } from "../../../../chase-detail/chase-detail-state.model";
import { ChaseDetailStateService } from "../../../../chase-detail/chase-detail-state.service";
import { RiskDx } from "../dx-models/response/risk-dx.model";
import { RiskDxService } from "../risk-dx.service";
@Component({
  selector: "app-risk-dx-container",
  templateUrl: "./dx-container.component.html",
  styleUrls: ["./dx-container.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxContainerComponent implements OnInit, OnDestroy {
  riskData: RiskDx;
  chaseDetailState: ChaseDetailState;
  private sink = new SubSink();
  addNewCoder = false;
  encounterId: Number;

  get chaseId(): number {
    return this.riskData.chaseId;
  }

  get isReadOnly(): boolean {
    return this.riskData.isReadOnly;
  }

  get showAdmin(): boolean {
    return ArrayHelper.isAvailable(this.riskData?.admin);
  }

  get showEve(): boolean {
    return ArrayHelper.isAvailable(this.riskData?.eve);
  }

  get showCoder(): boolean {
    return ArrayHelper.isAvailable(this.riskData?.coder);
  }

  get masterEveEncounterId(): number {
    return this.riskData?.masterEveEncounterId;
  }
  get isCodingReviewModeDX(): boolean {
    return this.chaseDetailState?.projectConfiguration?.codingReviewMode === CodingReviewMode.DIAGNOSIS;
  }
  constructor(
    private readonly riskDxService: RiskDxService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly chaseDetailStateService: ChaseDetailStateService
  ) {
  }

  ngOnInit(): void {
    this.sink.add(
      this.chaseDetailStateService.state.subscribe(state => {
        this.chaseDetailState = state;
        this.changeDetector.markForCheck();
      }),
      this.riskDxService.addNewHccCard$.subscribe(value => {
        this.addNewCoder = value;
        this.changeDetector.markForCheck();
      }),
      this.loadRiskData()

    );
  }

  ngOnDestroy(): void {
    this.sink.unsubscribe();
  }

  refreshAdminState(): void {
    this.riskDxService.setData({admin: this.riskData.admin});
  }

  refreshEveState(): void {
    this.riskDxService.setData({eve: this.riskData.eve});
  }

  hideNetNewHccCard(): void {
    this.addNewCoder = false;
  }

  trackByIndex(index): number {
    return index;
  }

  private loadRiskData(): SubscriptionLike {
    return this.riskDxService.data$
      .pipe(
        filter(data => !ObjectHelper.isEmpty(data)),
        map(data => {
          this.riskData = data;
          if (ArrayHelper.isAvailable(this.riskData?.eve)) {
            this.encounterId =  this.riskData.eve[0]?.diagnoses[0]?.encounterId;
          }
        }))
      .subscribe(() => this.changeDetector.markForCheck());
  }
}
