import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../core/environment.tokens";
import { SelectableInput } from "../../../dynamic-forms/inputs/selectable-input.model";
import { ListItem } from "../../../shared/list/list-item";
import { EmailNotification } from "./data-load-email-notification.model";
import { DataLoadState } from "./data-load-state.model";
import { IProjectFile } from "./data-load.types";

export interface IUploadObject {
  presignedURL: string;
  fileName: string;
}

@Injectable({
  providedIn: "root",
})
export class DataLoadService {
  state = new BehaviorSubject<DataLoadState>(new DataLoadState());

  constructor(@Inject(BASE_API_URL)
    private readonly baseApiUrl: string,
              private http: HttpClient,
              private automapper: AutomapperService
  ) { }

  setState(data: Partial<DataLoadState>): void {
    const newData = new DataLoadState({ ...this.state.value, ...data });
    this.state.next(newData);
  }

  resetState(data: Partial<DataLoadState>): void {
    this.state.next(new DataLoadState(data));
  }

  clearState(): void {
    this.state.next(new DataLoadState());
  }

  getListOfProjects(): Observable<SelectableInput[]> {
    const url = `${this.baseApiUrl}dataload/projectlist`;

    return this.http.get(url)
      .pipe(map(this.automapper.curryMany("ProjectList", "SelectableInput"))
      );
  }

  getListOfFilesToLoad(projectId: number): Observable<IProjectFile[]> {
    const url = `${this.baseApiUrl}dataload/loadfilelist?projectid=${projectId}`;

    return this.http.get(url)
      .pipe(map((response: any) => response));
  }

  getListOfDataLoadTypes(projectId: number): Observable<SelectableInput[]> {
    const url = `${this.baseApiUrl}dataload/loadtypelist?projectid=${projectId}`;

    return this.http.get(url)
      .pipe(map(this.automapper.curryMany("string", "SelectableInput"))
      );
  }

  upload(formData: FormData): Observable<string> {
    const url = `${this.baseApiUrl}dataload/upload`;

    return this.http.post(url, formData)
      .pipe(map((response: any) => response as string)
      );
  }

  saveUpload(formData: string): Observable<string> {
    const url = `${this.baseApiUrl}dataload/saveuploadfile`;

    return this.http.post(url, formData, { headers: new HttpHeaders({ "Content-Type": "application/json" }) })
      .pipe(map((response: any) => response as string)
      );
  }

  getPresignedURL(formData: string): Observable<IUploadObject> {
    const url = `${this.baseApiUrl}presignedurl`;

    return this.http.post(url, formData, { headers: new HttpHeaders({ "Content-Type": "application/json" }) })
      .pipe(map((response: any) => response as IUploadObject)
      );
  }

  validateData(projectId: number, dataLoadId: number, dataLoadType: string): Observable<number> {
    const url = `${this.baseApiUrl}dataload/validate?projectId=${projectId}&dataLoadId=${dataLoadId}&dataLoadType=${dataLoadType}`;

    return this.http.get(url)
      .pipe(map((response: number) => response));
  }

  deleteData(projectId: number, dataLoadId: number): Observable<null> {
    const url = `${this.baseApiUrl}dataload/delete?projectId=${projectId}&dataLoadId=${dataLoadId}`;

    return this.http.delete(url) as Observable<null>;
  }

  isProcessDone(projectId: number, loadId: number): Observable<boolean> {
    const url = `${this.baseApiUrl}dataload/status?projectId=${projectId}&dataLoadId=${loadId}`;

    return this.http.get(url).pipe(map((response: any) => response as boolean));
  }

  loadData(projectId: number, loadId: number, dataLoadType: string, processMode: string): Observable<number> {
    const url = `${this.baseApiUrl}dataload/load?projectId=${projectId}&dataLoadId=${loadId}&dataLoadType=${dataLoadType}&processMode=${processMode}`;

    return this.http.get(url)
      .pipe(map((response: number) => response));
  }

  getSummaryReport(dataLoadId: number): Observable<ListItem[]> {
    const url = `${this.baseApiUrl}dataload/report/summary?dataLoadId=${dataLoadId}`;

    return this.http.get(url)
      .pipe(map(this.automapper.curryMany("DataLoadSummaryReport", "ListItem"))
      );
  }

  getDetailErrorReport(dataLoadId: number, projectName: string) {
    return `${this.baseApiUrl}dataload/error/report/detail?dataLoadId=${dataLoadId}&projectName=${projectName}`;
  }

  getSummaryErrorDisplayReport(dataLoadId: number): Observable<any> {
    const url = `${this.baseApiUrl}dataload/error/report/Summary/display?dataLoadId=${dataLoadId}`;
    return this.http.get(url, {})as Observable<any>;
   }

  getSummaryErrorReport(dataLoadId: number, projectName: string) {
    return `${this.baseApiUrl}dataload/error/report/summary?dataLoadId=${dataLoadId}&projectName=${projectName}`;
  }

  getChaseOmissionFromLoadReport(dataLoadId: number, projectName: string) {
    return `${this.baseApiUrl}dataload/chaseomissionfromload/report?dataLoadId=${dataLoadId}&projectName=${projectName}`;
  }

  getErrorChasesFromLoadReport(dataLoadId: number, projectName: string, projectId: number) {
    return `${this.baseApiUrl}dataload/chases/invalid/report?dataLoadId=${dataLoadId}&projectName=${projectName}&projectId=${projectId}`;
  }

  getErrorDiagnosisFromLoadReport(dataLoadId: number, projectName: string, projectId: number, projectType: string) {
    return `${this.baseApiUrl}dataload/diagnosis/invalid/report?dataLoadId=${dataLoadId}&projectName=${projectName}&projectId=${projectId}&projectType=${projectType}`;
  }

  getErrorComplianceFromLoadReport(dataLoadId: number, projectName: string, projectId: number) {
    return `${this.baseApiUrl}dataload/compliance/invalid/report?dataLoadId=${dataLoadId}&projectName=${projectName}&projectId=${projectId}`;
  }

  sendEmailNotification(emailNotification: EmailNotification): Observable<boolean> {
    const url = `${this.baseApiUrl}dataload/notification/email`;

    return this.http.post(url, emailNotification, { observe: "response" })
      .pipe(
        map((response: HttpResponse<any>) => response.ok)
      );
  }

  deleteFile(projectId: number): Observable<null> {
    const url = `${this.baseApiUrl}dataload/delete/file?projectId=${projectId}`;

    return this.http.delete(url) as Observable<null>;
  }
}
