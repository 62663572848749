<div class="project-bulk-update-container">
  <h3>Bulk Change</h3>
  <div class="project-bulk-container">
    <form [formGroup]="formGroup">
      <div class="ui-g ui-fluid">
      </div>
      <div class="ui-g ui-fluid">
        <div class="ui-g-12 ui-md-6 ui-lg-3 item_align_left">
          <div class="label-block">
            <form-factory [formGroup]="formGroup" [model]="bulkUpdateTypesInput" (onChange)="onSelectType($event)" class="resizeDropdown">
            </form-factory>

          </div>
        </div>
      </div>
      <div class="ui-g ui-fluid">
        <div class="ui-g-12 ui-md-6 ui-lg-3 item_align_left" *ngIf="isShowChaseFormatType">
          <div class="label-block">
            <form-factory [formGroup]="formGroup" [model]="chaseFormatTypeInput" (onChange)="onFormatTypeChange($event.value)" class="resizeDropdown">
            </form-factory>
          </div>
        </div>
      </div>
      <div class="ui-g ui-fluid">
        <div class="ui-g-12 ui-md-6 ui-lg-3 item_align_left">
          <div class="label-block">
            <form-factory [formGroup]="formGroup" [model]="bulkUpdateActions" (onChange)="onBulkActionChange($event)" class="resizeDropdown">
            </form-factory>
          </div>

        </div>
      </div>
      <div class="ui-g ui-fluid" *ngIf="isChangeManageActionTypeSelected || hasAddressManageActionTypeSelected || isDocumentManageActionTypeSelected">
        <div class="ui-g-12 ui-md-6 ui-lg-3 item_align_left">
          <div class="label-block">
            <form-factory [formGroup]="formGroup" [model]="tagActionInput" (onChange)="onManageTagSelection($event)" class="resizeDropdown">
            </form-factory>
          </div>
        </div>
      </div>
      <div class="ui-g ui-fluid" *ngIf="isTagBulkActionSelected && hasManageTagControl">
        <div class="ui-g-12 ui-md-12 ui-lg-12 item_align_left">
          <div class="label-block">
            <label class="label" for="bulkAction">Tags</label>
            <form-bulk-update-manage-tag [tagInputActive]="isTagBulkActionSelected" (tagDataModel)="onSelectedTagData($event)" [tagType]="tagType" [tagActionType]="tagActionType" [showAddTagValidationError]="showAddTagValidationError" (addTagValid)="addTagValidation($event)"></form-bulk-update-manage-tag>
          </div>
        </div>
      </div>
      <div class="ui-g ui-fluid" *ngIf="isShowClientOrgAndChaseControls && showClientBasedOnChaseFormatType">
        <div class="ui-g-12 ui-md-6 ui-lg-6 item_align_left">
          <div class="label-block">
            <form-factory [formGroup]="formGroup" [model]="clienOrgsInput" (onChange)="onSelectClientOrg($event.value)" class="resizeDropdown">
            </form-factory>
          </div>

        </div>
      </div>
      <div *ngIf="isShowProjectsAndAddressControls || isFormatTypeChasesKey">
        <div class="ui-g ui-fluid">
          <div class="ui-g-12 ui-md-6 ui-lg-3 item_align_left" *ngIf="isMarkAsContactedActionTypeSelected || isFormatTypeChasesKey">
            <div class="label-block">
              <form-factory [formGroup]="formGroup" [model]="projectInput" class="resizeDropdown"></form-factory>
            </div>
          </div>
        </div>

        <div class="ui-g ui-fluid" *ngIf="isChangeRetrievalActionTypeSelected && !isFormatTypeChasesKey">
          <div class="ui-g-12 ui-md-6 ui-lg-3 item_align_left">
            <div class="label-block">
              <form-factory [formGroup]="formGroup" [model]="changeRetrievalTypeInput" class="resizeDropdown"></form-factory>
            </div>
          </div>
        </div>
        <div class="ui-g ui-fluid" *ngIf="isThirdPartyRetrievalTypeSelected && !isFormatTypeChasesKey">
          <div class="ui-g-12 ui-md-6 ui-lg-3 item_align_left">
            <div class="label-block">
              <form-factory [formGroup]="formGroup" [model]="assignThirdPartyVendorInput" class="resizeDropdown"></form-factory>
            </div>
          </div>
        </div>
        <div class="ui-g ui-fluid">
          <div class="ui-g-12 ui-md-6 ui-lg-3 item_align_left" *ngIf="hasAddressEnableSpecialHandlingActionSelected && !isFormatTypeChasesKey">
            <div class="label-block">
              <form-factory [formGroup]="formGroup" [model]="specialHandlingReasonInput" class="resizeDropdown"></form-factory>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="isShowDocumentHoldControls">
        <div class="ui-g ui-fluid">
          <div class="ui-g-12 ui-md-6 ui-lg-3 item_align_left" *ngIf="isHoldActionTypeSelected">
            <div class="label-block">
              <form-factory [formGroup]="formGroup" [model]="holdStatusInput" class="resizeDropdown"></form-factory>
            </div>
          </div>
        </div>

        <div class="ui-g ui-fluid">
          <div class="ui-g-12 ui-md-6 ui-lg-3 item_align_left" *ngIf="isAssignActionTypeSelected">
            <div class="label-block">
              <form-factory [formGroup]="formGroup" [model]="assignToUsersInput" class="resizeDropdown"></form-factory>
            </div>
          </div>
        </div>
      </div>
      <div class="ui-g ui-fluid">
        <div class="ui-g-12 ui-md-6 ui-lg-3 item_align_left">
          <div  *ngIf="isDueDateSelected">
            <form-factory [formGroup]="formGroup" [model]="applyDueDateInput"></form-factory>
          </div>
          <div  *ngIf="isRequestedDateSelected">
            <form-factory [formGroup]="formGroup" [model]="applyRequestedDateInput" ></form-factory>
          </div>
          <div  *ngIf="isCommitmentDateSelected">
            <form-factory [formGroup]="formGroup" [model]="applyCommitmentDateInput" ></form-factory>
          </div>
        </div>
      </div>
      <div *ngIf="!isShowCopyChartChaseInputs" class="ui-g ui-fluid">
        <div class="ui-g-12 ui-md-6 ui-lg-6 item_align_left">
          <div class="label-container">
            <label class="label" for="user">
              <label *ngIf="showChasesId">{{ bulkUpdateIds | titlecase }}</label> <label *ngIf="showChaseskey">{{bulkUpdateChaseKey | titlecase }}</label><span class="error-icon">
                <app-icon class="error-icon" iconName="question-circle" *ngIf="isShowChaseIDs" [title]="getError(bulkUpdateIds)">
                </app-icon>
              </span>
            </label>
            <div class="invoice-pendcode" *ngIf="isBulkPendUpdate">{{ bulkInvoicePendsMessage }}</div>
          </div>
          <form-factory [formGroup]="formGroup" [model]="selectedChaseIds" (keypress)="onSelectChaseIds($event)"
                        (keyup)="onChange($event)"></form-factory>
        </div>

        <div [ngClass]="customFileUploadClass">
          <app-file-upload (onUpload)="uploadFile($event)" accept=".xls,.xlsx,.csv" [multiple]="false" [sendThroughPresignUrl]="false"
                           chooseLabel="UPLOAD FROM FILE">
          </app-file-upload>
        </div>
      </div>
      <div *ngIf="isShowCopyChartChaseInputs" class="ui-g ui-fluid">
        <div class="ui-g-12 ui-md-6 ui-lg-6 item_align_left">
          <div class="label-container">
            <label class="label" for="user">
              <label>Original Chase Ids</label>
            </label>
          </div>
          <form-factory [formGroup]="formGroup" [model]="originalChaseIds" (keypress)="onOriginalChaseIds($event)"
                        (keyup)="onChange($event)"></form-factory>
           <div class="label-container">
              <label class="label" for="user">
                <label>Copy Over Chase Ids</label>
                </label>
            </div>
           <form-factory [formGroup]="formGroup" [model]="copyOverChaseIds" (keypress)="onCopyOverChaseIds($event)"
                           (keyup)="onChange($event)"></form-factory>
        </div>

        <div [ngClass]="customFileUploadClass">
          <div class="customfiledownload">
            <a (click)="downloadTemplate()">DOWNLOAD COPY OVER CHART TEMPLATE</a>
          </div>
          <app-file-upload (onUpload)="uploadCopyChartChaseFile($event)" accept=".xls,.xlsx,.csv" [multiple]="false"
                           chooseLabel="UPLOAD FROM FILE">
          </app-file-upload>
        </div>
      </div>
      <div class="ui-g ui-fluid">
        <div class="ui-g-3 ui-md-3 ui-lg-3 item_align_left">
          <div class="label-container">
            <app-button text="CONTINUE" (onClick)="validateContinue()" *ngIf="isShowContinueButton && !isShowEditBulk"
                        [disabled]="hasClinicalPends">
            </app-button>
            <app-button text="EDIT" (onClick)="validateContinue()" *ngIf="(isShowEditBulk)"></app-button>
          </div>
        </div>
      </div>

      <div *ngIf="isShowControls">
        <app-bulk-update-chase [actionControls]="formValues" *ngIf="isShowClientOrgAndChaseControls && !hasManageTagControl"
                               (isShowContinueButton)="onShowButton($event)" (isShowControls)="onShowControls($event)"
                               (isShowEditBulk)="onShowEditButton($event)" [isShowChaseControls]="isShowClientOrgAndChaseControls && !hasManageTagControl"
                               [isClinical]="isClinical" [clinicalType]="clinicalType" [hasClinicalNonClinicalChases]="hasClinicalNonClinicalChases"
                               [isMoveChase]="isMoveChase"
                               [isChaseAssignUser]="isChaseAssignUser"
                               [refreshFlag]="refreshFlag"
                               (refreshEvent)="handleRefresh($event)"
                               >
        </app-bulk-update-chase>
        <app-bulk-update-pend [actionControls]="formValues" *ngIf="isShowPend && !hasManageTagControl"
                              (isShowContinueButton)="onShowButton($event)" (isShowEditBulk)="onShowEditButton($event)">
        </app-bulk-update-pend>
        <app-bulk-update-address [addressbulkUpdateData]="addressbulkUpdateData"
                                 *ngIf="isShowProjectsAndAddressControls  && !hasManageTagControl"
                                  (isValidated)="onShowButton($event)"
                                 (isProcessingAddressIds)="onShowEditButton($event)"
                                 [isAddressAssignUser]="isAddressAssignUser"
                                 ></app-bulk-update-address>
        <app-bulk-update-tag *ngIf="hasManageTagControl" [actionControls]="formValues" [tagActionType]="tagActionType" [tagFormData]="tagFormValues" [tagType]="tagType" [addressbulkUpdateData]="addressbulkUpdateData"
                             (isValidatedManageTag)="onShowButton($event)" (isProcessingManageTag)="onShowEditButton($event)" (isShowControls)="onShowControls($event)"></app-bulk-update-tag>
        <app-bulk-update-document *ngIf="isShowDocumentHoldControls && !hasManageTagControl"
                                  [actionControls]="formValues"
                                  (isValidationContinue)="onShowButton($event)"
                                  (isProcessingDocumentIds)="onShowEditButton($event)"
                                  [isDocumentGrid]="isDocumentGrid"
                                  >
        </app-bulk-update-document>

      </div>
    </form>
  </div>
</div>
