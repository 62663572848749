<div class="projectfiles-container">
  <div class="header bold">PROJECT FILES - {{projectName}}</div>
  <p>New: </p>
  <p-fileUpload #fileUpload name="file[]"
                (onSelect)="onFileSelect($event)"
                (uploadHandler)="uploadDocument(fileUpload)"
                customUpload="true"                
                accept="image/*,.txt,.doc,.docx,.tiff,.xls,.xlsx,.pdf,.zip,.xml,.pptx,.csv"
                maxFileSize="1000000000"
                [showUploadButton]="true"
                [disabled]="isUploadStarted">
                <ng-template let-file pTemplate="file">
                  <div class="fileName">{{file.name}}</div>
                  <div class="deleteBtn">
                    <app-button text="x" (onClick)="onFileRemove(fileUpload)"></app-button>
                  </div>
                  <div class="uploadDescription">
                    <form-factory [formGroup]="projectFilesFormGroup" [model]="uploadDescription"></form-factory>
                  </div>
                </ng-template>
  </p-fileUpload>

  <app-basic-grid [configuration]="gridConfigurationModel" 
                  [data]="projectFiles" 
                  [actionTemplate]="actionTemplate">
      <ng-template #actionTemplate let-i="index" let-rowData let-localActionPopover="actionPopover">
        <div class="action-template-container">
          <app-button class="uploading" text="View File" (onClick)="onViewFile(rowData); localActionPopover.hide();"></app-button>
          <app-button text="Delete File" (onClick)="onDeleteFile(rowData); localActionPopover.hide();"></app-button>
        </div>
      </ng-template>  
</app-basic-grid>

</div>
