export enum LookerRiskDashboardType {
  OVERVIEW = 36,
  PENDS_SUMMARY = 51,
  CHASE_STATUS_UNDERLYING = 55,
  LIFE_OF_A_CHASE_REPORT = 56,
  MEMBER_STATUS = 57,
  PROVIDER_STATUS = 58,
  PROJECT_SUMMARY = 59,
  DIAGNOSIS_BY_HCC = 60,
  HCC_SUMMARY_BY_MEMBER = 61,
  HCC_SUMMARY_BY_PROVIDER = 62,
  HCC_SUMMARY_BY_PROJECT = 63,
  HCC_SUMMARY_BY_CLAIM_ID = 64,
  HCC_SUMMARY_BY_AID = 65,
  VALIDATION_REASON_CODE_REPORT = 66,
  USER_PRODUCTIVITY = 67,
  RISK_GAP_STATUS = 71,
  CLINICAL_SUMMARY = 75,
  RISK_OPEN_GAPS_BY_MEASURE = 78,
  RISK_OPEN_GAPS_BY_MEMBER = 80,
  MEMBER_ADDITIONAL_DETAILS = 87,
  PROJECT_STATUS_REPORT = 102,
  PEND_REPORT = 103,
  CLINICAL_PRODUCTIVITY_OVERVIEW = 104,
  TOP_AIDS_WITH_OPEN_CHASE = 105,
  CLINICAL_PRODUCTIVITY_AND_ACCURACY_OVERALL = 106,
  CLINICAL_ACCURACY_BY_MEASURE = 107,
  CLINICAL_ACCURACY_BY_CHASE_WITH_ERROR_DETAIL_LEGACY = 108,
  RISK_PROJECT_HEALTH = 110,
  CONDITION_CATEGORY_QA_REPORT = 124,
  CLINICAL_PRODUCTIVITY_REPORT = 126,
  CLIENT_OVERREAD2_REPORT = 127,
  CODER_PRODUCTIVITY_REPORT = 128,
  RETRIEVAL_REPORT = 129,
  RETRIEVAL_REPORT_BY_CHASE = 130,
  INTAKE_PRODUCTIVITY_REPORT_LEGACY = 131,
  USER_PRODUCTIVITY_REPORT = 132,
  CALL_COUNT_DETAIL_REPORT_LEGACY = 133,
  PROVIDER_OUTREACH_BY_CONTACT_TYPE = 135,
  TRAINNIG_FEEDBACK_REPORT = 136,
  FT_DAILY_SUMMARY_REPORT = 137,
  MANUALLY_CREATED_CHASES_REPORT = 138,
  AUTO_CLOSE_REPORT = 146,
  RISK_NLP_DASHBOARD_OVERVIEW = 156,
  RISK_NLP_ICD_DASHBOARD_OVERVIEW = 157,
  RISK_NLP_ICD_DRILLDOWN = 158,
  SFTP_REPORT = 159,
  PROVIDER_VALIDATION_REPORT = 167,
  CHASE_MOVE_BACK_REPORT = 190,
  DETAIL_CLINICAL_PRODUCTIVITY_AND_ACCURACY_OVERALL = 285,
  RISK_CLINICAL_PRODUCTION_INVENTORY_QA_SCORES = 307,
  CHASE_MOVE_BACK_HISTORY_REPORT_LEGACY = 318,
  PENDS_SUMMARY_NEW = 338,
  RETRIEVAL_SUMMARY = 339,
  DOB_DISCREPANCY = 361,
  SUSPECTING = 369,
  ROI = 387,
  PERSISTENCY_REPORT = 388,
  HISTORICAL_STATUS_INDICATORS_REPORT = 389,
  CUSTOMER_INVOICE_DETAIL = 391,
  CHART_ROUTING = 393,
  PEND_REPORT_DW = 411,
  ACCURACY_REPORT = 412,
  INTAKE_PRODUCTIVITY_REPORT = 422,
  CALL_COUNT_DETAIL_REPORT = 426,
  CHASE_MOVE_BACK_HISTORY_REPORT = 430,
  CLINICAL_ACCURACY_BY_CHASE_WITH_ERROR_DETAIL = 432,
  AID_TAGS_HISTORY = 436,
  PERSISTENCY_REPORT_DW = 440,
  ROI_DW = 441,
  STALLED_DOCUMENTS_REPORT = 442,
  HISTORICAL_STATUS_INDICATORS_REPORT_DW = 443,
  CLIENT_OVERREAD2_REPORT_DW = 445,
  LIFE_OF_A_CHASE_REPORT_DW = 447,
  PROVIDER_GATEWAY_ACTIVITY = 449,
  CODING_DURATION_REPORT = 450,
  CODER_PRODUCTIVITY_REPORT_DW = 454,
  CLINICAL_PRODUCTIVITY_REPORT_DW = 455,
  CLINICAL_PRODUCTIVITY_AND_ACCURACY_OVERALL_DW = 462,
  CHASE_MOVE_REPORT = 466,
  USER_PRODUCTIVITY_DASHBOARD = 477,
  DELETES_TRACKING_DASHBOARD = 486,
  CHASE_COUNTS_BY_STATUS = 498,
  DETAILED_AUDIT_REPORT = 501,
  CHART_ROUTING_DW = 503,
  DOB_DISCREPANCY_DW = 511,
  SLA_MILESTONE_REPORT_DW = 512,
  CHASE_STATUS_UNDERLYING_REPORT = 533,
  PROJECT_STATUS_REPORT_DW = 554,
  DIAGNOSIS_DATA_MULTIPLE_MODELS = 557,
  DATA_LOAD_ERROR_REPORT = 559,
  ANNOTATIONS_REPORT = 560,
  SFTP_REPORT_DW = 578,
  RETRIEVAL_SUMMARY_DW = 591,
  TOP_AIDS_WITH_OPEN_CHASES = 592,
  VALIDATION_REASON_CODE_REPORT_DW= 596,
  OVERVIEW_DW= 598,
  PENDS_SUMMARY_DW= 600,
  HCC_BY_DOS= 601,
  DIAGNOSIS_BY_HCC_DW= 603,
  HCC_SUMMARY_BY_AID_DW= 604,
  HCC_SUMMARY_BY_MEMBER_DW= 605,
  HCC_SUMMARY_BY_PROVIDER_DW= 606,
  MEMBER_STATUS_DW= 607,
  PROVIDER_STATUS_DW= 608,
  CONDITION_CATEGORY_QA_REPORT_DW= 609,
  HCC_SUMMARY_BY_PROJECT_DW= 610,
  PROJECT_SUMMARY_DW= 611,
  HCC_SUMMARY_DW= 612,
  DELETES_TRACKING_DASHBOARD_DW= 613,
  RISK_CLINICAL_SUMMARY = 617,
  STALLED_DOCUMENTS_REPORT_DW= 618,
  CHASE_MOVE_REPORT_DW = 619,
  PROVIDER_OUTREACH_BY_CONTACT_TYPE_DW= 620,
  TRAINING_FEEDBACK_REPORT_DW = 622,
  CODING_DURATION_REPORT_DW = 624,
  RISK_SCORE_TREND_DW = 652,
  HCC_TRACKING = 656,
}
