import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AutoCompleteModule } from "primeng/autocomplete";
import { CalendarModule } from "primeng/calendar";
import { CheckboxModule } from "primeng/checkbox";
import { DropdownModule } from "primeng/dropdown";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { ListboxModule } from "primeng/listbox";
import { MultiSelectModule } from "primeng/multiselect";
import { RadioButtonModule } from "primeng/radiobutton";
import { TooltipModule } from "primeng/tooltip";
import { ButtonModule } from "../shared/button/button.module";
import { GridModule } from "../shared/grid/grid.module";
import { IconModule } from "../shared/icon/icon.module";
import { MenuModule } from "../shared/menu/menu.module";
import { NationalProviderGridComponent } from "../shared/national-provider-grid/national-provider-grid.component";
import { SelectButtonComponent } from "../shared/national-provider-grid/select-button/select-button.component";
import { PanelModule } from "../shared/panel/panel.module";
import { ToolTipModule } from "../shared/tool-tip/tool-tip.module";
import { FactoryComponent } from "./factory/factory.component";
import { FixedArrayComponent } from "./form-arrays/fixed-array/fixed-array.component";
import { SaveArrayComponent } from "./form-arrays/save-array/save-array.component";
import { AbaHeightGroupComponent } from "./form-groups/aba-height-group/aba-height-group.component";
import { BmiGroupComponent } from "./form-groups/bmi-group/bmi-group.component";
import { ConditionalGroupComponent } from "./form-groups/conditional-group/conditional-group.component";
import { ConditionalNrcGroupComponent } from "./form-groups/conditional-nrc-group/conditional-nrc-group.component";
import { DischargeGroupComponent } from "./form-groups/discharge-group/discharge-group.component";
import { NegativeReasonCodeGroupComponent } from "./form-groups/negative-reason-code-group/negative-reason-code-group.component";
import { NegativeReasonCodeService } from "./form-groups/negative-reason-code-group/negative-reason-code.service";
import { DatesBetweenComponent } from "./form-groups/ppc/dates-between/dates-between.component";
import { DeliveryGroupComponent } from "./form-groups/ppc/delivery-group/delivery-group.component";
import { DodDeliveryGroupComponent } from "./form-groups/ppc/dod-delivery-group/dod-delivery-group.component";
import { EarlyLateIndicatorGroupComponent } from "./form-groups/ppc/early-late-indicator-group/early-late-indicator-group.component";
import { EddPrenatalGroupComponent } from "./form-groups/ppc/edd-prenatal-group/edd-prenatal-group.component";
import { PrenatalGroupComponent } from "./form-groups/ppc/prenatal-group/prenatal-group.component";
import { SaveGroupComponent } from "./form-groups/save-group/save-group.component";
import { StandardGroupComponent } from "./form-groups/standard-group/standard-group.component";
import { AutocompleteComponent } from "./inputs/autocomplete/autocomplete.component";
import { CalendarComponent } from "./inputs/calendar/calendar.component";
import { CheckboxGroupOmissionCodesComponent } from "./inputs/checkbox-group-omission-codes/checkbox-group-omission-codes.component";
import { CheckboxGroupModule } from "./inputs/checkbox-group/checkbox-group.module";
import { CheckBoxModule } from "./inputs/checkbox/checkbox.module";
import { ChipAutocompleteComponent } from "./inputs/chip-autocomplete/chip-autocomplete.component";
import { ConfirmAutocompleteComponent } from "./inputs/confirm-autocomplete/confirm-autocomplete.component";
import { ConfirmNoPageNumberComponent } from "./inputs/confirm-no-page-number/confirm-no-page-number.component";
import { ConfirmProviderComponent } from "./inputs/confirm-provider/confirm-provider.component";
import { ConfirmComponent } from "./inputs/confirm/confirm.component";
import { CustomRadiobuttonComponent } from "./inputs/custom-radiobutton/custom-radiobutton.component";
import { DropdownComponent } from "./inputs/dropdown/dropdown.component";
import { MultiselectComponent } from "./inputs/multiselect/multiselect.component";
import { NoConfirmProviderComponent } from "./inputs/no-confirm-provider/no-confirm-provider.component";
import { NoConfirmComponent } from "./inputs/no-confirm/no-confirm.component";
import { ProviderDropdownComponent } from "./inputs/provider-dropdown/provider-dropdown.component";
import { RadiobuttonComponent } from "./inputs/radiobutton/radiobutton.component";
import { SigninUserDropdownComponent } from "./inputs/signin-user-dropdown/signin-user-dropdown.component";
import { SwitchModule } from "./inputs/switch/switch.module";
import { TagSearchAutoCompleteComponent } from "./inputs/tag-search-autocomplete/tag-search-autocomplete.component";
import { TagSearchMultiselectComponent } from "./inputs/tag-search-multiselect/tag-search-multiselect.component";
import { TextareaComponent } from "./inputs/textarea/textarea.component";
import { TextboxSourceModule } from "./inputs/textbox-source/textbox-source.module";
import { TextboxModule } from "./inputs/textbox/textbox.module";
import { VrcDropdownComponent } from "./inputs/vrc-dropdown/vrc-dropdown.component";
import { DateBetweenValidatorDirective } from "./validators/date-between.validator";
import { DateGreaterThanValidatorDirective } from "./validators/date-greather-than.validator";
import { EnabledOptionsOnlyValidatorDirective } from "./validators/enabled-options-only.validator";

@NgModule({
  imports: [
    TooltipModule,
    AutoCompleteModule,
    ButtonModule,
    CalendarModule,
    CheckboxGroupModule,
    CheckboxModule,
    CheckBoxModule,
    CommonModule,
    DropdownModule,
    FormsModule,
    GridModule,
    IconModule,
    InputSwitchModule,
    InputTextareaModule,
    InputTextModule,
    ListboxModule,
    MultiSelectModule,
    PanelModule,
    RadioButtonModule,
    ReactiveFormsModule,
    SwitchModule,
    TextboxModule,
    TextboxSourceModule,
    ToolTipModule,
    MenuModule,
  ],
  declarations: [
    AbaHeightGroupComponent,
    AutocompleteComponent,
    BmiGroupComponent,
    CalendarComponent,
    CheckboxGroupOmissionCodesComponent,
    CheckboxGroupOmissionCodesComponent,
    ChipAutocompleteComponent,
    ConditionalGroupComponent,
    ConditionalNrcGroupComponent,
    ConfirmAutocompleteComponent,
    ConfirmComponent,
    ConfirmNoPageNumberComponent,
    ConfirmProviderComponent,
    DateBetweenValidatorDirective,
    DatesBetweenComponent,
    DateGreaterThanValidatorDirective,
    DeliveryGroupComponent,
    DischargeGroupComponent,
    DodDeliveryGroupComponent,
    DropdownComponent,
    EarlyLateIndicatorGroupComponent,
    EddPrenatalGroupComponent,
    EnabledOptionsOnlyValidatorDirective,
    FactoryComponent,
    FixedArrayComponent,
    MultiselectComponent,
    NationalProviderGridComponent,
    NegativeReasonCodeGroupComponent,
    NoConfirmComponent,
    NoConfirmProviderComponent,
    PrenatalGroupComponent,
    ProviderDropdownComponent,
    RadiobuttonComponent,
    CustomRadiobuttonComponent,
    SaveArrayComponent,
    SaveGroupComponent,
    SigninUserDropdownComponent,
    StandardGroupComponent,
    TagSearchAutoCompleteComponent,
    TagSearchMultiselectComponent,
    TextareaComponent,
    VrcDropdownComponent,
    SelectButtonComponent,
  ],
  entryComponents: [
    AbaHeightGroupComponent,
    AutocompleteComponent,
    BmiGroupComponent,
    CalendarComponent,
    CheckboxGroupOmissionCodesComponent,
    ChipAutocompleteComponent,
    ConditionalGroupComponent,
    ConditionalNrcGroupComponent,
    ConfirmAutocompleteComponent,
    ConfirmComponent,
    ConfirmNoPageNumberComponent,
    ConfirmProviderComponent,
    DeliveryGroupComponent,
    DischargeGroupComponent,
    DodDeliveryGroupComponent,
    DropdownComponent,
    EarlyLateIndicatorGroupComponent,
    EddPrenatalGroupComponent,
    FixedArrayComponent,
    MultiselectComponent,
    NegativeReasonCodeGroupComponent,
    NoConfirmComponent,
    NoConfirmProviderComponent,
    PrenatalGroupComponent,
    ProviderDropdownComponent,
    CustomRadiobuttonComponent,
    RadiobuttonComponent,
    SaveArrayComponent,
    SaveGroupComponent,
    SigninUserDropdownComponent,
    StandardGroupComponent,
    TagSearchAutoCompleteComponent,
    TagSearchMultiselectComponent,
    TextareaComponent,
    VrcDropdownComponent,
  ],
  exports: [
    CheckBoxModule,
    DateBetweenValidatorDirective,
    DateGreaterThanValidatorDirective,
    EnabledOptionsOnlyValidatorDirective,
    FactoryComponent,
    ReactiveFormsModule,
    NationalProviderGridComponent,
    SelectButtonComponent,
    MenuModule,
  ],
  providers: [
    NegativeReasonCodeService,
  ],
})
export class DynamicFormsModule { }
