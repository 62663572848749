import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, ViewChild } from "@angular/core";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { DynamicControlDirective } from "../../dynamic-control-component.model";
import { SelectableInput } from "../selectable-input.model";
import { CustomRadiobutton } from "./custom-radiobutton.model";

@Component({
  selector: "form-custom-radiobutton",
  templateUrl: "./custom-radiobutton.component.html",
  styleUrls: ["./custom-radiobutton.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomRadiobuttonComponent extends DynamicControlDirective<CustomRadiobutton> implements AfterViewInit {

  @ViewChild("radioBtn", { static: true }) radioBtn: ElementRef;

  ngAfterViewInit(): void {
    this.bindSelectedValue();
  }

  bindSelectedValue(): void {
    setTimeout(() => {
      const selectdValue = this.formGroup.value[this.model.key];
      const elementRef = this.radioBtn.nativeElement;
      const radioBtns = elementRef.children;
      for (const radio of radioBtns) {
        const input = radio?.children[0]?.children[0]?.firstChild as HTMLElement;
        const btnValue = input?.getAttribute("value");
        if (selectdValue === btnValue) {
          radio.click();
        }
      }
    },         500);
  }

  get groupName(): string {
    return this.model.name;
  }

  get options(): SelectableInput[] {
    return this.model.options;
  }

  get hasLabel(): boolean {
    return StringHelper.isAvailable(this.model.label);
  }

  get hasTooltip(): boolean {
    return StringHelper.isAvailable(this.model.tooltip);
  }

  onChangeEvent(event: MouseEvent, option: SelectableInput): void {
    event.preventDefault();
    event.stopPropagation();
    // FIXME: The emit should not need `as any` but it does. Please fix!
    this.onChange.emit(option.value as any);
    this.onInput.emit(option.value as any);
  }

  get classes(): string {
    return this.getClasses("radiobutton");
  }

  get hasSubLabel(): boolean {
    return StringHelper.isAvailable(this.model.subLabel);
  }

  trackByIndex(index, item) {
    return index;
  }
}
