import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { List } from "immutable";
import { SubSink } from "subsink";
import { UserToken } from "../../../../../../auth/user-token.model";
import { AutomapperService } from "../../../../../../core/automapper/automapper.service";
import { UserService } from "../../../../../../core/user/user.service";
import { FormService } from "../../../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { Textarea } from "../../../../../../dynamic-forms/inputs/textarea/textarea.model";
import { ButtonAction } from "../../../../../../shared/button/button-action.model";
import { ListItem } from "../../../../../../shared/list/list-item";
import { PhoneNumberPipe } from "../../../../../../shared/pipes/phone.pipe";
import { TagItems } from "../../../../../../shared/tags/model/tag-items.model";
import { TagType } from "../../../../../../shared/tags/model/tag-type.enum";
import { TagService } from "../../../../../../shared/tags/tag.service";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../../../utilities/contracts/date-helper";
import { NumberHelper } from "../../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";
import { ServiceOrgConfigurationService } from "../../../../service-org-admin/service-org-config/service-org-config.service";
import { AddressTimelineStateService } from "../../../address-timeline/address-timeline-state.service";
import { AddressTimelineService } from "../../../address-timeline/address-timeline.service";
import { Contact } from "../../../contact.model";
import { ContactMethodType } from "../../../cover-letter-template/contact-method-type.enum";
import { DirectoryUserRole } from "../../../directory-user-role";
import { DocumentSourceType } from "../../../retrieval-document-source-type.enum";
import { AddressDetailState } from "../../address-detail-state.model";
import { AddressDetailStateService } from "../../address-detail-state.service";
import { AddressDetailService } from "../../address-detail.service";
import { AddressDetailInfoEditService } from "../address-detail-info-edit/address-detail-info-edit.service";

@Component({
    selector: "retrieval-address-detail-info-data",
    templateUrl: "./address-detail-info-data.component.html",
    styleUrls: [ "./address-detail-info-data.component.scss" ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetrievalAddressDetailInfoDataComponent implements OnInit, OnDestroy {
    private sink = new SubSink();
    private selectedContact: Contact;
    private phonePipe = new PhoneNumberPipe();
    addressDetailState: AddressDetailState;
    isAddressEditView = false;
    isAddressAssignmentView = false;
    isAddressVendorAssignmentView = false;
    assignmentItems: ButtonAction[] = [];
    assignmentType: DocumentSourceType;
    isChangeVendorConfirmationModalVisible = false;
    isResetPINConfirmationModalVisible = false;
    changeVendorConfirmationModalHeader: string;
    changeVendorConfirmationModalText: string;
    isRetrievalChangeModalVisible: boolean;
    selectedRetrievalOption: number;
    isRetrievalTypeChanged: boolean;
    tagList: any[] = [];
    isTagListVisible = false;
    tagType = TagType.MASTERDOCUMENTSOURCE;
    objectId: number;
    isVendorChanged = false;
    addressDetailForm: FormGroup;
    isSpecialHandling: boolean;
    isSpecialHandlingModalVisible = false;
    formGroup: FormGroup;
    specialHandlingReason: Dropdown;
    specialHandlingNote: Textarea;
    isSpecialHandlingNote = true;
    isEnableSpecialHandling: boolean;
    specialHandlingText: string;
    specialHandlingComment: string;
    isSpecialHandlingReasonVisible = false;
    isEditSpecialHandlingReasonVisible = false;
    isEditSHReasonVisible = false;
    tagItems: TagItems;
    isAssignToUserModalVisible = false;
    is3rdParty = false;
    isAssignmentModalOpened = false;
    selectedAddress: any[] = [];
    loggedInUser: UserToken;
    deleteSpecialHandling = false;
    editSpecialHandling = false;

    constructor(
        private readonly addressDetailStateService: AddressDetailStateService,
        private changeDetector: ChangeDetectorRef,
        private readonly route: ActivatedRoute,
        private readonly formService: FormService,
        private addressDetailInfoEditService: AddressDetailInfoEditService,
        private serviceOrgConfigService: ServiceOrgConfigurationService,
        private addressTimelineStateService: AddressTimelineStateService,
        private addressTimelineService: AddressTimelineService,
        protected readonly automapper: AutomapperService,
        private tagService: TagService,
        private userService: UserService,
        private readonly addressDetailService: AddressDetailService

    ) { }

    ngOnInit() {

        this.loggedInUser = this.userService.getUserToken();
        this.sink.add(
            this.route.paramMap.subscribe(params => {
                this.objectId = +params.get("addressId");
                this.changeDetector.markForCheck();
            }),
            this.addressDetailStateService.state.subscribe(state => {
                if (!this.isPrimaryContact) {
                    this.selectedContact = state.contact;
                }
                this.addressDetailState = state;
                this.is3rdParty = this.addressDetailState.is3rdParty;
                this.isSpecialHandling = this.addressDetailState.isSpecialHandling;
                this.assignmentType = state.documentSourceTypeId;

                this.addressTimelineStateService.timelineItems.next([]);
                this.removeRetrivalType();
                if (this.isRetrievalTypeChanged) {
                    this.getAssignmentItems();
                    this.removeRetrivalType();
                }
                this.changeDetector.markForCheck();
            })
        );
        this.getAssignmentItems();
        this.removeRetrivalType();
        this.initializeForm();
    }

    get isPrimaryContact(): boolean {
        return this.selectedContact?.isPrimaryContact;
    }

    getAssignmentItems() {
        this.assignmentItems = [];
        this.assignmentItems.push(
            new ButtonAction({
                name: "To PSR",
                action: () => this.showModalBasedOnRetrievalType(DocumentSourceType.PSR),
            }),
            new ButtonAction({
                name: "To Field Tech",
                action: () => this.showModalBasedOnRetrievalType(DocumentSourceType.FIELDTECH),
            }),
            new ButtonAction({
                name: "To EMR",
                action: () => this.showModalBasedOnRetrievalType(DocumentSourceType.EMR),
            }),
            new ButtonAction({
                name: "To 3rd Party",
                action: () => this.showThirdPartyAssignmentModal(),
            })
        );
    }

    showThirdPartyAssignmentModal() {
        this.is3rdParty = false;
        this.showAddressVendorAssignmentModal();
    }

    showModalBasedOnRetrievalType(retrievalType: number) {
        this.isRetrievalTypeThirdParty ? this.showRetrievalTypeConfirmModal(retrievalType) : this.showAddressAssignmentModal(retrievalType);
    }

    ngOnDestroy() {
        this.sink.unsubscribe();
    }

    get hasContacts(): boolean {
        return ArrayHelper.isAvailable(this.addressDetailState.contacts);
    }

    get hasOnlyOneContact(): boolean {
        return this.hasContacts && this.addressDetailState.contacts.length === 1;
    }

    get shouldDisplayContactDropdown(): boolean {
        return this.hasContacts && !this.hasOnlyOneContact;
    }

    get addressInfo(): List<ListItem> {

        const addressInfo = [
            new ListItem({
                key: "Address:",
                value: this.addressDetailState.address1,
            }),
            new ListItem({
                key: "Address 2:",
                value: this.addressDetailState.address2,
            }),
            new ListItem({
                key: "City:",
                value: this.addressDetailState.city,
            }),
            new ListItem({
                key: "State:",
                value: this.addressDetailState.state,
            }),
            new ListItem({
                key: "Zip:",
                value: this.addressDetailState.postalCode,
            }),
            new ListItem({
                key: "Status:",
                value: this.addressDetailState.masterDocumentSourceStatus,
            }),
        ];

        return List(addressInfo);
    }

    get contactInfo(): List<ListItem> {

        const contactInfo = [
            new ListItem({
                key: "Phone:",
                value: this.phonePipe.transform(this.selectedContact?.contactPhone),
            }),
            new ListItem({
                key: "Fax:",
                value: this.phonePipe.transform(this.selectedContact?.contactFax),
            }),
            new ListItem({
                key: "Email:",
                value: this.selectedContact?.contactEmail,
            }),
            new ListItem({
                key: "Preferred Request Method:",
                value: this.selectedContact?.weeklyContactMethodTypeName,
            }),
            new ListItem({
                key: "Provider Gateway PIN:",
                value: this.addressDetailState.uploadAccessCode,
            }),
        ];

        if (this.hasOnlyOneContact) {
            contactInfo.unshift(new ListItem({
                key: "Contact:",
                value: this.selectedContact?.contactName,
            }));

            this.selectedContact = this.addressDetailState.contact;
        }

        return List(contactInfo);
    }

    get retrievalInfo(): List<ListItem> {

        const retrievalInfo = [];
        if (this.loggedInUser.isCoRetrievalEnabled) {
            retrievalInfo.push(
                new ListItem({
                    key: "Retriever:",
                    value: this.addressDetailState.coRetrievalOwner,
                })
            );
        }
        retrievalInfo.push(
            new ListItem({
                key: "Assigned To:",
                value: this.addressDetailState.assignedTo === "Unassigned" ? "" : this.addressDetailState.assignedTo,
            }),
            new ListItem({
                key: "Retrieval Type:",
                value: this.addressDetailState.documentSourceTypeName,
            }),
            new ListItem({
                key: "Group Name:",
                value: this.addressDetailState.groupName,
            }),
            new ListItem({
                key: "NPI:",
                value: "", // TODO: Need data from DB
            })
        );

        if (this.addressDetailState.isPSR) {
            retrievalInfo.push(
                new ListItem({
                    key: "EMR System:",
                    value: this.addressDetailState.accessInfo.accessEmrSystem,
                })
            );
        }

        if (this.addressDetailState.is3rdParty) {
            retrievalInfo.push(
                new ListItem({
                    key: "3rd Party Vendor:",
                    value: this.addressDetailState.vendorName,
                }),
                new ListItem({
                    key: "3rd Party Vendor Invoicing:",
                    value: this.addressDetailState.vendorInvoicing,
                }),
                new ListItem({
                    key: "Vendor Confirmed:",
                    value: this.vendorConfirmDate,
                })
            );
            if (this.isVendorNotConfirmed) {
                retrievalInfo.pop();
            }
        }
        return List(retrievalInfo);
    }

    get isRetrievalTypeThirdParty(): boolean {
        return this.addressDetailState.is3rdParty && StringHelper.isAvailable(this.vendorConfirmDate);
    }

    showEditAddressModal() {
        this.isAddressEditView = true;
        this.changeDetector.markForCheck();
    }

    receiveAddressEditValue(event: boolean): void {
        this.isAddressEditView = event;
    }

    showAddressAssignmentModal(assignmentType: DocumentSourceType): void {
        this.assignmentType = assignmentType;
        this.isAddressAssignmentView = true;
        this.changeDetector.markForCheck();
    }

    showAddressVendorAssignmentModal(): void {
        this.isAddressVendorAssignmentView = true;
        this.isAssignmentModalOpened = true;
        this.changeDetector.markForCheck();
    }

    get isVendorNotConfirmed(): boolean {
        return this.addressDetailState.is3rdParty && !StringHelper.isAvailable(this.addressDetailState.vendorConfirmDate);
    }

    showChangeVendorConfirmationModal(): void {
        this.changeVendorConfirmationModalHeader = "Change Vendor";
        this.changeVendorConfirmationModalText = "Are you sure you want to change this vendor? This will delete all data entry performed for invoices at this AID";
        this.isChangeVendorConfirmationModalVisible = true;
    }

    changeVendor(): void {
        this.isVendorChanged = true;
        this.isChangeVendorConfirmationModalVisible = false;
        this.showAddressVendorAssignmentModal();
    }
    showResetPINConfirmationModal(): void {
        this.isResetPINConfirmationModalVisible = true;
    }

    resetPIN(): void {
        this.isResetPINConfirmationModalVisible = false;
        this.addressDetailInfoEditService.resetAccessPortalCode(this.addressDetailState.masterDocumentSourceId).subscribe(() => {
            this.updateAccessPortalCode();
            this.fetchAddressTimelineItems(this.addressDetailState.masterDocumentSourceId);
        });
    }

    get isVendorAssigned(): boolean {
        return this.addressDetailState.is3rdParty;
    }

    cancelAction(): void {
        this.isChangeVendorConfirmationModalVisible = false;
    }

    get vendorConfirmDate(): string {
        return StringHelper.isAvailable(this.addressDetailState.vendorConfirmDate) ? DateHelper.format(this.addressDetailState.vendorConfirmDate) : null;
    }

    showRetrievalTypeConfirmModal(selectedType: number): void {
        this.selectedRetrievalOption = selectedType;
        this.isRetrievalChangeModalVisible = true;
        this.changeDetector.markForCheck();
    }

    onCancel(): void {
        this.isRetrievalChangeModalVisible = false;
    }

    onConfirmChange(): void {
        this.showAddressAssignmentModal(this.selectedRetrievalOption);
    }

    removeRetrivalType() {
        // TODO: Have put this condition as 'this.assignmentItems' Array got empty due to addressDetailState gets triggered multiple times.
        if (ArrayHelper.clean(this.assignmentItems).length > 3) {
            switch (this.addressDetailState.documentSourceTypeId) {
                case DocumentSourceType.PSR:
                    this.removeRetrivalTypeByType("To PSR");
                    break;
                case DocumentSourceType.EMR:
                    this.removeRetrivalTypeByType("To EMR");
                    break;
                case DocumentSourceType.FIELDTECH:
                    this.removeRetrivalTypeByType("To Field Tech");
                    break;
                case DocumentSourceType.THIRDPARTY:
                    this.removeRetrivalTypeByType("To 3rd Party");
                    break;
                default: break;
            }
        }
    }

    removeRetrivalTypeByType(type: string) {
        this.assignmentItems.splice(this.assignmentItems.map(item => item.name).indexOf(type), 1);
    }

    retrievalTypeChanged(event: boolean): void {
        this.isRetrievalTypeChanged = event;
    }

    validateWeeklyStatement(weeklyContactMethodTypeId: number, faxNumber: string, email: string): boolean {
        if ((weeklyContactMethodTypeId === ContactMethodType.Fax && StringHelper.isAvailable(faxNumber)) ||
            (weeklyContactMethodTypeId === ContactMethodType.Email && StringHelper.isAvailable(email))) {
            return true;
        }

        return false;
    }
    resetData(): void {
        this.addressDetailStateService.setData(this.addressDetailState);
        this.fetchAddressTimelineItems(this.addressDetailState.masterDocumentSourceId);
        this.changeDetector.markForCheck();
    }

    private fetchAddressTimelineItems(addressId: number): void {
        this.addressTimelineService
            .get(addressId)
            .subscribe(timelineItems => this.addressTimelineStateService.timelineItems.next(timelineItems));
    }

    private updateAccessPortalCode() {
        this.addressDetailService.getAddressDetail(this.addressDetailState.masterDocumentSourceId).subscribe({
            next: addressDetail => {
                this.addressDetailStateService.setData({
                    uploadAccessCode: addressDetail.uploadAccessCode,

                });
            },
        });
    }

    get specialHandlingHeading(): string {
        return this.isEnableSpecialHandling ? "Enable Special Handling" : "Disable Special Handling";
    }

    get specialHandlingDescription(): string {
        return this.isEnableSpecialHandling ? "Are you sure you want to enable Special Handling? This will move the Address into a new workflow called Special Handling."
            : "Are you sure you want to disable Special Handling? This will move the Address back to its original retrieval workflow.";
    }

    enableSpecialHandling(event): void {
        this.initializeForm();
        this.isEnableSpecialHandling = event;
        this.isSpecialHandlingModalVisible = true;
    }

    private initializeForm(): void {
        this.specialHandlingReason = new Dropdown({
            key: "specialHandlingReason",
            label: "Reason",
            placeholder: "Select From List",
            validators: Validators.required,
            errorMessages: {
                required: "Reason is required",
            },
            appendTo: "body",
        });

        this.specialHandlingNote = new Textarea({
            key: "specialHandlingNote",
            label: "Additional Notes",
            placeholder: "Enter an optional note..",
            errorMessages: {
                minlength: "Write a note between 4 - 1000 characters.",
                maxlength: "Write a note between 4 - 1000 characters.",
            },
        });

        this.formGroup = this.formService.createFormGroup([ this.specialHandlingReason, this.specialHandlingNote ]);
        this.formGroup.get(this.specialHandlingNote.key).setValidators([ Validators.minLength(4), Validators.maxLength(1000) ]);
    }

    private getSpecialHandlingReasons(): void {
        this.addressDetailInfoEditService.getSpecialHandlingReasons()
            .subscribe(data => {
                this.specialHandlingReason = new Dropdown({ ...this.specialHandlingReason, options: data });
                this.changeDetector.markForCheck();
            });
    }

    onReasonChange(event): void {
        this.specialHandlingText = event.originalEvent.currentTarget.innerText;
        if (this.specialHandlingText === "Other") {
            this.formGroup.get(this.specialHandlingNote.key).setValidators([Validators.required, Validators.minLength(4), Validators.maxLength(1000)]);
        } else {
          this.formGroup.get(this.specialHandlingNote.key).setValidators([Validators.minLength(4), Validators.maxLength(1000)]);
        }
        this.formGroup.get(this.specialHandlingNote.key).updateValueAndValidity();
    }

    enableDeleteSpecialHandling(): void {
      this.deleteSpecialHandling = true;
    }

    get specialHandlingReasonControl(): FormControl {
        return this.formGroup.get(this.specialHandlingReason.key) as FormControl;
    }

    enableSHReason(): void {
        this.formGroup.get(this.specialHandlingReason.key).reset();
        this.formGroup.get(this.specialHandlingReason.key).updateValueAndValidity();
        this.isEditSHReasonVisible = true;
    }

    enableEditSpecialHandling(): void {
        if (this.addressDetailState.specialHandlingComment) {
            this.formGroup.get(this.specialHandlingNote.key).patchValue(this.addressDetailState.specialHandlingComment);
            this.changeDetector.detectChanges();
        }

        this.addressDetailInfoEditService.getSpecialHandlingReasons()
            .subscribe(data => {
                this.specialHandlingReason.options = data;
                this.formGroup.get(this.specialHandlingReason.key).setValue(this.addressDetailState.specialHandlingText);
                this.changeDetector.detectChanges();
            });

        if (this.addressDetailState.specialHandlingText === "Other") {
            this.formGroup.get(this.specialHandlingNote.key).setValidators([Validators.required, Validators.minLength(4), Validators.maxLength(1000)]);
        } else {
          this.formGroup.get(this.specialHandlingNote.key).setValidators([Validators.minLength(4), Validators.maxLength(1000)]);
        }
        this.formGroup.get(this.specialHandlingNote.key).updateValueAndValidity();

        this.editSpecialHandling = true;
        this.isEditSpecialHandlingReasonVisible = true;
        this.isEditSHReasonVisible = false;
    }

    formatSpecialHandlingDate(): string {
      if (DateHelper.isDate(this.addressDetailState.specialHandlingUpdateDate)) {
        const date = new Date(this.addressDetailState.specialHandlingUpdateDate);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
        const year = date.getFullYear();

        return `${month}/${day}/${year}`;
      }
      return "";
    }

    confirmSpecialHandling() {
        if (this.isEnableSpecialHandling) {
            this.isSpecialHandlingReasonVisible = true;
            this.initializeForm();
            this.isSpecialHandlingNote = true;
            this.getSpecialHandlingReasons();
        } else {
            this.tagService.deleteTagByTagType(TagType.MASTERDOCUMENTSOURCESPECIALHANDLING, [ this.objectId ])
                .subscribe(() => {
                    this.addressDetailState.isSpecialHandling = false;
                    this.addressDetailState.specialHandlingId = 0;
                    this.addressDetailState.specialHandlingText = undefined;
                    this.addressDetailState.specialHandlingComment = undefined;
                    this.resetData();
                });
        }
        this.isSpecialHandlingModalVisible = false;
        this.deleteSpecialHandling = false;
        this.editSpecialHandling = false;
    }

    confirmSpecialHandlingReason() {
      if (this.formGroup.valid) {
            this.tagItems = new TagItems({
                tagTypeId: TagType.MASTERDOCUMENTSOURCESPECIALHANDLING,
                tagId: this.formGroup.controls.specialHandlingReason.value,
                objectIds: [ this.objectId ],
                tagText: this.isSpecialHandlingNote ? this.formGroup.controls.specialHandlingNote.value : undefined,
            });
            if (this.addressDetailState.specialHandlingId) {
                const isReasonChanged = NumberHelper.isAvailable(this.formGroup.controls.specialHandlingReason?.value);
                const isSameReason = this.formGroup.controls.specialHandlingReason?.value === this.addressDetailState.specialHandlingText;
                const isNoteNotChanged = this.formGroup.controls.specialHandlingNote.value === this.addressDetailState.specialHandlingComment;

                if (isNoteNotChanged && isSameReason) {
                  this.resetData();
                  this.isSpecialHandlingReasonVisible = false;
                  this.isSpecialHandlingModalVisible = false;
                  this.isEditSpecialHandlingReasonVisible = false;
                  return;
                }

                if (isReasonChanged) {
                  this.tagItems.assignToTagId = this.formGroup.controls.specialHandlingReason.value;
                } else {
                  const reasonId = this.specialHandlingReason.options.find(x => x.text === this.addressDetailState.specialHandlingText).value;
                  this.tagItems.assignToTagId = +reasonId;
                }
                this.tagItems.tagId = this.addressDetailState.specialHandlingId;
                this.tagService.replaceTags(this.tagItems)
                .subscribe(() => {
                  this.addressDetailState.isSpecialHandling = true;
                  if (isReasonChanged) {
                      this.addressDetailState.specialHandlingId = this.formGroup.controls.specialHandlingReason.value;
                      this.addressDetailState.specialHandlingText = this.specialHandlingText;
                  }
                  this.addressDetailState.specialHandlingComment = this.formGroup.controls.specialHandlingNote.value;
                  this.addressDetailState.specialHandlingUpdateUserName = `${this.loggedInUser.firstName} ${this.loggedInUser.lastName}`;
                  this.addressDetailState.specialHandlingUpdateDate = new Date();
                  this.resetData();
                });
            } else {
              this.tagService.associateTags(this.tagItems)
                .subscribe(() => {
                  this.addressDetailState.isSpecialHandling = true;
                  this.addressDetailState.specialHandlingId = this.formGroup.controls.specialHandlingReason.value;
                  this.addressDetailState.specialHandlingText = this.specialHandlingText;
                  this.addressDetailState.specialHandlingComment = this.formGroup.controls.specialHandlingNote.value;
                  this.addressDetailState.specialHandlingUpdateUserName = `${this.loggedInUser.firstName} ${this.loggedInUser.lastName}`;
                  this.addressDetailState.specialHandlingUpdateDate = new Date();
                  this.resetData();
                });
            }
            this.isSpecialHandlingReasonVisible = false;
            this.isSpecialHandlingModalVisible = false;
            this.isEditSpecialHandlingReasonVisible = false;
        } else {
            this.formService.markAllAsTouched(this.formGroup);
        }
    }

    openAssignToUserModal(): void {
        this.selectedAddress = [ { masterDocumentSourceID: this.addressDetailState.masterDocumentSourceId, specialHandling: this.addressDetailState.isSpecialHandling ? 1 : 0 } ];
        this.isAssignToUserModalVisible = true;
    }

    closeModal(): void {
        this.selectedAddress = [];
        this.isAssignToUserModalVisible = false;
    }

    getChangeVendorClass(): string {
        let cssClass = "address-detail-info-data__";
        cssClass += !this.isVendorNotConfirmed ? "edit-vendor-button" : "vendor-button-not-confirmed";
        return cssClass;
    }

    onContactSelected(contact: Contact): void {
        this.selectedContact = contact;
    }

    get hasAssignButton(): boolean {
        const roles = [ DirectoryUserRole.Admin, DirectoryUserRole.RetrievalLead, DirectoryUserRole.ThirdPartyManager,
                        DirectoryUserRole.CallCenterManager, DirectoryUserRole.EMRManager, DirectoryUserRole.FieldTechManager, DirectoryUserRole.SpecialHandlingManager ];

        return this.loggedInUser.directoryRoleIds.some(roleId => roles.includes(roleId));
    }
}
