import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild, ViewChildren, ViewEncapsulation } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { MessagingService } from "../../core/messaging/messaging.service";
import { SeverityType } from "../../core/messaging/severity-type.enum";
import { FormService } from "../../dynamic-forms/form.service";
import { Textbox } from "../../dynamic-forms/inputs/textbox/textbox.model";
import { DocumentUploadRequestObjectType } from "../../platform/api/document-upload/document-upload-request-objecttype.enum";
import { DocumentUploadService } from "../../platform/api/document-upload/document-upload.service";
import { retrievalMethodType } from "../../platform/modules/project/approval-center/retrieval-method-enum";
import { DocumentTypeName } from "../../platform/modules/retrieval/retreival-document-review/document-type.enum";
import { ArrayHelper } from "../../utilities/contracts/array-helper";
import { DigitalUnitConverterHelper } from "../../utilities/contracts/digital-unit-converter-helper";
import { NumberHelper } from "../../utilities/contracts/number-helper";
import { StringHelper } from "../../utilities/contracts/string-helper";
import { EmailVerificationService } from "../chart-upload/email-verification/email-verification.service";
import { BulkUploadStatus, ProviderDownloadHeader } from "./provider-download-header";
import { catchError, map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { LocalService } from "../../core/storage/local.service";
import { RegExHelper } from "../../utilities/reg-Ex-Helper";

export interface IUploadObject {
  presignedURL: string;
  fileName: string;
}
@Component({
  selector: "app-provider-upload-portal",
  templateUrl: "./provider-upload-portal.component.html",
  styleUrls: ["./provider-upload-portal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [EmailVerificationService],
})
export class ProviderUploadPortalComponent implements OnInit {
  organizationId: string;
  addressId: string;
  form: FormGroup;
  @ViewChildren("formRow") rows: any;

  pinCode: Textbox;
  presignedResponse: IUploadObject[] = [];
  fileArray :any[]=[];
  email: Textbox;
  formGroup: FormGroup;
  groupName: string;
  selectedFileList: any[] = [];
  formData: FormData = new FormData();
  @ViewChild("fileUpload") fileUpload: any;
  showUploadPortal = false;
  totalSelectedFileSize = 0;

  maxFileSizeForProviderUploadInBytes: number;
  maxFileSizeForProviderUploadInMB: number;
  uploadBatchCode: string;
  showProviderPortal = true;
  providerVerificationCode: string;
  providerUploadReceipt: any[] = [];
  headers: ProviderDownloadHeader[] = [
    ProviderDownloadHeader.create("File Name", "fileName"),
    ProviderDownloadHeader.create("File Size", "fileSize"),
    ProviderDownloadHeader.create("Upload Date", "uploadDate"),
    ProviderDownloadHeader.create("Upload Time", "uploadTime"),
    ProviderDownloadHeader.create("Confirmation Number", "confirmationNumber"),
    ProviderDownloadHeader.create("AID", "aid"),
    ProviderDownloadHeader.create("Upload Status", "uploadStatus"),
  ];
  isUploadLoader = false;
  code = "";
  private uploadStatus: BulkUploadStatus;

  get showOnSuccess(): boolean {
    return this.uploadStatus === BulkUploadStatus.Success;
  }
  get showOnFailure(): boolean {
    return this.uploadStatus === BulkUploadStatus.Failure;
  }
  get showOnPartial(): boolean {
    return this.uploadStatus === BulkUploadStatus.Partial;
  }
  get showEmailNotification(): boolean {
    return StringHelper.isAvailable(this.formGroup.get("email")?.value);
  }
  constructor(
    protected readonly formService: FormService,
    private messagingService: MessagingService,
    private documentUploadService: DocumentUploadService,
    private verificationService: EmailVerificationService,
    protected readonly changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute,
    private service: EmailVerificationService,
    private localService: LocalService,
  ) { }

  ngOnInit(): void {
    this.setFormInputs();
    this.setEmailAddress();
  }

  setEmailAddress(): void {
    let sgemail = this.localService.get("pgemailid", null);
    if (StringHelper.isAvailable(sgemail)) {
      this.formGroup.controls.email.setValue(sgemail);
    }
  }

  setFormInputs(): void {
    this.createForm();
    this.getMaxFileSizeForProviderUpload();
    this.code = this.route.snapshot.paramMap.get("code");
    if (StringHelper.isAvailable(this.code)) {
      this.getDecryptedCode();
    }
  }
  getDecryptedCode(): void {
    this.service.getDecryptedCode(this.code).subscribe(result => {
      if (result) {
        this.formGroup.controls.pinCode.setValue(result.body);
        this.keyUpEvent();
      }
    });
  }
  createForm() {
    this.pinCode = new Textbox({
      key: "pinCode",
      maxlength: 8,
    });
    this.email = new Textbox({
      key: "email",
      maxlength: 320,
      placeholder: "xyz@abc.com (optional)",
      validators: [Validators.pattern(RegExHelper.emailPattern)],
      errorMessages: {
        required: "Invalid email address",
        
      },

    });
    this.formGroup = this.formService.createFormGroup([
      this.pinCode,
      this.email,
    ]);
  }


  keyUpEvent(): void {
    let code = this.formGroup.get("pinCode").value
    if (StringHelper.isAvailable(code) && code.length === 8) {
      this.providerVerificationCode = code;
      this.verifyCode(code);
    } else {
      this.showUploadPortal = false;
    }
  }

  emailKeyUpEvent(): void {
    let emailId = this.formGroup.get("email").value
    let validEmail=this.isValidEmailAddress();
    if (validEmail) {
      if (StringHelper.isAvailable(emailId)) {
        this.localService.put("pgemailid", emailId);
      }
      else {
        this.localService.delete("pgemailid");
      }
    } 
  }

  private isValidEmailAddress(): boolean
  {
    return this.formGroup.get('email')?.valid;
  }



  verifyCode(verificationCode) {

    this.verificationService.verifyUploadAccessCode(verificationCode).subscribe(result => {
      if (NumberHelper.isGreaterThan(result.masterDocumentSourceId, 0)) {
        this.showUploadPortal = true;
        this.addressId = result.masterDocumentSourceId.toString();
        this.organizationId = result.organizationId.toString();
        this.groupName = result.groupName
      } else {
        this.messagingService.showToast("Incorrect Pin Entered.", SeverityType.ERROR);
        this.showUploadPortal = false;
      }
      this.changeDetector.markForCheck();
    });
  }

  trackByIndex(index): number {
    return index;
  }

  onFileSelect(event, fileUpload) {
    for (const file of event.files) {
      this.totalSelectedFileSize += file.size;
      const fileExtension = file.name.substr(file.name.lastIndexOf(".") + 1).trim().toLowerCase();
      if (fileExtension === "pdf") {
        if (NumberHelper.isLessThan(this.totalSelectedFileSize, Number(fileUpload.maxFileSize), true)) {
          this.selectedFileList.push(file);
          this.formData.append("Document", file);
        } else {
          this.messagingService.showToast("File(s) upload size exceeds limit of 1.5GB", SeverityType.ERROR);
          this.totalSelectedFileSize -= file.size;
          break;
        }
      } else {
        this.messagingService.showToast("Invalid File Format!", SeverityType.ERROR);
      }
    }
    this.changeDetector.detectChanges();
    this.setFileRemoveIconOnScroll(fileUpload);
  }

  setFileRemoveIconOnScroll(fileUpload): void {
    if (fileUpload.content.nativeElement.scrollHeight > fileUpload.content.nativeElement.clientHeight) {
      const removeIcon = fileUpload.content.nativeElement.getElementsByClassName("ui-button-icon-only") as HTMLCollectionOf<HTMLButtonElement>;
      if (removeIcon.length > 0) {
        let index = 0;
        while (index < removeIcon.length) {
          removeIcon[index].classList.add("inline-icon");
          index++;
        }
      }
    }
  }

  onFileRemove(file: any, index: number) {
    this.formData = new FormData();

    let i = this.selectedFileList.length;
    while (i--) {
      if (i === index && this.selectedFileList[i].name === file.name) {
        this.selectedFileList.splice(i, 1);
        this.totalSelectedFileSize -= file.size;
      } else {
        this.formData.append("Document", this.selectedFileList[i]);
      }
    }
  }

  resetFormData(): void {
    this.formData = new FormData();
    this.selectedFileList = [];
  }

   uploadDocuments() {
    if(this.isValidEmailAddress())
    {
    this.verificationService.verifyUploadAccessCode(this.providerVerificationCode).subscribe(result => {
      if (NumberHelper.isGreaterThan(result.masterDocumentSourceId, 0)) {
        this.showProviderPortal = false;
        this.isUploadLoader = true;
        this.addressId = result.masterDocumentSourceId.toString();
        this.organizationId = result.organizationId.toString();
        if (ArrayHelper.isAvailable(this.selectedFileList) && NumberHelper.isGreaterThan(this.selectedFileList.length, 0)) {

          this.formData.append("masterDocumentSourceId", this.addressId.toString());
          this.formData.append("organizationId", this.organizationId.toString());
          this.formData.append("documentTypeId", Number(DocumentTypeName.DocTypeMedicalRecord).toString());
          this.formData.append("objectType", Number(DocumentUploadRequestObjectType.Intake).toString());
          this.formData.append("retrievalTypeId", Number(retrievalMethodType.Provider_Email_Upload).toString());
          this.formData.append("source", "Bulk Intake");
          this.formData.append("pincode", this.providerVerificationCode);
          
           const fileArray: any = [];
            this.selectedFileList.forEach((file, index) => {
                fileArray.push(file.name);
            });

            const obj = {
              pinCode: this.providerVerificationCode,
              type: this.selectedFileList[0].type,
              fileType:"ProviderGateway",
              fileNames: fileArray,
             };

            this.documentUploadService.getPresignedURL(JSON.stringify(obj))
            .subscribe({
            next: (data) => { 
            if (data) 
            {
           
              const filePromises = this.selectedFileList.map((file, index) => {
                return this.documentUploadService.uploadFiles(data[index].presignedURL, file);
              });

              Promise.all(filePromises)
              .then((results) => {
                // All promises resolved successfully
                const documents = this.formData.getAll("Document");
                const newFormData = new FormData();
                newFormData.append("masterDocumentSourceId", this.addressId.toString());
                newFormData.append("organizationId", this.organizationId.toString());
                newFormData.append("documentTypeId", Number(DocumentTypeName.DocTypeMedicalRecord).toString());
                newFormData.append("objectType", Number(DocumentUploadRequestObjectType.Intake).toString());
                newFormData.append("retrievalTypeId", Number(retrievalMethodType.Provider_Email_Upload).toString());
                newFormData.append("source", "Bulk Intake");
                newFormData.append("emailId", this.formGroup.get("email").value);
                newFormData.append("NumFiles",documents.length.toString());
                newFormData.append("pincode", this.providerVerificationCode);
                let fileNumCounter = 0;

                for (const document of documents) {
                  const name = data[fileNumCounter.toString()];
                  const OFN = this.selectedFileList[fileNumCounter.toString()].name;
                  newFormData.append(`${fileNumCounter}`, data[fileNumCounter].fileName);//Guid.pdf
                  newFormData.append(`fileSize${fileNumCounter}`, this.selectedFileList[fileNumCounter.toString()].size);
                  newFormData.append(`OFN${fileNumCounter}`, OFN);
                  newFormData.delete("Document");
                  fileNumCounter++;
                }

              this.documentUploadService.uploadGateway(newFormData)
              .pipe(
                catchError(this.onUploadError.bind(this)),
                map(this.onAfterUpload.bind(this)),
              )
              .subscribe();
            })
          //promise error
            .catch((error) => {
              console.log(error);
                this.uploadStatus = BulkUploadStatus.Failure;
                this.uploadBatchCode=null;
            });
        }
        else{
          this.stopUploderScreen();
        }
      },
      error: (error) => {
        this.stopUploderScreen();
      } 
     });//End of presigned url 
        this.changeDetector.markForCheck();
      }
      } else {
        this.messagingService.showToast("Incorrect Pin Entered.", SeverityType.ERROR);
        this.setFormInputs();
        this.showUploadPortal = false;
      }
      this.changeDetector.markForCheck();
    });
    }
    else{
      this.messagingService.showToast("Invalid Email Address Entered.", SeverityType.ERROR);
    }
    this.changeDetector.markForCheck();
  }

 
  onUploadError(): Observable<any> {
    return of({
      body: {
        batchUploadStatus: BulkUploadStatus.Failure,
        uploadBatchCode: null,
      }
    });
  }

  private stopUploderScreen():void
  {
    this.uploadStatus= BulkUploadStatus.Failure;
    this.uploadBatchCode=null;
    this.changeDetector.markForCheck();
    this.isUploadLoader = false;
    this.resetFormData();
  }

  onAfterUpload(result: any): void {
    this.uploadBatchCode = result.body?.uploadBatchCode;
    this.isUploadLoader = false;
    this.uploadStatus = result.body?.batchUploadStatus as BulkUploadStatus;
    if (this.showOnPartial || this.showOnSuccess) {
      this.providerUploadReceipt = result.body?.providerReceiptResponse;
    }
    this.changeDetector.markForCheck();
    this.resetFormData();
  }

  get filesUploaded(): boolean {
    return ArrayHelper.isAvailable(this.selectedFileList);
  }

  downloadReceipt(): void {
    this.downloadFile(this.providerUploadReceipt);
  }

  downloadFile(data: any[]): void {
    const replacer = (_, value) => StringHelper.isAvailable(value) || NumberHelper.isAvailable(value) ? value : "";
    const csv = data.map(row => this.headers.map(header => JSON.stringify(row[header.column], replacer)).join(","));
    csv.unshift(this.headers.map(h => h.display).join(","));
    const csvArray = csv.join("\r\n");
    const blob = new Blob([csvArray], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Confirmation_${this.uploadBatchCode}.csv`;
    a.click();
    a.remove();
  } 

  startBulkUpload(): void {
    this.uploadStatus = null;
    this.showProviderPortal = true;
    this.isUploadLoader = false;
  }

  getMaxFileSizeForProviderUpload(): void {
    this.documentUploadService.getMaxFileSizeForBulkUpload()
      .subscribe((data: number) => {
        this.maxFileSizeForProviderUploadInBytes = data;
        this.maxFileSizeForProviderUploadInMB = DigitalUnitConverterHelper.byteToMB(data);
        this.changeDetector.markForCheck();
      });
  }
}
