import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { List } from "immutable";
import { combineLatest } from "rxjs";
import { distinctUntilKeyChanged, filter, map } from "rxjs/operators";
import { SubSink } from "subsink";
import { SelectableInput } from "../../../../../../dynamic-forms/inputs/selectable-input.model";
import { MenuItem } from "../../../../../../shared/menu/menu-item.model";
import { BooleanHelper } from "../../../../../../utilities/contracts/boolean-helper";
import { WorkflowStatusDb } from "../../../../../api/workflow/workflow-status-db.enum";
import { OMISSION_CODE } from "../../../chase-detail/chase-detail-chart/attributes";
import { OmissionCodeService } from "../../../chase-detail/chase-detail-chart/risk/diagnosis/omission-code.service";
import { VrcService } from "../../../chase-detail/chase-detail-chart/risk/diagnosis/vrc.service";
import { RiskState } from "../../../chase-detail/chase-detail-chart/risk/risk-state.model";
import { RiskService } from "../../../chase-detail/chase-detail-chart/risk/risk.service";
import { ChaseDetailState } from "../../../chase-detail/chase-detail-state.model";
import { ChaseDetailStateService } from "../../../chase-detail/chase-detail-state.service";
import { CodingOptimizationMode } from "../coding-optimization-mode.enum";
import { ChaseDetailV2ChartRiskService } from "./risk.service";

@Component({
  selector: "member-chase-detail-v2-chart-risk",
  templateUrl: "./risk.component.html",
  styleUrls: ["./risk.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseDetailV2ChartRiskComponent implements OnInit {
  private hasEncounterTypes: boolean;
  private sink = new SubSink();
  @Input() chaseId: number;
  @Output() selectedSubMenu = new EventEmitter<string>();
  menuItems = List<MenuItem>();
  riskState: RiskState;
  selectedTab = "member";
  private previousUrl: string = null;
  private currentUrl: string = null;
  private vrcsRemovedFromTheDropdown = ["01", "21", "61", "99", "106"];
  private measureYear = 0;
  private projectType = "";
  private workflowStatus = "";
  private displayEncountersWithNoHcc: boolean;
  private hasVrcs: boolean;
  private hasOmissionCodes: boolean;
  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly chaseDetailState: ChaseDetailStateService,
    private readonly chaseDetailV2ChartRiskService: ChaseDetailV2ChartRiskService,
    private readonly riskService: RiskService,
    private readonly vrcService: VrcService,
    private router: Router,
    private readonly omissionCodeService: OmissionCodeService,
    private readonly activatedRoute: ActivatedRoute
  ) { }

  get pathLastRoute(): string {
    const pathUrl = window.location.href;
    return pathUrl.substring(pathUrl.lastIndexOf("/") + 1).trim().toLowerCase();
  }

  ngOnInit(): void {
    this.sink.add(
      this.chaseDetailState.state.pipe(
        distinctUntilKeyChanged("workflowStatus")
      ).subscribe(state => {
        this.displayEncountersWithNoHcc = Number(state.projectConfiguration?.codingOptimizationMode) === CodingOptimizationMode.HIDE_DX;
      })
    );
    this.getRiskData();
    this.sink.add(
      this.activatedRoute.paramMap.pipe(filter(params => this.chaseId !== +params.get("chaseGd"))).subscribe(() => {
        location.reload();
        this.changeDetector.markForCheck();
      })
    );
  }

  private getRiskData(): void {
    const isRisk20Project = true;
    this.riskService.initData(this.chaseId, isRisk20Project, this.displayEncountersWithNoHcc, () => {
      this.subscribeToState();
      this.getEncounterTypes();
      this.getOmissionCodes();
      this.changeDetector.markForCheck();
    });
  }

  private subscribeToState(): void {
    this.sink.add(
      combineLatest([
        this.riskService.data,
        this.chaseDetailState.state,
      ]).subscribe(([riskState, chaseDetailState]: any) => {
        this.riskState = riskState;
        this.setStateProperties(riskState, chaseDetailState);
        this.getMenuItems();
        this.getVrcs();
      }),
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        this.chaseDetailV2ChartRiskService.setPreviousUrl(this.previousUrl);
      })
    );
  }

  private setStateProperties(state: RiskState, chaseDetailState: ChaseDetailState): void {
    this.hasEncounterTypes = BooleanHelper.tryGet(state.hasEncounterTypes, false);

    this.hasOmissionCodes = BooleanHelper.tryGet(state.hasOmissionCodes, false);
    if (chaseDetailState != null && chaseDetailState.hasProject) {
      this.measureYear = chaseDetailState.measureYear;
      this.projectType = chaseDetailState.project.projectTypeName.toUpperCase();
      this.workflowStatus = WorkflowStatusDb[chaseDetailState.workflowStatus];
      this.chaseId = chaseDetailState.chaseId;
    }
  }


  private getMenuItems(): void {
    if (this.hasEncounterTypes) {
      this.chaseDetailV2ChartRiskService
        .getMenuItems()
        .subscribe(menuItems => {
          this.menuItems = this.setMenuItems(menuItems);
          this.changeDetector.markForCheck();
        });
    }
  }
  private getVrcs(): void {
    if (!this.measureYear || !this.projectType || !this.workflowStatus || !this.chaseId) {
      setTimeout(() => this.getVrcs(), 50);
      return;
    }

    if (!this.hasVrcs) {
      this.hasVrcs = true;
      this.vrcService.getVrcs(this.measureYear, this.projectType, this.workflowStatus, this.chaseId)
        .pipe(map(this.filterVrcs.bind(this)))
        .subscribe(this.setStateVrcs.bind(this));
    }
  }

  private filterVrcs(vrcs: SelectableInput[]): SelectableInput[] {
    return vrcs.filter(this.removeVrcs.bind(this));
  }

  private removeVrcs(vrc: SelectableInput): boolean {
    return !this.vrcsRemovedFromTheDropdown.includes(vrc.value.toString());
  }

  private setStateVrcs(vrcs: SelectableInput[]): void {
    this.riskService.setData({ vrcs });
  }

  private setMenuItems(menuItems: MenuItem[]): List<MenuItem> {
    const newArr: MenuItem[] = menuItems.map(item => {
      if (item.description !== "Member") {
        return new MenuItem({
          ...item,
          extra: {
            ...item.extra,
            isDisabled: this.shouldDisableTab(item),
            count: item.description === "Encounters" ? this.riskState.encounterCount : this.riskState.validEncounterCount,
          },
        });
      }
      return item;
    });

    return List(newArr);
  }

  private shouldDisableTab(item: MenuItem) {
    if (!this.riskState.assignedToCurrentUser) {
      return false;
    }

    return item.description === "Submit" ? !this.enableSubmitTab() : !this.riskState.isEnabled;
  }

  private enableSubmitTab(): boolean {
    return this.chaseDetailV2ChartRiskService.isSubmitEnabled(this.riskState);
  }

  private getEncounterTypes(): void {
    if (!this.hasEncounterTypes) {
      this.riskService.getEncounterTypes().subscribe(this.setStateEncounterTypes.bind(this));
    }
  }

  private setStateEncounterTypes(encounterTypes: SelectableInput[]): void {
    this.riskService.setData({ encounterTypes });
  }

  onTabSelected(value: any) {
    this.chaseDetailState.selectedTab.next(value);
    this.selectedSubMenu.emit(value);
  }

  private getOmissionCodes(): void {
    if (!this.hasOmissionCodes) {
      this.omissionCodeService.getOmissionCodes(this.chaseId, OMISSION_CODE.attributeCode)
        .pipe(filter(this.filterOmissionCodes.bind(this)))
        .subscribe(this.setStateOmissionCodes.bind(this));
    }
  }

  private setStateOmissionCodes(omissionCodes: SelectableInput[]): void {
    this.riskService.setData({ omissionCodes });
  }

  private filterOmissionCodes(omissionCodes: SelectableInput[]): SelectableInput[] {
    return omissionCodes.filter(code => code.extra.isActive);
  }
}
