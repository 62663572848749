import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../core/environment.tokens";
import { ProviderPacketItem } from "../../modules/internal-pends/internal-pends-detail/internal-pends-detail-info/provider-packet-item.model";
import { ContactRequest } from "../../modules/retrieval/cover-letter-template/contact-request.model";
import { EmailProviderRequest } from "../../modules/retrieval/psr/address-detail/address-detail-info/email-provider-request-model";
import { FaxProviderRequest } from "./fax-provider-request-model";
import { ProviderSearchRequest } from "./provider-search-request-model";
import { ProviderItem } from "./provider-search-result-item";

@Injectable({
  providedIn: "root",
})
export class ProviderService {
  reset = new Subject<any>();
  constructor(@Inject(BASE_API_URL)
  private readonly baseApiUrl: string,
              private http: HttpClient,
              private automapper: AutomapperService
  ) { }

  resetFunction(value: string) {

    this.reset.next(value);
  }

  providerSearch(searchRequest: ProviderSearchRequest): Observable<ProviderItem[]> {
    const url = `${this.baseApiUrl}provider/list`;

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    const headers = new HttpHeaders().set("content-type", "application/json");

    return this.http.post(url, searchRequest, { headers }).pipe(
      map(this.automapper.curryMany("provider-search-item", "ProviderItem"))
    );
  }

  updateProviderInfo(providerDetailEditModel: ProviderItem, retrievalId: number): Observable<number> {

    const url = `${this.baseApiUrl}provider/UpdateProviderInfo?retrievalId=${retrievalId}`;

    return this.http
      .post(url, providerDetailEditModel, { observe: "response" })
      .pipe(
        map((response: HttpResponse<any>): number => response.ok ? 1 : 0)
      );
  }

  sendFaxProviderPacket(faxProviderModel: FaxProviderRequest) {
    const url = `${this.baseApiUrl}provider/faxProviderPacket`;

    return this.http
      .post(url, faxProviderModel);
  }

  printProviderPacket(providerPacketItem: ProviderPacketItem): any {
    const url = `${this.baseApiUrl}provider/printRequest`;
    const httpOptions = {
      responseType  : "arraybuffer" as "json",
    };

    return this.http.post<any>(url, providerPacketItem, httpOptions);
  }

  sendEmailProviderRequest(emailProviderModel: EmailProviderRequest): Observable<boolean> {
    const url = `${this.baseApiUrl}provider/email/request/send`;

    return this.http
      .post(url, emailProviderModel, { observe: "response" })
      .pipe(
        map((response: HttpResponse<any>): boolean => response.ok)
      );
  }

  sendProviderContactRequest(contactRequest: ContactRequest): Observable<null> {
    const url = `${this.baseApiUrl}provider/contact/request`;
    return this.http.post(url, contactRequest) as Observable<null>;
  }

  updateProviderVerification(searchRequest: ProviderSearchRequest): Observable<null> {
    const url = `${this.baseApiUrl}provider/verification`;
    const headers = new HttpHeaders().set("content-type", "application/json");

    return this.http.post(url, searchRequest, { headers }) as Observable<null>;
  }

  downloadProviderPacket(providerPacketId: number, contactMethodTypeId: number): Observable<string> {
    const url = `${this.baseApiUrl}provider/downloadProviderPacket?providerPacketId=${providerPacketId}&contactMethodType=${contactMethodTypeId}`;

    return this.http.post(url, {}) as Observable<string>;
  }

  downloadPdf(data: any): void {
    const blob = new Blob([data], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;

    const fileName = a.href.substring(a.href.lastIndexOf("/") + 1);
    a.download = `${fileName}.pdf`;
    a.click();
    a.remove();
  }
}
