<div class="main-container">
  <span class="header bold">AUDIT RESULTS</span>
  <br>
  <div class="filters">
    <form-factory class="filter-input" [formGroup]="form" [model]="projectInput"
                  (onChange)="onProjectChange($event)"></form-factory>
    <form-factory class="filter-input" [formGroup]="form" [model]="requestTypeInput"
                  (onChange)="onPackageChange($event)"></form-factory>
    <form-factory *ngIf="isXmlSelected" class="filter-input__checkbox" [formGroup]="form"
                  [model]="processModeCheckbox"></form-factory>
    <app-button class="filter-input__button" title="GENERATE SUBMISSION FILES" text="GENERATE SUBMISSION FILES"
                [disabled]="!isProjectSelected || !isTypeSelected" (onClick)="onSubmit()"></app-button>
  </div>
  <ng-container *ngIf="!isGridLoading">
    <div *ngIf="gridHasData else noResults">
      <app-basic-grid [configuration]="gridConfigurationModel" [data]="data" [actionTemplate]="actionTemplate"
                      [rowExpandTemplate]="rowExpandTemplate" (onRowExpand)="onRowExpand($event)">
        <ng-template #rowExpandTemplate>
          <div>
            <ng-container *ngIf="!isGridLoading else loading">
              <div *ngIf="downloadsGridHasData else noResults">
                <app-basic-grid class="grid-row-expansion"
                                [configuration]="downloadsGridConfigurationModel"
                                [data]="requestDownloads">
                </app-basic-grid>
              </div>
            </ng-container>
          </div>
        </ng-template>
        <ng-template #actionTemplate let-rowData let-localActionPopover="actionPopover">
          <div class="action-template-container">
            <app-button *ngFor="let action of actions; trackBy: trackByIndex" [text]="action.name"
                        (onClick)="action.action(rowData); localActionPopover.hide();"
                        [disabled]="rowData.isDisabled"></app-button>
          </div>
        </ng-template>
      </app-basic-grid>
    </div>
    <ng-template #noResults>
      <p>No results available</p>
    </ng-template>
  </ng-container>
</div>
<app-modal [(visible)]="isUploadRequestVisible"
           header="Upload | Request Id: {{request?.requestId}} | {{request?.projectName}} | {{request?.requestTypeName}}">
  <div class="modal-container" *ngIf="statusCanBeUpdated else uploadNotAvailable">
    <div class="status-filter">
      <form-factory class="status-filter__input" [formGroup]="uploadForm" [model]="fileTypeInput"></form-factory>
    </div>
    <div class="status-filter" *ngIf="!isUploadSuccessOrFailed">
      <form-factory class="status-filter__input" [formGroup]="modalForm" [model]="statusInput"></form-factory>
    </div>
    <div class="text-left">
      <p-fileUpload #fileUpload name="file[]" (onSelect)="onSelectFile($event)" mode="advanced"
                    chooseLabel="Choose file"
                    [customUpload]="true"
                    [multiple]="false" accept=".zip" [maxFileSize]="25000000" [showCancelButton]="false"
                    [showUploadButton]="false">
        <ng-template let-file pTemplate="file">
          <br>
          <div>{{ file?.name }} - {{ file?.size / 1000 }} KB</div>
        </ng-template>
      </p-fileUpload>
    </div>
  </div>
  <ng-template #uploadNotAvailable>
    <div class="text-center">
      <p>Current status does not allow uploads.</p>
    </div>
  </ng-template>
  <footer *ngIf="isUploadSuccessOrFailed else auditToolButton">
    <app-button [disabled]="!uploadFilterHasOptions || !isFileSelected || !isFileTypeSelected" text="Upload"
                (onClick)="uploadFile(request)"></app-button>
  </footer>
  <ng-template #auditToolButton>
    <footer>
      <app-button
        [disabled]="!uploadFilterHasOptions || !isFileSelected || !isFileTypeSelected || !isStatusSelected"
        text="Upload" (onClick)="uploadFile(request)"></app-button>
    </footer>
  </ng-template>
</app-modal>
<app-modal [(visible)]="isUpdateRequestVisible"
           header="Update Status | Request Id: {{request?.requestId}} | {{request?.projectName}} | {{request?.requestTypeName}}">
  <div class="modal-container" *ngIf="filterHasOptions else noStatusUpdate">
    <div class="status-filter">
      <form-factory class="status-filter__input" [formGroup]="modalForm" [model]="statusInput"></form-factory>
    </div>
  </div>
  <ng-template #noStatusUpdate>
    <div class="text-center">
      <p>Current status can't be updated.</p>
    </div>
  </ng-template>
  <footer>
    <app-button [disabled]="!filterHasOptions" text="Update" (onClick)="updateRequest(request)"></app-button>
  </footer>
</app-modal>
<ng-template #downloadAction let-rowData>
  <app-button title="Download" [disabled]="!downloadHasUrl(rowData)" text="Download"
              (onClick)="onDownloadFile(rowData)"></app-button>
</ng-template>
<ng-template #loading>
  <p>Loading results</p>
</ng-template>
<app-confirmation-modal [(visible)]="showConfirmationModal" header="Do you want to proceed?"
                        (onYes)="updateRequest(request, true)">
  Are you sure want to cancel this request, no further changes will be allowed.
</app-confirmation-modal>
