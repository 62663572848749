export enum AuditStatus {
    InProgress = 1,
    AvailableForDownload = 2,
    CompletedWithErrors = 3,
    Downloaded = 4,
    Verified = 5,
    VerificationFailed = 6,
    UploadedToAuditTool = 7,
    UploadSuccessful = 8,
    UploadFailed = 9,
    Cancelled = 10,
}
