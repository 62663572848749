<div class="dx-grid" [ngClass]="{'active': addNewDx}">

  <table class="dx-grid__table">
    <thead>
      <tr>
        <td class="dx-grid__table--empty-cell">Valid</td>
        <td class="dx-grid__table--date-of-service-cell">DOS</td>
        <td class="dx-grid__table--icd-cell">ICD Code</td>
        <td class="dx-grid__table--provider-cell">Provider</td>
        <td class="dx-grid__table--page-cell">Page</td>

      </tr>
    </thead>
    <tbody>
      <tr app-risk-dx-admin-grid-row *ngFor="let diagnosis of diagnoses; trackBy: trackByIndex;"
          [diagnosis]="diagnosis" [disabled]="disabled" [icdGroup]="icdGroup" [riskChaseState]="riskChaseState"
          (removeCoderAddedDiagnosis)="removeCoderAddedDiagnosis($event)" (resetAll) ="onResetDiagnosis($event)" (onValid)="onValidDiagnosis($event)" (onInvalid)="onInValidDiagnosis($event)"
          >
      </tr>

      <tr app-risk-dx-add-admin-grid-row *ngIf="addNewDx"
      [riskChaseState]="riskChaseState" 
      [icdGroup]="icdGroup" 
      (saveDiagnosis)="saveDiagnosis($event)"
      (hideDiagnosisForm)="clearDiagnosis($event)"
      ></tr>

    </tbody>
  </table>
</div>
