import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, map, skip, takeUntil } from "rxjs/operators";
import { SubSink } from "subsink";
import { LocalService } from "../../../../../../core/storage/local.service";
import { SessionService } from "../../../../../../core/storage/session.service";
import { DocumentListItem } from "../../../../../../shared/document/chase-document-list/document-list-item.model";
import { CacheTracker } from "../../../../../../shared/document/document-page-viewer/cache-tracker.model";
import { DocumentPageViewerComponent } from "../../../../../../shared/document/document-page-viewer/document-page-viewer.component";
import { DocumentThumbnailState } from "../../../../../../shared/document/document-page-viewer/document-thumbnail-state.model";
import { DocumentThumbnailType } from "../../../../../../shared/document/document-page-viewer/document-thumbnail-type.enum";
import { SourceDocumentType } from "../../../../../../shared/document/document-page-viewer/source-document-type.enum";
import { MemberCentricChase } from "../../../../../../shared/membercentric-doc-attachment/membercentric-chase.model";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { BooleanHelper } from "../../../../../../utilities/contracts/boolean-helper";
import { NumberHelper } from "../../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";
import { debounceTimeAfterFirst } from "../../../../../../utilities/debounce-time-after";
import { LoaderHelper } from "../../../../../../utilities/loader-helper.class";
import { JobsqueueService } from "../../../../jobsqueue/jobsqueue.service";
import { DocumentPage } from "../../../../retrieval/retreival-document-review/document-page.model";
import { DocumentPages } from "../../../../retrieval/retreival-document-review/document-pages.models";
import { RetrievalDocumentServiceService } from "../../../../retrieval/retrieval-document-service.service";
import { ChartService } from "../../../chase-detail/chase-detail-chart/chart.service";
import { ChaseDetailStateService } from "../../../chase-detail/chase-detail-state.service";
import { ChaseDocumentService } from "../../../chase-document.service";
import { DiagnosisService } from "../../chase-detail-v2-chart/risk/risk-encounter/diagnosis/diagnosis.service";
import { ClinicalTermsService } from "../../chase-detail-v2-chart/risk/risk-encounter/eve-clinical-terms/clinical-terms.service";
import { ChaseDetailV2ChartRiskService } from "../../chase-detail-v2-chart/risk/risk.service";
import { DocumentViewerSessionService } from "../../document-viewer-session.service";

@Component({
  selector: "app-chase-document-page-thumbnail-expand-labeled",
  templateUrl: "./chase-document-page-thumbnail-expand-labeled.component.html",
  styleUrls: ["./chase-document-page-thumbnail-expand-labeled.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseDocumentPageThumbnailExpandLabeledComponent extends LoaderHelper implements OnInit, OnChanges, OnDestroy {
  @Input()
  set currentTabSelected(val: string) {
    this.memberTabSelected = val === "member";
    this.encounterTabSelected = val === "encounters";
    this.submitTabSelected = val === "submit";

    this.memberNameFilter = true;
    this.dobFilter = true;
    this.genderFilter = true;
    this.providerFilter = true;
    this.dosFilter = true;

    if (this.submitTabSelected) {
      this.diagnosisService.toggleDiagnosisSelected(false);
    }

    if (this.memberTabSelected) {
      this.diagnosisService.toggleDiagnosisSelected(false);
    }

    if (this.selectedTabText !== val) {
      this.selectedTabText = val;
      this.documentViewerSessionService.setNoEvidenceSelected(false);
    }
  }
  //#region

  /* https://material.angular.io/cdk/scrolling/api#CdkFixedSizeVirtualScroll  */
  get minBuffer(): number {
    return ChaseDocumentPageThumbnailExpandLabeledComponent.itemSize * 4;
  }

  get maxBuffer(): number {
    return ChaseDocumentPageThumbnailExpandLabeledComponent.itemSize * 6;
  }
  get itemSizeHeight(): number {
    return ChaseDocumentPageThumbnailExpandLabeledComponent.itemSize;
  }

  //#endregion
  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly documentViewerSessionService: DocumentViewerSessionService,
    private readonly chaseDetailStateService: ChaseDetailStateService,
    private readonly chartService: ChartService,
    private jobsqueueService: JobsqueueService,
    private readonly retrievalDocumentService: RetrievalDocumentServiceService,
    protected readonly zone: NgZone,
    private chaseDocumentService: ChaseDocumentService,
    private sessionService: SessionService,
    private localService: LocalService,
    private clinicalTermsService: ClinicalTermsService,
    private readonly chaseDetailV2ChartRiskService: ChaseDetailV2ChartRiskService,
    private readonly diagnosisService: DiagnosisService
  ) {
    super(zone);
  }

  get cleanDocumentId(): number {
    return Number(DocumentPageViewerComponent.documentId.getValue());
  }

  get pages$(): BehaviorSubject<(DocumentPage | null)[]> {
    return this.state.pages$;
  }

  get pages(): DocumentPage[] {
    return this.state.pages;
  }

  get isWindowOpen(): boolean {
    return this.localService.get(this.localStorageIsWindowOpen, null) === "1";
  }

  get currentThumbnailNumber(): number {
    return this.state.currentThumbnailNumber;
  }
  set currentThumbnailNumber(value: number) {
    this.state.currentThumbnailNumber = value;
    this.changeDetector.markForCheck();
  }

  get currentDocumentQueueId(): number {
    return this.state.currentDocumentQueueId;
  }
  set currentDocumentQueueId(value: number) {
    this.state.currentDocumentQueueId = value;
    this.changeDetector.markForCheck();
  }

  get totalPages(): number {
    return this.state.totalPages;
  }

  get showThumbnailLabels(): boolean {
    return !(this.isOverread || this.isOverread2) && this.isProjectEnabledForRiskNlp;
  }

  get isThumbnailDataReady(): boolean {
    return this.isProjectEnabledForRiskNlp;
  }
  static documentId: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  static readonly itemSize = 350;
  @ViewChild(CdkVirtualScrollViewport, { static: true }) private thumbnailExpandedViewport: CdkVirtualScrollViewport;
  @Input() chaseId: number;
  @Input() addressId: number;
  @Input() projectId: number;
  @Input() currentChartPageNumber = 1;
  @Input() isMaximized = false;
  @Input() documentPageIds: number[] = [];
  @Input() isSplitScreenMainTab = false;
  @Output() expandedLabelView = new EventEmitter<boolean>();
  private readonly NUMBER_OF_THUMBNAILS_PER_ROW = 4;
  private currentThumbnailNumber$ = new BehaviorSubject<number>(0);
  private cacheTracker: CacheTracker;
  private sink = new SubSink();
  private state: DocumentThumbnailState;
  private selectedEncounter = "";
  private isNoEvidenceFilterSelected = false;
  private isDiagnosisSelected = false;
  private previousUrl = "";
  private isProjectEnabledForRiskNlp: boolean;
  private isOverread = false;
  private isOverread2 = false;
  private isEveEncounter: boolean;
  private isEveNotMatchingDiagnosis: boolean;
  readonly AUTHENTICATION_KEY = "authentication";
  private forceThumbnailLoad = false;
  private currentThumbnailPages: DocumentPage[] = [];
  private breakSubscription$ = new Subject<void>();

  allThumbnails: any[];
  clearCache = false;
  selectedThumbnail: number;
  thumbnailFilterValue: number;
  memberChases: MemberCentricChase[];
  totalChartPages: number;
  isFromToggledView = false;
  isBucketFolderChecked = false;
  currentSpineColorIndex = 0;
  cdnBucketFolders = [];
  documentListItems: DocumentListItem[];
  maxDocuments = 0;
  diagnosisFilterSelected = false;
  isFilterButtonselected = false;
  selectedGroup: string;
  selectedDiagnosisType: string;
  getSelectedFilters: string[] = ["Member Name", "DOB", "Gender", "Provider", "DOS"];
  memberTabSelected = false;
  encounterTabSelected = false;
  submitTabSelected = false;
  memberNameFilter = true;
  dobFilter = true;
  genderFilter = true;
  providerFilter = true;
  dosFilter = true;
  diagnosisFilter = true;
  signsFilter = true;
  symptomsFilter = true;
  medicationsFilter = true;
  private localStorageIsWindowOpen = "isWindowOpen";
  private localStoragePageNumber = "pageNumber";
  localStorageDocumentIds = "documentStorageIds";
  selectedTabText = "";
  encounterStartDate = "";
  encounterEndDate = "";
  isTermsActive = false;
  termsPagesNumber: number[] = [];
  defaultEncounterStartDate: Date;
  defaultEncounterEndDate: Date;
  diagnosisNoEvidenceFilter = false;
  encounterNoEvidenceFilter = false;
  memberNoEvidenceFilter = false;

  getFormattedDate(date): string {
    const year = date?.getFullYear();
    const month = (date?.getMonth() + 1).toString().padStart(2, "0");
    const day = date?.getDate().toString().padStart(2, "0");
    return `${month}/${day}/${year}`;
  }

  ngOnInit() {
    this.state = new DocumentThumbnailState();
    this.cacheTracker = new CacheTracker();

    this.sink.add(
      this.breakSubscription$,
      this.chaseDetailStateService.state
        .pipe(
          map(state => {
            this.isProjectEnabledForRiskNlp = state.isProjectEnabledForRiskNlp;
            this.isOverread = state.isOverread;
            this.isOverread2 = state.isOverread2;
          }))
        .subscribe(),

      this.currentThumbnailNumber$.pipe(debounceTimeAfterFirst(50)).subscribe(pageNumber => {
        this.currentThumbnailNumber = pageNumber;
      }),

      this.documentViewerSessionService.isNoEvidenceFilterSelected$
        .subscribe(isNoEvidenceFilterSelected => {
          this.isNoEvidenceFilterSelected = isNoEvidenceFilterSelected;
        }),

      this.chaseDetailStateService.memberChases.subscribe(data => {
        this.memberChases = data;
      }),

      this.jobsqueueService.newJobEvent.subscribe(data => {
        if (data.find(d => d.stateMachineName === "MRCS Chart Upload")) {
          this.resetThumbnailView();
          if (!this.encounterTabSelected) {
            this.encounterStartDate = "";
            this.encounterEndDate = "";
          }
          this.initialThumbnailLoad();
          this.changeDetector.markForCheck();
        }
      }),
      this.clinicalTermsService.pagesNumber$.subscribe(pagesNumber => {
        this.isTermsActive = ArrayHelper.isAvailable(pagesNumber);
        this.termsPagesNumber = pagesNumber;
        this.initialThumbnailLoad();
      }),
      this.chaseDetailV2ChartRiskService.previousUrl$
        .pipe(
          map(previousUrl => this.previousUrl = previousUrl),
          filter((previousUrl: string) => previousUrl?.includes("submit") || previousUrl?.includes("member")))
        .subscribe(_ => this.encounterTabSelected = true),

      this.chaseDetailStateService.encounterSelectionEvent$
        .pipe(
          filter(data => data && this.isSplitScreenMainTab && this.encounterTabSelected && !this.isDiagnosisSelected),
          map(data => this.encounterSelectionSetup(data[0], data[1])))
        .subscribe(() => this.initialThumbnailLoad()),

      this.diagnosisService.isDiagnosisSelected$.subscribe(isOpen => {
        this.isDiagnosisSelected = isOpen;
        if (isOpen && this.encounterTabSelected) {
          this.diagnosisFilter = true;
          this.signsFilter = true;
          this.symptomsFilter = true;
          this.medicationsFilter = true;
          this.initialThumbnailLoad();
        }
      }),

      this.chaseDetailStateService.selectedFilters.subscribe(data => {
        this.breakSubscription$.next();
        if (data != null) {
          this.getSelectedFilters = data;

          this.memberNameFilter = this.getSelectedFilters.indexOf("Member Name") > -1;
          this.dobFilter = this.getSelectedFilters.indexOf("DOB") > -1;
          this.genderFilter = this.getSelectedFilters.indexOf("Gender") > -1;
          this.providerFilter = this.getSelectedFilters.indexOf("Provider") > -1;
          this.dosFilter = this.getSelectedFilters.indexOf("DOS") > -1;

          this.diagnosisFilter = this.getSelectedFilters.indexOf("Diagnosis") > -1;
          this.signsFilter = this.getSelectedFilters.indexOf("Signs") > -1;
          this.symptomsFilter = this.getSelectedFilters.indexOf("Symptoms") > -1;
          this.medicationsFilter = this.getSelectedFilters.indexOf("Medications") > -1;
          this.initialThumbnailLoad();
        }
        this.changeDetector.markForCheck();
      }),

      this.chaseDetailStateService.isEncounterSelected$
        .pipe(
          filter(encounter => encounter && encounter.id !== this.selectedEncounter && encounter.startDate && encounter.endDate && this.encounterTabSelected && !this.isSplitScreenMainTab),
          map(encounter => {
            this.defaultEncounterStartDate = encounter.startDate;
            this.defaultEncounterEndDate = encounter.endDate;
            this.selectedEncounter = encounter.id;
            this.isEveEncounter = encounter.isAdminWithEveMatch || encounter.isEve;
            this.encounterSelectionSetup(encounter.startDate, encounter.endDate);
            this.initialThumbnailLoad();
            this.changeDetector.markForCheck();
          })
        ).subscribe(),

      this.chaseDetailStateService.encounterDetails.subscribe(data => {
        if (data) {
          this.diagnosisFilterSelected = data?.isDiagnosSelected;
          this.documentViewerSessionService.setNoEvidenceSelected(false);
        }
        this.changeDetector?.markForCheck();
      }),

      this.chaseDetailStateService.isFilterButtonSelected.subscribe(data => {
        if (data !== undefined) {
          this.isFilterButtonselected = data;
        }
      }),

      this.chaseDetailStateService.thumbnailLabelFilterByDiagnosis.subscribe(data => {
        if (!NumberHelper.isAvailable(data?.diagnosisId) && data?.isManuallyAddedSource) {
          return;
        }
        if (data && Object.keys(data).length > 0) {
          this.selectedDiagnosisType = data.isEveDiagnosis ? "Eve" :
            data.isAdmin ? "Admin" : "";
          this.selectedGroup = data.isEveDiagnosis ? data.diagnosisCode : data.icd;
          this.selectedGroup = this.selectedGroup == undefined ? "" : this.selectedGroup;
          if ((data.startDate && data.endDate) || (StringHelper.isAvailable(data.dosFrom))) {
            this.encounterStartDate = data.startDate ? this.getFormattedDate(data.startDate) : data.dosFrom;
            this.encounterEndDate = data.endDate ? this.getFormattedDate(data.endDate) : data.dosThrough;
          }
          this.diagnosisFilterSelected = true;
          this.isEveNotMatchingDiagnosis = this.isEveEncounter && !data.isEveMatch && !data.isEveDiagnosis;
        } else {
          this.selectedDiagnosisType = "";
          this.selectedGroup = "";
        }
      }),

      this.documentViewerSessionService.updatedDocumentPageIds$.subscribe(list => {
        this.documentPageIds = list;
      }),

      this.diagnosisService.tabSelected$.subscribe(tab => {
        this.selectedTabText = tab;
        this.submitTabSelected = tab === "submit";
        this.encounterStartDate = this.submitTabSelected ? "" : this.defaultEncounterStartDate ? this.getFormattedDate(this.defaultEncounterStartDate) : "";
        this.encounterEndDate = this.submitTabSelected ? "" : this.defaultEncounterEndDate ? this.getFormattedDate(this.defaultEncounterEndDate) : "";
        if (this.submitTabSelected) {
          this.selectedDiagnosisType = "";
          this.selectedGroup = "";
          this.diagnosisFilterSelected = false;
          this.initialThumbnailLoad();
        }
      })
    );

    if (this.isThumbnailDataReady && !this.submitTabSelected) {
      this.initialThumbnailLoad();
    }

    if (this.submitTabSelected) {
      this.clearAllFilters();
      this.initialThumbnailLoad();
    }

    this.getDocumentListItems();
    this.changeDetector.detectChanges();
    this.expandedLabelView.emit(true);

    this.sink.add(this.thumbnailExpandedViewport.scrolledIndexChange.pipe(
      skip(2),
      filter(index => NumberHelper.isAvailable(index)),
      distinctUntilChanged(),
      debounceTime(500)
    ).subscribe(index => this.updateIndex(index)));
  }

  getEvidencedata(item: any, val: string) {

    let evidenceData;
    if (val === "text") {
      evidenceData = item.map(ele => ele.text).filter((value, index, self) => self.indexOf(value) === index).join(", ");
      return evidenceData;
    }

    if (val === "chartlocation") {
      evidenceData = item.map(ele => ele.chartLocation).filter((value, index, self) => self.indexOf(value) === index).join(", ");
      return evidenceData;
    }
  }

  getDiagnosisData(item: any): string {
    const diagnosisData = item
      .filter(diagnosis => diagnosis.diagnosisCode === this.selectedGroup)
      .map(ele => ele.labelText || ele.text)
      .filter((value, index, self) => self.indexOf(value) === index)
      .join(", ");

    if (diagnosisData.indexOf(",") === 0) {
      return diagnosisData.substring(1);
    }
    return diagnosisData;
  }

  isDiagnosisLabelAvailble(data: any[], isFilterSelected: boolean): boolean {
    return data && isFilterSelected && StringHelper.isAvailable(this.getDiagnosisData(data));
  }

  isEvidenceLabelAvailble(evidence: any[], isFilterSelected: boolean, thumbnail?: any): boolean {
    return evidence && isFilterSelected;
  }

  isMemberLabelAvailble(evidence: any[], isFilterSelected: boolean): boolean {
    return evidence && isFilterSelected;
  }

  @HostListener("window:beforeunload")
  ngOnDestroy() {
    this.sink.unsubscribe();
    this.cleanCacheAfterNGDestory();
    this.resetThumbnailView();
    this.expandedLabelView.emit(false);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentChartPageNumber) {
      if (changes.currentChartPageNumber.currentValue !== changes.currentChartPageNumber.previousValue) {
        this.currentThumbnailNumber$.next(changes.currentChartPageNumber.currentValue);
        this.selectedThumbnail = changes.currentChartPageNumber.currentValue;
        this.changeDetector.markForCheck();
      }
    }

    if ((!this.isDiagnosisSelected && changes.documentPageIds && !ArrayHelper.compareArrayIsEqual(changes.documentPageIds.currentValue, changes.documentPageIds.previousValue))) {
      const user = this.sessionService.get<any>(this.AUTHENTICATION_KEY, {});
      if (user.enableThumbnailView) {
        if (!this.submitTabSelected) {
          if (!this.encounterTabSelected) {
            this.diagnosisFilterSelected = false;
            this.encounterStartDate = "";
            this.encounterEndDate = "";
            this.selectedDiagnosisType = "";
            this.selectedGroup = "";
          }
          this.initialThumbnailLoad();
        }
      }
    }
  }

  getDocumentListItems(): void {
    this.chaseDocumentService
      .getDocumentListItems(this.chaseId, this.maxDocuments)
      .subscribe(result => {
        this.documentListItems = result;
        this.changeDetector.markForCheck();
      });
  }

  updateCurrentPage(pageNumber: number): void {
    this.currentThumbnailNumber = pageNumber;
    this.selectedThumbnail = pageNumber;
    this.documentViewerSessionService.updateDataEntryPageNumber(pageNumber);
  }

  hasPageAndSource(page: DocumentPage): boolean {
    return page != null && StringHelper.isAvailable(page.source);
  }

  hasThumbnailImageUrl(thumbnail: DocumentPage): boolean {
    return StringHelper.isAvailable(thumbnail.image);
  }

  isThumbnailSelected(thumbnail: DocumentPage): boolean {
    return this.selectedThumbnail === thumbnail.pageNumber;
  }

  updateIndex(index: number): void {
    const pageNumber = (index + 1) * this.NUMBER_OF_THUMBNAILS_PER_ROW;
    this.currentThumbnailNumber = pageNumber;
    this.tryLoading();
  }

  getThumbnailSource(thumbnail: DocumentPage): string {
    return this.hasThumbnailImageUrl ? (thumbnail.isCdnChartImageExist ? thumbnail.source.replace("data:image/jpg;base64,", "")
      : thumbnail.source) : "";
  }

  isDocumentThumbnail(thumbnail: DocumentPage): boolean {
    return thumbnail.documentThumbnail.thumbnailType === DocumentThumbnailType.THUMBNAIL_IMAGE;
  }

  thumbnailClicked(thumbnail: DocumentPage): void {
    if (this.localService.get(this.localStorageIsWindowOpen, null) === "1") {
      this.localService.put(this.localStoragePageNumber, thumbnail.pageNumber);
    }
    this.updateCurrentPage(thumbnail.pageNumber);
  }

  trackByIndex(index: number) {
    return index;
  }

  private encounterSelectionSetup(startDate: Date, endDate: Date) {
    this.documentViewerSessionService.setNoEvidenceSelected(false);
    this.selectedGroup = "";
    this.selectedDiagnosisType = "";
    this.dosFilter = true;
    this.providerFilter = true;
    this.isFilterButtonselected = false;
    this.diagnosisFilterSelected = false;
    this.selectedThumbnail = 1;
    this.encounterStartDate = startDate ? this.getFormattedDate(startDate) : "";
    this.encounterEndDate = endDate ? this.getFormattedDate(endDate) : "";
  }

  private isEvidenceAvailable(evidencesCollection: any[]): boolean {
    const specificityDelimiter = ".";

    if (!this.selectedGroup.includes(specificityDelimiter)) {
      if (StringHelper.isAvailable(this.selectedGroup) && this.selectedGroup.indexOf(specificityDelimiter) < 0 && this.selectedGroup.length > 3 && this.selectedGroup.indexOf("-") < 0) {
        this.selectedGroup = this.selectedGroup.substring(0, 3) + specificityDelimiter + this.selectedGroup.substring(3, this.selectedGroup.length);
      }
    }
    return ArrayHelper.isAvailable(evidencesCollection) &&
      evidencesCollection.some(evidence => evidence.diagnosisCode.replace(".", "") === this.selectedGroup.replace(".", ""));
  }

  private clearAllFilters(): void {
    this.clearDiagnosisFilters();
    this.clearMemberFilters();
    this.clearEncounterFilters();
  }

  private clearDiagnosisFilters(): void {
    this.diagnosisFilter =
      this.signsFilter =
      this.symptomsFilter =
      this.medicationsFilter = false;
  }

  private clearMemberFilters(): void {
    this.memberNameFilter = this.dobFilter = this.genderFilter = false;
  }

  private clearEncounterFilters(): void {
    this.dosFilter = this.providerFilter = false;
  }

  private updateFilters(): void {
    this.chaseDetailStateService.setFilterButtonSelection(
      [
        this.diagnosisFilter,
        this.signsFilter,
        this.symptomsFilter,
        this.medicationsFilter,
        this.dosFilter,
        this.providerFilter,
        this.memberNameFilter,
        this.dobFilter,
        this.genderFilter,
        this.isNoEvidenceFilterSelected,
      ]
    );
  }

  private forceNoEvidenceFilter(): void {
    this.clearAllFilters();
    this.updateFilters();
  }

  private initialThumbnailLoad(): void {
    if (!BooleanHelper.isAvailable(this.isProjectEnabledForRiskNlp)) {
      return;
    }

    this.forceThumbnailLoad = !ArrayHelper.isAvailable(this.documentPageIds);

    if (!this.isSplitScreenMainTab) {
      this.selectedGroup = "";
      this.selectedDiagnosisType = "";
    }

    const pagesToLoad = this.isTermsActive ? this.termsPagesNumber : [1, 15];

    pagesToLoad.forEach(pageNumber => this.state?.setThumbnailPage({ pageNumber } as any));
    this.loadThumbnails(pagesToLoad)
      .pipe(takeUntil(this.breakSubscription$))
      .subscribe(thumbnailPages => {
        if (this.forceThumbnailLoad) {
          this.forceNoEvidenceFilter();
        }
        const length = (thumbnailPages && ArrayHelper.isAvailable(thumbnailPages.pages)) ? this.getLengthOfThumbnailPages(thumbnailPages.pages[0]) : 0;
        this.isBucketFolderChecked = (thumbnailPages && thumbnailPages.isCdnBucketFolderChecked) ? thumbnailPages.isCdnBucketFolderChecked : false;
        this.getCdnImages(thumbnailPages);
        this.cacheTracker = new CacheTracker();
        this.state = new DocumentThumbnailState({
          documentId: this.cleanDocumentId,
          totalPages: length,
          pages: Array.from<DocumentPage>({ length }),
        });
        // Set Pages
        this.currentDocumentQueueId = ArrayHelper.isAvailable(thumbnailPages.pages) ? thumbnailPages.pages[0].documentQueueId : 0;
        const thumbnailPagesLength = thumbnailPages.pages.length;
        const emptyArray: DocumentPage[] = new Array(length - thumbnailPagesLength).fill({});
        const pagestToSet = [...thumbnailPages.pages, ...emptyArray].map((page: DocumentPage, index) => {
          if (!StringHelper.isAvailable(page.image)) {
            return new DocumentPage();
          }
          return page;
        });
        this.currentThumbnailPages = [...pagestToSet];
        this.setPages(pagestToSet);
        this.changeDetector.markForCheck();
      });

    this.chartService.getTotalChaseDocumentPages(this.chaseId).subscribe(
      data => {
        this.totalChartPages = data;
        if (ArrayHelper.isAvailable(this.documentPageIds)) {
          this.totalChartPages = this.documentPageIds.length;
        }
      });
    this.isTermsActive = false;
  }

  evidenceFilter(): boolean {
    if (this.submitTabSelected) {
      return true;
    }
    return this.isNoEvidenceFilterSelected;
  }

  getCdnImages(documentPages: DocumentPages) {
    this.cdnBucketFolders = documentPages.s3ImagesFolders;
  }

  private loadThumbnails(pages: number[]): Observable<DocumentPages> {
    const evidenceFitler = this.isTermsActive ? true : this.evidenceFilter();
    const begPage = pages[0];
    const endPage = pages[pages.length - 1];
    const documentPageIds = this.submitTabSelected ? null : this.documentPageIds;

    if (this.forceThumbnailLoad) {
      return this.retrievalDocumentService.getDocumentThumbnails(
        this.cleanDocumentId,
        SourceDocumentType.DocumentThumbnail,
        begPage,
        endPage,
        null,
        this.isBucketFolderChecked,
        documentPageIds,
        true,
        true,
        this.selectedTabText,
        this.cdnBucketFolders
      );
    }

    return this.retrievalDocumentService.getDocumentThumbnailsWithDocQueueId(
      this.cleanDocumentId,
      SourceDocumentType.DocumentThumbnail,
      begPage,
      endPage,
      this.thumbnailFilterValue, null, this.isBucketFolderChecked,
      documentPageIds, true, this.selectedGroup, this.selectedDiagnosisType, evidenceFitler, this.selectedTabText,
      this.isSplitScreenMainTab, this.diagnosisFilterSelected, this.encounterStartDate, this.encounterEndDate, this.isTermsActive, this.isProjectEnabledForRiskNlp, this.cdnBucketFolders);
  }

  private setPages(pages: DocumentPage[]): void {
    const pagesWithStyles = this.updatePageStyle(pages);
    this.filterDocumentPages(pagesWithStyles);
    this.changeDetector.markForCheck();
  }

  private cleanCacheAfterNGDestory(): void {
    this.clearCache = true;

    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        for (let i = 0; i < this.pages.length; ++i) {
          this.pages[i] = null;
        }
      });
    });
  }

  private tryLoading(): void {
    const isInRange = this.isInRange(this.currentThumbnailNumber, this.lowerLoadRange, this.upperLoadRange, this.currentThumbnailPages, "image");
    if (!isInRange) {
      return;
    }
    const pagesToLoad = this.getPagesToLoad(this.currentThumbnailNumber, 25, 24, this.currentThumbnailPages, "image");
    if (!ArrayHelper.isAvailable(pagesToLoad)) {
      return;
    }
    this.loadThumbnails(pagesToLoad).pipe(
      takeUntil(this.breakSubscription$)
    ).subscribe(thumbnailPages => {
      this.getCdnImages(thumbnailPages);
      let start = pagesToLoad[0] - 1;
      const elements = [...thumbnailPages.pages];
      elements.forEach(element => {
        this.currentThumbnailPages.splice(start, 1, element);
        start++;
      });
      this.setPages(this.currentThumbnailPages);
    });
  }

  private resetThumbnailView(): void {
    this.currentSpineColorIndex = 0;
    this.changeDetector.markForCheck();
  }

  private filterMemberPage(page: DocumentPage): boolean {
    const memberDOBEvidences = page.evidence?.memberDOBEvidences;
    const memberNameEvidences = page.evidence?.memberNameEvidences;
    const memberGenderEvidences = page.evidence?.memberGenderEvidences;

    return this.isFilterButtonselected ?
      ((memberDOBEvidences && this.dobFilter) ||
        (memberNameEvidences && this.memberNameFilter) ||
        (memberGenderEvidences && this.genderFilter) ||
        this.filterPagesWithNoEvidence([memberDOBEvidences, memberNameEvidences, memberGenderEvidences])) : true;
  }

  private filterEncounterPage(page: DocumentPage): boolean {
    const dosEvidences = page.evidence?.dosEvidences;
    const providerEvidences = page.evidence?.providerEvidences;

    return this.isFilterButtonselected ?
      ((dosEvidences && this.dosFilter) ||
        (providerEvidences && this.providerFilter) ||
        this.filterPagesWithNoEvidence([dosEvidences, providerEvidences])) : ArrayHelper.isAvailable(dosEvidences) || ArrayHelper.isAvailable(providerEvidences);
  }

  private filterDiagnosesPage(page: DocumentPage): boolean {
    if (this.isEveNotMatchingDiagnosis) {
      const filteredDos = page.evidence?.dosEvidences?.filter(dos =>
        dos.dosFrom === this.encounterStartDate &&
        dos.dosThrough === this.encounterEndDate) as any[];
      return ArrayHelper.isAvailable(filteredDos);
    }
    if (this.isFilterButtonselected) {
      const filteredDiagnoses = page.evidence?.diagnoses?.filter(diagnosis => diagnosis.diagnosisCode === this.selectedGroup) as any[];
      const signs = page.evidence?.signs;
      const symptoms = page.evidence?.symptoms;
      const medications = page.evidence?.medications;

      return (ArrayHelper.isAvailable(filteredDiagnoses) && this.diagnosisFilter) ||
        (signs && this.signsFilter) ||
        (symptoms && this.symptomsFilter) ||
        (medications && this.medicationsFilter) ||
        this.filterPagesWithNoEvidence([filteredDiagnoses, signs, symptoms, medications]);
    }
    return true;
  }

  private filterPagesWithNoEvidence(evidences: any[]): boolean {
    return this.isNoEvidenceFilterSelected &&
      evidences.every(evidence => !ArrayHelper.isAvailable(evidence));
  }

  resetNoEvidenceFilters(): void {
    this.diagnosisNoEvidenceFilter = this.encounterNoEvidenceFilter = this.memberNoEvidenceFilter = false;
  }

  private filterDocumentPages(pages: DocumentPage[]): void {
    this.resetNoEvidenceFilters();
    let statePagesWithThumbnails = [...pages];
    this.autoSelectFilters(statePagesWithThumbnails);

    if (this.diagnosisFilterSelected && this.diagnosisNoEvidenceFilter && StringHelper.isAvailable(this.selectedGroup)) {
      statePagesWithThumbnails = pages;
      this.autoSelectFilters(statePagesWithThumbnails);
    } else if (this.encounterNoEvidenceFilter || this.memberNoEvidenceFilter) {
      statePagesWithThumbnails = pages;
    }

    if (this.isWindowOpen) {
      const count = statePagesWithThumbnails.filter(x => x?.pageNumber > 0).length;
      this.retrievalDocumentService.totalDocumentPages.next(count);
      if (this.encounterTabSelected) {
        const encounterDocumentPages = [];
        statePagesWithThumbnails.map(page => {
          if (page?.documentPageId) { encounterDocumentPages.push(page.documentPageId); }
        });
        this.localService.put(this.localStorageDocumentIds, encounterDocumentPages);
      }
    }
    this.allThumbnails = ArrayHelper.chunkArray(statePagesWithThumbnails, this.NUMBER_OF_THUMBNAILS_PER_ROW);
  }

  private autoSelectFilters(pages: DocumentPage[]): void {
    if (this.memberTabSelected) {
      this.clearEncounterFilters();
      this.clearDiagnosisFilters();
      this.setMemberFilters(pages);
    }

    if (this.encounterTabSelected && !this.isDiagnosisSelected) {
      this.clearMemberFilters();
      this.clearDiagnosisFilters();
      this.setEncounterFilters(pages);
    }

    if (this.isDiagnosisSelected && !this.isTermsActive) {
      this.clearMemberFilters();
      this.clearEncounterFilters();
      this.setDiagnosesFilters(pages);
    }

    this.updateFilters();
  }

  private setEncounterFilters(pages: DocumentPage[]): void {
    this.providerFilter = pages.some(page => ArrayHelper.isAvailable(page?.evidence?.providerEvidences)) && this.providerFilter;
    this.dosFilter = pages.some(page => ArrayHelper.isAvailable(page?.evidence?.dosEvidences)) && this.dosFilter;

    const evidences = [this.providerFilter, this.dosFilter];
    this.encounterNoEvidenceFilter = evidences.every(value => !value);
  }

  private setMemberFilters(pages: DocumentPage[]): void {
    this.memberNameFilter = pages.some(page => ArrayHelper.isAvailable(page?.evidence?.memberNameEvidences)) && this.memberNameFilter;
    this.dobFilter = pages.some(page => ArrayHelper.isAvailable(page?.evidence?.memberDOBEvidences)) && this.dobFilter;
    this.genderFilter = pages.some(page => ArrayHelper.isAvailable(page?.evidence?.memberGenderEvidences)) && this.genderFilter;

    const evidences = [this.memberNameFilter, this.dobFilter, this.genderFilter];
    this.memberNoEvidenceFilter = evidences.every(value => !value);
  }

  private setDiagnosesFilters(pages: DocumentPage[]): void {
    this.diagnosisFilter = pages.some(page => this.isEvidenceAvailable(page?.evidence?.diagnoses)) && this.diagnosisFilter;
    this.signsFilter = pages.some(page => this.isEvidenceAvailable(page?.evidence?.signs)) && this.signsFilter;
    this.symptomsFilter = pages.some(page => this.isEvidenceAvailable(page?.evidence?.symptoms)) && this.symptomsFilter;
    this.medicationsFilter = pages.some(page => this.isEvidenceAvailable(page?.evidence?.medications)) && this.medicationsFilter;

    const evidences = [this.diagnosisFilter, this.signsFilter, this.symptomsFilter, this.medicationsFilter];
    this.diagnosisNoEvidenceFilter = evidences.every(value => !value);
  }

  private getLengthOfThumbnailPages(page: DocumentPage): number {
    return this.isNoEvidenceFilterSelected ? page.numberOfPages : page.recordCount;
  }
}
