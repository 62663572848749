<div #containerBody class="audit-log">
  <div class="audit-log--chart" [ngClass]="{ 'max': isChartMaxed }">
    <app-document-page-viewer [chaseId]="chaseId" [isReadOnly]="!assignedToCurrentUser" [(isMaximized)]="isChartMaxed">
    </app-document-page-viewer>
  </div>
  <div class="audit-log--data__container">
    <div class="audit-log--data__logs">
      <div class="audit-log--legend">
        <div class="audit-log--legend__item--eve audit-log--legend__item">
          <app-icon iconName="circle"></app-icon><span class="audit-log--legend__text">Eve</span>
        </div>
        <div class="audit-log--legend__item--abstractor audit-log--legend__item">
          <app-icon iconName="circle"></app-icon><span class="audit-log--legend__text">Abstraction/Coding</span>
        </div>
        <div class="audit-log--legend__item--overreader audit-log--legend__item">
          <app-icon iconName="circle"></app-icon><span class="audit-log--legend__text">Overreader</span>
        </div>
        <div class="audit-log--legend__item--overreader2 audit-log--legend__item">
          <app-icon iconName="circle"></app-icon><span class="audit-log--legend__text">Overreader 2</span>
        </div>
      </div>
      <app-accordion-panel>
        <app-accordion-tab *ngFor="let auditEntity of auditEntities; trackBy: trackByIndex"
          header="{{ auditEntity.entityTypeName }}:">
          <ng-container *ngFor="let coderAudit of auditEntity.coderAuditDetail; trackBy: trackByIndex">
            <app-accordion-tab *ngIf="!(auditEntity.entityTypeName === 'Member Validation' && coderAudit.coderName === 'Eve')" 
            header="{{ coderAudit.coderName}}" class="{{coderAudit.coderClass}}">
            <div *ngFor="let attribute of coderAudit.attributes; trackBy: trackByIndex">
              {{ attribute.attributeName }}: {{ attribute.attributeDisplayValue }}
            </div>
            <app-accordion-tab *ngFor="let diagnoses of coderAudit.diagnoses; trackBy: trackByIndex" header="Diagnosis">
              <div *ngFor="let diagnosis of diagnoses; trackBy: trackByIndex">
               {{ diagnosis.attributeName }}: {{ diagnosis.attributeDisplayValue }}</div>
            </app-accordion-tab>
          </app-accordion-tab>
          </ng-container>
        </app-accordion-tab>
      </app-accordion-panel>
    </div>
  </div>
</div>