import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-hcc-tracking-report",
  templateUrl: "./hcc-tracking-report.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HccTrackingReportComponent {
  filters: string[] = ["Projects"];
  dashboardType = LookerRiskDashboardType.HCC_TRACKING;
  hideFilters: string[] = [HideFilters.PROJECT_ID, HideFilters.USER_ID, HideFilters.ORG_ID];
}
