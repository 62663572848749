import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FileUpload } from "primeng/fileupload";
import { finalize } from "rxjs/operators";
import { SubSink } from "subsink";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../dynamic-forms/form.service";
import { Checkbox } from "../../../../dynamic-forms/inputs/checkbox/checkbox.model";
import { Dropdown } from "../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { BulkAction } from "../../../../shared/grid/bulk-actions/bulk-action.model";
import { GridPipeName } from "../../../../shared/grid/grid-pipe.enum";
import { GridColumnDefinition } from "../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../shared/grid/models/grid-configuration.model";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { NotificationService } from "../../../notification/notification.service";
import { AuditStatus } from "./audit-status.enum";
import { ExcludedFileTypes } from "./excluded-types.enum";
import { SubmissionRequest } from "./iva-submission.model";
import { IvaSubmissionService } from "./iva-submission.service";
import { RequestFile } from "./request-file.model";

enum FormInputs {
  PROJECT = "project",
  REQUEST_TYPE = "requestType",
  PROCESS_MODE = "processMode",
}

enum ModalFormInputs {
  STATUS = "status",
}

enum UploadFormInputs {
  FILE_TYPE = "fileType",
}

enum RequestTypes {
  XML = 1,
}

enum ProcessMode {
  DELTA,
  ALL,
}

@Component({
  selector: "app-iva-submission",
  templateUrl: "./iva-submission.component.html",
  styleUrls: ["./iva-submission.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IvaSubmissionComponent implements OnInit {

  @ViewChild("downloadAction", {static: true}) downloadAction: TemplateRef<any>;
  @ViewChild("fileUpload", {static: false}) filesToUpload: FileUpload;

  constructor(
    protected readonly formService: FormService,
    private changeDetector: ChangeDetectorRef,
    private ivaSubmissionService: IvaSubmissionService,
    private messagingService: MessagingService,
    private notificationService: NotificationService) {
  }

  private sink = new SubSink();
  private submissionResults: SubmissionRequest[] = [];
  private originalResults: SubmissionRequest[] = [];
  private gridLoading = true;
  private currentProjectId: number;
  private bulkActions: BulkAction[] = [];
  private submissionRequest = new SubmissionRequest();
  private requestDownloadsData: RequestFile[] = [];

  gridConfigurationModel = new GridConfiguration();
  downloadsGridConfigurationModel = new GridConfiguration();
  isUpdateRequestVisible = false;
  isUploadRequestVisible = false;
  showConfirmationModal = false;
  selectedFile: File;
  expandedRows = {};

  form: FormGroup;
  modalForm: FormGroup;
  uploadForm: FormGroup;

  statusInput = new Dropdown({
    key: "status",
    label: "Status",
    placeholder: "Select a status...",
  });

  fileTypeInput = new Dropdown({
    key: "fileType",
    label: "File Type",
    placeholder: "Select a file type...",
  });

  projectInput = new Dropdown({
    key: "project",
    label: "Project",
    placeholder: "Select a project...",
  });

  requestTypeInput = new Dropdown({
    key: "requestType",
    label: "Package Type",
    placeholder: "Select a package type...",
  });

  processModeCheckbox = new Checkbox({
    key: "processMode",
    label: "Process New Pages",
    value: false,
  });

  get isProjectSelected(): boolean {
    return NumberHelper.isGreaterThan(this.form.get(FormInputs.PROJECT)?.value, 0);
  }

  get isTypeSelected(): boolean {
    return NumberHelper.isGreaterThan(this.form.get(FormInputs.REQUEST_TYPE)?.value, 0);
  }

  get data(): SubmissionRequest[] {
    return this.submissionResults;
  }

  get gridHasData(): boolean {
    return ArrayHelper.isAvailable(this.data);
  }

  get downloadsGridHasData(): boolean {
    return ArrayHelper.isAvailable(this.requestDownloads);
  }

  get isGridLoading(): boolean {
    return this.gridLoading;
  }

  get actions(): BulkAction[] {
    return this.bulkActions;
  }

  get request(): SubmissionRequest {
    return this.submissionRequest;
  }

  get filterHasOptions(): boolean {
    return ArrayHelper.isAvailable(this.statusInput?.options);
  }

  get uploadFilterHasOptions(): boolean {
    return ArrayHelper.isAvailable(this.fileTypeInput?.options);
  }

  get isFileTypeSelected(): boolean {
    return NumberHelper.isGreaterThan(this.uploadForm.get(UploadFormInputs.FILE_TYPE)?.value, 0);
  }

  get isStatusSelected(): boolean {
    return NumberHelper.isGreaterThan(this.modalForm.get(ModalFormInputs.STATUS)?.value, 0);
  }

  get requestDownloads(): RequestFile[] {
    return this.requestDownloadsData;
  }

  get statusCanBeUpdated(): boolean {
    const allowedStatus: number[] = [AuditStatus.UploadedToAuditTool, AuditStatus.UploadSuccessful, AuditStatus.UploadSuccessful];
    return allowedStatus.includes(this.submissionRequest?.auditStatusId);
  }

  get isUploadSuccessOrFailed(): boolean {
    const allowedStatus: number[] = [AuditStatus.UploadSuccessful, AuditStatus.UploadSuccessful];
    return allowedStatus.includes(this.submissionRequest?.auditStatusId);
  }

  get isFileSelected(): boolean {
    return StringHelper.isAvailable(this.selectedFile?.name);
  }

  get isXmlSelected(): boolean {
    return this.form.get(FormInputs.REQUEST_TYPE)?.value === RequestTypes.XML;
  }

  ngOnInit(): void {
    this.updateGridConfig();
    this.updateDownloadsGridConfig();
    this.createForm();
    this.getRequestTypes();
    this.getRequestGrid();
    this.getProjectList();
  }

  private getRequestTypes(): void {
    this.sink.add(
      this.ivaSubmissionService.getRequestTypes().subscribe(options => {
        this.requestTypeInput = new Dropdown({...this.requestTypeInput, options: [...options]});
        this.changeDetector.markForCheck();
      })
    );
  }

  private getRequestGrid(projectId?: number): void {
    this.gridLoading = true;
    this.sink.add(
      this.ivaSubmissionService.getResultsGrid(projectId).pipe(
        finalize(() => this.gridLoading = false)
      ).subscribe(results => {
        this.submissionResults = [...results];
        this.originalResults = [...results];
        if (this.isTypeSelected) {
          const event = {value: this.form.get(FormInputs.REQUEST_TYPE).value};
          this.onPackageChange(event);
        }
        this.changeDetector.markForCheck();
      })
    );
  }

  private getProjectList(): void {
    this.sink.add(
      this.ivaSubmissionService.getProjects().subscribe(options => {
        this.projectInput = new Dropdown({...this.projectInput, options: [...options]});
        this.changeDetector.markForCheck();
      })
    );
  }

  private getPresignedUrl(requestId: number, auditRequestFileId: number): void {
    this.sink.add(
      this.ivaSubmissionService.getPreSignedUrl(requestId, auditRequestFileId).subscribe(url => {
        window.open(url, "_self");
        const request = this.data.find(r => r.requestId === requestId);
        if (request?.auditStatusId === AuditStatus.AvailableForDownload) {
          this.getRequestGrid(this.currentProjectId);
        }
      })
    );
  }

  protected createForm(): void {
    this.form = this.formService.createFormGroup([
      this.projectInput, this.requestTypeInput, this.processModeCheckbox,
    ]);
    this.modalForm = this.formService.createFormGroup([
      this.statusInput,
    ]);
    this.uploadForm = this.formService.createFormGroup([
      this.fileTypeInput,
    ]);
  }

  private updateGridConfig(): void {
    this.gridConfigurationModel = new GridConfiguration({
      columns: [
        new GridColumnDefinition({field: "requestId", header: "Request ID"}),
        new GridColumnDefinition({field: "projectName", header: "Project Name"}),
        new GridColumnDefinition({field: "hios", header: "HIOS ID"}),
        new GridColumnDefinition({field: "requestTypeName", header: "Package Type"}),
        new GridColumnDefinition({field: "requestUserName", header: "Requested By"}),
        new GridColumnDefinition({
          field: "auditRequestDate",
          header: "Request Date",
          pipeName: GridPipeName.Date,
          format: "MM-dd-yyyy hh:mm a",
          timeZone: "local",
        }),
        new GridColumnDefinition({field: "auditStatusName", header: "Status"}),
      ],
      showRowExpansionColumn: true,
      pageSize: 10,
      showActionColumn: true,
      showExportAction: false,
      showMenu: false,
      expandedRows: this.expandedRows,
      trackByField: "requestId",
      rowExpandMode: "single",
      bulkActions: this.getActions(),
    });
    this.bulkActions = this.getActions();
  }

  private getActions(): BulkAction[] {
    return [
      new BulkAction({
        name: "Update Status",
        action: this.onUpdateRequest.bind(this),
      }),
      new BulkAction({
        name: "Upload Response File",
        action: this.onUploadRequest.bind(this),
      }),
    ];
  }

  onDownloadFile(rowData: RequestFile): void {
    this.messagingService.showToast("Download started.", SeverityType.SUCCESS);
    const {requestId, auditRequestFileId} = rowData;
    this.getPresignedUrl(requestId, auditRequestFileId);
  }

  onUpdateRequest(rowData: SubmissionRequest): void {
    this.updateStatusOptions(rowData.auditStatusId);
    this.submissionRequest = rowData;
    this.isUpdateRequestVisible = true;
    this.modalForm.reset();
  }

  onUploadRequest(rowData: SubmissionRequest): void {
    this.uploadForm.reset();
    this.modalForm.reset();
    this.filesToUpload?.clear();
    this.submissionRequest = rowData;
    this.isUploadRequestVisible = true;
    if (!this.statusCanBeUpdated) {
      return;
    }
    this.updateOptions();
    this.getRequestFileTypes(rowData.requestTypeId);
  }

  private updateOptions(): void {
    const options = [];
    options.push(this.getOption(AuditStatus.UploadSuccessful));
    options.push(this.getOption(AuditStatus.UploadFailed));
    this.statusInput = new Dropdown({...this.statusInput, options});
  }

  private getRequestFileTypes(requestTypeId: number): void {
    this.sink.add(this.ivaSubmissionService.getRequestFileTypes().subscribe(
      options => {
        const exclusions = Object.values(ExcludedFileTypes) as string[];
        const filteredOptions = options.filter(o => !exclusions.includes(o.text) && o.extra?.requestTypeId === requestTypeId);
        this.fileTypeInput = new Dropdown({...this.fileTypeInput, options: [...filteredOptions]});
        this.changeDetector.markForCheck();
      }
    ));
  }

  private updateStatusOptions(currentStatus: number): void {
    const options = [];
    if (currentStatus !== AuditStatus.InProgress && currentStatus !== AuditStatus.Cancelled) {
      options.push(this.getOption(AuditStatus.Cancelled));
    }
    switch (currentStatus) {
      case AuditStatus.Downloaded:
        options.push(this.getOption(AuditStatus.Verified));
        options.push(this.getOption(AuditStatus.VerificationFailed));
        break;
      case AuditStatus.Verified:
        options.push(this.getOption(AuditStatus.UploadedToAuditTool));
        break;
      default:
        break;
    }
    this.statusInput = new Dropdown({...this.statusInput, options});
  }

  private getOption(auditStatus: number): SelectableInput {
    const options = [
      {
        value: AuditStatus.Verified,
        text: "Verified",
        disabled: false,
        extra: undefined,
        label: "Verified",
      },
      {
        value: AuditStatus.VerificationFailed,
        text: "Verification failed",
        disabled: false,
        extra: undefined,
        label: "Verification failed",
      },
      {
        value: AuditStatus.UploadedToAuditTool,
        text: "Uploaded to Audit tool",
        disabled: false,
        extra: undefined,
        label: "Uploaded to Audit tool",
      },
      {
        value: AuditStatus.UploadSuccessful,
        text: "Upload successful",
        disabled: false,
        extra: undefined,
        label: "Upload successful",
      },
      {
        value: AuditStatus.UploadFailed,
        text: "Upload failed",
        disabled: false,
        extra: undefined,
        label: "Upload failed",
      },
      {
        value: AuditStatus.Cancelled,
        text: "Cancelled",
        disabled: false,
        extra: undefined,
        label: "Cancelled",
      },
    ];
    return options.find(opt => opt.value === auditStatus);
  }

  onSubmit(): void {
    if (!this.isProjectSelected || !this.isTypeSelected) {
      return;
    }
    const projectId = this.form.get(FormInputs.PROJECT)?.value;
    const requestTypeId = this.form.get(FormInputs.REQUEST_TYPE)?.value;
    const processAllCharts = this.isXmlSelected ? this.form.get(FormInputs.PROCESS_MODE)?.value : null;
    const processMode = processAllCharts ? ProcessMode.ALL : ProcessMode.DELTA;

    const request = new SubmissionRequest();
    request.requestTypeId = requestTypeId;
    request.projectId = projectId;
    if (this.isXmlSelected) {
      request.processMode = processMode;
    }
    this.messagingService.showToast("Request sent.", SeverityType.SUCCESS);
    this.sink.add(
      this.ivaSubmissionService.submitRequest(request).pipe(
        finalize(() => this.getRequestGrid(this.currentProjectId))
      ).subscribe(() => this.notificationService.refreshNotifications())
    );
  }

  onProjectChange(event: { value: number }): void {
    this.submissionResults = [];
    this.getRequestGrid(event.value);
    this.currentProjectId = event.value;
    this.form.get(FormInputs.REQUEST_TYPE).reset();
    this.changeDetector.markForCheck();
  }

  onPackageChange(event: { value: number }): void {
    this.submissionResults = [...this.originalResults.filter(r => r.requestTypeId === event.value)];
    this.changeDetector.markForCheck();
  }

  updateRequest(request: SubmissionRequest, updateAccepted = false): void {
    const auditId: number = this.modalForm.get(ModalFormInputs.STATUS)?.value;
    if (auditId === AuditStatus.Cancelled && !updateAccepted) {
      this.showConfirmationModal = true;
      return;
    }
    this.isUpdateRequestVisible = false;
    this.showConfirmationModal = false;
    this.sink.add(
      this.ivaSubmissionService.updateRequest(request.requestId, auditId).pipe(
        finalize(() => this.getRequestGrid(this.currentProjectId))
      ).subscribe()
    );
    this.messagingService.showToast("Request sent.", SeverityType.SUCCESS);
  }

  onRowExpand(event: { originalEvent: PointerEvent; data: SubmissionRequest }): void {
    this.requestDownloadsData = [];
    const {requestId} = event.data;
    this.getRequestDownloads(requestId);
    this.changeDetector.markForCheck();
  }

  private getRequestDownloads(requestId: number): void {
    this.sink.add(
      this.ivaSubmissionService.getRequestDownloads(requestId)
        .subscribe(downloads => {
          this.requestDownloadsData = [...downloads];
          this.changeDetector.markForCheck();
        })
    );
  }

  private updateDownloadsGridConfig(): void {
    this.downloadsGridConfigurationModel = new GridConfiguration({
      columns: [
        new GridColumnDefinition({field: "requestFileTypeDesc", header: "File Type"}),
        new GridColumnDefinition({field: "fileName", header: "File Name"}),
        new GridColumnDefinition({field: "updateByUserName", header: "Update By"}),
        new GridColumnDefinition({
          field: "updateDate",
          header: "Update Date",
          pipeName: GridPipeName.Date,
          format: "MM-dd-yyyy hh:mm a",
          timeZone: "local",
        }),
        new GridColumnDefinition({header: "", template: this.downloadAction, isSortableColumn: false}),
      ],
      showActionColumn: false,
      showExportAction: false,
      showMenu: false,
    });
  }

  downloadHasUrl(rowData: RequestFile): boolean {
    return StringHelper.isAvailable(rowData.fileLocation);
  }

  onSelectFile(event: { currentFiles: File[] }): void {
    this.selectedFile = event.currentFiles[0];
  }

  uploadFile(request: SubmissionRequest): void {
    this.messagingService.showToast("Upload request sent.", SeverityType.SUCCESS);
    this.isUploadRequestVisible = false;
    const fileTypeId: number = this.uploadForm.get(UploadFormInputs.FILE_TYPE)?.value;
    const statusId: number = this.modalForm.get(ModalFormInputs.STATUS)?.value;
    const formData = new FormData();
    formData.append("file", this.selectedFile);
    this.sink.add(
      this.ivaSubmissionService.uploadResponseFile(request.requestId, fileTypeId, statusId, formData).subscribe(() => {
        this.getRequestDownloads(request.requestId);
        this.getRequestGrid(this.currentProjectId);
        this.messagingService.showToast("Upload completed.", SeverityType.SUCCESS);
        this.changeDetector.markForCheck();
      })
    );
  }

  trackByIndex(index: number) {
    return index;
  }
}
