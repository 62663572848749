<td valign="bottom">
  <div class="dx-grid__table--checkbox">
    <form-factory [formGroup]="diagnosisForm" [model]="diagnosisInput" class="provider_dropdown diagnosisInput"></form-factory>
  </div>
</td>
<td valign="bottom" class="ellipsis"
  title="{{formattedDxDate(diagnosis.dosFrom)}} - {{formattedDxDate(diagnosis.dosThrough)}}">
  <div class="dx-grid__table" [ngClass]="{'dx-grid__table--DOS': !diagnosis.isCoderAddedDiagnosis}">
    <ng-container
      *ngIf="!diagnosis.isCoderAddedDiagnosis; else coderAdded">{{formattedDxDate(diagnosis.dosFrom)}}</ng-container>
    <ng-template #coderAdded>
      <form-factory (onBlur)="validateDate()" [formGroup]="diagnosisForm" class="provider_dropdown diagnosisInput"
        [model]="startDateInput"></form-factory></ng-template>
  </div>
</td>
<td valign="bottom">
  <div class="dx-grid__table" [ngClass]="{'dx-grid__table--icdHolder': !diagnosis.isCoderAddedDiagnosis}">
    <ng-container *ngIf="!diagnosis.isCoderAddedDiagnosis; else coderAddedICD">
      <span class="dx-grid__table--icdHolder__icdCode">{{getICDCode(diagnosis.code)}}: </span>
      <span class="dx-grid__table--icdHolder__icdText" title="{{diagnosis.text}}">{{diagnosis.text}}</span>
    </ng-container>
    <ng-template #coderAddedICD>
      <form-factory  [title]="icdTitle" class="diagnosis-save-group provider_dropdown diagnosisInput" [formGroup]="diagnosisForm"
        (onChange)="onIcdCodeChange()" [model]="icdCodeInput"></form-factory>
    </ng-template>
  </div>
</td>
<td class="ellipsis" [title]="diagnosis.providerName">
  <form-factory [formGroup]="diagnosisForm" [model]="providerInput" class="provider_dropdown"></form-factory>
</td>
<td valign="bottom">
  <div class="dx-grid__table dx-grid__table--button" [ngClass]="{'dx-grid__table--pageNumber': !diagnosis.isCoderAddedDiagnosis}" >
    <div (click)="openPage(diagnosis)">
      <ng-container
        *ngIf="!enablePageNumberEdit; else coderAddedPageNumber">{{diagnosis.pageNumber}}</ng-container>
      <ng-template #coderAddedPageNumber>
        <form-factory [formGroup]="diagnosisForm" class="provider_dropdown diagnosisInput" [model]="pageNumberInput"></form-factory>
      </ng-template>
      <app-tool-tip class="dxToolTip" [text]="claimIdText" *ngIf="diagnosis.isAdmin" tooltipPosition="right"></app-tool-tip>
    </div>
    <app-button class="trash-button trash-diagnosis" [disabled]="disabled" *ngIf="diagnosis.isCoderAddedDiagnosis" icon="trash"
      styleType="far" (onClick)="removeDiagnosis()"></app-button>
  </div>
</td>