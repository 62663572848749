import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { DynamicFormEvent } from "../../../../../../../dynamic-forms/dynamic-form-event.model";
import { BooleanHelper } from "../../../../../../../utilities/contracts/boolean-helper";

@Component({
  selector: "app-risk-dx-admin-validation-buttons",
  templateUrl: "./admin-validation-buttons.component.html",
  styleUrls: ["./admin-validation-buttons.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminValidationButtonsComponent {
  @Input() disabled = false;
  @Output() addNewDx = new EventEmitter<DynamicFormEvent>();

  addNewDiagnosis(): void {
    this.addNewDx.emit();
  }
}
