import { DynamicInput, IDynamicInputOptions } from "../dynamic-input.model";
import { CustomRadiobuttonComponent } from "./custom-radiobutton.component";

export interface ICustomRadiobuttonOptions extends IDynamicInputOptions {
  options?: { text: string; value: string | number }[];
  name?: string;
  subLabel?: string;
  tooltip?: string;
}

export class CustomRadiobutton extends DynamicInput implements ICustomRadiobuttonOptions {
    name: string;
    options: any[];
    subLabel: string;
    tooltip: string;
    readonly controlType = "radiobutton";
    readonly componentType = CustomRadiobuttonComponent;


    constructor(options: ICustomRadiobuttonOptions = {}) {
      super(options);
      this.options = options.options || [];
      this.name = options.name || this.createRandomName();
      this.subLabel = options.subLabel;
      this.tooltip = options.tooltip;
    }


    private createRandomName = (): string => {
      return Math.random().toString(36).substr(2, 9);
    }
  }
