import { AbstractControl, FormGroup } from "@angular/forms";
import { Autocomplete } from "../../../dynamic-forms/inputs/autocomplete/autocomplete.model";
import { Calendar } from "../../../dynamic-forms/inputs/calendar/calendar.model";
import { CheckboxGroup } from "../../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { Dropdown } from "../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { DynamicInput } from "../../../dynamic-forms/inputs/dynamic-input.model";
import { Radiobutton } from "../../../dynamic-forms/inputs/radiobutton/radiobutton.model";
import { SelectableInput } from "../../../dynamic-forms/inputs/selectable-input.model";
import { TagSearchMultiselect } from "../../../dynamic-forms/inputs/tag-search-multiselect/tag-search-multiselect.model";
import { Textbox } from "../../../dynamic-forms/inputs/textbox/textbox.model";
import { BooleanHelper } from "../../../utilities/contracts/boolean-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";

export class GridFilter {
    private form: FormGroup;
    private name: string;
    private dynamicInput: DynamicInput;

    show: boolean;
    showChip: boolean;
    forceFilter: boolean;

    constructor(options: {
        input?: DynamicInput;
        key?: string;
        value?: string;
        name?: string;
        show?: boolean;
        text?: string;
        validators?: any;
        errorMessages?: any;
        showChip?: boolean;
        forceFilter?: boolean;

    } = {}) {
        this.input = options.input;
        this.name = StringHelper.clean(options.name);
        this.show = typeof options.show === "boolean" ? options.show : true;
        this.showChip = BooleanHelper.tryGet(options.showChip, true);
        this.forceFilter = BooleanHelper.tryGet(options.forceFilter, false);

        this.dynamicInput.key = StringHelper.clean(options.key);
        this.dynamicInput.value = options.value;
        this.dynamicInput.label = this.displayName;
        this.dynamicInput.validators = options.validators;
        this.dynamicInput.errorMessages = options.errorMessages;
    }


    private get control(): AbstractControl {
        if (this.form == null) {
            return null;
        }

        return this.form.get(this.key);
    }

    get inputValue(): any {
        if (this.control == null || this.control.value == null) {
            return "";
        }

        return this.control.value;
    }

    set inputValue(value: any) {
        if (this.control != null) {
            this.control.setValue(value);
        }
    }

    get input(): DynamicInput {
        return this.dynamicInput;
    }

    set input(value: DynamicInput) {
        this.dynamicInput = this.getInput(value);
    }

    get key(): string {
        return this.input.key;
    }

    get hasValue(): boolean {
        return StringHelper.isAvailable(this.value) || (this.input?.controlType !== "textbox" && StringHelper.isAvailable(this.input.value));
    }

    get value(): string {

        if (Array.isArray(this.inputValue)) {
            return this.inputValue
                .filter(a => a != null)
                .map(a => a.value)
                .join(",");
        } else if (typeof this.inputValue === "string") {
            return this.inputValue;
        } else if (typeof this.inputValue === "number") {
            return this.inputValue.toString();
        } else if (this.inputValue instanceof Date) {
            const month = (this.inputValue.getMonth() + 1).toString().padStart(2, "0");
            const day = this.inputValue.getDate().toString().padStart(2, "0");
            const year = this.inputValue.getFullYear();
            return `${month}/${day}/${year}`;
        } else if (typeof this.inputValue === "object" && this.inputValue != null) {
            return this.inputValue.value.toString();
        } else {
            throw new Error("The value type is unaccounted for.");
        }
    }

    set value(value: string) {
        if (this.control == null) {
            return;
        }

        this.control.setValue(value);
    }

    get hasName(): boolean {
        return StringHelper.isAvailable(this.name);
    }

    get displayName(): string {
        return this.hasName ? this.name : this.key;
    }

    get displayValue(): string {
        if (Array.isArray(this.inputValue)) {
            return this.inputValue
                .filter(a => a != null)
                .map(a => a.text.replace(",", "@@"))
                .join(", ");
        } else if (typeof this.inputValue === "string") {
            return this.inputValue;
        } else if (typeof this.inputValue === "number") {
            return this.inputValue.toString();
        } else if (this.inputValue instanceof Date) {
            const month = (this.inputValue.getMonth() + 1).toString().padStart(2, "0");
            const day = this.inputValue.getDate().toString().padStart(2, "0");
            const year = this.inputValue.getFullYear();
            return `${month}/${day}/${year}`;
        } else if (typeof this.inputValue === "object" && this.inputValue != null) {
            return this.inputValue.text;
        } else {
            throw new Error("The value type is unaccounted for.");
        }
    }

    get displayValues(): string[] {
        const values = Array.isArray(this.inputValue)
            ? this.displayValue.split(", ")
            : [this.displayValue];
        return values;
    }

    setForm(value: FormGroup | null): void {
        this.form = value;
    }

    private getInput(input: DynamicInput): DynamicInput {
        // TODO: Make this more global. Breaking everything when I do...
        switch (input.controlType) {
            case "autocomplete":
                return new Autocomplete(input);
            case "textbox":
                return new Textbox(input);
            case "checkbox-group":
                return new CheckboxGroup(input);
            case "radiobutton":
                return new Radiobutton(input);
            case "calendar":
                return new Calendar(input);
            case "dropdown":
                return new Dropdown({
                    ...input,
                    options: (input as Dropdown).options.map(a => new SelectableInput(a)),
                });
            case "multiselect":
                return new TagSearchMultiselect(input);
            default:
                throw new Error(`The input control type '${input.controlType}' does not exist.`);
        }
    }

    removeValue(value = ""): void {
        if (Array.isArray(this.inputValue)) {
            this.inputValue = this.inputValue.filter(a => a != null && a.text !== value);
            this.input.value = "";
        } else if (typeof this.inputValue === "string") {
            this.inputValue = this.input.value = "";
        } else if (typeof this.inputValue === "number") {
            this.inputValue = this.input.value = "";
        } else if (this.inputValue instanceof Date) {
            this.inputValue = this.input.value = ""; // Format the date as needed
        } else if (typeof this.inputValue === "object" && this.inputValue != null) {
            this.inputValue = this.input.value = null;
        } else {
            throw new Error("The value type is unaccounted for.");
        }
    }
}
