import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SubscriptionLike, combineLatest } from "rxjs";
import { distinctUntilKeyChanged, filter, map, switchMap } from "rxjs/operators";
import { SubSink } from "subsink";
import { MenuItem } from "../../../../../../shared/menu/menu-item.model";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";
import { DynamicEntityAttribute } from "../../../../../api/member-validation/dynamic-entity-attribute.model";
import { EntityType } from "../../../../../api/member-validation/entity-type.enum";
import { MemberValidation } from "../../../../../api/member-validation/member-validation.model";
import { MemberValidationService } from "../../../../../api/member-validation/member-validation.service";
import { WorkflowStatusDb } from "../../../../../api/workflow/workflow-status-db.enum";
import { CodingReviewMode } from "../../../../project/project-config/coding-review-mode.enum";
import { MEMBER_VALIDATION_RESULT } from "../../../chase-detail/chase-detail-chart/attributes";
import { RiskEntity } from "../../../chase-detail/chase-detail-chart/risk/risk-entity.model";
import { RiskService } from "../../../chase-detail/chase-detail-chart/risk/risk.service";
import { ChaseDetailStateService } from "../../../chase-detail/chase-detail-state.service";
import { RiskDxService } from "./risk-dx.service";

@Component({
  selector: "app-risk-dx",
  templateUrl: "./risk-dx.component.html",
  styleUrls: ["./risk-dx.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskDxComponent implements OnInit, OnDestroy {

  @Input() chaseId: number;
  @Output() selectedSubMenu = new EventEmitter<string>();
  private isChaseStatusCompletedOrDelivered = false;
  private sink = new SubSink();
  private workflowStatusName: string;
  dxTabSelected = false;
  isProjectEnabledForAddNewCard: boolean;
  isCodingReviewModeDX: boolean;
  memberValidationData = new MemberValidation();
  isReadonly: boolean;
  constructor(
    private readonly riskService: RiskService,
    private readonly riskDxService: RiskDxService,
    private readonly chaseDetailStateService: ChaseDetailStateService,
    private readonly activatedRoute: ActivatedRoute, private readonly memberValidationService: MemberValidationService,
    public router: Router) {
  }

  ngOnInit(): void {
    this.sink.add(
      this.activatedRoute.paramMap.subscribe(params => {

        if (this.chaseId !== +params.get("chaseGd")) {
          this.chaseId = +params.get("chaseGd");
        }
        this.sink.add(
          this.setRiskDxData()
        );
        this.sink.add(
          this.riskDxService.refreshDxReviewDataEntry$.subscribe(() => {
            this.sink.add(
              this.setRiskDxData()
            );
          })
        );
      })
    );
    this.dxTabSelected = this.router.url.includes("dx-review");
  }

  selectedTab(menu: MenuItem) {
    this.dxTabSelected = menu.description === "Dx Review";
    this.selectedSubMenu.emit(menu.description.replace(/\s/g, ""));
  }

  ngOnDestroy(): void {
    this.sink.unsubscribe();
  }

  private setRiskDxData(): SubscriptionLike {
    return this.chaseDetailStateService.state
    .pipe(
      filter(state => StringHelper.isAvailable(WorkflowStatusDb[state?.workflowStatus])),
      distinctUntilKeyChanged("workflowStatus"),
      map(state => {
        this.isProjectEnabledForAddNewCard = Boolean(Number(state?.projectConfiguration?.hccCardAdditionEnabled));
        this.isChaseStatusCompletedOrDelivered = state.workflowStatus === WorkflowStatusDb.Completed || state.workflowStatus === WorkflowStatusDb.Delivery;
        this.workflowStatusName = state.workflowStatusName;
        this.isCodingReviewModeDX = state?.projectConfiguration?.codingReviewMode === CodingReviewMode.DIAGNOSIS;
        this.getMemberValidation();
      }),
      switchMap(_ => combineLatest([
        this.riskDxService.getData(this.chaseId, this.workflowStatusName, this.isCodingReviewModeDX),
        this.riskService.data,
      ])),
      map(([riskDxData, riskData]: any) => {
        this.riskDxService.setData({
          ...riskDxData,
          isReadOnly: this.isChaseStatusCompletedOrDelivered || !riskData.isEnabled,
        });
        this.isReadonly = this.isChaseStatusCompletedOrDelivered || !riskData.isEnabled;
      })
    )
    .subscribe();
}
  private getMemberValidation(): void {
  if (this.chaseId && StringHelper.isAvailable(this.workflowStatusName)) {
    this.memberValidationService.getData(this.chaseId, this.workflowStatusName).subscribe({
      next: data => {
        this.memberValidationData = data;
        this.setMemberValidationResult();
      },
    });

  }
}
  private setMemberValidationResult(): void {
  this.riskService.setData({
    memberValidation: new RiskEntity({
      entityTypeId: EntityType.MEMBER_VALIDATION,
      attributes: [new DynamicEntityAttribute({
        ...MEMBER_VALIDATION_RESULT,
        value: this.memberValidationData.isValidInfo.value,
      })],
    }),
  });
}

  addNewNetHCC() {
    this.riskDxService.addCard(true);
    const element = document.getElementById("scrollElement");
    if (element) {
      element.scrollTop = element.scrollHeight;
    }

  }

  showNetNewButton(): boolean {
    return this.dxTabSelected && this.isProjectEnabledForAddNewCard && !this.isReadonly;
  }

}
