import { IAutoMapper } from "../../../../../core/automapper/interfaces";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../../utilities/contracts/date-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { RiskEntity } from "../chase-detail-chart/risk/risk-entity.model";

export const mapChaseDetailAuditLog = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "ChaseDetailAuditLog")
    .forMember("abstractionDate", o => o.abstractionDate)
    .forMember("abstractionUserID", o => o.abstractionUserID)
    .forMember("abstractionUserName", o => o.abstractionUserName)
    .forMember("chaseId", o => o.chaseId)
    .forMember("chaseSourceAliasId", o => o.chaseSourceAliasId)
    .forMember("entityData", o => automapper.mapMany("default", "RiskEntity", o.entityData))
    .forMember("eveEntityData", o => automapper.mapMany("default", "RiskEntity", o.eveEntityData))
    .forMember("measureCode", o => o.measureCode)
    .forMember("overread2Date", o => o.overread2Date)
    .forMember("overread2UserID", o => o.overread2UserID)
    .forMember("overread2UserName", o => o.overread2UserName)
    .forMember("overreadDate", o => o.overreadDate)
    .forMember("overreadUserID", o => o.overreadUserID)
    .forMember("overreadUserName", o => o.overreadUserName)
    .forMember("projectId", o => o.projectId)
    .forMember("projectName", o => o.projectName)
    .forMember("projectTypeID", o => o.projectTypeID)
    .forMember("projectTypeName", o => o.projectTypeName);
};

export class ChaseDetailAuditLog {
  abstractionDate?: string;
  abstractionUserID?: number;
  abstractionUserName?: string;
  chaseId?: number;
  chaseSourceAliasId?: string;
  entityData?: RiskEntity[];
  eveEntityData?: RiskEntity[];
  measureCode?: string;
  overread2Date?: string;
  overread2UserID?: number;
  overread2UserName?: string;
  overreadDate?: string;
  overreadUserID?: number;
  overreadUserName?: string;
  projectId?: number;
  projectName?: string;
  projectTypeID?: number;
  projectTypeName?: string;

  constructor(options: Partial<ChaseDetailAuditLog> = {}) {
    this.abstractionDate = DateHelper.format(options.abstractionDate);
    this.abstractionUserID = options.abstractionUserID;
    this.abstractionUserName = StringHelper.clean(options.abstractionUserName);
    this.chaseId = options.chaseId;
    this.chaseSourceAliasId = StringHelper.clean(options.chaseSourceAliasId);
    this.entityData = ArrayHelper.clean(options.entityData);
    this.eveEntityData = ArrayHelper.clean(options.eveEntityData);
    this.measureCode = StringHelper.clean(options.measureCode);
    this.overread2Date = DateHelper.format(options.overread2Date);
    this.overread2UserID = options.overread2UserID;
    this.overread2UserName = StringHelper.clean(options.overread2UserName);
    this.overreadDate = DateHelper.format(options.overreadDate);
    this.overreadUserID = options.overreadUserID;
    this.overreadUserName = StringHelper.clean(options.overreadUserName);
    this.projectId = options.projectId;
    this.projectName = StringHelper.clean(options.projectName);
    this.projectTypeID = options.projectTypeID;
    this.projectTypeName = StringHelper.clean(options.projectTypeName);
  }
}
