<div [formGroup]="formGroup" class="control control--input control--radiobutton" [hidden]="hidden">
  <div *ngIf="model.hasLabel" class="control__header">
    <label class="control__header__label" [for]="model.key" [title]="model.label">{{ model.label | titlecase }}</label>
    <div class="control__header__error" *ngIf="hasError" [title]="error">{{ error }}</div>
    <app-tool-tip *ngIf="hasTooltip" [text]="model.tooltip"></app-tool-tip>
  </div>
  <div #radioBtn class="customRadio" >
    <p-radioButton *ngFor="let option of options; trackBy: trackByIndex"
    [name]="groupName"
    [value]="option.value"
    (click)="onChangeEvent($event, option)"
    [formControlName]="model.key"
    [ngClass]="{
      'tick-radio-button': option.value === '1', 
      'cross-radio-button': option.value === '0'
  }">
<!-- Add the tick and cross icons inside the custom radio button -->
<span class="custom-icon">
<i *ngIf="option.value === '1'" class="tick-icon"></i>
<i *ngIf="option.value === '0'" class="cross-icon"></i>
</span>
</p-radioButton>
</div>
  <div *ngIf="hasSubLabel" class="control__header">
    <label class="control__header__label control__header__label--subLabel" [for]="model.key" [title]="model.subLabel">{{ model.subLabel | titlecase }}</label>
  </div>
</div>
