<a *ngIf="isOverreadFeedbackEmpty; else hasOverreadFeedback" [href]="getChaseDetailURL()" (click)="savePreviousUrl()">{{chaseId}}</a>
<ng-template #hasOverreadFeedback>
  <a (click)="isOverreadFeedbackNotEmpty()">
    {{ chaseId }}
  </a>
</ng-template>

<div
     *ngIf="hasParentOrPursuitChases"
     class="chase-id__related-chases-container"
     (mouseenter)="relatedChasesPopover.show($event)">

  ({{ parentOrPursuitText }})

  <app-popover #relatedChasesPopover>
    <div class="chase-id__related-chases-popover-container" (mouseleave)="relatedChasesPopover.hide()">
      <div class="chase-id__title-container">{{ popOverTitle }}</div>
      <div *ngFor="let relatedChaseId of relatedChaseIds; trackBy: trackByIndex">
        <a [href]="getChildURL(relatedChaseId)">{{relatedChaseId}}</a>
      </div>
    </div>
  </app-popover>

</div>
<div class="chase-id__related-chases-badge" *ngIf="isNewPage">New Pages</div> 
