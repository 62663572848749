import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { List } from "immutable";
import { SubSink } from "subsink";
import { UserToken } from "../../../../../auth/user-token.model";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { LocalService } from "../../../../../core/storage/local.service";
import { UserService } from "../../../../../core/user/user.service";
import { Calendar } from "../../../../../dynamic-forms/inputs/calendar/calendar.model";
import { DynamicInput } from "../../../../../dynamic-forms/inputs/dynamic-input.model";
import { GridPipeName } from "../../../../../shared/grid/grid-pipe.enum";
import { GridColumnDefinition } from "../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../shared/grid/models/grid-configuration.model";
import { GridFilter } from "../../../../../shared/grid/models/grid-filter.model";
import { GridRequest } from "../../../../../shared/grid/models/grid-request.model";
import { ListItem } from "../../../../../shared/list/list-item";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../../utilities/contracts/date-helper";
import { DateFormats } from "../../../../../utilities/contracts/helper-types";
import { DASHBOARD_MY_CHASES_GRID } from "../../../member/chase-detail/chase-detail-chart/attributes";
import { OverreadFeedbackQuerySearch } from "../../../member/chase-detail/chase-detail-chart/overread-feedback/overread-feedback-query-search.model";
import { OverreadFeedbackStatus } from "../../../member/chase-detail/chase-detail-chart/overread-feedback/overread-feedback-status.enum";
import { OverreadFeedbackService } from "../../../member/chase-detail/chase-detail-chart/overread-feedback/overread-feedback.service";
import { ClinicalService } from "../clinical.service";

@Component({
  selector: "app-clincal-completed",
  templateUrl: "./clincal-completed.component.html",
  styleUrls: ["./clincal-completed.component.scss"],
})
export class ClincalCompletedComponent implements OnInit {

  private sink = new SubSink();
  private user: UserToken;
  clinicalGridConfiguration = new GridConfiguration();
  serverRequest: GridRequest;
  private selectedStat = "";
  myForm: FormGroup;
  headerStatistics = List<ListItem>();
  stateName = DASHBOARD_MY_CHASES_GRID.attributeCode;
  isOverreadFeedbackEnabled = false;
  overreadFeedbackCount = 0;
  isOverreadFeedbackEmpty = true;

  get startDate(): Calendar {
    return this.getInput("startDate");
  }

  get endDate(): Calendar {
    return this.getInput("endDate");
  }


  getInput<T extends DynamicInput>(key: string): T {
    if (this.serverRequest == null) {
      return null;
    }

    return this.serverRequest.getInput<T>(key);
  }
  setInput<T extends DynamicInput>(key: string, value: T): void {

    if (this.serverRequest == null) {
      return null;
    }

    this.serverRequest.setInput<T>(key, value);
  }

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly clinicalService: ClinicalService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly localService: LocalService,
    private readonly router: Router,
    private overreadFeedbackService: OverreadFeedbackService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.user = this.userService.getUserToken();
    this.createGrid();
    this.getStatisticsData();

    this.sink.add(
      this.overreadFeedbackService.getOverreadFeedbackList(new OverreadFeedbackQuerySearch())
        .subscribe(data => {
          if (ArrayHelper.isAvailable(data)) {
            const feedbackData = data.filter(x => x.status !== OverreadFeedbackStatus.MANAGER && ((
              x.abstractorUserId === this.user.userId && (
                x.abstractorReceiptStatus === OverreadFeedbackStatus.REQUESTED ||
                x.status === OverreadFeedbackStatus.ABSTRACTOR)) || (
                x.overreaderUserId === this.user.userId &&
                x.overreaderReceiptStatus === OverreadFeedbackStatus.REQUESTED))
            );
            this.isOverreadFeedbackEnabled = true;
            const inCompleteFeedback = feedbackData.filter(x => x.status !== OverreadFeedbackStatus.COMPLETED ||
              (x.status === OverreadFeedbackStatus.COMPLETED && (x.abstractorReceiptStatus === OverreadFeedbackStatus.REQUESTED && x.abstractorUserId === this.user.userId) ||
                (x.overreaderReceiptStatus === OverreadFeedbackStatus.REQUESTED && x.overreaderUserId === this.user.userId)));
            this.overreadFeedbackCount = inCompleteFeedback.length;
            this.setIsOverreadFeedbackEmpty();
            this.changeDetector.markForCheck();
          }
        })
    );

  }

  createGrid(): void {
    this.clinicalGridConfiguration.columns = [
      new GridColumnDefinition({ field: ("chaseId" ? "chaseId" : ""), header: "CHASE ID", routeUrl: "/members/chase/:chaseId" }),
      new GridColumnDefinition({ field: "chaseSourceAliasID", header: "Client Chase Key" }),
      new GridColumnDefinition({ field: "projectName", header: "PROJECT" }),
      new GridColumnDefinition({ field: "measureCode", header: "MEASURE" }),
      new GridColumnDefinition({ field: "billingEventTypeName", header: "ROLE" }),
      new GridColumnDefinition({ field: "pageCount", header: "PAGES" }),
      new GridColumnDefinition({ field: "dateAssigned", header: "Date Assigned", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
      new GridColumnDefinition({ field: "billingEventDate", header: "Date Completed", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
    ];

    this.clinicalGridConfiguration.pageSize = 10;
    this.clinicalGridConfiguration.showExportAction = true;
    this.clinicalGridConfiguration.showActionColumn = false;

    this.serverRequest = new GridRequest({
      url: `${this.baseApiUrl}dashboard/chasescompleted`,
      filters: [
        new GridFilter({
          input: new Calendar(),
          key: "startDate",
          name: "Start Date Completed",
          value: DateHelper.formatToUtcTime(new Date()),
        }),
        new GridFilter({
          input: new Calendar(),
          key: "endDate",
          name: "End Date Completed",
          value: DateHelper.formatToUtcTime(new Date()),
        }),
      ],
    });
  }


  getStatisticsData(): void {
    this.clinicalService
      .getStatisticsData()
      .subscribe(result => {
        this.headerStatistics = List(result);
        this.changeDetector.markForCheck();
      });
  }

  applyFilter(filterName: string): void {
    this.setCurrentFilterName(filterName);
    this.selectedStat = filterName;

    if (filterName === "Completed Today") {
      this.router.navigateByUrl("/dashboard/completed");
    } else {
      this.router.navigateByUrl("/dashboard/clinical");
    }

  }

  setIsOverreadFeedbackEmpty(): void {
    this.isOverreadFeedbackEmpty = this.overreadFeedbackCount === 0 || this.getCurrentFilterName() === "Completed";
  }


  getStatisticsClass(item: ListItem): string {
    return item.key === this.selectedStat ? "active" : "";
  }

  getHeaderItemClass(itemKey: string): string {
    return itemKey === this.getCurrentFilterName() ? "selected-header-item" : "";
  }

  getCurrentFilterName(): string {
    return this.localService.get(`FILTER_${this.stateName}`, null);
  }

  setCurrentFilterName(filterName: string): void {
    this.localService.put(`FILTER_${this.stateName}`, filterName);
  }
  trackByIndex(index) {
    return index;
  }
}
