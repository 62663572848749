import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { SubSink } from "subsink";
import { Member } from "../../clinical/member.model";
import { CodingReviewMode } from "../../project/project-config/coding-review-mode.enum";
import { ProjectAttribute } from "../../project/project-config/models/project-attribute.model";
import { ProjectConfigurationService } from "../../project/project-config/project-config.service";
import { ProjectConfiguration } from "../../project/project-config/project-configuration.model";
import { Project } from "../../project/project.model";
import { CODING_LEVEL, CODING_OPTIMIZATION_MODE, CODING_REVIEW_MODE, DATA_ENTRY_PROVIDER_CONFIRMATION_ENABLED, ENABLEMRRBOT, ENCOUNTER_TYPE_REQUIRED, EVEONLY_CARDSVALIDATION_REQUIRED, HCC_CARD_ADDITTION_ENABLED, MEMBER_CENTRIC_CHASE_RETRIEVAL, OMISSION_CODE_ENABLED, REVIEW_ALL_ADMIN_CLAIMS_REQUIRED, REVIEW_PERIOD_FROM_DATE, REVIEW_PERIOD_THRU_DATE, SERVICE_PROVIDER_REQUIRED, VALIDATION_REASON_CODE_REQUIRED } from "../chase-detail/chase-detail-chart/attributes";
import { ChaseDetailStateService } from "../chase-detail/chase-detail-state.service";
import { ChaseDetailService } from "../chase-detail/chase-detail.service";


@Component({
  selector: "member-chase-detail-v2",
  templateUrl: "./chase-detail-v2.component.html",
  styleUrls: ["./chase-detail-v2.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseDetailV2Component implements OnInit, OnDestroy {

  private sink = new SubSink();
  private chaseId: number;
  showDataEntry = true;
  isMainHeaderVisible = false;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly chaseDetailService: ChaseDetailService,
    private readonly chaseDetailStateService: ChaseDetailStateService,
    private readonly projectConfigurationService: ProjectConfigurationService
  ) { }

  ngOnInit(): void {
    this.sink.add(
      this.activatedRoute.paramMap.subscribe(params => {
        if (this.chaseId !== +params.get("chaseGd")) {
          this.chaseId = +params.get("chaseGd");
          this.getChaseDetails();
          this.getChaseOCRDetails();
          this.changeDetector.markForCheck();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.sink.unsubscribe();
  }

  showEntry(data) {
    this.showDataEntry = data;
  }

  checkMainHeaderVisible(event: boolean) {
    this.isMainHeaderVisible = event;
  }

  private getChaseOCRDetails(): void {
    this.chaseDetailService.getChaseOCRDetails(this.chaseId).subscribe(res => {
      this.chaseDetailService.ocrDataAvailableChange.next(res);
    });
  }

  private getChaseDetails(): void {
    this.chaseDetailService.getChaseDetail(this.chaseId)
      .pipe(
        switchMap(chaseDetail =>
          this.getProjectConfiguration(chaseDetail.projectId)
            .pipe(map(projectAttributes => {
              return {chaseDetail, projectAttributes};
            }))
        ),
        map(({chaseDetail, projectAttributes}) => {
          this.chaseDetailService.chaseDetailsChange.next(chaseDetail.keyValueItems);
          const project = new Project({...chaseDetail});
          const projectConfiguration = this.setProjectConfiguration(projectAttributes);

          this.chaseDetailStateService.setData({
            ...chaseDetail,
            member: new Member({...chaseDetail}),
            project,
            projectConfiguration,
          });
        }))
      .subscribe();
  }

  private getProjectConfiguration(projectId: number): Observable<ProjectAttribute[]> {
    const attributeIdsAsCsv = `${REVIEW_PERIOD_FROM_DATE.attributeId},
      ${REVIEW_PERIOD_THRU_DATE.attributeId},
      ${SERVICE_PROVIDER_REQUIRED.attributeId},
      ${VALIDATION_REASON_CODE_REQUIRED.attributeId},
      ${ENCOUNTER_TYPE_REQUIRED.attributeId},
      ${ENABLEMRRBOT.attributeId},
      ${MEMBER_CENTRIC_CHASE_RETRIEVAL.attributeId},
      ${OMISSION_CODE_ENABLED.attributeId},
      ${DATA_ENTRY_PROVIDER_CONFIRMATION_ENABLED.attributeId},
      ${REVIEW_ALL_ADMIN_CLAIMS_REQUIRED.attributeId},
      ${CODING_LEVEL.attributeId},
      ${CODING_REVIEW_MODE.attributeId},
      ${HCC_CARD_ADDITTION_ENABLED.attributeId},
      ${CODING_OPTIMIZATION_MODE.attributeId},
      ${EVEONLY_CARDSVALIDATION_REQUIRED.attributeId}`;

    return this.projectConfigurationService.getProjectAttributeList(projectId, attributeIdsAsCsv);
  }

  private setProjectConfiguration(configs: ProjectAttribute[]): ProjectConfiguration {
    return new ProjectConfiguration({
      reviewPeriodFromDate: configs.find(x => x.attributeId === REVIEW_PERIOD_FROM_DATE.attributeId).attributeValue,
      reviewPeriodThruDate: configs.find(x => x.attributeId === REVIEW_PERIOD_THRU_DATE.attributeId).attributeValue,
      serviceProviderRequired: configs.find(x => x.attributeId === SERVICE_PROVIDER_REQUIRED.attributeId).attributeValue,
      validationReasonCodeRequired: configs.find(x => x.attributeId === VALIDATION_REASON_CODE_REQUIRED.attributeId).attributeValue,
      encounterTypeRequired: configs.find(x => x.attributeId === ENCOUNTER_TYPE_REQUIRED.attributeId).attributeValue,
      enableMrrBot: configs.find(x => x.attributeId === ENABLEMRRBOT.attributeId).attributeValue,
      memberCentricChaseRetrieval: configs.find(x => x.attributeId === MEMBER_CENTRIC_CHASE_RETRIEVAL.attributeId).attributeValue,
      omissionCodeEnabled: configs.find(x => x.attributeId === OMISSION_CODE_ENABLED.attributeId).attributeValue,
      dataEntryProviderConfirmationEnabled: configs.find(x => x.attributeId === DATA_ENTRY_PROVIDER_CONFIRMATION_ENABLED.attributeId).attributeValue,
      reviewAdminEncountersAndDiagnoses: configs.find(x => x.attributeId === REVIEW_ALL_ADMIN_CLAIMS_REQUIRED.attributeId).attributeValue,
      codingLevel: configs.find(x => x.attributeId === CODING_LEVEL.attributeId).attributeValue,
      codingReviewMode: this.getCodingReviewModeValue(configs.find(x => x.attributeId === CODING_REVIEW_MODE.attributeId)),
      hccCardAdditionEnabled: configs.find(x => x.attributeId === HCC_CARD_ADDITTION_ENABLED.attributeId)?.attributeValue,
      codingOptimizationMode: configs.find(x => x.attributeId === CODING_OPTIMIZATION_MODE.attributeId)?.attributeValue,
      eveOnlyCardsValidationRequired: configs.find(x => x.attributeId === EVEONLY_CARDSVALIDATION_REQUIRED.attributeId)?.attributeValue,
    });

  }

  private getCodingReviewModeValue(value: ProjectAttribute): string {
    if (value?.attributeValue) {
      return value.attributeValue;
    }
    return CodingReviewMode.ENCOUNTER;
  }
}
