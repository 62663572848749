import { RiskDxDiagnosis } from "./risk-dx-diagnosis.model";

export enum DxType {
  ADMIN,
  EVE,
  CODER,
  UNKNOWN,
}

export class RiskDxIcd {
  hccId: string;
  hccName: string;
  isValidated?: boolean;
  diagnoses: RiskDxDiagnosis[];
  dxType: DxType;
  modelId: string;
  rxHCC?: boolean;
  hccCardValidationStatus: string;
}
