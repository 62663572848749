import { NgModule } from "@angular/core";
import { AccordionModule } from "primeng/accordion";
import { AutoCompleteModule } from "primeng/autocomplete";
import { CheckboxModule } from "primeng/checkbox";
import { DropdownModule } from "primeng/dropdown";
import { FileUploadModule } from "primeng/fileupload";
import { DynamicFormsModule } from "../../../dynamic-forms/dynamic-forms.module";
import { DocumentModule } from "../../../shared/document/document.module";
import { DobGenV2Component } from "../../../shared/document/member-validation-v2/dob-gen-v2/dob-gen-v2.component";
import { DobV2Component } from "../../../shared/document/member-validation-v2/dob-v2/dob-v2.component";
import { MedicalRecordDobV2Component } from "../../../shared/document/member-validation-v2/dob-v2/medical-record-dob-v2/medical-record-dob-v2.component";
import { GenericBorderTemplateModule } from "../../../shared/generic-border-template/generic-border-template.module";
import { MenuModule } from "../../../shared/menu/menu.module";
import { SharedModule } from "../../../shared/shared.module";
import { AddressDetailInfoService } from "../../modules/retrieval/psr/address-detail/address-detail-info/address-detail-info.service";
import { ArchiveChaseDetailChartComponent } from "./archive-chase-detail/archive-chase-detail-chart/archive-chase-detail-chart.component";
import { ArchiveChaseDetailHeaderComponent } from "./archive-chase-detail/archive-chase-detail-header/archive-chase-detail-header.component";
import { ArchiveChaseDetailComponent } from "./archive-chase-detail/archive-chase-detail.component";
import { ArchiveChaseDetailService } from "./archive-chase-detail/archive-chase-detail.service";
import { ChaseDetailV2ChartComponent } from "./chase-detail-v2/chase-detail-v2-chart/chase-detail-v2-chart.component";
import { MemberDobGenV2Component } from "./chase-detail-v2/chase-detail-v2-chart/member-dob-gen-v2/member-dob-gen-v2.component";
import { AdminValidationButtonsComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk-dx/admin-validation-buttons/admin-validation-buttons.component";
import { DxAdminCardHeaderComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk-dx/dx-admin-card-header/dx-admin-card-header.component";
import { DxAdminCardComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk-dx/dx-admin-card/dx-admin-card.component";
import { RiskDxAddAdminGridRowComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk-dx/dx-admin-grid/dx-add-admin/risk-dx-add-admin-grid-row.component";
import { DxAdminGridRowComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk-dx/dx-admin-grid/dx-admin-grid-row.component";
import { DxAdminGridComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk-dx/dx-admin-grid/dx-admin-grid.component";
import { DxCardHeaderComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk-dx/dx-card-header/dx-card-header.component";
import { DxCardComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk-dx/dx-card/dx-card.component";
import { DxContainerComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk-dx/dx-container/dx-container.component";
import { RiskDxAddGridRowComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk-dx/dx-grid/dx-add/risk-dx-add-grid-row.component";
import { DxGridRowComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk-dx/dx-grid/dx-grid-row.component";
import { DxGridComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk-dx/dx-grid/dx-grid.component";
import { RiskDxSubmitComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk-dx/dx-submit/dx-submit.component";
import { DxValidationModalComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk-dx/dx-submit/dx-validation-modal/dx-validation-modal.component";
import { RiskDxMenuComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk-dx/menu/menu.component";
import { RiskDxComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk-dx/risk-dx.component";
import { ValidationButtonsComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk-dx/validation-buttons/validation-buttons.component";
import { DiagnosisV2AdminComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk/risk-encounter/diagnosis/admin/admin.component";
import { CardHeaderComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk/risk-encounter/diagnosis/card-header/card-header.component";
import { DiagnosisV2CoderComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk/risk-encounter/diagnosis/coder/coder.component";
import { DiagnosisV2Component } from "./chase-detail-v2/chase-detail-v2-chart/risk/risk-encounter/diagnosis/diagnosis.component";
import { DiagnosisService } from "./chase-detail-v2/chase-detail-v2-chart/risk/risk-encounter/diagnosis/diagnosis.service";
import { DiagnosisV2EVEComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk/risk-encounter/diagnosis/eve/eve.component";
import { ScoringCheckboxComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk/risk-encounter/diagnosis/eve/scorring/scoring.component";
import { SliderEVEComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk/risk-encounter/diagnosis/eve/slider/slider.component";
import { EncounterDeleteComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk/risk-encounter/encounter/data-entry/delete/delete.component";
import { EncounterDataEntryComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk/risk-encounter/encounter/data-entry/encounter-data-entry.component";
import { ValidationComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk/risk-encounter/encounter/data-entry/validation/validation.component";
import { EncounterV2Component } from "./chase-detail-v2/chase-detail-v2-chart/risk/risk-encounter/encounter/encounter.component";
import { EncounterListComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk/risk-encounter/encounter/list/encounter-list.component";
import { EveClinicalTermsComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk/risk-encounter/eve-clinical-terms/eve-clinical-terms.component";
import { RiskMemberComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk/risk-member/risk-member.component";
import { RiskMenuComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk/risk-menu/risk-menu.component";
import { RiskReadOnlyComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk/risk-read-only/risk-read-only.component";
import { RiskSubmitComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk/risk-submit/risk-submit.component";
import { RiskSubmitSummaryComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk/risk-submit/summary/summary.component";
import { RiskValidationModalComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk/risk-validation-modal/risk-validation-modal.component";
import { ChaseDetailV2ChartRiskComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk/risk.component";
import { ChaseDetailV2FooterComponent } from "./chase-detail-v2/chase-detail-v2-footer/chase-detail-v2-footer.component";
import { ChaseDetailV2HeaderComponent } from "./chase-detail-v2/chase-detail-v2-header/chase-detail-v2-header.component";
import { ChaseDetailV2Component } from "./chase-detail-v2/chase-detail-v2.component";
import { ChaseDetailV2ChartViewerHeaderComponent } from "./chase-detail-v2/chase-document-page-viewer/chase-detail-v2-chart-viewer-header/chase-detail-v2-chart-viewer-header.component";
import { ChaseDocumentPageThumbnailExpandLabeledComponent } from "./chase-detail-v2/chase-document-page-viewer/chase-document-page-thumbnail-expand-labeled/chase-document-page-thumbnail-expand-labeled.component";
import { ChaseDocumentPageThumbnailExpandComponent } from "./chase-detail-v2/chase-document-page-viewer/chase-document-page-thumbnail-expand/chase-document-page-thumbnail-expand.component";
import { ChaseDocumentPageThumbnailsComponent } from "./chase-detail-v2/chase-document-page-viewer/chase-document-page-thumbnails/chase-document-page-thumbnails.component";
import { ChaseDocumentPageViewerComponent } from "./chase-detail-v2/chase-document-page-viewer/chase-document-page-viewer.component";
import { ChaseDetailAuditLogComponent } from "./chase-detail/chase-detail-audit-log/chase-detail-audit-log.component";
import { AdditionalMemberDetailComponent } from "./chase-detail/chase-detail-chart/additional-member-detail/additional-member-detail.component";
import { AdrvComponent } from "./chase-detail/chase-detail-chart/adrv/adrv.component";
import { AttComponent } from "./chase-detail/chase-detail-chart/att/att.component";
import { AuditPageEntryComponent } from "./chase-detail/chase-detail-chart/audit-page-entry/audit-page-entry.component";
import { ChaseDetailChartComponent } from "./chase-detail/chase-detail-chart/chase-detail-chart.component";
import { ComplianceComponent } from "./chase-detail/chase-detail-chart/compliance/compliance.component";
import { ExemptComponent } from "./chase-detail/chase-detail-chart/compliance/exempt.component";
import { DobGenComponent } from "./chase-detail/chase-detail-chart/dob-gen/dob-gen.component";
import { DobComponent } from "./chase-detail/chase-detail-chart/dob/dob.component";
import { EnrComponent } from "./chase-detail/chase-detail-chart/enr/enr.component";
import { EntityErrorModalComponent } from "./chase-detail/chase-detail-chart/entity-error-modal/entity-error-modal.component";
import { ExclusionAndContraComponent } from "./chase-detail/chase-detail-chart/exclusion-and-contra/exclusion-and-contra.component";
import { NonCompliantInfoComponent } from "./chase-detail/chase-detail-chart/exclusion-and-contra/non-compliant-info.component";
import { GenComponent } from "./chase-detail/chase-detail-chart/gen/gen.component";
import { MedicalRecordDobComponent } from "./chase-detail/chase-detail-chart/medical-record-dob/medical-record-dob.component";
import { MemberDobGenComponent } from "./chase-detail/chase-detail-chart/member-dob-gen/member-dob-gen.component";
import { AcceptanceResultComponent } from "./chase-detail/chase-detail-chart/nlp-hedis/acceptance-result/acceptance-result.component";
import { NlpHedisComponent } from "./chase-detail/chase-detail-chart/nlp-hedis/nlp-hedis.component";
import { SystemResultComponent } from "./chase-detail/chase-detail-chart/nlp-hedis/system-result/system-result.component";
import { ChallengeModalComponent } from "./chase-detail/chase-detail-chart/overread-feedback/challenge-modal/challenge-modal.component";
import { FeedbackModalComponent } from "./chase-detail/chase-detail-chart/overread-feedback/feedback-modal/feedback-modal.component";
import { ResolvePendWarningModalComponent } from "./chase-detail/chase-detail-chart/resolve-pend-warning-modal/resolve-pend-warning-modal.component";
import { AddDiagnosisComponent } from "./chase-detail/chase-detail-chart/risk/diagnoses-grid/add-diagnosis.component";
import { DeleteDiagnosisComponent } from "./chase-detail/chase-detail-chart/risk/diagnoses-grid/delete-diagnosis.component";
import { DiagnosesGridComponent } from "./chase-detail/chase-detail-chart/risk/diagnoses-grid/diagnoses-grid.component";
import { ExemptDiagnosisComponent } from "./chase-detail/chase-detail-chart/risk/diagnoses-grid/exempt-diagnosis.component";
import { DiagnosisComponent } from "./chase-detail/chase-detail-chart/risk/diagnosis/diagnosis.component";
import { AddEncounterComponent } from "./chase-detail/chase-detail-chart/risk/encounter-grid/add-encounter.component";
import { DeleteEncounterComponent } from "./chase-detail/chase-detail-chart/risk/encounter-grid/delete-encounter.component";
import { EncounterGridComponent } from "./chase-detail/chase-detail-chart/risk/encounter-grid/encounter-grid.component";
import { ExemptEncounterComponent } from "./chase-detail/chase-detail-chart/risk/encounter-grid/exempt-encounter.component";
import { EncounterComponent } from "./chase-detail/chase-detail-chart/risk/encounter/encounter.component";
import { RiskErrorModalComponent } from "./chase-detail/chase-detail-chart/risk/risk-error-modal/risk-error-modal.component";
import { RiskSystemResultKeyComponent } from "./chase-detail/chase-detail-chart/risk/risk-system-result-key/risk-system-result-key.component";
import { RiskSystemResultComponent } from "./chase-detail/chase-detail-chart/risk/risk-system-result/risk-system-result.component";
import { RiskComponent } from "./chase-detail/chase-detail-chart/risk/risk.component";
import { RiskService } from "./chase-detail/chase-detail-chart/risk/risk.service";
import { RxcmEncounterComponent } from "./chase-detail/chase-detail-chart/rxcm/encounter/encounter.component";
import { MedicalProcedureGridComponent } from "./chase-detail/chase-detail-chart/rxcm/medical-procedure-grid/medical-procedure-grid.component";
import { MedicalProcedureComponent } from "./chase-detail/chase-detail-chart/rxcm/medical-procedure/medical-procedure.component";
import { RxcmComponent } from "./chase-detail/chase-detail-chart/rxcm/rxcm.component";
import { RxcpComponent } from "./chase-detail/chase-detail-chart/rxcp/rxcp.component";
import { ServiceProviderComponent } from "./chase-detail/chase-detail-chart/service-provider/service-provider.component";
import { SummaryComponent } from "./chase-detail/chase-detail-chart/summary/summary.component";
import { YesNoRadioComponent } from "./chase-detail/chase-detail-chart/yes-no-radio/yes-no-radio.component";
import { ChaseDetailCommentsComponent } from "./chase-detail/chase-detail-comments/chase-detail-comments.component";
import { ChaseDetailDocumentsComponent } from "./chase-detail/chase-detail-documents/chase-detail-documents.component";
import { ChaseDetailHeaderComponent } from "./chase-detail/chase-detail-header/chase-detail-header.component";
import { ChaseDetailHeaderService } from "./chase-detail/chase-detail-header/chase-detail-header.service";
import { ChaseDetailHealthCardComponent } from "./chase-detail/chase-detail-health-card/chase-detail-health-card.component";
import { ChaseDetailInfoChartComponent } from "./chase-detail/chase-detail-info/chase-detail-info-chart/chase-detail-info-chart.component";
import { ChaseDetailInfoCurrentPendsComponent } from "./chase-detail/chase-detail-info/chase-detail-info-current-pends/chase-detail-info-current-pends.component";
import { ChaseDetailInfoPursuitItemComponent } from "./chase-detail/chase-detail-info/chase-detail-info-pursuits/chase-detail-info-pursuit-item/chase-detail-info-pursuit-item.component";
import { ChaseDetailInfoPursuitsComponent } from "./chase-detail/chase-detail-info/chase-detail-info-pursuits/chase-detail-info-pursuits.component";
import { ChaseDetailInfoComponent } from "./chase-detail/chase-detail-info/chase-detail-info.component";
import { ChaseDetailInfoService } from "./chase-detail/chase-detail-info/chase-detail-info.service";
import { ChaseDetailMemberChasesComponent } from "./chase-detail/chase-detail-member-chases/chase-detail-member-chases.component";
import { ChaseDetailMemberGapsComponent } from "./chase-detail/chase-detail-member-gaps/chase-detail-member-gaps.component";
import { ChaseDetailStateService } from "./chase-detail/chase-detail-state.service";
import { ChaseDetailTimelineComponent } from "./chase-detail/chase-detail-timeline/chase-detail-timeline.component";
import { ChaseDetailTimelineService } from "./chase-detail/chase-detail-timeline/chase-detail-timeline.service";
import { ChaseDetailComponent } from "./chase-detail/chase-detail.component";
import { ChaseDetailService } from "./chase-detail/chase-detail.service";
import { ChaseDocumentService } from "./chase-document.service";
import { ChasePageUrlService } from "./chase-page-url.service";
import { CreateNewChaseAddressComponent } from "./create-new-chase/create-new-chase-address/create-new-chase-address.component";
import { CreateNewChaseEncountersComponent } from "./create-new-chase/create-new-chase-encounters/create-new-chase-encounters.component";
import { CreateNewChaseFinalProcessComponent } from "./create-new-chase/create-new-chase-final-process/create-new-chase-final-process.component";
import { CreateNewChaseGapComponent } from "./create-new-chase/create-new-chase-gap/create-new-chase-gap.component";
import { CreateNewChaseMemberComponent } from "./create-new-chase/create-new-chase-member/create-new-chase-member.component";
import { CreateNewChaseProjectComponent } from "./create-new-chase/create-new-chase-project/create-new-chase-project.component";
import { CreateNewChaseProviderComponent } from "./create-new-chase/create-new-chase-provider/create-new-chase-provider.component";
import { CreateNewChaseSuccessConfirmationComponent } from "./create-new-chase/create-new-chase-success-confirmation/create-new-chase-success-confirmation.component";
import { CreateNewChaseComponent } from "./create-new-chase/create-new-chase.component";
import { CreateStepsMenuComponent } from "./create-new-chase/create-steps-menu/create-steps-menu.component";
import { GapQueryDataExchangeService } from "./create-new-chase/gap-query-data-exchange.service";
import { MemberDetailBodyGridsChasesComponent } from "./member-detail/member-detail-body/member-detail-body-grids-chases/member-detail-body-grids-chases.component";
import { MemberDetailBodyComponent } from "./member-detail/member-detail-body/member-detail-body.component";
import { MemberDetailHeaderComponent } from "./member-detail/member-detail-header/member-detail-header.component";
import { MemberDetailComponent } from "./member-detail/member-detail.component";
import { MemberDetailService } from "./member-detail/member-detail.service";
import { MemberRoutingModule } from "./member-routing.module";
import { MemberListingComponent } from "./member/member-listing/member-listing.component";
import { MemberComponent } from "./member/member.component";
import { MemberService } from "./member/member.service";
import { NewPagesGridComponent } from "./new-pages/new-pages-grid/new-pages-grid.component";
import { NewPagesService } from "./new-pages/new-pages.service";
import { ProviderServicesEmployeeComponent } from "./provider-services-employee/provider-services-employee.component";
@NgModule({
  imports: [
    AccordionModule,
    AutoCompleteModule,
    CheckboxModule,
    DropdownModule,
    DynamicFormsModule,
    FileUploadModule,
    GenericBorderTemplateModule,
    MemberRoutingModule,
    MenuModule,
    SharedModule,
    DocumentModule,
  ],
  declarations: [
    AcceptanceResultComponent,
    AddDiagnosisComponent,
    AddEncounterComponent,
    AdditionalMemberDetailComponent,
    AdminValidationButtonsComponent,
    AdrvComponent,
    ArchiveChaseDetailChartComponent,
    ArchiveChaseDetailComponent,
    ArchiveChaseDetailHeaderComponent,
    AttComponent,
    AuditPageEntryComponent,
    CardHeaderComponent,
    ChallengeModalComponent,
    ChaseDetailAuditLogComponent,
    ChaseDetailChartComponent,
    ChaseDetailCommentsComponent,
    ChaseDetailComponent,
    ChaseDetailDocumentsComponent,
    ChaseDetailHeaderComponent,
    ChaseDetailHealthCardComponent,
    ChaseDetailInfoChartComponent,
    ChaseDetailInfoComponent,
    ChaseDetailInfoCurrentPendsComponent,
    ChaseDetailInfoPursuitItemComponent,
    ChaseDetailInfoPursuitsComponent,
    ChaseDetailMemberChasesComponent,
    ChaseDetailMemberGapsComponent,
    ChaseDetailTimelineComponent,
    ChaseDetailV2ChartComponent,
    ChaseDetailV2ChartRiskComponent,
    ChaseDetailV2ChartViewerHeaderComponent,
    ChaseDetailV2Component,
    ChaseDetailV2FooterComponent,
    ChaseDetailV2HeaderComponent,
    ChaseDocumentPageThumbnailExpandComponent,
    ChaseDocumentPageThumbnailExpandLabeledComponent,
    ChaseDocumentPageThumbnailsComponent,
    ChaseDocumentPageViewerComponent,
    ComplianceComponent,
    CreateNewChaseAddressComponent,
    CreateNewChaseComponent,
    CreateNewChaseEncountersComponent,
    CreateNewChaseFinalProcessComponent,
    CreateNewChaseGapComponent,
    CreateNewChaseMemberComponent,
    CreateNewChaseProjectComponent,
    CreateNewChaseProviderComponent,
    CreateNewChaseSuccessConfirmationComponent,
    CreateStepsMenuComponent,
    DeleteDiagnosisComponent,
    DeleteEncounterComponent,
    DiagnosesGridComponent,
    DiagnosisComponent,
    DiagnosisV2AdminComponent,
    DiagnosisV2CoderComponent,
    DiagnosisV2EVEComponent,
    EveClinicalTermsComponent,
    DiagnosisV2Component,
    DobComponent,
    DobGenComponent,
    DobGenV2Component,
    DobV2Component,
    DxAdminCardHeaderComponent,
    DxAdminCardComponent,
    DxAdminGridComponent,
    DxCardComponent,
    DxCardHeaderComponent,
    RiskDxAddAdminGridRowComponent,
    DxAdminGridRowComponent,
    DxContainerComponent,
    DxGridComponent,
    DxGridRowComponent,
    DxValidationModalComponent,
    EncounterComponent,
    EncounterDataEntryComponent,
    EncounterDeleteComponent,
    EncounterGridComponent,
    EncounterListComponent,
    EncounterV2Component,
    EnrComponent,
    EntityErrorModalComponent,
    ExclusionAndContraComponent,
    ExemptComponent,
    ExemptDiagnosisComponent,
    ExemptEncounterComponent,
    FeedbackModalComponent,
    GenComponent,
    MedicalProcedureComponent,
    MedicalProcedureGridComponent,
    MedicalRecordDobComponent,
    MedicalRecordDobV2Component,
    MemberComponent,
    MemberDetailBodyComponent,
    MemberDetailBodyGridsChasesComponent,
    MemberDetailComponent,
    MemberDetailHeaderComponent,
    MemberDobGenComponent,
    MemberDobGenV2Component,
    MemberListingComponent,
    NewPagesGridComponent,
    NlpHedisComponent,
    NonCompliantInfoComponent,
    ProviderServicesEmployeeComponent,
    ResolvePendWarningModalComponent,
    RiskComponent,
    RiskDxComponent,
    RiskDxMenuComponent,
    RiskDxSubmitComponent,
    RiskErrorModalComponent,
    RiskMemberComponent,
    RiskMenuComponent,
    RiskReadOnlyComponent,
    RiskSubmitComponent,
    RiskSubmitSummaryComponent,
    RiskSystemResultComponent,
    RiskSystemResultKeyComponent,
    RiskValidationModalComponent,
    RxcmComponent,
    RxcmEncounterComponent,
    RxcpComponent,
    ScoringCheckboxComponent,
    ServiceProviderComponent,
    SliderEVEComponent,
    SummaryComponent,
    SystemResultComponent,
    ValidationButtonsComponent,
    ValidationComponent,
    YesNoRadioComponent,
    RiskDxAddGridRowComponent,
  ],
  providers: [
    AddressDetailInfoService,
    ArchiveChaseDetailService,
    ChaseDetailInfoService,
    ChaseDetailHeaderService,
    ChaseDetailService,
    ChaseDetailStateService,
    ChaseDetailTimelineService,
    ChaseDocumentService,
    ChasePageUrlService,
    DiagnosisService,
    GapQueryDataExchangeService,
    MemberDetailService,
    MemberService,
    NewPagesService,
    RiskService,
  ],
  exports: [
    ChaseDetailChartComponent,
  ],
  entryComponents: [
    ChaseDetailV2ChartRiskComponent,
    RiskDxComponent,
  ],
})
export class MemberModule { }
