import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild
} from "@angular/core";
import { FileUpload } from "primeng/fileupload";
import { BASE_API_URL } from "../../core/environment.tokens";
import { MessagingService } from "../../core/messaging/messaging.service";
import { SeverityType } from "../../core/messaging/severity-type.enum";
import { UserService } from "../../core/user/user.service";
import { NotificationService } from "../../platform/notification/notification.service";
import { ArrayHelper } from "../../utilities/contracts/array-helper";

export interface IOnUpload {
  formData: FormData;
  usePresignedURLs: boolean;
  presignedURLList: string[];
  originalFilenameList: string[];

  success(): void;

  fail(): void;
}

export interface IUploadObject {
  presignedURL: string;
  fileName: string;
}

@Component({
  selector: "app-file-upload",
  template: `
    <p-fileUpload
      #primeUpload
      name="file[]"
      (uploadHandler)="uploadDocument($event)"
      (onRemove)="removeEvent($event)"
      customUpload="true"
      [multiple]="multiple"
      [accept]="accept"
      maxFileSize="1000000000"
      [chooseLabel]="chooseLabel"
      [uploadLabel]="uploadLabel"
      [showUploadButton]="showUploadButton"
      [showCancelButton]="showCancelButton"
      [disabled]="disabled"
      [auto]="isAutoUpload"
      (onSelect)="onFileSelected($event)"
      (onClear)="cancelAllDocuments()"
    ></p-fileUpload>
  `,
  styleUrls: ["./upload.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadComponent {
  @ViewChild("primeUpload", {static: true}) primeUpload: FileUpload;
  @Input() accept = ".pdf";
  @Input() sendThroughPresignUrl = true;
  @Input() multiple = true;
  @Input() chooseLabel = "Select Documents";
  @Input() uploadLabel = "Submit";
  @Input() data: KeyValuePairs<string> = [];
  @Input() successMessage = "Uploaded successfully.";
  @Input() disabled = false;
  @Input() showUploadButton = true;
  @Input() showCancelButton = true;
  @Input() isAutoUpload = false;
  @Input() isAutoUploadThumbnail = false;
  @Output() onSubmit: EventEmitter<null> = new EventEmitter();
  @Output() onUpload = new EventEmitter<IOnUpload>(true);
  // NOTE: DO NOT USE "onSelect". This is not approved because it is returning a primeng event and now this component is tied to primeng.
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  private formData = new FormData();

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly messagingService: MessagingService,
    private userService: UserService,
    private http: HttpClient,
    private notificationService: NotificationService
  ) {
  }

  private presignedURLList = [];
  private presignedURLArray = new Array();
  private fileNameArray = new Array();
  private fileNameSaver = [];
  private dict = new Map();
  private fileNameToPresigned = new Map();
  private globalFileCounter = 0;
  private usePresignedURLs = this.userService.getUserToken().isPreSignedUrlUploadEnabled;

  async onFileSelected(event): Promise<void> {
    if (!this.usePresignedURLs) {
      if (event.currentFiles.length > 0) {
        event.files = event.currentFiles;
      }
      for (const file of event.files) {
        if (!this.fileNameSaver.includes(file.name)) {
          this.fileNameSaver.push(file.name);
        }
      }
    }
    if (this.usePresignedURLs) {
      if (event.currentFiles.length > 0) {
        event.files = event.currentFiles;
      }
      for (const file of event.files) {
        const fileExtension = file.name.substr(file.name.lastIndexOf(".") + 1).trim().toLowerCase();

        if (!this.fileNameSaver.includes(file.name)) {
          this.formData.append(file.name, file);
          this.fileNameSaver.push(file.name);

          const obj = {
            name: file.name,
            type: file.type,
          };

          if (!this.sendThroughPresignUrl) {
            return;
          }

          await this.http.post(`${this.baseApiUrl}medicalrecord/presignedurl`, JSON.stringify(obj), { headers: new HttpHeaders({ "Content-Type": "application/json" }) })
            .toPromise().then(async (x: IUploadObject) => {
              this.presignedURLArray.push(x.presignedURL);
              this.fileNameArray.push(x.fileName);
              this.dict.set(this.fileNameSaver[this.globalFileCounter], x.fileName);
              this.fileNameToPresigned.set(this.fileNameSaver[this.globalFileCounter], x.presignedURL);
              this.globalFileCounter++;
            });
        }
      }

      if (this.isAutoUploadThumbnail) {
        this.uploadDocument(event);
      }
    }
  }

  uploadFiles(file, j): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        this.http.put(this.presignedURLArray[j], file).toPromise().then(() => {
          resolve(true);
        });
      } catch (e) {
        reject(e);
      }
    });
  }

  async uploadDocument(event): Promise<void> {
    const files = event.files;
    if (ArrayHelper.isAvailable(files)) {
      const formData = this.createFormData(files);
      if (this.usePresignedURLs) {
        const filePromises = files.map((file, index) => {
          // Return a promise per file

          if (!this.sendThroughPresignUrl) {
            return;
          }
          return this.uploadFiles(file, index);
        });
        await Promise.all(filePromises).catch(res => {
          return false;
        });
        for (const key of this.dict.keys()) {
          if (key) {
            this.formData.append(this.dict.get(key), this.dict.get(key));
            this.presignedURLList.push(this.dict.get(key));
          }
        }
      }
      this.onUpload.emit({
        formData,
        success: () => {
          this.primeUpload.clear();
          this.notificationService
            .refreshNotifications();
          this.formData = new FormData();
        },
        fail: () => {
          this.primeUpload.clear();
          this.changeDetector.markForCheck();
          this.formData = new FormData();
        },
        usePresignedURLs: this.usePresignedURLs,
        presignedURLList: this.presignedURLList,
        originalFilenameList: this.fileNameSaver,
      });
    } else {
      this.messagingService.showToast(
        "Please select a file.",
        SeverityType.ERROR
      );
    }
  }

  cancelAllDocuments(): void {
    this.presignedURLArray = [];
    this.presignedURLList = [];
    this.fileNameArray = [];
    this.fileNameSaver = [];
    this.dict = new Map();
    this.fileNameToPresigned = new Map();
    this.globalFileCounter = 0;
    this.formData = new FormData();
  }

  private createFormData(files: any): FormData {
    const formData = new FormData();
    let size = 0;
    files.forEach(file => {
      formData.append("Document", file);
      size += file.size;
    });
    formData.append("Size", size.toString());
    if (this.data != null && this.data.length > 0) {
      this.data.forEach(data => formData.append(data.key, data.value));
    }
    return formData;
  }

  deleteFile(deleteFile: string): void {
    if (ArrayHelper.isAvailable(this.primeUpload.files)) {
      this.primeUpload.files = this.primeUpload.files.filter(file => file.name !== deleteFile);
    }
    this.removeFileByName(deleteFile);
  }

  removeEvent(event): void {
    this.removeFileByName(event.file.name);
  }

  removeFileByName(deleteFile: string) {
    const nameholder = this.fileNameToPresigned.get(deleteFile);
    const guidFileName = this.dict.get(deleteFile);
    this.presignedURLArray = this.presignedURLArray.filter(item => item !== nameholder);
    this.dict.delete(deleteFile);
    this.fileNameSaver = this.fileNameSaver.filter(item => item !== deleteFile);
    this.fileNameToPresigned.delete(deleteFile);
    this.fileNameArray = this.fileNameArray.filter(item => item !== guidFileName);
    this.formData.delete(guidFileName);
    this.globalFileCounter--;
    this.presignedURLList.filter(item => item !== guidFileName);
  }
}
