export class SubmissionRequest {
  requestId: number;
  requestTypeId: number;
  requestTypeName: string;
  projectId: number;
  projectName: string;
  clientName: string;
  hios: number;
  totalMember: number;
  fileLocation: string;
  requestUserId: number;
  requestUserName: string;
  auditStatusId: number;
  auditStatusName: string;
  auditRequestDate: string;
  auditUpdateDate: string;
  processMode: number;
}
