import { DateHelper } from "../../../../utilities/contracts/date-helper";
import { dateTypes } from "../../../../utilities/contracts/helper-types";

export class ProjectConfiguration {
  enableMrrBot: string;
  encounterTypeRequired: string;
  memberCentricChaseRetrieval: string;
  omissionCodeEnabled: string;
  reviewPeriodFromDate: dateTypes;
  reviewPeriodThruDate: dateTypes;
  serviceProviderRequired: string;
  validationReasonCodeRequired: string;
  dataEntryProviderConfirmationEnabled: string;
  eveOnlyCardsValidationRequired: string;
  demographicRequired: string;
  reviewAdminEncountersAndDiagnoses: string;
  codingLevel: string;
  codingReviewMode: string;
  hccCardAdditionEnabled: string;
  codingOptimizationMode: string;

  constructor(options: Partial<ProjectConfiguration> = {}) {
    this.enableMrrBot = options.enableMrrBot;
    this.encounterTypeRequired = options.encounterTypeRequired;
    this.memberCentricChaseRetrieval = options.memberCentricChaseRetrieval;
    this.omissionCodeEnabled = options.omissionCodeEnabled;
    this.reviewPeriodFromDate = DateHelper.create(options.reviewPeriodFromDate, null);
    this.reviewPeriodThruDate = DateHelper.create(options.reviewPeriodThruDate, null);
    this.serviceProviderRequired = options.serviceProviderRequired;
    this.validationReasonCodeRequired = options.validationReasonCodeRequired;
    this.dataEntryProviderConfirmationEnabled = options.dataEntryProviderConfirmationEnabled;
    this.demographicRequired = options.demographicRequired;
    this.reviewAdminEncountersAndDiagnoses = options.reviewAdminEncountersAndDiagnoses;
    this.codingLevel = options.codingLevel;
    this.codingReviewMode = options.codingReviewMode;
    this.hccCardAdditionEnabled = options.hccCardAdditionEnabled;
    this.codingOptimizationMode = options.codingOptimizationMode;
    this.eveOnlyCardsValidationRequired = options.eveOnlyCardsValidationRequired;
  }
}
