<p-fileUpload #primeUpload
  name="file[]"
  customUpload="true"
  maxFileSize="1000000000"
  uploadLabel="Submit"
  [mode]="mode"
  [auto]="auto"
  [multiple]="multiple"
  [accept]="accept"
  [chooseLabel]="chooseLabel"
  [showUploadButton]="showUploadButton"
  [showCancelButton]="showCancelButton"
  [disabled]="disabled"
  (onSelect)="onFileSelected($event)"
  (uploadHandler)="uploadDocument($event)">

  <!-- TODO: Change template after design reviews upload component -->
  <ng-template let-file pTemplate="file">
    <div>{{file.name}}</div>
    <p-messages [hidden]="!hasError" severity="error" [(value)]="errorMessage" [enableService]="false"></p-messages>
  </ng-template>
</p-fileUpload>
