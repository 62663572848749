import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ChaseDetailState } from "../../../../chase-detail/chase-detail-state.model";
import { RiskDxDiagnosis } from "../dx-models/response/risk-dx-diagnosis.model";
import { RiskDxIcd } from "../dx-models/response/risk-dx-icd.model";


@Component({
  selector: "app-risk-dx-admin-grid",
  templateUrl: "./dx-admin-grid.component.html",
  styleUrls: ["./dx-admin-grid.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxAdminGridComponent implements OnInit {
  @Input() diagnoses: RiskDxDiagnosis[] = [];
  @Input() disabled: boolean;
  @Input() icdGroup: RiskDxIcd;
  @Input() riskChaseState: ChaseDetailState;
  @Input() addNewDx: boolean;


  @Output() diagnosesChange: EventEmitter<RiskDxDiagnosis[]> = new EventEmitter();
  @Output() saveNewDiagnosis: EventEmitter<any> = new EventEmitter();
  @Output() removeDx: EventEmitter<any> = new EventEmitter();
  @Output() removeDiagnosis: EventEmitter<any> = new EventEmitter();
  @Output() onValid: EventEmitter<any> = new EventEmitter<boolean>();
  @Output() onInvalid: EventEmitter<any> = new EventEmitter<boolean>();
  @Output() onResetAll: EventEmitter<any> = new EventEmitter<boolean>();
  constructor() {
  }

  ngOnInit(): void {
  }

  trackByIndex(index): number {
    return index;
  }

  saveDiagnosis($event) {
    this.saveNewDiagnosis.emit($event);
  }
  clearDiagnosis(): void {
    this.removeDx.emit();
  }
  removeCoderAddedDiagnosis(val) {
    this.removeDiagnosis.emit(val);
  }
  onValidDiagnosis(val) {
    this.onValid.emit(val);
  }
  onInValidDiagnosis(val) {
    this.onInvalid.emit(val);
  }
  onResetDiagnosis(val) {
    this.onResetAll.emit(val);
  }
}
