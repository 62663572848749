import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { AutomapperService } from "../../../../../../../../core/automapper/automapper.service";
import { MessagingService } from "../../../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../../../../../dynamic-forms/form.service";
import { Autocomplete } from "../../../../../../../../dynamic-forms/inputs/autocomplete/autocomplete.model";
import { Checkbox } from "../../../../../../../../dynamic-forms/inputs/checkbox/checkbox.model";
import { ProviderDropdown } from "../../../../../../../../dynamic-forms/inputs/provider-dropdown/provider-dropdown.model";
import { TextboxType } from "../../../../../../../../dynamic-forms/inputs/textbox/textbox-type.enum";
import { Textbox } from "../../../../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { dateBetweenValidator } from "../../../../../../../../dynamic-forms/validators/date-between.validator";
import { DateHelper } from "../../../../../../../../utilities/contracts/date-helper";
import { StringHelper } from "../../../../../../../../utilities/contracts/string-helper";
import { RegExHelper } from "../../../../../../../../utilities/reg-Ex-Helper";
import { IcdService } from "../../../../../chase-detail/chase-detail-chart/risk/diagnosis/icd.service";
import { ChaseDetailState } from "../../../../../chase-detail/chase-detail-state.model";
import { RiskDxIcd } from "../../dx-models/response/risk-dx-icd.model";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "tr[app-risk-dx-add-grid-row]",
  templateUrl: "./risk-dx-add-grid-row.component.html",
  styleUrls: ["./../dx-grid.component.scss"],
})
export class RiskDxAddGridRowComponent implements OnInit {

  @Input() riskChaseState: ChaseDetailState;
  @Input() icdGroup: RiskDxIcd;
  @Output() saveDiagnosis: EventEmitter<any> = new EventEmitter();
  @Output() removeDx: EventEmitter<any> = new EventEmitter();
  @Output() hideDiagnosisForm: EventEmitter<any> = new EventEmitter();

  diagnosisInput: Checkbox;
  startDateInput: Textbox;
  icdCodeInput: Autocomplete;
  pageNumberInput: Textbox;
  providerInput: ProviderDropdown;
  diagnosisForm: FormGroup;

  get isFormValid(): boolean {
    return this.diagnosisForm.valid;
  }

  get modelId(): string {
    return this.icdGroup?.modelId;
  }

  get categoryCode(): string {
    return this.icdGroup?.hccId;
  }

  constructor(
    private readonly formService: FormService,
    private readonly automapper: AutomapperService,
    private changeDetector: ChangeDetectorRef,
    private readonly icdService: IcdService,
    private readonly messagingService: MessagingService
  ) { }

  ngOnInit(): void {
    this.initializeForm();
    this.getProviderDetails();
    this.updateFormWithProjectConfiguration(this.riskChaseState);
  }

  private initializeForm(): void {
    this.diagnosisInput = new Checkbox({
      key: "diagnosis",
      updateOn: "change",
      value: true,
    });
    this.providerInput = new ProviderDropdown({
      key: "Provider",
      label: "Provider",
      placeholder: "Select Provider",
      validators: [Validators.required],
      errorMessages: {
        required: "Provider is required",
      },
    });
    this.startDateInput = new Textbox({
      key: "startDate",
      label: "Date From",
      validators: [Validators.required],
      errorMessages: {
        required: "Date from is required",
      },
    });
    this.icdCodeInput = new Autocomplete({
      key: "Icd",
      label: "ICD",
      searchMinimum: 2,
      serverFilter: (query, setOptionsFn) => {
        const startDate = this.diagnosisForm.get(this.startDateInput.key)?.value;
        // Check if query is non-empty before making the API call
        if (StringHelper.isAvailable(query)) {
          if (this.categoryCode) {
            this.icdService.getIcdsForDxCard(
              this.riskChaseState.chaseId,
              query,
              startDate,
              this.modelId,
              this.categoryCode
            ).subscribe(setOptionsFn);
          } else {
            this.icdService.getIcds(this.riskChaseState.chaseId, query, startDate as string).subscribe(setOptionsFn);
          }
        } else {
          setOptionsFn([]);
        }
      },
      validators: [Validators.required],
      errorMessages: {
        required: "ICD Code is required",
        invalidicd: "Invalid ICD Code",
      },
    });
    this.pageNumberInput = new Textbox({
      key: "pageNumber",
      label: "Page Number",
      classOverride: "control-page-number",
      type: TextboxType.NUMBER,
      validators: [
        Validators.required,
        Validators.min(1),
        Validators.pattern(RegExHelper.wholeNumber),
      ],
      errorMessages: {
        required: "Enter a page number greater than 1",
        min: "Enter a page number greater than 1",
        pattern: "The page number must be an integer",
      },
    });
    this.createForm();
  }

  private createForm(): void {
    this.diagnosisForm = this.formService.createFormGroup(
      [
        this.diagnosisInput,
        this.startDateInput,
        this.icdCodeInput,
        this.providerInput,
        this.pageNumberInput,
      ]
    );
  }

  saveNewDiagnosis(): void {
    if (this.diagnosisForm.get(this.pageNumberInput.key)?.value > this.riskChaseState.totalDocumentPages) {
      const errorMessage = `The entered page number is outside the chart's 1 to ${this.riskChaseState.totalDocumentPages} page range.`;
      this.messagingService.showToast(errorMessage, SeverityType.ERROR);
      return;
    }
    const dxActionRequests = {
      code: this.diagnosisForm.get(this.icdCodeInput.key)?.value?.value,
      dosFrom: this.diagnosisForm.get(this.startDateInput.key)?.value,
      dosThrough: this.diagnosisForm.get(this.startDateInput.key)?.value,
      encounterId: this.icdGroup?.diagnoses[0].encounterId,
      hcc: this.icdGroup?.hccId,
      isCoderAddedDiagnosis: true,
      isEve: false,
      isSelected: true,
      modelId: this.icdGroup?.modelId,
      pageNumber: this.diagnosisForm.get(this.pageNumberInput.key)?.value,
      providerName: this.getProviderName(),
      vrcCode: "00",
      isDxAddedFromNewCard: !StringHelper.isAvailable(this.icdGroup?.hccId),
    };

    const isDuplicate = this.icdGroup?.diagnoses?.some(item => {
      return item.code.replace(".", "") === this.diagnosisForm.get(this.icdCodeInput.key)?.value?.value
        && item.dosFrom === this.diagnosisForm.get(this.startDateInput.key)?.value;
    });


    if (isDuplicate) {
      const errorMessage = "Adding duplicate diagnoses is not permitted.";
      this.messagingService.showToast(errorMessage, SeverityType.ERROR);
    } else {
      this.saveDiagnosis.emit(dxActionRequests);
      this.diagnosisForm.reset();
      this.hideForm();
    }
  }

  hideForm(): void {
    this.hideDiagnosisForm.emit();
  }

  getProviderName(): string {
    const selectedProvider = this.diagnosisForm.get(this.providerInput.key)?.value;
    const selectedProviderName = this.providerInput.options.filter(item => item.value === selectedProvider);
    return selectedProviderName[0].text;
  }

  private getProviderDetails(): void {
    this.providerInput.options = this.riskChaseState.providers.map(this.automapper.curry("Provider", "SelectableInput"));
    this.setSaveInfo();
    this.changeDetector.markForCheck();
  }

  private setSaveInfo(): void {
    const options = JSON.stringify(this.providerInput.options);
    const saveInfo = {
      chaseId: this.riskChaseState.chaseId,
      options,
    };
    const control = this.diagnosisForm.get(this.providerInput.getMasterKey());
    (control as any).saveInfo = saveInfo;
    this.providerInput.saveInfo = saveInfo;
  }

  private updateFormWithProjectConfiguration({ projectConfiguration }: ChaseDetailState): void {
    if (projectConfiguration != null) {
      const { reviewPeriodFromDate, reviewPeriodThruDate } = projectConfiguration;
      const dateValidators = [
        Validators.required,
        dateBetweenValidator(reviewPeriodFromDate, reviewPeriodThruDate),
      ];
      const datebetween = `The date must be between ${DateHelper.format(reviewPeriodFromDate)} - ${DateHelper.format(reviewPeriodThruDate)}`;

      this.diagnosisForm.get(this.startDateInput.getMasterKey())?.setValidators(dateValidators);
      this.startDateInput.validators = dateValidators;
      this.startDateInput.errorMessages = {
        ...this.startDateInput.errorMessages,
        datebetween,
      };

    }
  }

  validateDate(): void {
    const startDate = this.diagnosisForm.get(this.startDateInput.key);
    if (!DateHelper.format(startDate?.value, "MM/DD/YYYY")) {
      startDate?.setErrors({ "server-error": "Date should be in MM/DD/YYYY format" });
      this.changeDetector.markForCheck();
    } else {
      const formattedDate = DateHelper.format(startDate?.value);
      this.diagnosisForm.get(this.startDateInput.key)?.setValue(formattedDate);
    }
  }
}
