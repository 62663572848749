/* tslint:disable:max-file-line-count */
import { CdkDrag } from "@angular/cdk/drag-drop";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, combineLatest } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, finalize, skip } from "rxjs/operators";
import { SubSink } from "subsink";
import { AuthService } from "../../../auth/auth.service";
import { AutomapperService } from "../../../core/automapper/automapper.service";
import { LoaderService } from "../../../core/interceptor/loader/loader.service";
import { MessagingService } from "../../../core/messaging/messaging.service";
import { SeverityType } from "../../../core/messaging/severity-type.enum";
import { LocalService } from "../../../core/storage/local.service";
import { SessionService } from "../../../core/storage/session.service";
import { FormService } from "../../../dynamic-forms/form.service";
import { TextboxType } from "../../../dynamic-forms/inputs/textbox/textbox-type.enum";
import { Textbox } from "../../../dynamic-forms/inputs/textbox/textbox.model";
import { MedicalRecordUploadService } from "../../../platform/api/medical-record-upload/medical-record-upload.service";
import {
  LOCALSTORAGE_PAGEAFTERSCREENSYNC,
  LOCALSTORAGE_PAGEBEFORESCREENSYNC,
  LOCALSTORAGE_PAGERELOAD
} from "../../../platform/modules/member/chase-detail-v2/chase-detail-v2-chart/local-storage-keys";
import {
  Diagnosis
} from "../../../platform/modules/member/chase-detail/chase-detail-chart/risk/diagnosis/diagnosis.model";
import { RiskService } from "../../../platform/modules/member/chase-detail/chase-detail-chart/risk/risk.service";
import { ChaseDetailState } from "../../../platform/modules/member/chase-detail/chase-detail-state.model";
import { ChaseDetailStateService } from "../../../platform/modules/member/chase-detail/chase-detail-state.service";
import { ProjectRetrievalType } from "../../../platform/modules/member/create-new-chase/project-retrieval-type.enum";
import { ProjectConfiguration } from "../../../platform/modules/project/project-config/project-configuration.model";
import { DirectoryUserRole } from "../../../platform/modules/retrieval/directory-user-role";
import { DocumentPage } from "../../../platform/modules/retrieval/retreival-document-review/document-page.model";
import { DocumentPages } from "../../../platform/modules/retrieval/retreival-document-review/document-pages.models";
import { PageTypeName } from "../../../platform/modules/retrieval/retreival-document-review/page-type.enum";
import { RetrievalDocumentServiceService } from "../../../platform/modules/retrieval/retrieval-document-service.service";
import { PlatformService } from "../../../platform/platform.service";
import { AnnotationService } from "../../../platform/widget/annotations/annotation.service";
import { Widget } from "../../../platform/widget/widget.enum";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../utilities/contracts/date-helper";
import { NumberHelper } from "../../../utilities/contracts/number-helper";
import { ObjectHelper } from "../../../utilities/contracts/object-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { debounceTimeAfterFirst } from "../../../utilities/debounce-time-after";
import { LoaderHelper } from "../../../utilities/loader-helper.class";
import { MenuService } from "../../menu/menu.service";
import { DocumentPageService } from "../document-page.service";
import { CacheTracker } from "./cache-tracker.model";
import { DocumentPageImage } from "./document-image.model";
import { DocumentOcrMatch } from "./document-ocr-match.model";
import { AnnotationSource } from "./document-page-annotations/annotation-source-enum";
import { Annotations } from "./document-page-annotations/annotations.model";
import { ChaseDocumentPages } from "./document-page-annotations/chase-document-pages.model";
import { DocumentPageAnnotations } from "./document-page-annotations/document-page-annotations.model";
import { DocumentPageNlpMatches } from "./document-page-nlp/document-page-nlp-matches.model";
import { NlpType } from "./document-page-nlp/nlp-type.enum";
import { NlpWord } from "./document-page-nlp/nlp-word.model";
import { DocumentPageOcrMatches } from "./document-page-ocr-matches.model";
import { DocumentViewerSessionService } from "./document-viewer-session.service";
import { DocumentViewerState } from "./document-viewer-state.model";
import { OcrWord } from "./ocr-word.model";

@Component({
  selector: "app-document-page-viewer",
  templateUrl: "./document-page-viewer.component.html",
  styleUrls: ["./document-page-viewer.component.scss"],
  providers: [RetrievalDocumentServiceService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentPageViewerComponent extends LoaderHelper implements OnInit, OnChanges, DoCheck, OnDestroy, AfterViewInit {
  constructor(
    private annotationService: AnnotationService,
    private localService: LocalService,
    private medicalRecordUploadService: MedicalRecordUploadService,
    private pageService: DocumentPageService,
    private platformService: PlatformService,
    private readonly automapper: AutomapperService,
    private readonly cd: ChangeDetectorRef,
    private readonly chaseDetailStateService: ChaseDetailStateService,
    private readonly documentViewerSessionService: DocumentViewerSessionService,
    private readonly formService: FormService,
    private readonly loaderService: LoaderService,
    private readonly messagingService: MessagingService,
    private readonly retrievalDocumentService: RetrievalDocumentServiceService,
    private readonly riskService: RiskService,
    protected readonly zone: NgZone,
    private sessionService: SessionService,
    public menuService: MenuService,
    private router: Router,
    private readonly authService: AuthService,
    private renderer: Renderer2
  ) {
    super(zone);
  }

  /* #region State Properties */
  get cleanSourceDocumentTypeId(): number {
    return Number(this.sourceDocumentTypeId);
  }

  get cleanDocumentId(): number {
    return Number(DocumentPageViewerComponent.documentId.getValue());
  }

  get cleanDocumentTypeId(): number {
    return this.state.documentTypeId;
  }

  get totalPages(): number {
    return this.state?.totalPages;
  }

  set totalPages(value: number) {
    this.state.totalPages = value;
  }

  get hasPages(): boolean {
    return ArrayHelper.isAvailable(this.state.pages);
  }

  get pages$(): BehaviorSubject<(DocumentPage | null)[]> {
    return this.state.pages$;
  }

  get pages(): DocumentPage[] {
    return this.state.pages;
  }

  get currentPageNumber(): number {
    return this.state.currentPageNumber;
  }

  set currentPageNumber(value: number) {
    this.state.currentPageNumber = value;
    this.updateViewedCurrentPage();
    this.zoomLevel = this.minZoomLevel;
    this.currentPageNumberForm.get(this.currentPageNumberInput.key).setValue(this.currentPageNumber);
    this.cd.markForCheck();
  }

  get hasCurrentPage(): boolean {
    return this.state.hasCurrentPage;
  }

  get currentPage(): DocumentPage {
    return this.state.currentPage;
  }

  set currentPage(value: DocumentPage) {
    if (value != null) {
      value.viewed = true;
      this.documentViewerSessionService.add(
        this.cleanSourceDocumentTypeId,
        this.cleanDocumentId,
        value
      );
    }
  }

  get highlightBtnClass(): string {
    return this.annotationToggle ? "active--annotation" : "";
  }

  get negativeExclusionBtnClass(): string {
    return this.negativeExclusionBtnActive ? "active--negative-exclusion" : "";
  }

  get ocrMappingBtnClass(): string {
    return this.ocrMappingMatchesBtnActive ? "active--ocr-mapping" : "";
  }

  get memberBtnClass(): string {
    return this.memberBtnActive ? "active--member" : "";
  }

  get disableChartFunctions(): boolean {
    return (this.annotationToggle && !this.isReadOnly && this.annotationsEnabled) ||
      this.isNlpSearchEnabled ||
      this.isNlpMemberEnabled ||
      this.ocrMappingMatchesBtnActive;
  }

  get showExpandedView(): boolean {
    return this.isExpandedViewFromToggle || this.isExpandedViewFromUrl || this.isSplitScreenMainTab;
  }

  get annotationsEnabled(): boolean {
    return true;
  }

  get isCurrentPageLoaded(): boolean {
    return this.currentPage && NumberHelper.isAvailable(this.currentPage.documentPageId);
  }

  get hasIsMaximized(): boolean {
    return this.isMaximized != null;
  }

  get maximizeButtonIconName(): string {
    return this.isMaximized ? "compress-arrows-alt" : "expand-arrows-alt";
  }

  get addressId(): number {
    return this.chaseDetailState.masterDocumentSourceId;
  }

  get projectId(): number {
    return this.chaseDetailState?.project?.projectId;
  }

  get zoomPercentage(): number {
    const minZoomPercentage = this.localService.get(this.localStorageIsWindowOpen, null) === "1" ? 60 : 100;
    const zoomPercentageDelta = 20;
    return minZoomPercentage + (this.zoomLevel * zoomPercentageDelta);
  }

  get zoomStyle(): any {
    const imageSize = document.querySelector("#documentsImg") as HTMLElement;
    this.margin = Number(this.zoomPercentage) / this.ratio === 1 ? 0
      : this.isWindowOpen ? (this.canvasWidth - imageSize.offsetWidth) / 2 : Number(this.zoomPercentage) / this.ratio;
    this.margin = this.margin < 0 ? 0 : this.margin;
    const newMargin = (this.margin / this.canvasWidth) * 100;
    const width = this.isWindowOpen ? "auto" : `${this.zoomPercentage}%`;
    this.itemSizeHeight = this.itemSize * ((this.zoomPercentage !== 100 ? this.zoomPercentage + 40 : (this.isWindowOpen ? this.zoomPercentage + 40 : this.zoomPercentage)) / 100);
    return {
      width,
      height: `${this.itemSizeHeight * 0.999}px`,
      top: "0",
      left: "0",
      margin: `0 ${this.margin < 5 ? 0 : newMargin}% 0`,
    };
  }

  get minBuffer(): number {
    return this.isWindowOpen ? this.itemSizeHeight * this.itemSizeHeight : this.itemSizeHeight * 2;
  }

  get maxBuffer(): number {
    return this.isWindowOpen ? this.itemSizeHeight * this.itemSizeHeight : this.itemSizeHeight * 3;
  }

  get hasOcrSearchResults(): boolean {
    return this.documentOCRMatch && this.documentOCRMatch.hasPages && this.hasPages;
  }

  get ocrCurrentPage(): DocumentPageOcrMatches {
    return this.hasOcrSearchResults ? this.documentOCRMatch.currentPage : null;
  }

  get ocrDocumentResultsSummary(): string {
    return this.hasOcrSearchResults
      ? `${this.documentOCRMatch.currentOcrPageNumberIndex + 1} / ${this.documentOCRMatch.pages.length}`
      : "";
  }

  get ocrDocumentPageWordsSummary(): string {
    return this.hasOcrSearchResults
      ? `${this.ocrCurrentPage.currentIndex + 1} / ${this.ocrCurrentPage.ocrMatches.length}`
      : "";
  }

  get isNlpEnabled(): boolean {
    return (this.chaseDetailState.isDataEntryWorkflow) &&
      this.chaseDetailState.isMeasureCodesToEnableNlp &&
      this.isEnableMrrBot;
  }

  get hasNlpData(): boolean {
    return this.documentNlpMatch && this.hasPages;
  }

  get hasNlpMatches(): boolean {
    return this.hasNlpData && ArrayHelper.isAvailable(this.documentNlpMatch.nlpMatches);
  }

  get hasNlpMemberMatches(): boolean {
    return this.hasNlpMemberData && ArrayHelper.isAvailable(this.memberNlpMatch.nlpMatches);
  }

  get hasNlpOcrMappingMatches(): boolean {
    return this.hasNlpOcrMappingData && ArrayHelper.isAvailable(this.ocrMappingNlpMatch.nlpMatches);
  }

  get nlpDescription(): string {
    const numberOfNlpWords = this.hasNlpMatches ? this.documentNlpMatch.nlpMatches.length : 0;
    if (this.isDiagnosisNLP) {
      return `${numberOfNlpWords} Evidence Terms Found`;
    } else if (this.isNegativeExclusionNLP) {
      return `${numberOfNlpWords} Negative Exclusion`;
    }
  }

  get numberOfNlpWords(): number {
    return this.hasNlpMatches ? this.documentNlpMatch.nlpMatches.length : 0;
  }

  get nlpWordSummary(): string {
    return this.hasNlpMatches ?
      `Page ${this.documentNlpMatch.nlpMatches[this.documentNlpMatch.currentIndex].pageNumber} "${this.documentNlpMatch.nlpMatches[this.documentNlpMatch.currentIndex].text}"` :
      "";
  }

  get nlpDosOfCurrentDiagnosis(): string {
    return this.hasNlpMatches ?
      `${this.documentNlpMatch.nlpMatches[this.documentNlpMatch.currentIndex].diagnosisDOS}` : "No DOS Found";
  }

  get nlpDosPageNumberOfCurrentDiagnosis(): number {
    return this.hasNlpMatches ?
      this.documentNlpMatch.nlpMatches[this.documentNlpMatch.currentIndex].dosPageNumber : null;
  }

  get getNlpDosClass(): string {
    return NumberHelper.isAvailable(this.nlpDosPageNumberOfCurrentDiagnosis) ? "nlp-results__DOS--date" : "";
  }

  get isEnableMrrBot(): boolean {
    return this.enableMrrBot === "1";
  }

  get isDiagnosisNLP(): boolean {
    return this.selectedNlpType === NlpType.DIAGNOSIS;
  }

  get isNegativeExclusionNLP(): boolean {
    return this.selectedNlpType === NlpType.NEGATIVE_EXCLUSION;
  }

  get displayOcrBlocks(): boolean {
    return this.chaseDetailState.displayOcrBlocks;
  }

  /* #endregion Risk NLP */

  /* #region Ocr Mapping NLP */
  get hasNlpOcrMappingData(): boolean {
    return this.ocrMappingNlpMatch && this.hasPages;
  }

  /* #endregion Member NLP */

  /* #region Member NLP */
  get hasNlpMemberData(): boolean {
    return this.memberNlpMatch && this.hasPages;
  }

  /* #endregion Member NLP */

  /* #region Thumbnail */

  get isDataEntryWorkflow(): boolean {
    return this.chaseDetailState.isDataEntryWorkflow;
  }

  /* #endregion Thumbnail */

  /* #region DOS NLP */

  get hasNlpDosData(): boolean {
    return this.dosNlpMatch && this.hasPages;
  }

  get enableOptimizeImageButton(): boolean {
    return !this.isHighResolutionImage && this.isOptimizedImagesAvailable && !this.sessionOptimizeImageSaved;
  }

  get sessionOptimizeImageName(): string {
    return `${this.SHOWOPTIMIZEIMAGE}_${this.chaseId}`;
  }

  get sessionOptimizeImageSaved(): boolean {
    return this.sessionService.get(this.sessionOptimizeImageName, false);
  }

  get displayHighlights(): boolean {
    return !this.isReadOnly || this.chaseDetailState.isRiskProject || this.chaseDetailState.isIVAProject || (!this.isReadOnly && this.chaseDetailState.isMemberCentric);
  }

  get isReloadPage(): boolean {
    return this.localService.get(LOCALSTORAGE_PAGERELOAD.key, "0") === "0";
  }

  // Using subject so it is easy to reload component from parent.
  static documentId: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private static memberCentricPageNumberObserver: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  memberCentricSelectedPage = 0;
  /* #endregion OCR */

  /* #region Risk NLP */

  margin = 0;

  private localStorageIsWindowOpen = "isWindowOpen";
  private localStoragePageNumber = "pageNumber";
  ratio: number = this.localService.get(this.localStorageIsWindowOpen, null) === "1" ? 7 : this.zoomPercentage;
  private isWindowOpen = this.localService.get(this.localStorageIsWindowOpen, null) === "1";
  private currentPageNumber$ = new BehaviorSubject<number>(0);
  private sink = new SubSink();
  private currentIndex = 0;

  readonly MAX_ICD_DESCRIPTION_LENGTH = 45;
  private readonly THUMBNAILKEY = "THUMBNAIL_FILTER";
  private readonly SHOWOPTIMIZEIMAGE = "SHOW_OPTIMIZE_IMAGE";

  @Input() chaseId: number;
  @Input() sourceDocumentTypeId: number;
  @Input() maxPages = 3;
  @Input() isReadOnly = false;
  @Input() isOcrDataAvailable = false;
  @Input() status: string;
  @Input() isDocApproval = false;

  @Input() isMaximized = false;
  @Input() disableMaximize = false;
  @Input() pageTypeId: number;
  @Input() isArchived = false;
  @Input() isExpandedViewFromUrl = false;
  @Input() showChartThumbnails = false;
  @Input() jumpPageNumber = 1;
  @Input() isSplitScreenEnabled = false;
  @Input() isPopOutBtnEnabled = true;
  @Input() isSplitScreenMainTab = false;
  @Input() workingDocumentId = 0;

  @Output() isMaximizedChange = new EventEmitter<boolean>(true);

  @ViewChild(CdkVirtualScrollViewport, {static: true}) private viewport: CdkVirtualScrollViewport;
  @ViewChildren(CdkDrag) private images: QueryList<CdkDrag>;
  @ViewChild("ocrSearchContainer") ocrSearchContainer: ElementRef;
  @ViewChild("ocrSearchResults") ocrSearchResults: ElementRef;
  @ViewChild("nlpSearchContainer") nlpSearchContainer: ElementRef;
  @ViewChild("nlpSearchInput") nlpSearchInput: ElementRef;
  @ViewChild("nlpSearchResults") nlpSearchResults: ElementRef;
  @ViewChildren(CdkVirtualScrollViewport) viewports: QueryList<CdkVirtualScrollViewport>;
  @Input() tabSelected;
  private state: DocumentViewerState;
  private cacheTracker: CacheTracker;

  itemSize: number = this.localService.get(this.localStorageIsWindowOpen, null) === "1" ? window.innerHeight : 1560;
  currentPageNumberForm: FormGroup;
  currentPageNumberInput = new Textbox({key: "CurrentPageNumber"});
  searchPhraseForm: FormGroup;
  searchPhraseInput = new Textbox({key: "SearchPhrase"});
  chaseDetailState: ChaseDetailState;
  isMemberProjectRetrievalType = false;
  zoomLevel = 0;
  currentZoomLevel = 1;
  private minZoomLevel = 0;
  private maxZoomLevel = 8;
  isOcrFocus = false;
  isOcrSearchInProgress = false;
  documentOCRMatch: DocumentOcrMatch;
  showOcrSearchIcon = true;
  ocrSearchValue: string;
  isOcrSearchEnabled = true;
  annotationToggle = true;
  documentPageAnnotations: DocumentPageAnnotations;
  userDocumentPages: ChaseDocumentPages[] = [];
  chaseDocumentPages: ChaseDocumentPages[] = [];
  encounterDocumentPages: ChaseDocumentPages[] = [];
  annotations: Annotations[];
  canvasWidth: number;
  canvasHeight = this.itemSize - 2;
  nlpPhraseForm: FormGroup;
  nlpPhraseInput = new Textbox();
  documentNlpMatch: DocumentPageNlpMatches;
  diagnoses: Diagnosis[];
  selectedDiagnosis: Diagnosis;
  projectConfiguration: ProjectConfiguration;
  isNlpSearchInProgress = false;
  icdDescription: string;
  enableMrrBot = "";
  isNlpSearchEnabled = false;
  nlpPhrase: AbstractControl;
  selectedNlpType: NlpType;
  negativeExclusionBtnActive = false;
  memberBtnActive = false;
  ocrMappingMatchesBtnActive = false;

  ocrMappingNlpMatch: DocumentPageNlpMatches;
  isNlpOcrMappingEnabled = false;

  memberNlpMatch: DocumentPageNlpMatches;
  isNlpMemberEnabled = false;
  isDuplicateWindowOpen = false;
  @Input() duplicateTab = false;
  isMemberValid = false;
  clearCache = false;
  cdnBucketFolders = [];
  isExpandedViewFromToggle = false;
  isBucketFolderChecked = false;
  dosNlpMatch: DocumentPageNlpMatches;
  isHighResolutionImage = false;
  isOptimizedImagesAvailable = false;
  getAnnotations = true;
  itemSizeHeight = 1560;

  previousPageClicked = false;
  nextPageClicked = false;
  pageJumpClicked = false;
  deletedHighlightId: string = null;
  selectedWidget = "";
  previousChase: number = null;
  isNextChase = false;

  showChaseOpenWarning = false;
  openChaseAndUserInfo = "openChaseAndUserInfo";
  globalLocalStoreChaseId = null;
  globalLocalStoreUserId = null;

  @Output() hasNoDocumentPages = new EventEmitter<boolean>(false);
  @Output() isOpenSplitScreenUrl = new EventEmitter<boolean>(true);


  @HostListener("window:resize")
  windowResize() {
    if (this.annotationsEnabled) {
      if (this.canvasWidth !== this.viewport?.elementRef.nativeElement.clientWidth) {
        this.canvasWidth = this.viewport?.elementRef.nativeElement.clientWidth;
      }
    }
  }


  ngOnInit() {
    this.state = new DocumentViewerState();
    this.cacheTracker = new CacheTracker();
    if (NumberHelper.isGreaterThan(this.workingDocumentId, 0, false)) {
      DocumentPageViewerComponent.documentId.next(this.workingDocumentId);
    } else {
      DocumentPageViewerComponent.documentId.next(0);
    }
    if (this.isReloadPage && !this.isWindowOpen) {
      this.localService.delete(LOCALSTORAGE_PAGEAFTERSCREENSYNC.key);
      this.localService.delete(LOCALSTORAGE_PAGEBEFORESCREENSYNC.key);
    }
    this.platformService.isEnableWidgetSection.next(true);
    this.annotationService.updateChaseId(this.chaseId);
    this.currentPageNumberInput = new Textbox({
      key: "CurrentPageNumber",
      type: TextboxType.NUMBER,
      value: 1,
      disabled: true,
    });
    this.currentPageNumberForm = this.formService.createFormGroup([this.currentPageNumberInput]);

    this.searchPhraseInput = new Textbox({
      key: "SearchPhrase",
      placeholder: this.isOcrDataAvailable ? "Search" : "Search Not Available Yet",
      disabled: !this.isOcrDataAvailable,
    });
    this.searchPhraseForm = this.formService.createFormGroup([this.searchPhraseInput]);

    this.nlpPhraseInput = new Textbox({
      key: "nlpPhraseInput",
      title: "nlpphraseinput",
      disabled: true,
    });
    this.nlpPhraseForm = this.formService.createFormGroup([this.nlpPhraseInput]);
    this.nlpPhrase = this.nlpPhraseForm.get(this.nlpPhraseInput.getMasterKey());

    this.sink.add(
      this.pageService.outsidePage$.subscribe(page => {
        this.gotoPage(page);
        this.cd.markForCheck();
      }),
      combineLatest([
        this.riskService.data,
        this.chaseDetailStateService.state,
      ]).subscribe(([riskState, chaseDetailState]: any[]) => {
        this.chaseDetailState = chaseDetailState;
        this.chaseDetailStateService.chaseBeyondMrr(this.chaseDetailState.isBeyondMRR);
        if (ArrayHelper.isAvailable(this.chaseDetailState.memberCentricChases)) {
          this.chaseDetailStateService.setMemberChases(this.chaseDetailState.memberCentricChases);
        }
        const diagnosisEntities = riskState.hasSelectedEncounterIndex ? riskState.selectedEncounter.diagnoses : [];
        this.diagnoses = this.automapper.mapMany("RiskData", "Diagnosis", diagnosisEntities);
        this.selectedDiagnosis = riskState.hasSelectedDiagnosisIndex ? this.diagnoses[riskState.selectedDiagnosisIndex] : {} as any;
        this.isMemberValid = riskState.isEnabled;

        this.projectConfiguration = this.chaseDetailState.projectConfiguration;
        this.updateProjectConfiguration();

        if (this.isNlpEnabled && !ObjectHelper.isEmpty(this.selectedDiagnosis)) {
          if (this.selectedDiagnosis.isManuallyAddedSource) {
            this.deactivateNlp();
          } else {
            this.getNlpData(NlpType.DIAGNOSIS, this.selectedDiagnosis);
            this.getDosData(this.selectedDiagnosis);
          }
        } else {
          this.deactivateNlp();
        }

        if (this.router.url.includes("auditquery")) {
          this.annotationToggle = true;
        }

        this.updateGetAnnotationStatus();
        if (this.getAnnotations && this.chaseDetailState?.chaseId) {
          this.getDocumentTotalCaptureAnnotations();
          this.getDocumentAnnotations();
          this.getCatalyticAnnotations();
          this.getAnnotations = !this.annotationsEnabled;
        }
        this.isSplitScreenEnabled = this.chaseDetailState.projectTypeId === 1;
        this.cd.markForCheck();
      }),
      DocumentPageViewerComponent.documentId
        .pipe(debounceTime(500) /* do this to handle double subscribe firing when value changed */)
        .subscribe(documentId => {
          this.initialLoad();
          if (documentId > 0) {
            this.verifyOptimizedImagesAreAvailable();
          }
        }),
      DocumentPageViewerComponent.memberCentricPageNumberObserver.subscribe(pageNumber => {
        this.memberCentricSelectedPage = NumberHelper.isGreaterThan(pageNumber, 0) ? pageNumber : 1;
      }),
      this.currentPageNumber$
        .pipe(debounceTimeAfterFirst(50))
        .subscribe(pageNumber => {
          this.currentPageNumber = pageNumber;
          if (this.images) {
            setTimeout(() => this.images.forEach(a => a.reset()));
          }

          if (this.state.getPagesToLoad().includes(pageNumber)) {
            this.tryLoadingJump();
          } else {
            this.tryLoading();
          }
        }),
      this.searchPhraseForm.get(this.searchPhraseInput.key).valueChanges
        .subscribe(value => {
          if (this.ocrSearchValue !== value) {
            this.clearOcrSearch();
          }
          this.ocrSearchValue = value;
        }),
      this.documentViewerSessionService.catalyticNumeratorState
        .subscribe(numerator => {
          if (+numerator.medicalRecordPageNumber) {
            if (!this.annotationToggle) {
              this.toggleAnnotation();
            }
            this.gotoPage(+numerator.medicalRecordPageNumber);
          }
        }),
      this.documentViewerSessionService.dataEntryPageNumber
        .subscribe(pageNumber => {
          if (NumberHelper.isAvailable(pageNumber)) {
            this.currentPageNumber = pageNumber;
            if (this.isDuplicateWindowOpen && !this.duplicateTab) {
              this.localService.put(LOCALSTORAGE_PAGEAFTERSCREENSYNC.key, pageNumber);
            }
            if (this.state.pages[pageNumber]?.pageNumber) {
              this.gotoPage(pageNumber);
            } else {
              if (!this.previousPageClicked) {
                this.tryLoadingJump();
              }
              setTimeout(() => {
                this.viewport.scrollToIndex(pageNumber - 1);
              },         1000);
            }
            this.previousPageClicked = false;
          }
        }),
      this.documentViewerSessionService.selectedDiagnosisIcdDescription
        .subscribe(icdDescription => {
          if (StringHelper.isAvailable(icdDescription)) {
            if (icdDescription.length > this.MAX_ICD_DESCRIPTION_LENGTH) {
              const displayIcdDescription = `${icdDescription.slice(0, this.MAX_ICD_DESCRIPTION_LENGTH)}...`;
              this.nlpPhrase.setValue(displayIcdDescription);
            } else {
              this.nlpPhrase.setValue(icdDescription);
            }
            this.nlpPhraseInput.title = icdDescription;
            this.cd.markForCheck();
          }
        }),
      this.documentViewerSessionService.isMemberValidated
        .subscribe(isMemberValidated => {
          if (isMemberValidated && this.memberBtnActive && this.isNlpEnabled) {
            this.getMemberData();
          }
        }),
      this.menuService.selectedWidget$
        .subscribe(widget => {
          this.selectedWidget = widget;
        }),
      this.viewport?.scrolledIndexChange.pipe(
        skip(1),
        distinctUntilChanged(),
        filter(index => NumberHelper.isAvailable(index)),
        debounceTime(500)
      ).subscribe(index => this.updatePageAndIndex(index))
    );

    this.chaseId = Number(this.chaseId);
    if (this.isArchived) {
      this.sourceDocumentTypeId = 5;
      DocumentPageViewerComponent.documentId.next(this.chaseId);
    } else if (NumberHelper.isGreaterThan(this.chaseId, 0) && this.isDocApproval) {
      this.pageTypeId = PageTypeName.Approval;
      this.sourceDocumentTypeId = 4;
      DocumentPageViewerComponent.documentId.next(this.chaseId);
    } else if (NumberHelper.isGreaterThan(this.chaseId, 0)) {
      this.sourceDocumentTypeId = 2;
      DocumentPageViewerComponent.documentId.next(this.chaseId);
    }
    this.jumpPageNumber = Number(this.jumpPageNumber);


    if (this.isRoleAbstractorOrOverreader(this.authService?.user.directoryRoleIds)) {
      this.handlePopupEvent();
    }

  }

  private isRoleAbstractorOrOverreader(arr: number[]): boolean {
    return (arr.length >= 1 && (arr.includes(DirectoryUserRole.Abstractor) || arr.includes(DirectoryUserRole.Overreader)));
  }

  private handlePopupEvent(): void {
    this.storageHandler();
    window.addEventListener("beforeunload", () => {
      this.removeOpenChaseInfo();
    });
  }


  private updateGetAnnotationStatus() {
    this.isNextChase = this.previousChase !== this.chaseDetailState.chaseId;
    this.previousChase = this.chaseDetailState.chaseId;
    this.getAnnotations = this.isNextChase;
  }


  ngOnChanges(changes: SimpleChanges) {
    this.isDuplicateWindowOpen = this.localService.get(this.localStorageIsWindowOpen, null) === "1";
    const pageViewer = document.getElementById("pageViewer");
    const commandBar = document.getElementById("commandBar");
    if (pageViewer && this.isDuplicateWindowOpen) {
      pageViewer.style.display = "none";
    }
    if (commandBar && this.isDuplicateWindowOpen) {
      commandBar.style.display = "none";
    }
    if (pageViewer && this.duplicateTab === true) {
      pageViewer.style.display = "block";
    }
    if (commandBar && this.duplicateTab === true) {
      commandBar.style.display = "block";
    }
    if (changes.isOcrDataAvailable) {
      if (changes.isOcrDataAvailable.isFirstChange()) {
        return;
      }

      if (changes.isOcrDataAvailable.currentValue !== changes.isOcrDataAvailable.previousValue) {
        if (this.isOcrDataAvailable) {
          this.searchPhraseForm.get(this.searchPhraseInput.getMasterKey()).enable();
          this.searchPhraseInput.placeholder = "Search";
        }
      }
    }

    if (changes.chaseId) {
      if (changes.chaseId.isFirstChange()) {
        return;
      }

      if (changes.chaseId.currentValue !== changes.chaseId.previousValue) {
        if (!this.chaseDetailState.isMemberCentric) {
          this.ngOnInit();
        } else {
          this.getAnnotations = true;
        }
      }
    }
    if (this.jumpPageNumber) {
      this.gotoPage(this.jumpPageNumber);
    }
  }

  updateTotalPages(deletedPages: number): void {
    if (NumberHelper.isGreaterThan(this.state.totalPages, 0)) {
      this.state.totalPages = (this.state.totalPages - deletedPages);
    }
  }

  ngDoCheck() {
    if (this.annotationsEnabled) {
      if (this.canvasWidth !== this.viewport?.elementRef.nativeElement.clientWidth) {
        this.canvasWidth = this.viewport?.elementRef.nativeElement.clientWidth;
      }
    }
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
    this.cleanCacheAfterNGDestory();
    DocumentPageViewerComponent.documentId.next(null);
    this.platformService.isEnableWidgetSection.next(false);
    this.removeOpenChaseInfo();
  }

  private removeOpenChaseInfo(): void {
    const localChaseUserInfo = this.localService.get(this.openChaseAndUserInfo, null);
    if (localChaseUserInfo) {
      const parsedLocalChaseUserInfo = JSON.parse(localChaseUserInfo);
      const localStoreChaseId = parsedLocalChaseUserInfo.openChaseId;
      if (localStoreChaseId === this.chaseId) {
        this.localService.delete(this.openChaseAndUserInfo);
      }
    }
  }

  get imageWidthSize(): number {
    const imageSize = document.querySelector("#documentsImg") as HTMLElement;
    return imageSize.offsetWidth;
  }

  hasPageAndSource(page: DocumentPage): boolean {
    return page && StringHelper.isAvailable(page.image);
  }

  hasNewPage(page: DocumentPage): boolean {
    if (page != null) {
      return page.isNewPage === 1;
    }
  }

  updateIndex(index): void {
    let pageNumber = index + 1;
    this.chaseDetailStateService.setPageChangeValue(true);
    if (this.previousPageClicked) {
      if (this.isDuplicateWindowOpen && this.duplicateTab) {
        // case: split screen back arrow issue
        pageNumber = pageNumber - 1;
        this.previousPageClicked = false;
      } else {
        // case: normal view back issue
        this.documentViewerSessionService.dataEntryPageNumber.next(pageNumber);
      }
    } else {
      if (this.isDuplicateWindowOpen && this.duplicateTab && this.nextPageClicked) {
        if (this.currentPageNumber > pageNumber) {
          // case: split screen forward issue
          pageNumber = pageNumber + 1;
          this.nextPageClicked = false;
        }
      }
      if (this.currentPageNumber > pageNumber && !this.duplicateTab) {
        // case: normal view scroll back issue
        this.gotoPage(pageNumber + 1);
        this.currentPageNumber$.next(pageNumber);
      } else {
        if (this.currentPageNumber > pageNumber && this.duplicateTab && this.pageJumpClicked) {
          // split screen page jump case when 1 page less shows
          pageNumber = pageNumber + 1;
          this.pageJumpClicked = false;
        }
        if (this.currentPageNumber > pageNumber && this.duplicateTab && !this.pageJumpClicked) {
          // scroll back issue split
          this.gotoPage(pageNumber + 1);
          this.currentPageNumber$.next(pageNumber);
        } else {
          // case: forward scroll
          this.currentPageNumber$.next(pageNumber);
          this.currentPageNumberForm.get(this.currentPageNumberInput.key)?.setValue(pageNumber);
        }
      }
    }
    this.handlePageNumberState(pageNumber);
  }

  onGotoPage(event: any): void {
    const pageNumber = event.target.valueAsNumber;
    this.gotoPage(pageNumber);
  }

  nextPage(): void {
    this.nextPageClicked = true;
    this.gotoPage(this.currentPageNumber + 1);
  }

  prevPage(): void {
    this.previousPageClicked = true;
    this.chaseDetailStateService.setPageChangeValue(true);
    const pageNumber = this.currentPageNumber - 1;
    this.gotoPage(pageNumber);
  }

  gotoPage(pageNumber: number): void {
    this.updatePageService(pageNumber);
    this.currentPageNumber = pageNumber;
    this.handlePageNumberState(pageNumber);
    this.viewport?.scrollToIndex(this.currentPageNumber - 1);
  }

  toggleIsMaximized(): void {
    this.isMaximized = !this.isMaximized;
    this.isMaximizedChange.emit(this.isMaximized);
  }

  updateViewportTransform(): void {
    this.viewports.forEach(viewport => {
      const zoomTransform = `scale(${this.currentZoomLevel})`; // Use this.zoomLevel instead of this.zoomLevels
      const pageElements = viewport.elementRef.nativeElement.querySelectorAll(".document__page");
      pageElements.forEach((pageElement: HTMLElement) => {
        pageElement.style.transform = zoomTransform; // Apply the same zoom level to each page within the viewport
      });
    });
  }

  zoomIn(): void {
    if (this.currentZoomLevel < this.maxZoomLevel) {
      if (this.currentZoomLevel === 0) {
        this.currentZoomLevel = 1;
      }
      if (this.isWindowOpen) {
        this.ratio += 7;
      }
      this.currentZoomLevel += 0.1;
      this.updateViewportTransform();
    }
  }

  zoomOut(): void {
    if (this.currentZoomLevel > this.minZoomLevel) {
      this.currentZoomLevel -= 0.1;
      if (this.isWindowOpen) {
        this.ratio -= 7;
      }
      this.updateViewportTransform();
    }
  }

  rotate(direction: string) {
    this.messagingService.showToast("Page Rotation has started", SeverityType.SUCCESS);
    this.saveImage(this.state.getPage(this.currentPageNumber), this.getRotationDegrees(direction));
  }

  private getRotationDegrees(direction: string): number {
    const rotateDirection = direction === "right" ? 1 : -1;
    const rotateDegreesDelta = 90;
    return rotateDegreesDelta * rotateDirection;
  }

  toggleAnnotation(): void {
    this.resetPagePosition();
    this.annotationToggle = !this.annotationToggle;
  }

  resetPagePosition(): void {
    this.images.forEach(image => image.reset());
  }

  removePages(begPage: number, endPage: number): void {
    // TODO: How does this method sync with the backend?
    const documentTypeId = this.cleanDocumentTypeId;
    this.state.removePages(begPage, endPage);

    // TODO: Update session service remove to just update the values.
    this.documentViewerSessionService.remove(
      this.cleanSourceDocumentTypeId,
      this.cleanDocumentId,
      documentTypeId,
      Number(begPage),
      Number(endPage)
    );

    this.cd.markForCheck();
  }

  getPageKeys(begPage: number, endPage: number): number[] {
    const documentPageIds = this.pages
      .filter(page => page && page.pageNumber >= begPage && page.pageNumber <= endPage)
      .map(prop => prop.documentPageId);

    return documentPageIds;
  }

  validatePagesViewed(begPage: number, endPage: number): boolean {
    if (!this.hasPages) {
      return false;
    }

    // TODO: We should make sure to pass type number to parameters that expect a number.
    const begPageNumber = Number(begPage);
    const endPageNumber = Number(endPage);
    return this.documentViewerSessionService.validPages(
      this.cleanSourceDocumentTypeId,
      this.cleanDocumentId,
      this.cleanDocumentTypeId,
      Number(begPageNumber),
      Number(endPageNumber)
    );
  }

  validateAllPagesViewed() {
    if (!this.hasPages) {
      return false;
    }

    return this.documentViewerSessionService.valid(
      this.cleanSourceDocumentTypeId,
      this.cleanDocumentId,
      this.cleanDocumentTypeId,
      Number(this.totalPages)
    );
  }

  getDocumentTotalCaptureAnnotations(): void {
    this.sink.add(
      this.retrievalDocumentService.getDocumentTotalCaptureAnnotations(this.chaseId)
        .subscribe(result => {
          if (result) {
            this.chaseDocumentPages = result.chaseDocumentPages;
            this.annotationToggle = this.displayHighlights;
            this.resetPagePosition();
            this.cd.markForCheck();
          } else {
            this.chaseDocumentPages = [];
          }
        })
    );
  }

  getDocumentAnnotations(): void {
    this.sink.add(
      combineLatest([
        this.retrievalDocumentService.getDocumentNlpHighlights(this.chaseId),
        this.retrievalDocumentService.getDocumentUserHighlights(this.chaseId),
      ]).subscribe(([nlpHighlights, userHighlights]) => {
        this.setDocumentHighlights(nlpHighlights, AnnotationSource.Nlp);
        this.setDocumentHighlights(userHighlights, AnnotationSource.User);
      })
    );
  }

  private setAnnotationSource(documentHighlights: DocumentPageAnnotations, annotationSource: AnnotationSource): void {
    if (documentHighlights) {
      documentHighlights.chaseDocumentPages.forEach(chaseDocumentPage => chaseDocumentPage.annotationSource = annotationSource);
      if (annotationSource === AnnotationSource.Nlp) {
        documentHighlights.encounterDocumentPages.forEach(encounterDocumentPage => encounterDocumentPage.annotationSource = AnnotationSource.Nlp);
      }
    }
  }

  private setDocumentHighlights(documentHighlights: DocumentPageAnnotations, annotationSource: AnnotationSource): void {
    this.setAnnotationSource(documentHighlights, annotationSource);

    if (documentHighlights) {
      if (annotationSource === AnnotationSource.Nlp) {
        this.chaseDocumentPages = ArrayHelper.clean(documentHighlights.chaseDocumentPages);
        this.encounterDocumentPages = ArrayHelper.clean(documentHighlights.encounterDocumentPages);
      } else {
        this.userDocumentPages = ArrayHelper.clean(documentHighlights.chaseDocumentPages);
      }
      this.cd.markForCheck();
    }
  }

  getCatalyticAnnotations(): void {
    this.sink.add(
      this.documentViewerSessionService.catalyticDeletedNumerators
        .subscribe(deletedNumerators => {
          if (deletedNumerators != null) {
            this.saveNlpAnnotations(deletedNumerators);
            this.cd.markForCheck();
          }
        }),
      this.documentViewerSessionService.catalyticSavedNumeratorSet
        .subscribe(savedNumerators => {
          if (savedNumerators != null) {
            this.saveNlpAnnotationSet(savedNumerators);
            this.cd.markForCheck();
          }
        }),
      this.documentViewerSessionService.catalyticDeletedNumeratorSet
        .subscribe(deletedNumerators => {
          if (deletedNumerators != null) {
            this.deleteNlpAnnotationSet(deletedNumerators);
            this.cd.markForCheck();
          }
        })
    );
  }

  getPageAnnotationsForChase(documentPageId: number): Annotations[] {
    let chaseDocumentPages: ChaseDocumentPages;
    const filteredAnnots: Annotations[] = [];
    if (ArrayHelper.isAvailable(this.encounterDocumentPages)) {
      chaseDocumentPages = this.encounterDocumentPages.find(item => {
        return item.chaseDocumentPageId === documentPageId;
      });
      if (chaseDocumentPages != undefined) {
        chaseDocumentPages.annotations.map(data => {
          if (data.annotationSourceId !== AnnotationSource.Nlp) {
            filteredAnnots.push(data);
          }
        });
      }
    }
    return (filteredAnnots && ArrayHelper.isAvailable(filteredAnnots)) ? filteredAnnots : [];
  }

  getPageAnnotations(documentPageId: number): Annotations[] {
    let chaseDocumentAnnotations: Annotations[] = [];

    if (ArrayHelper.isAvailable(this.chaseDocumentPages)) {
      const chaseDocumentPages = this.chaseDocumentPages
        .find(item => item.chaseDocumentPageId === documentPageId);
      chaseDocumentAnnotations = ArrayHelper.clean(chaseDocumentPages?.annotations);
    }

    if (ArrayHelper.isAvailable(this.userDocumentPages)) {
      const userDocumentPages = this.userDocumentPages
        .find(item => item.chaseDocumentPageId === documentPageId);
      chaseDocumentAnnotations = [...chaseDocumentAnnotations, ...ArrayHelper.clean(userDocumentPages?.annotations)];
    }
    return ArrayHelper.clean(chaseDocumentAnnotations);
  }

  saveDocumentAnnotations(event: ChaseDocumentPages, deletedHighlightId: string): void {
    const addedUserHighlight = new ChaseDocumentPages({
      chaseDocumentPageId: event.chaseDocumentPageId,
      annotations: event.annotations.filter(highlight => highlight.annotationSourceId !== AnnotationSource.Nlp),
    });
    const existingUserHighlights = this.userDocumentPages
      .filter(c => c.chaseDocumentPageId !== event.chaseDocumentPageId);

    const isAuditQuery = this.router.url.includes("auditquery");
    if (isAuditQuery) {
      const newHighlightIndex = addedUserHighlight.annotations.length - 1;
      addedUserHighlight.annotations.map((annotation, index) => {
        if (index === newHighlightIndex) {
          annotation.isFromAuditQuery = true;
        }
      });
    }


    this.documentPageAnnotations = new DocumentPageAnnotations({
      chaseDocumentId: this.chaseId,
      chaseDocumentPages: [...[addedUserHighlight], ...existingUserHighlights],
      encounterDocumentPages: [],
      memberDocumentPages: [],
      diagnosisDocumentPages: [],
    });
    if (StringHelper.isAvailable(deletedHighlightId)) {
      this.retrievalDocumentService.deleteUserHighlight(this.chaseId, this.documentPageAnnotations, deletedHighlightId)
        .subscribe(result => {
          this.setDocumentHighlights(result, AnnotationSource.User);
          if (result) {
            this.annotationService.refreshAnnotations(true);
          }
        });
    } else {
      this.retrievalDocumentService.addDocumentUserHighlight(this.chaseId, this.documentPageAnnotations)
        .subscribe(result => this.setDocumentHighlights(result, AnnotationSource.User));
    }
  }

  saveNlpAnnotations(event: string[]): void {
    if (ArrayHelper.isAvailable(this.chaseDocumentPages) && this.chaseDocumentPages.length > 1) {
      const chaseDocumentPages = this.chaseDocumentPages.map(item => {
        return {
          ...item,
          annotations: item.annotations.filter(x => event.indexOf(x.annotationKey) === -1).map(y => ({
            ...y,
            reviewedByUser: y.annotationSourceId === AnnotationSource.Nlp,
          })),
        };
      });

      this.saveAnnotations(chaseDocumentPages);
    }
  }

  saveNlpAnnotationSet(event: string[]): void {
    if (ArrayHelper.isAvailable(this.chaseDocumentPages) && this.chaseDocumentPages.length > 1) {
      const chaseDocumentPages = this.chaseDocumentPages.map(item => {
        return {
          ...item,
          annotations: item.annotations.map(x => ({...x, reviewedByUser: event.indexOf(x.annotationKey) > -1})),
        };
      });

      this.saveAnnotations(chaseDocumentPages);
    }
  }

  deleteNlpAnnotationSet(event: string[]): void {
    if (ArrayHelper.isAvailable(this.chaseDocumentPages) && this.chaseDocumentPages.length > 1) {
      const chaseDocumentPages = this.chaseDocumentPages.map(item => {
        return {
          ...item,
          annotations: item.annotations.filter(x => event.indexOf(x.annotationKey) === -1),
        };
      });

      this.saveAnnotations(chaseDocumentPages);
    }
  }

  openSplitScreenURL() {
    this.isOpenSplitScreenUrl.emit(true);
    this.platformService.isEnableWidgetSection.next(false);
  }

  private handlePageNumberState(pageNumber: any) {
    // Jump on selected thumbnail page number on second screen
    if (this.localService.get(this.localStorageIsWindowOpen, null) === "1"
      && this.localService.get(this.localStoragePageNumber, null) !== pageNumber) {
      this.jumpPageNumber = Number(pageNumber);
      this.localService.put(LOCALSTORAGE_PAGEAFTERSCREENSYNC.key, pageNumber);
      this.localService.put(this.localStoragePageNumber, pageNumber);
    }
    if (this.chaseDetailState.isMemberCentric) {
      DocumentPageViewerComponent.memberCentricPageNumberObserver.next(pageNumber);
    }
    if (!this.isWindowOpen && NumberHelper.isGreaterThan(pageNumber, 0) && !this.isDuplicateWindowOpen) {
      this.localService.put(LOCALSTORAGE_PAGEBEFORESCREENSYNC.key, pageNumber);
    }
  }

  private saveAnnotations(chaseDocumentPages: ChaseDocumentPages[]) {
    this.chaseDocumentPages = chaseDocumentPages;

    this.documentPageAnnotations = new DocumentPageAnnotations({
      chaseDocumentId: this.chaseId,
      chaseDocumentPages: this.chaseDocumentPages,
    });

    this.retrievalDocumentService.setDocumentAnnotations(this.chaseId, this.documentPageAnnotations).subscribe(annotations => {
      if (annotations) {
        this.chaseDocumentPages = annotations.chaseDocumentPages;
        this.cd.markForCheck();
      }
    });
  }

  /* tslint:disable:no-parameter-reassignment */
  private initialLoad(): void {
    // TODO: SRP. Get document meta data. Then get pages. This method will change to get document data to set the state only.
    const pagesToLoad = new Array(5).fill(0).map((n, index) => n = index + 1);
    pagesToLoad.forEach(pageNumber => this.state.setPage({pageNumber} as any));
    this.loaderService.isLoading.next(true);
    this.loadInitialDocumentPages(
      this.retrievalDocumentService,
      "getPages",
      pagesToLoad,
      this.cleanDocumentId,
      this.cdnBucketFolders,
      this.cleanSourceDocumentTypeId,
      this.sessionOptimizeImageSaved || this.isOptimizedImagesAvailable,
      this.pageTypeId,
      documentPages => {
        const length = (documentPages && ArrayHelper.isAvailable(documentPages.pages)) ? documentPages.pages[0].numberOfPages : 0;
        this.retrievalDocumentService.totalDocumentPages.next(length);
        this.cacheTracker = new CacheTracker();
        this.state = new DocumentViewerState({
          documentId: this.cleanDocumentId,
          pages: Array.from<DocumentPage>({length}),
        });

        const documentPagesLength = documentPages.pages.length;
        const emptyArray: DocumentPage[] = new Array(length - documentPagesLength).fill({});
        const pagestToSet = [...documentPages.pages, ...emptyArray].map((page: DocumentPage, index) => {
          if (!StringHelper.isAvailable(page.image)) {
            return new DocumentPage({pageNumber: index + 1});
          }
          return page;
        });
        this.setPages(pagestToSet);

        if (!ArrayHelper.isAvailable(this.state.pages)) {
          this.hasNoDocumentPages.emit(true);
        } else {
          this.hasNoDocumentPages.emit(false);
        }

        // TODO: Delete this when page keys are saved in session
        // Remove viewed pages in session storage
        if (NumberHelper.isGreaterThan(this.cleanSourceDocumentTypeId, 0) && NumberHelper.isGreaterThan(this.cleanDocumentId, 0)) {
          this.documentViewerSessionService.delete(
            this.cleanSourceDocumentTypeId,
            this.cleanDocumentId,
            this.cleanSourceDocumentTypeId);
        }
        // Set Pages
        let screenSyncPageNumber = this.localService.get(LOCALSTORAGE_PAGEAFTERSCREENSYNC.key, null);
        const pageBeforePageSync = this.localService.get(LOCALSTORAGE_PAGEBEFORESCREENSYNC.key, null);
        if (screenSyncPageNumber !== null && !this.isDuplicateWindowOpen && !this.isReloadPage) {
          this.localService.delete(LOCALSTORAGE_PAGEAFTERSCREENSYNC.key);
        } else if (pageBeforePageSync !== null && this.duplicateTab && this.isReloadPage && NumberHelper.isGreaterThan(pageBeforePageSync, 0)) {
          screenSyncPageNumber = pageBeforePageSync;
          this.localService.put(LOCALSTORAGE_PAGEBEFORESCREENSYNC.key, -1);
        }
        this.currentPageNumber = NumberHelper.isGreaterThan(screenSyncPageNumber, 1) ? screenSyncPageNumber : 1;
        this.updatePageService(this.currentPageNumber);
        if (NumberHelper.isGreaterThan(screenSyncPageNumber, 1)) {
          this.documentViewerSessionService.updateDataEntryPageNumber(this.currentPageNumber);
        } else {
          this.viewport?.scrollToIndex(0);
        }
        this.enablePageNumberInput();
        if (!this.isMemberValid && this.isNlpEnabled) {
          this.getMemberData();
        }
        this.cd.markForCheck();
        this.isBucketFolderChecked = (documentPages && documentPages.isCdnBucketFolderChecked) ? documentPages.isCdnBucketFolderChecked : false;
        this.getCdnImages(documentPages);
        // Load next pages
        // if (!NumberHelper.isGreaterThan(screenSyncPageNumber, 1)) {
        //   this.subsequentLoad([2, 3]);
        // }
      },
      () => this.loaderService.isLoading.next(false),
      () => {
        this.messagingService.showToast("Document Pages not available", SeverityType.WARN);
        this.hasNoDocumentPages.emit(true);
      });
  }

  /* tslint:enable:no-parameter-reassignment */

  private updatePageService(pageNumber: number): void {
    const match = this.pages.find(page => page?.pageNumber === pageNumber);
    if (match) {
      const event = {page: pageNumber, documentPageId: match.documentPageId};
      this.pageService.updateDocumentPageInfo(event);
    }
  }

  getCdnImages(documentPages: DocumentPages) {
    this.cdnBucketFolders = documentPages.s3ImagesFolders;
  }

  async getAllDocumentPages(begPage, endPage) {
    return this.loadSubsequent([begPage, endPage], false);
  }

  private subsequentLoad(pagesToLoad: number[]): void {
    if (this.currentPageNumber > 0 && this.totalPages > 2) {
      this.zone.runOutsideAngular(() =>
        setTimeout(() => {
          if (this.state.shouldLoadMore && !this.clearCache) {
            pagesToLoad.forEach(pageNumber => this.setPage({pageNumber} as any));
            this.loadSubsequent(pagesToLoad)
              .pipe(finalize(() => this.loaderService.isLoading.next(false)))
              .subscribe(documentPages => {
                this.getCdnImages(documentPages);
                this.setPages(documentPages.pages);
                this.cleanCache();
                pagesToLoad[0] += 2;
                pagesToLoad[1] += 2;
                const loadPages = pagesToLoad[0] === this.totalPages ? [this.totalPages] : pagesToLoad;
                this.subsequentLoad(loadPages);
              });
          }
        })
      );
    } else if (this.currentPageNumber > 0 && this.totalPages === 2) {
      this.zone.runOutsideAngular(() =>
        setTimeout(() => {
          if (this.state.shouldLoadMore && !this.clearCache) {
            this.setPage(2 as any);
            this.loadSubsequent([2])
              .pipe(finalize(() => this.loaderService.isLoading.next(false)))
              .subscribe(documentPages => {
                this.getCdnImages(documentPages);
                this.setPages(documentPages.pages);
                this.cleanCache();
              });
          }
        })
      );
    }
  }

  private tryLoading(): void {
    if (this.currentPageNumber > 0 && this.totalPages > 0) {
      this.zone.runOutsideAngular(() =>
        setTimeout(() => {
          if (this.state.shouldLoadMore) {
            const pagesToLoad = this.state.getPagesToLoad();
            if (ArrayHelper.isAvailable(pagesToLoad)) {
              pagesToLoad.forEach(pageNumber => this.setPage({pageNumber} as any));
              this.loadSubsequent(pagesToLoad).subscribe(documentPages => {
                this.getCdnImages(documentPages);
                this.setPages(documentPages.pages);
                this.cleanCache();
              });
            }
          }
        })
      );
    }
  }

  private tryLoadingJump(): void {
    if (this.currentPageNumber > 0 && this.totalPages > 0) {
      this.zone.runOutsideAngular(() =>
        setTimeout(() => {
          if (this.state.shouldLoadMore) {
            const pagesToLoad = this.state.getPagesToLoad();

            if (ArrayHelper.isAvailable(pagesToLoad)) {
              pagesToLoad.forEach(pageNumber => this.setPage({pageNumber} as any));
              this.loadSubsequent([this.currentPageNumber]).subscribe(documentPages => {
                this.getCdnImages(documentPages);
                this.setPages(documentPages.pages);
                this.cleanCache();

                for (let i = 0; i < pagesToLoad.length; i += 2) {
                  const start = i;
                  const end = i + 1;

                  if (pagesToLoad[start] && pagesToLoad[end]) {
                    this.loadSubsequent([pagesToLoad[start], pagesToLoad[end]]).subscribe(pageRange => {
                      this.getCdnImages(pageRange);
                      this.setPages(pageRange.pages);
                      this.cleanCache();
                    });
                  } else {
                    this.loadSubsequent([pagesToLoad[start]]).subscribe(pageSingle => {
                      this.getCdnImages(pageSingle);
                      this.setPages(pageSingle.pages);
                      this.cleanCache();
                    });
                  }
                }
              });
            }
          }
        })
      );
    }
  }

  private cleanCache(): void {
    if (this.cacheTracker.isLimitReached) {
      this.zone.runOutsideAngular(() => {
        const minTime = this.cacheTracker.currentTime;
        setTimeout(() => {
          for (let i = 0; i < this.pages.length; ++i) {
            if (this.pages[i] != null && this.pages[i].time < minTime) {
              this.pages[i] = null;
            }
          }
        });
      });
    }
  }

  private cleanCacheAfterNGDestory(): void {
    this.clearCache = true;

    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        for (let i = 0; i < this.pages.length; ++i) {
          this.pages[i] = null;
        }
      });
    });

    this.sessionService.delete(this.THUMBNAILKEY);
  }

  // private load(pages: number[]): Observable<DocumentPages> {
  //   const begPage = pages[0];
  //   const endPage = pages[pages.length - 1];

  //   return this.retrievalDocumentService.getPages(this.cleanDocumentId, this.sourceDocumentTypeId, begPage, endPage, this.sessionOptimizeImageSaved, this.pageTypeId, null, false,
  //                                                 this.cdnBucketFolders);
  // }

  private loadSubsequent(pages: number[], includeImageString?: boolean): Observable<DocumentPages> {
    const begPage = pages[0];
    const endPage = pages[pages.length - 1];
    return this.retrievalDocumentService.getSubsequentPages(this.cleanDocumentId, this.sourceDocumentTypeId, begPage, endPage, this.sessionOptimizeImageSaved, this.pageTypeId,
                                                            includeImageString, this.isBucketFolderChecked, null, null, false, this.cdnBucketFolders);
  }

  private loadPageWithContent(pages: number[], includeImageString?: boolean): Observable<DocumentPages> {
    const begPage = pages[0];
    const endPage = pages[pages.length - 1];
    return this.retrievalDocumentService.getSubsequentPages(this.cleanDocumentId, this.sourceDocumentTypeId, begPage, endPage, this.sessionOptimizeImageSaved, this.pageTypeId,
                                                            includeImageString, this.isBucketFolderChecked, null, null, true, this.cdnBucketFolders);
  }

  private updateViewedCurrentPage(): void {
    if (this.hasCurrentPage) {
      this.currentPage.viewed = true; // NOTE: Need this for now but Obsolete?
      this.documentViewerSessionService.add(this.cleanSourceDocumentTypeId, this.cleanDocumentId, this.currentPage);
    }
  }

  private saveImage(pageToSave: DocumentPage, rotateDegrees: number): void {
    this.retrievalDocumentService.rotateDocumentPage(pageToSave.documentPageId, rotateDegrees)
      .subscribe(url => {
        if (!StringHelper.isAvailable(url)) {
        this.messagingService.showToast("Page Rotation has failed, please try again", SeverityType.ERROR);
        return;
        }
        this.updatePageAfterRotation(this.currentIndex, url);
        this.messagingService.showToast("Page Rotation has been updated", SeverityType.SUCCESS);
        return;
      });
  }

  private updatePageAfterRotation(currentPage: number, url: string): void {
    const page = this.currentPages[currentPage];
    if (this.currentPage.isCdnChartImageExist) {
      page.isCdnChartImageExist = false;
    }
    this.state.setPage(new DocumentPage({...page, image: url}));
    this.state.pages$.next(this.state.pages);
  }

  private enablePageNumberInput(): void {
    this.currentPageNumberForm.get(this.currentPageNumberInput.getMasterKey()).enable();
    this.currentPageNumberInput.disabled = false;
    this.cd.markForCheck();
  }

  private setPage(page: DocumentPage): void {
    if (StringHelper.isAvailable(page.source)) {
      const time = performance.now();
      page.time = time;
      this.cacheTracker.add(time);
    }

    this.state.setPage(page);
  }

  private setPages(pages: DocumentPage[]): void {
    pages.forEach(this.setPage.bind(this));
    this.pages$.next(this.pages);
    this.cd.markForCheck();
  }

  trackByIndex(index, item) {
    return index;
  }


  /* #region OCR */
  getWordStyles(word: OcrWord): any {
    const isSelected = this.ocrCurrentPage.ocrMatches[this.ocrCurrentPage.currentIndex].ocrWords.includes(word);
    const width = this.isWindowOpen ? "auto" :
      `${(word.boundingBoxes[1].x - word.boundingBoxes[0].x) * (this.zoomPercentage)}%`;
    const imageSize = document.querySelector("#documentsImg") as HTMLElement;
    const updatedHeigh = Number(this.zoomStyle.height.slice(0, -2));
    if (this.isWindowOpen) {
      const newYHeight = (word.boundingBoxes[1].y - word.boundingBoxes[0].y) * updatedHeigh;
      const newXWidth = (word.boundingBoxes[1].x - word.boundingBoxes[0].x) * imageSize.offsetWidth;
      const newXInit = (word.boundingBoxes[0].x * imageSize.offsetWidth) + this.margin;
      const newYInit = (word.boundingBoxes[0].y * updatedHeigh);
      return {
        width: `${newXWidth}px`,
        height: `${newYHeight}px`,
        top: `${newYInit}px`,
        left: `${newXInit}px`,
        position: "absolute",
        backgroundColor: isSelected ? "#00FF11" : "#00F6FF",
        opacity: "0.5",
      };
    } else {
      return {
        width,
        height: `${(word.boundingBoxes[1].y - word.boundingBoxes[0].y) * updatedHeigh}px`,
        top: `${word.boundingBoxes[0].y * updatedHeigh}px`,
        left: `${word.boundingBoxes[0].x * (this.zoomPercentage)}%`,
        position: "absolute",
        backgroundColor: isSelected ? "#00FF11" : "#00F6FF",
        opacity: "0.5",
      };
    }
  }

  getOcrWords(page: DocumentPage): OcrWord[] {
    const ocrPage = this.getOcrPage(page);
    const phrases = ocrPage == null ? [] : ocrPage.ocrMatches;
    const words = phrases.reduce((acc, phrase) => {
      acc.push(...phrase.ocrWords);
      return acc;
    },                           []);
    return words;
  }

  getOcrPage(page: DocumentPage): DocumentPageOcrMatches {
    const ocrPage = this.hasOcrWords(page) ? this.documentOCRMatch.pages.find(a => a.pageNumber === page.pageNumber) : null;
    return ocrPage;
  }

  hasOcrWords({pageNumber}: DocumentPage): boolean {
    return this.hasOcrSearchResults && this.documentOCRMatch.pages.find(p => p.pageNumber === pageNumber) != null;
  }

  hasOcrWordsAndDocumentMetaData(page: DocumentPage): boolean {
    return this.hasPageAndSource(page) && this.hasOcrWords(page);
  }

  gotoNextOCRMatchPage(): void {
    this.documentOCRMatch.nextPage();
    this.scrollToOcrCurrentWord();
  }

  gotoPrevOCRMatchPage(): void {
    this.documentOCRMatch.prevPage();
    this.scrollToOcrCurrentWord();
  }

  gotoNextOCRMatchPageWord(): void {
    if (this.hasOcrSearchResults) {
      this.ocrCurrentPage.nextWord();
      this.scrollToOcrCurrentWord();
    }
  }

  gotoPrevOCRMatchPageWord(): void {
    if (this.hasOcrSearchResults) {
      this.ocrCurrentPage.prevWord();
      this.scrollToOcrCurrentWord();
    }
  }

  scrollToOcrCurrentWord() {
    const offset = this.ocrCurrentPage.offset(this.itemSize);
    this.viewport?.scrollToOffset(offset);
  }

  onOcrSearchPhraseFocus(input: HTMLElement, ocr: HTMLElement): void {
    this.resetPagePosition();
    this.isOcrFocus = true;
    const {left, bottom} = input.getBoundingClientRect();
    ocr.style.top = `${bottom - 2}px`;
    ocr.style.left = `${left}px`;
  }

  preventHide(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
  }

  showElement(element: HTMLElement): void {
    element.hidden = false;
  }

  fetchOcrMatches(ocr: HTMLElement): void {
    this.isOcrSearchInProgress = true;
    this.documentOCRMatch = null;
    const searchPhrase = this.searchPhraseForm.get(this.searchPhraseInput.key).value;
    this.showElement(ocr);

    this.retrievalDocumentService
      .getOCRMatches(this.cleanDocumentId, searchPhrase, this.sourceDocumentTypeId)
      .pipe(finalize(() => {
        this.isOcrSearchInProgress = false;
        this.cd.markForCheck();
      }))
      .subscribe(data => {
        this.documentOCRMatch = data;
        this.documentOCRMatch.firstViewed = false;
        const hasPages = ArrayHelper.isAvailable(this.documentOCRMatch.pages);
        this.documentOCRMatch.currentOcrPageNumberIndex = hasPages ? 0 : -1;
        if (hasPages) {
          this.scrollToOcrCurrentWord();
          this.showOcrSearchIcon = false;
        }
        this.cd.markForCheck();
      });
    this.cd.markForCheck();
  }

  resetOcrSearchInput(): void {
    this.searchPhraseForm.get(this.searchPhraseInput.key).reset();
    this.clearOcrSearch();
  }

  private clearOcrSearch(): void {
    this.documentOCRMatch = null;
    if (this.ocrSearchResults) {
      this.ocrSearchResults.nativeElement.hidden = true;
    }
    this.showOcrSearchIcon = true;
  }

  getNlpWords(page: DocumentPage): NlpWord[] {
    const matchedNlpWords = this.getMatchedNlpWords(page);
    const words = matchedNlpWords == null ? [] : matchedNlpWords;
    return words;
  }

  getMatchedNlpWords(page: DocumentPage): NlpWord[] {
    const matchedNlpWords = this.documentNlpMatch.nlpMatches.filter(a => a.pageNumber === page.pageNumber);
    return matchedNlpWords;
  }

  getNlpWordStyles(word: NlpWord): any {
    const defaultStyles = {
      width: `${(word.boundingBoxes[1].x - word.boundingBoxes[0].x) * this.zoomPercentage}%`,
      height: `${(word.boundingBoxes[1].y - word.boundingBoxes[0].y) * this.zoomPercentage}%`,
      top: `${word.boundingBoxes[0].y * this.zoomPercentage}%`,
      left: `${word.boundingBoxes[0].x * this.zoomPercentage}%`,
      position: "absolute",
    };

    const additionalStyles = this.getAdditionalStyles(word);

    const styles = {
      ...defaultStyles,
      ...additionalStyles,
    };

    return styles;
  }

  hasNlpWords({pageNumber}: DocumentPage): boolean {
    return this.hasNlpMatches && this.documentNlpMatch.nlpMatches.find(p => p.pageNumber === pageNumber) != null;
  }

  hasNlpWordsAndDocumentMetaData(page: DocumentPage): boolean {
    return this.hasPageAndSource(page) && this.hasNlpWords(page);
  }

  activateNlp(): void {
    this.resetOcrSearchInput();
    this.documentNlpMatch = null;
    this.isOcrSearchEnabled = false;
    this.isNlpSearchEnabled = true;
    if (this.nlpSearchInput && this.nlpSearchResults) {
      this.onNlpSearchPhraseFocus(this.nlpSearchInput.nativeElement, this.nlpSearchResults.nativeElement);
    }
    this.resetPagePosition();
  }

  deactivateNlp(): void {
    this.isNlpSearchEnabled = false;
    this.isOcrSearchEnabled = true;
    this.documentNlpMatch = null;
    this.negativeExclusionBtnActive = false;
    this.nlpPhraseForm.get(this.nlpPhraseInput.key).reset();
    this.resetOcrSearchInput();
  }

  getNlpData(nlpType: NlpType, diagnosis?: Diagnosis): void {
    this.isNlpSearchInProgress = true;
    this.selectedNlpType = nlpType;
    this.activateNlp();

    switch (nlpType) {
      case NlpType.DIAGNOSIS:
        this.getDiagnosisNlpData(diagnosis);
        break;
      case NlpType.NEGATIVE_EXCLUSION:
        this.getNegativeExclusionNlpData();
        break;
      default:
        break;
    }

    this.cd.markForCheck();
  }

  scrollToNlpCurrentWord() {
    if (this.hasNlpMatches) {
      const offset = this.documentNlpMatch.offset(this.itemSize);
      this.viewport?.scrollToOffset(offset);
    }
  }

  gotoNextNlpMatchPageWord(): void {
    if (this.hasNlpMatches) {
      this.documentNlpMatch.nextWord();
      this.scrollToNlpCurrentWord();
    }
  }

  gotoPrevNlpMatchPageWord(): void {
    if (this.hasNlpMatches) {
      this.documentNlpMatch.prevWord();
      this.scrollToNlpCurrentWord();
    }
  }

  onNlpSearchPhraseFocus(input: HTMLElement, nlp: HTMLElement): void {
    const {left, bottom} = input.getBoundingClientRect();
    nlp.style.top = `${bottom - 2}px`;
    nlp.style.left = `${left}px`;
  }

  gotoCurrentDiagnosisDosPage(pageNumber: number): void {
    if (NumberHelper.isAvailable(pageNumber)) {
      this.gotoPage(pageNumber);
    }
  }

  private getDiagnosisNlpData(diagnosis: Diagnosis): void {
    this.retrievalDocumentService
      .getNlpMatches(
        this.chaseId,
        diagnosis.encounterId,
        diagnosis.diagnosisId,
        diagnosis.icd,
        DateHelper.format(diagnosis.startDate),
        DateHelper.format(diagnosis.endDate))

      .pipe(finalize(() => {
        this.isNlpSearchInProgress = false;
        this.negativeExclusionBtnActive = false;
        this.cd.markForCheck();
      }))
      .subscribe(data => {
        this.documentNlpMatch = data;
        if (!this.hasNlpMatches) {
          this.gotoPage(this.selectedDiagnosis.pageNumber);
        }
        this.cd.markForCheck();
      });
  }

  private getNegativeExclusionNlpData(): void {
    this.retrievalDocumentService
      .getNegativeExclusionMatches(this.chaseId)
      .pipe(finalize(() => {
        this.negativeExclusionBtnActive = true;
        this.isNlpSearchInProgress = false;
        this.cd.markForCheck();
      }))
      .subscribe(data => {
        this.documentNlpMatch = data;
        this.nlpPhrase.setValue("Negative Exclusion");
        this.nlpPhraseInput.title = "Negative Exclusion";
        this.scrollToNlpCurrentWord();
        this.cd.markForCheck();
      });
  }

  private updateProjectConfiguration(): void {
    if (this.projectConfiguration != null) {
      this.enableMrrBot =
        StringHelper.isAvailable(this.projectConfiguration.enableMrrBot) ? this.projectConfiguration.enableMrrBot : "0";

      this.isMemberProjectRetrievalType = Number(this.projectConfiguration.memberCentricChaseRetrieval) === ProjectRetrievalType.Member;

      this.medicalRecordUploadService.markMemberCentricProject(this.isMemberProjectRetrievalType);
    }
  }

  private getAdditionalStyles(word: NlpWord): {} {
    const isSelected = this.documentNlpMatch?.nlpMatches[this.documentNlpMatch.currentIndex] === word;

    let additionalStyles = {};
    if (this.isDiagnosisNLP) {
      additionalStyles = {
        backgroundColor: isSelected ? "rgb(131, 188, 106, 0.45)" : "rgb(131, 188, 106, 0.25)",
      };
    } else if (this.isNegativeExclusionNLP) {
      additionalStyles = {
        backgroundColor: isSelected ? "rgb(235, 166, 164, 0.55)" : "rgb(235, 166, 164, 0.3)",
      };
    }

    return additionalStyles;
  }

  getOcrMappingData(): void {
    this.ocrMappingMatchesBtnActive ? this.clearOcrMappingNlpData() : this.getOcrMappingNlpData();

    this.cd.markForCheck();
  }

  hasOcrMappingNlpWords({pageNumber}: DocumentPage): boolean {
    return this.hasNlpOcrMappingMatches && this.ocrMappingNlpMatch.nlpMatches.find(p => p.pageNumber === pageNumber) != null;
  }

  hasOcrMappingNlpWordsAndDocumentMetaData(page: DocumentPage): boolean {
    return this.hasPageAndSource(page) && this.hasOcrMappingNlpWords(page);
  }

  private getOcrMappingNlpData() {
    this.retrievalDocumentService
      .getOcrMappingMatches(this.chaseId)
      .pipe(finalize(() => {
        this.ocrMappingMatchesBtnActive = true;
        this.isNlpOcrMappingEnabled = true;
        this.cd.markForCheck();
      }))
      .subscribe(data => {
        this.resetPagePosition();
        this.ocrMappingNlpMatch = data;
        this.cd.markForCheck();
      });
  }

  private clearOcrMappingNlpData(): void {
    this.ocrMappingNlpMatch = null;
    this.ocrMappingMatchesBtnActive = false;
    this.isNlpOcrMappingEnabled = false;
  }

  getMemberData(): void {
    this.memberBtnActive ? this.clearMemberNlpData() : this.getMemberNlpData();

    this.cd.markForCheck();
  }

  hasMemberNlpWords({pageNumber}: DocumentPage): boolean {
    return this.hasNlpMemberMatches && this.memberNlpMatch.nlpMatches.find(p => p.pageNumber === pageNumber) != null;
  }

  hasMemberNlpWordsAndDocumentMetaData(page: DocumentPage): boolean {
    return this.hasPageAndSource(page) && this.hasMemberNlpWords(page);
  }

  private getMemberNlpData(): void {
    this.retrievalDocumentService
      .getMemberMatches(this.chaseId)
      .pipe(finalize(() => {
        this.memberBtnActive = true;
        this.isNlpMemberEnabled = true;
        this.cd.markForCheck();
      }))
      .subscribe(data => {
        this.resetPagePosition();
        this.memberNlpMatch = data;
        this.cd.markForCheck();
      });
  }

  private clearMemberNlpData(): void {
    this.memberNlpMatch = null;
    this.memberBtnActive = false;
    this.isNlpMemberEnabled = false;
  }

  toggleThumbnailView(data: any): void {
    this.isExpandedViewFromToggle = data.view === "expand";
    this.isExpandedViewFromUrl = false;

    if (!this.isExpandedViewFromToggle) {
      setTimeout(() => {
        this.gotoPage(data.pageNumber);
      },         0);
    }

    this.cd.markForCheck();
  }

  hasDosNlpWords({pageNumber}: DocumentPage): boolean {
    return this.hasNlpDosData && this.dosNlpMatch.nlpMatches.find(p => p.pageNumber === pageNumber) != null;
  }

  hasDosNlpWordsAndDocumentMetaData(page: DocumentPage): boolean {
    return this.hasPageAndSource(page) && this.hasDosNlpWords(page);
  }

  private getDosData(diagnosis: Diagnosis): void {
    this.retrievalDocumentService
      .getDosMatches(
        this.chaseId,
        diagnosis.encounterId,
        diagnosis.diagnosisId,
        diagnosis.icd,
        DateHelper.format(diagnosis.startDate),
        DateHelper.format(diagnosis.endDate))
      .subscribe(data => {
        this.dosNlpMatch = data;
        this.cd.markForCheck();
      });
  }

  /* #endregion DOS NLP */

  /* #region Optimize Images */

  optimizeChart(): void {
    this.isHighResolutionImage = true;
    this.sessionService.put(this.sessionOptimizeImageName, this.isHighResolutionImage);
    this.initialLoad();
  }

  private verifyOptimizedImagesAreAvailable(): void {
    this.retrievalDocumentService
      .verifyOptimizedImagesAreAvailable(this.chaseId)
      .subscribe(result => {
        this.isOptimizedImagesAvailable = result;
        this.cd.markForCheck();
      });
  }

  getSource(page: any, src: string) {
    return page.isCdnChartImageExist ? src.replace("data:image/png;base64,", "") : src;
  }

  /* #endregion Optimize Images */

  /* #region Hover Highlights */

  highlightToDelete(event: any) {
    const userDocumentPage = this.userDocumentPages.find(p => p.chaseDocumentPageId === event.pageId);
    const newAnnotations = userDocumentPage.annotations.filter(a => a.createDate !== event.data.createDate);
    const deletedHighlightId = StringHelper.isAvailable(event.data.highlightId) ? event.data.highlightId : null;
    this.saveDocumentAnnotations({
        chaseDocumentPageId: event.pageId,
        annotationSource: AnnotationSource.User,
        annotations: newAnnotations,
      },
                                 deletedHighlightId
    );
  }

  /* #endregion Hover Highlights */
  private updatePageAndIndex(index: number): void {
    this.currentIndex = index;
    this.updateViewportTransform();
    const pageNumber = index + 1;
    if (ArrayHelper.isAvailable(this.state.pages)) {
      this.loadMorePages(index);
      this.pageService.chartPageChange = (index);
    }
    this.chaseDetailStateService.setPageChangeValue(true);
    this.currentPageNumber$.next(pageNumber);
    this.handlePageNumberState(pageNumber);
    return;
  }

  private loadMorePages(pageNumber: number): void {
    // Need to add + 1 since the array starts at 0 but the pages start at 1
    const actualPageNumber = pageNumber + 1;
    const isInRange = this.isInRange(actualPageNumber, this.startRange, this.endRange, this.state.pages, "image");
    if (!isInRange || this.currentPages.length <= 0) {
      return;
    }
    const pagesToLoad = this.getPagesToLoad(actualPageNumber, this.lowerLoadRange, this.upperLoadRange, this.state.pages, "image");
    if (!ArrayHelper.isAvailable(pagesToLoad)) {
      return;
    }
    this.loadSubsequentDocumentPages(
      this.retrievalDocumentService,
      "getSubsequentPages",
      pagesToLoad,
      this.cleanDocumentId,
      this.cdnBucketFolders,
      this.sourceDocumentTypeId,
      this.sessionOptimizeImageSaved || this.isOptimizedImagesAvailable,
      this.pageTypeId,
      this.isBucketFolderChecked,
      (documentPages => {
        const startItemIndex = documentPages.pages[0] ? documentPages.pages[0].pageNumber : 0;
        const length = documentPages.pages?.length - 1;
        const lastItemIndex = documentPages.pages[length].pageNumber;
        const updatedPages = Array.from(this.state.pages);
        updatedPages.splice(startItemIndex, lastItemIndex, ...(documentPages.pages));
        this.setPages([...updatedPages]);
        this.isBucketFolderChecked = (documentPages && documentPages.isCdnBucketFolderChecked) ? documentPages.isCdnBucketFolderChecked : false;
      }));
  }


  private storageHandler(): void {
    try {
      const userId = this.authService.user.userId;
      const openChaseId = this.chaseId;
      const openChaseAndUserInfoDetail = {userId, openChaseId};
      const openChaseAndUserInfoObj = JSON.stringify(openChaseAndUserInfoDetail);
      const localChaseUserInfo = this.localService.get(this.openChaseAndUserInfo, null);
      if (!localChaseUserInfo) {
        this.localService.put(this.openChaseAndUserInfo, openChaseAndUserInfoObj);
      } else {
        const parsedLocalChaseUserInfo = JSON.parse(localChaseUserInfo);
        this.globalLocalStoreChaseId = parsedLocalChaseUserInfo.openChaseId;
        this.globalLocalStoreUserId = parsedLocalChaseUserInfo.userId;
        if (
          this.globalLocalStoreChaseId !== this.chaseId
          && this.globalLocalStoreUserId === this.authService.userId
          && this.router.url.includes("members")
        ) {
          this.messagingService.showMessage(`To work on this chase, please close chase id ${this.globalLocalStoreChaseId}.`, SeverityType.WARN);
        }
      }
    } catch (error) {
      console.error("Error handling storage event:", error);
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.globalLocalStoreChaseId !== this.chaseId
        && this.globalLocalStoreUserId === this.authService.userId
        && this.router.url.includes("members")
        && this.isRoleAbstractorOrOverreader(this.authService?.user.directoryRoleIds)
      ) {
        const elements = document.querySelectorAll(".ui-messages-close");
        elements.forEach(element => {
          this.renderer.setStyle(element, "display", "none");
        });
      }
      this.viewport?.scrollToIndex(this.currentPageNumber - 1);
      this.cd.detectChanges();
    },         3000);
  }
}

