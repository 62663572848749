import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { List } from "immutable";
import { SubSink } from "subsink";
import { AuthService } from "../../../../../auth/auth.service";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { SessionService } from "../../../../../core/storage/session.service";
import { ListItem } from "../../../../../shared/list/list-item";
import { MenuItem } from "../../../../../shared/menu/menu-item.model";
import { MenuService } from "../../../../../shared/menu/menu.service";
import { PendStatus } from "../../../../../shared/pend/pend-status.enum";
import { TagType } from "../../../../../shared/tags/model/tag-type.enum";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../../utilities/contracts/date-helper";
import { NumberHelper } from "../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { Member } from "../../../clinical/member.model";
import { ProjectType } from "../../../project/project-type.enum";
import { Project } from "../../../project/project.model";
import { DirectoryUserRole } from "../../../retrieval/directory-user-role";
import { ChaseDetailInfoService } from "../chase-detail-info/chase-detail-info.service";
import { ChaseDetailState } from "../chase-detail-state.model";
import { ChaseDetailStateService } from "../chase-detail-state.service";
import { ChaseDetailService } from "../chase-detail.service";
import { ChaseDetailHeaderService } from "./chase-detail-header.service";

@Component({
  selector: "member-chase-detail-header",
  templateUrl: "./chase-detail-header.component.html",
  styleUrls: ["./chase-detail-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseDetailHeaderComponent implements OnInit, OnDestroy, AfterViewChecked {
  private sink = new SubSink();
  readonly AUTHENTICATION_KEY = "authentication";

  chaseDetailState: ChaseDetailState;
  chaseId: number;
  fromPage: "review" | "mrr" | "or1" | "clientoverread" | string;
  menuItems = List<MenuItem>();
  chaseDetails = List<ListItem>();
  isClinical: boolean;
  workFlowStatusId = 4; // TODO: As matching status not available in workflowstatus enum. Will replace & get from enum when its same.

  selectedChases: any[];
  isPendModalVisible = false;
  isAssignToChaseModalVisible = false;
  @Input() clinical = true;
  tagType = TagType.CHASE;
  @ViewChild("containerHeight", {static: true}) containerHeight: ElementRef;
  selectedChaseStatus: any[];
  showTopBanner = false;

  constructor(
    private readonly chaseDetailService: ChaseDetailService,
    private chaseDetailStateService: ChaseDetailStateService,
    private chaseDetailHeaderService: ChaseDetailHeaderService,
    private changeDetector: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly messagingService: MessagingService,
    private router: Router,
    private chaseDetailInfoService: ChaseDetailInfoService,
    private renderer: Renderer2,
    private menuService: MenuService,
    private sessionService: SessionService
  ) { }

  ngOnInit() {
    this.menuService.showHeader.next(this.showTopBanner);
    this.sink.add(
      this.route.paramMap.
        subscribe(params => this.chaseId = +params.get("chaseGd")),

      this.chaseDetailStateService.state
        .subscribe(state => {
          this.chaseDetailState = state;
          if (this.chaseDetailState.chaseId) {
            this.setMenuItems();
          }
          this.changeDetector.markForCheck();
        }),

      this.chaseDetailService.chaseDetailsChange
        .subscribe(chaseDetails => {
          this.chaseDetails = List(chaseDetails);
          if (!this.assignedToCurrentUser) {
            this.messagingService.showMessage("You are not currently assigned to this Chase.  Certain functions will not be available to you.", SeverityType.WARN);
          }
          this.changeDetector.markForCheck();
        })
    );

    this.fromPage = this.route.snapshot.paramMap.get("fromPage");
    if (this.isChaseInfoTabSelected) {
      this.containerHeight?.nativeElement?.offsetParent?.classList.add("container-header-wrap");
    }
  }

  ngOnDestroy() {
    this.chaseDetailStateService.clearData();
    this.sink.unsubscribe();
    this.menuService.showHeader.next(true);
  }

  get assignedToId(): number {
    const assignedToIdItem = this.chaseDetails.find(item => item.key === "Assigned To Id");
    if (assignedToIdItem == null) {
      return null;
    }

    return +assignedToIdItem.value;
  }

  get assignedToCurrentUser(): boolean {
    if (this.assignedToId == null) {
      return true;
    }

    return this.assignedToId === this.authService.userId;
  }

  get memberItems(): List<ListItem> {
    let memberKeys = [];

    if (this.isRisk || this.isIVA) {
      memberKeys = ["Status", "Measure ID", "Member Name", "DOB", "Age", "Sex", "Project", "Review Period", "Client Member ID", "Client Chase Key"];
     } else if (this.chaseDetailState.hasGaps) {
      memberKeys = ["Status", "Measure ID", "Gaps", "Member Name", "DOB", "Age", "Sex", "Project", "Client Member ID", "Client Chase Key"];
    } else {
      memberKeys = ["Status", "Measure ID", "Specialty", "Member Name", "DOB", "Age", "Sex", "Project", "Client Member ID", "Client Chase Key"];
    }

    const list = [
      new ListItem({
        key: "Status",
        value: this.chaseDetailState.reportingStatusName,
      }),
      new ListItem({
        key: "Measure ID",
        value: this.chaseDetailState.measureCode,
      }),
      new ListItem({
        key: "Specialty",
        value:  ArrayHelper.isAvailable(this.chaseDetailState.providers) && this.chaseDetailState.providers[0].providerSpecialty !== "" ? this.chaseDetailState.providers[0].providerSpecialty : "N/A",
      }),
      new ListItem({
        key: "Client Chase Key",
        value: this.chaseDetailState.chaseSourceAliasId,
      }),
      new ListItem({
        key: "DOB",
        value: this.chaseDetailState.hasMember ? DateHelper.format(this.chaseDetailState.member.memberDateOfBirth, "MM/DD/YYYY") : "",
      }),
      new ListItem({
        key: "Age",
        value: this.chaseDetailState.hasMember ? this.chaseDetailState.member.memberAgeByMeasureYear.toString() : "",
      }),
      new ListItem({
        key: "Sex",
        value: this.chaseDetailState.hasMember ? this.chaseDetailState.member.memberGender : "",
      }),
      new ListItem({
        key: "Project",
        value: this.chaseDetailState.hasProject ? this.chaseDetailState.project.projectName : "",
      }),
      new ListItem({
        key: "Review Period",
        value: this.chaseDetailState.hasProject ? this.chaseDetailState.project.projectDuration : "",
      }),
      new ListItem({
        key: "Client Member ID",
        value: this.chaseDetailState.hasMember ? this.chaseDetailState.member.memberSourceAliasId : "",
      }),
      new ListItem({
        key: "Member Name",
        value: this.chaseDetailState.hasMember ? this.getMemberName(this.chaseDetailState.member) : "",
      }),
    ];

    if (this.chaseDetailState.numeratorList) {
      list.push(
        new ListItem({
          key: "Gaps",
          value: this.chaseDetailState.numeratorList,
        })
      );
    }

    const memberItems = list
      .filter(item => memberKeys.includes(item.key))
      .sort((a, b) => {
        const aValue = memberKeys.findIndex(key => key === a.key);
        const bValue = memberKeys.findIndex(key => key === b.key);
        return aValue - bValue;
      });

    return List(memberItems);
  }

  private getMemberName(member: Member): string {
    return StringHelper.isAvailable(member.memberLastName) ? `${member.memberLastName}, ${member.memberFirstName}`
      : member.memberLastName;
  }

  get chaseText() {
    return `Chase ID ${this.chaseId}`;
  }

  get pendButtonLabel(): string {
    return this.isNewPend ? "Pend" : "Edit Pend";
  }

  get pendButtonClass(): string {
    if (this.showAssignButton) {
      return this.assignedToCurrentUser ? "chase-button chase-button--left_margin" : "chase-button chase-button--left_margin disabledState";
    } else {
      return this.assignedToCurrentUser ? "chase-button" : "chase-button disabledState";
    }
  }

  get hasChaseDetails(): boolean {
    return this.chaseDetails.size > 0;
  }

  get pendChaseId(): string {
    if (!this.hasChaseDetails) {
      return "";
    }

    const pendChaseId = this.chaseDetails.find(item => item.key === "ChasePendId");
    return pendChaseId.value;
  }

  get isNewPend(): boolean {
    const isExistingPendClosed = this.pendStatusId === PendStatus.Resolved || this.pendStatusId === PendStatus.Closed;
    const hasPendChaseId = NumberHelper.isGreaterThan(Number(this.pendChaseId), 0);

    return (hasPendChaseId && isExistingPendClosed) || !hasPendChaseId;
  }

  get pendCode(): string {
    if (!this.hasChaseDetails) {
      return "";
    }

    const pendCode = this.chaseDetails.find(item => item.key === "Pend Code");
    return pendCode.value;
  }

  get pendStatusId(): number {
    if (!this.hasChaseDetails) {
      return null;
    }

    const pendStatusId = this.chaseDetails.find(item => item.key === "PendStatusId");
    return +pendStatusId.value;
  }

  get isOverread1(): boolean {
    return StringHelper.isAvailable(this.fromPage) && this.fromPage === "or1";
  }

  get isOverread2(): boolean {
    return StringHelper.isAvailable(this.fromPage) && this.fromPage === "clientoverread";
  }

  get enablePendModal(): boolean {
      return this.isOverread1 || this.isOverread2 || this.isMRR;
  }

  get projectType(): ProjectType {
    return this.chaseDetailState.projectTypeId;
  }

  get isRisk(): boolean {
    return this.projectType === ProjectType.RISK;
  }

  get isIVA(): boolean {
    return this.projectType === ProjectType.IVA;
  }

  private setMenuItems(): void {
    const user = this.sessionService.get<any>(this.AUTHENTICATION_KEY, {});
    this.chaseDetailHeaderService
      .getMenuItems()
      .subscribe(menuItems => {
        menuItems.forEach(item => {
          if (item.path === "auditLog") {
            item.show = this.chaseDetailHeaderService.showAuditLogSubmenu;
          }
          if (item.path === "documents") {
            item.show = !user.enableThumbnailView;
          }
          if (item.path === "healthCard") {
            item.show = this.isRisk || this.isIVA;
          }
        });
        this.menuItems = List(menuItems);

        this.changeDetector.markForCheck();
      });
  }

  onUpdate($event) {
    this.router.navigate(["clinical", "view", "mrr"]);
  }

  get addressId(): number {
    if (!ArrayHelper.isAvailable(this.chaseDetails)) {
      return null;
    }

    const addressId = this.chaseDetails.find(item => item.key === "AID");
    if (addressId == null || !StringHelper.isAvailable(addressId.value)) {
      return null;
    }

    return +addressId.value;
  }

  setSelectedChases(): void {
    this.selectedChases = this.isNewPend ? [{ chaseId: this.chaseId, assignedToUserId: this.chaseDetailState.assignedToUserId }]
     : [{ chasePendId: this.pendChaseId, pendCode: this.pendCode, chaseId: this.chaseId, pendStatusId: this.pendStatusId, assignedToUserId: this.chaseDetailState.assignedToUserId }];
  }

  openPendModal(): void {
    this.setSelectedChases();
    this.checkChasesStatus();
    this.isPendModalVisible = true;
  }

  closeModal(): void {
    this.selectedChases = [];
  }

  updateChaseData() {
    this.sink.add(
      this.chaseDetailService.getSummaryItems(this.chaseId)
        .subscribe(items => {
          this.chaseDetailService.chaseDetailsChange.next(items);
          this.setPendDetails(items);
        })
    );
    }

  get isMRR(): boolean {
     return StringHelper.isAvailable(this.fromPage) && this.fromPage === "mrr";
  }

  get isChaseInfoTabSelected(): boolean {
    const pathUrl = window.location.href;
    const activePath = pathUrl.substr(pathUrl.lastIndexOf("/") + 1).trim().toLowerCase();
    return (activePath === "info" || activePath === this.chaseId.toString() || activePath === "newpages" || activePath === "removepages");
  }

  updateTimelineData() {
    this.sink.add(
      this.chaseDetailInfoService.getTimelineItems(this.chaseId)
        .subscribe(items => {
          this.chaseDetailStateService.timelineItems.next(items);
        })
    );
  }

  get isHealthCardTabSelected(): boolean {
    return this.router.url.includes("healthCard");
  }

  get isChaseInfoTab(): boolean {
    const pathUrl = this.router.url;
    const activePath = pathUrl.substr(pathUrl.lastIndexOf("/") + 1).trim().toLowerCase();
    return activePath === "info";
  }

  get isNewPagesInChase(): boolean {
    const pathUrl = this.router.url;
    const activePath = pathUrl.substr(pathUrl.lastIndexOf("/") + 1).trim().toLowerCase();
    return activePath === "newpages";
  }

  get isChaseTab(): boolean {
    const pathUrl = this.router.url;
    const activePath = pathUrl.substr(pathUrl.lastIndexOf("/") + 1).trim().toLowerCase();
    return activePath === this.chaseId.toString();
  }

  onMenuItemClick(path: string): void {
    path === "info" || "./" ? this.toggleClasses(true) : this.toggleClasses(false);
  }

  onBackArrowClick() {
    setTimeout(() => {
      this.toggleClasses(this.isChaseInfoTabSelected);
    },         1000);
  }

  toggleClasses(isChaseInfoTab: boolean): void {
    if (isChaseInfoTab) {
      this.containerHeight?.nativeElement?.offsetParent?.classList.add("container-header-wrap");
    } else {
      this.removeContainerHeightClass();
    }
  }

  ngAfterViewChecked(): void {
    this.sink.add(this.chaseDetailStateService.hasPageChanged.subscribe(value => {
      if (this.isChaseInfoTabSelected && !value) {
        this.containerHeight?.nativeElement?.offsetParent?.classList.add("container-header-wrap");
      }
      if (!this.isChaseInfoTabSelected && !value) {
        this.removeContainerHeightClass();
      }
    }));
  }

  private removeContainerHeightClass(): void {
    const el1 = this.containerHeight?.nativeElement?.offsetParent?.lastElementChild.getElementsByClassName("container-header-wrap")[0];
    const el2 = this.containerHeight?.nativeElement?.offsetParent?.lastElementChild.getElementsByClassName("container-body-wrap")[0];
    if (el1) {
      this.renderer.removeClass(el1, "container-header-wrap");
    }
    if (el2) {
      this.renderer.removeClass(el2, "container-body-wrap");
    }
  }

  checkChasesStatus(): void {
    this.selectedChaseStatus = [this.chaseDetailState.workflowStatus];
    this.clinical = this.chaseDetailState.workflowStatus === 2 || this.chaseDetailState.workflowStatus === 3 ? false : true;
  }

  private setSelectedChasesForAssignment(): void {
    this.selectedChases = [this.chaseId];
  }

  get hasChaseAssignAccess(): boolean {
    const roles = [DirectoryUserRole.ClinicalLead, DirectoryUserRole.MRRManager, DirectoryUserRole.OverreadManager,
                   DirectoryUserRole.ClientOverreadManager, DirectoryUserRole.AuditLead, DirectoryUserRole.AuditManager, DirectoryUserRole.Admin];
    if (this.chaseDetailState.workflowStatusName === "Overread2") {
    roles.push(DirectoryUserRole.ClientLead);
    }
    return this.authService.user.directoryRoleIds.some(roleId => roles.includes(roleId));
  }

  get showAssignButton(): boolean {
    return (this.hasChaseAssignAccess && (this.isChaseInfoTab || this.isChaseTab || this.isNewPagesInChase));
  }

  openAssignModal() {
    this.setSelectedChasesForAssignment();
    this.isAssignToChaseModalVisible = true;
  }

  updateChaseAssignedData() {
    this.getChaseDetails();
  }

  private getChaseDetails(): void {
    this.chaseDetailService.getChaseDetail(this.chaseId).subscribe(chaseDetail => {
      this.chaseDetailService.chaseDetailsChange.next(chaseDetail.keyValueItems);
      this.setPendDetails(chaseDetail.keyValueItems);
      const project = new Project({ ...chaseDetail });
      this.chaseDetailStateService.setData({
        ...chaseDetail,
        member: new Member({ ...chaseDetail }),
        project,
      });
    });
  }

  setPendDetails(items: ListItem[]): void {
    items.forEach(x => {
      switch (x.key) {
        case "PendStatusId":
          this.chaseDetailStateService.setData({
            pendStatusId: Number(x.value),
            hasPend: NumberHelper.isGreaterThan(Number(x.value), 0),
          });
          break;
        case "Pend Code":
          this.chaseDetailStateService.setData({
            pendCode: x.value,
          });
          break;
        case "PendCodeName":
          this.chaseDetailStateService.setData({
            pendName: x.value,
          });
          break;
        case "ChasePendId":
          this.chaseDetailStateService.setData({
            chasePendId: Number(x.value),
          });
          break;
        case "PendStatusName":
          this.chaseDetailStateService.setData({ pendStatusName: x.value });
          break;
        case "Status":
          this.chaseDetailStateService.setData({ reportingStatusName: x.value });
          break;
      default:
        break;
      }
    });
  }
  get pathLastRoute(): string {
    const pathUrl = window.location.href;
    return pathUrl.substr(pathUrl.lastIndexOf("/") + 1).trim().toLowerCase();
  }

  toggleTopBanner() {
    this.showTopBanner = !this.showTopBanner;
    this.menuService.showHeader.next(this.showTopBanner);
  }
}
