<h4 class="header">{{summaryHeader}}</h4>
<div class="summary-section">
  <div class="summary-header">
    <div class="summary-header__item"><span class="bold">LOAD ID: </span><span>{{currentDataLoadId}}</span></div>
    <div class="summary-header__item"><span class="bold">PROJECT NAME: </span><span>{{dataLoadState.projectName}}</span></div>
    <div class="summary-header__item"><span class="bold">PRODUCT TYPE: </span><span>{{dataLoadState.projectType}}</span></div>
  </div>
  <div class="summary-body">
    <div class="summary-body__header">
      <div class="summary-body__type bold">TYPE</div>
      <div class="summary-body__details bold">DETAILS</div>
    </div>
    <div class="summary-body__content" [class.odd]="odd" 
      *ngFor="let item of summaryReport; let odd = odd, let last = last, trackBy: trackByIndex">
      <div class="summary-body__type">{{item.key}}</div>
      <div [ngClass]="getValidationMessageClass(item.value)" class="summary-body__details">
          {{item.value}}
          <div (click)="toggleSection(item.key)" class="iconHere" *ngIf="(item.key == 'Total Error Count' && item.value > 0) || (item.key == 'Total Warning Count' && item.value > 0)" >
            <app-icon 
            iconStyle="fal" 
            iconType="fa" 
            [iconName]="item.key === 'Total Error Count' && expandedError ? 'angle-down' : 
            item.key === 'Total Warning Count' && expandedWarning ? 'angle-down' : 'angle-right'">
         </app-icon>
          </div>
      </div>
      <div class="toggleData" *ngIf="item.key == 'Total Error Count' && item.value > 0 && expandedError" >
       <div class="showCount" *ngFor="let item of errorSummaryReport; trackBy: trackByIndex">
        <p title="{{item.messageGroupHeader}}">{{item.messageGroupHeader}}</p> <span>{{item.messageCount}}</span>
       </div>
    </div>
    <div class="toggleData" *ngIf="item.key == 'Total Warning Count' && item.value > 0 && expandedWarning" >
      <div class="showCount" *ngFor="let item of warningSummaryReport; trackBy: trackByIndex">
       <p title="{{item.messageGroupHeader}}">{{item.messageGroupHeader}}</p> <span> {{item.messageCount}} </span>
      </div>
   </div>
    </div>
  </div>
  <div *ngIf="isLoadProcess" class="summary-footer">
    <div *ngIf="isDataLoadNoWarningsEnabled" class="text-right">
      <app-button classes="danger" class="summary-footer__button" text="UPLOAD FILES" (onClick)="startDataLoad()">
      </app-button>
    </div>
    <div *ngIf="isDataLoadWithWarningsEnabled" class="text-center footer-button">
      <app-button classes="secondary" class="summary-footer__button" text="DOWNLOAD VALIDATION SUMMARY" (onClick)="getErrorReport()">
      </app-button>
      <app-button classes="danger" class="summary-footer__button" text="UPLOAD FILES WITH WARNINGS" (onClick)="startDataLoad()">
      </app-button>
    </div>
    <div *ngIf="isPartialDataLoadEnabled" class="text-center footer-button">
      <app-button classes="secondary" class="summary-footer__button" text="DOWNLOAD VALIDATION SUMMARY" (onClick)="getErrorReport()">
      </app-button>
      <app-button classes="danger" class="summary-footer__button" [text]="loadChasesText" (onClick)="startDataLoad()">
      </app-button>
    </div>
    <div *ngIf="isDataLoadDisabled" class="text-center">
      <div class="summary-footer__error-message">Please fix critical error(s) to continue.</div>
      <app-button classes="secondary" class="summary-footer__button" text="DOWNLOAD VALIDATION SUMMARY" (onClick)="getErrorReport()">
      </app-button>
    </div>
  </div>
  <div  *ngIf="isUploadSuccess && isDataLoadWithIssues" class="summary-footer">
    <div class="text-left">
      <app-button classes="secondary" class="summary-footer__button" text="DOWNLOAD VALIDATION SUMMARY" (onClick)="getErrorReport()">
      </app-button>
    </div>
  </div>
</div>

<app-result-modal 
  class="processing-modal" 
  [(visible)]="isProcessingVisible" 
  header="DATA UPLOAD IN PROGRESS... YOU MAY LEAVE THIS PAGE BY PRESSING THE ESC BUTTON. YOU WILL BE NOTIFIED WHEN YOUR UPLOAD IS COMPLETE."
  [allowClose]="true">
  <div class="processing-modal__icon-spinner">
    <app-icon iconName="spinner" spin=true></app-icon>
  </div>
</app-result-modal>