import { UserAttributeModel } from "./user-attribute-model";
import { UserRoleModel } from "./user-role-model";

export class UserModel {
  address1: string;
  address2: string;
  backgroundCheckRequested: boolean;
  city: string;
  clinicianType?: number;
  coRetreivalOrganization?: string;
  defaultRole: string;
  directoryUserType?: number;
  email: string;
  firstName: string;
  groupName?: string;
  isAddTags?: boolean;
  isOrganizationUser: boolean;
  isRemoveTags?: boolean;
  isTagManagement?: boolean;
  lastName: string;
  loginName: string;
  loginPassword: string;
  name?: string;
  organizationId: number;
  paymentSetupRequested: boolean;
  phone: string;
  postalCode: string;
  primaryRoleName?: string;
  primaryUserRoleId?: string;
  state: string;
  status: string;
  userAttributes?: UserAttributeModel[];
  userId: number;
  userMeasure: string;
  userMultiFactorAuthenticationEnabled?: boolean;
  userRoleModels: UserRoleModel[];

  constructor(options: Partial<UserModel> = {}) {
    this.address1 = options.address1;
    this.address2 = options.address2;
    this.backgroundCheckRequested = options.backgroundCheckRequested;
    this.city = options.city;
    this.clinicianType = options.clinicianType;
    this.coRetreivalOrganization = options.coRetreivalOrganization;
    this.defaultRole = options.defaultRole;
    this.directoryUserType = options.directoryUserType;
    this.email = options.email;
    this.firstName = options.firstName;
    this.groupName = options.groupName;
    this.isAddTags = options.isAddTags;
    this.isOrganizationUser = options.isOrganizationUser;
    this.isRemoveTags = options.isRemoveTags;
    this.isTagManagement = options.isTagManagement;
    this.lastName = options.lastName;
    this.loginName = options.loginName;
    this.loginPassword = options.loginPassword;
    this.name = options.name;
    this.organizationId = options.organizationId;
    this.paymentSetupRequested = options.paymentSetupRequested;
    this.phone = options.phone;
    this.postalCode = options.postalCode;
    this.primaryRoleName = options.primaryRoleName;
    this.primaryUserRoleId = options.primaryUserRoleId;
    this.state = options.state;
    this.status = options.status;
    this.userAttributes = options.userAttributes;
    this.userId = options.userId;
    this.userMeasure = options.userMeasure;
    this.userMultiFactorAuthenticationEnabled = options.userMultiFactorAuthenticationEnabled;
    this.userRoleModels = options.userRoleModels;
  }

  static createDefault(): UserModel {

    return new UserModel({
      address1: null,
      address2: null,
      backgroundCheckRequested: false,
      city: null,
      clinicianType: 1,
      coRetreivalOrganization: null,
      defaultRole: null,
      directoryUserType: null,
      email: null,
      firstName: null,
      groupName: null,
      isAddTags: false,
      isOrganizationUser: true,
      isRemoveTags: false,
      isTagManagement: false,
      lastName: null,
      loginName: null,
      name: null,
      organizationId: 0,
      paymentSetupRequested: false,
      phone: null,
      postalCode: null,
      primaryRoleName: null,
      primaryUserRoleId: null,
      state: null,
      status: "A",
      userAttributes: [],
      userId: 0,
      userMeasure: null,
      userMultiFactorAuthenticationEnabled: false,
      userRoleModels: [],
    }
    );
  }
}
