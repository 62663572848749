import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SubSink } from "subsink";
import { AuthService } from "../../../../../auth/auth.service";
import { ParameterService } from "../../../../../core/navigation/parameter.service";
import { ListItem } from "../../../../../shared/list/list-item";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { DynamicEntityAttribute } from "../../../../api/member-validation/dynamic-entity-attribute.model";
import { ProjectType } from "../../../project/project-type.enum";
import { RiskEntity } from "../chase-detail-chart/risk/risk-entity.model";
import { ChaseDetailService } from "../chase-detail.service";
import { AuditData } from "./audit-data.model";
import { AuditDetail } from "./audit-detail.model";
import { ChaseDetailAuditLog } from "./chase-detail-audit-log.model";
import { ChaseDetailAuditLogService } from "./chase-detail-audit-log.service";

@Component({
  selector: "member-chase-detail-audit-log",
  templateUrl: "./chase-detail-audit-log.component.html",
  styleUrls: ["./chase-detail-audit-log.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseDetailAuditLogComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  auditEntities: AuditData[] = [];
  chaseAuditLog: ChaseDetailAuditLog;
  chaseId: number;
  coderSourceTypes = ["Eve", "Abstractor", "Overreader", "Overreader2", "Client"];
  isChartMaxed = false;
  overreadSortOrder = ["Member Validation", "Bmi Test", "Body Weight Test", "Body Height Test", "Exclusion", "Contra", "Miscellaneous", "Workflow"];
  chaseDetails: ListItem[] = [];

  constructor(
    private changeDetector: ChangeDetectorRef,
    private chaseDetailAuditLogService: ChaseDetailAuditLogService,
    private parameterService: ParameterService,
    private router: ActivatedRoute,
    private readonly chaseDetailService: ChaseDetailService,
    private readonly authService: AuthService
  ) { }

  get abstractionName(): string {
    return StringHelper.isAvailable(this.chaseAuditLog.abstractionUserName) ? this.chaseAuditLog.abstractionUserName : "";
  }

  get overreaderName(): string {
    return StringHelper.isAvailable(this.chaseAuditLog.overreadUserName) ? this.chaseAuditLog.overreadUserName : "";
  }

  get overreader2Name(): string {
    return StringHelper.isAvailable(this.chaseAuditLog.overread2UserName) ? this.chaseAuditLog.overread2UserName : "";
  }

  get assignedToId(): number {
    const assignedToIdItem = this.chaseDetails.find(item => item.key === "Assigned To Id");
    if (assignedToIdItem == null) {
      return null;
    }

    return +assignedToIdItem.value;
  }

  get assignedToCurrentUser(): boolean {
    if (this.assignedToId == null) {
      return true;
    }

    return this.assignedToId === this.authService.userId;
  }

  ngOnInit(): void {
    this.sink.add(
      this.router.paramMap.subscribe(() => {
        this.chaseId = this.parameterService.getNumberNormal("chaseGd", null);
        this.getAuditLogDetails();
        this.changeDetector.markForCheck();
      }),
      this.chaseDetailService.chaseDetailsChangeObserver.subscribe(chaseDetails => {
        this.chaseDetails = chaseDetails;
      })

    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  getAuditLogDetails(): void {
    this.chaseDetailAuditLogService.getAuditLogDetails(this.chaseId).subscribe(data => {
      this.chaseAuditLog = data;
      if (this.chaseAuditLog.projectTypeID === ProjectType.HEDIS && this.chaseAuditLog.measureCode === "ABA") {
        this.chaseAuditLog.entityData.sort((a, b) => {
          return this.overreadSortOrder.indexOf(a.entityTypeName) - this.overreadSortOrder.indexOf(b.entityTypeName);
      });
     }


      const entity = [...this.chaseAuditLog.entityData, ...this.chaseAuditLog.eveEntityData];

      const mergedEntities = entity.reduce((acc, curr) => {

      const existingEntity = acc.find(entityData => entityData.entityTypeName === curr.entityTypeName);

      if (existingEntity) {
        existingEntity.attributes.push(...curr.attributes);
        existingEntity.diagnoses.push(...curr.diagnoses);
      } else {
          acc.push({ ...curr });
      }

      return acc;
    },                                     []);

      this.parseEntityData(mergedEntities);
    });
  }

  parseEntityData(chaseEntityData: RiskEntity[]): void {
    chaseEntityData.forEach(entity => {
      const auditData = new AuditData({
        entityTypeName: entity.entityTypeName,
        coderAuditDetail: this.getCoderAuditDetail(entity),
      });

      this.auditEntities.push(auditData);
    });

    this.auditEntities.push(this.auditEntities.splice(this.auditEntities.findIndex(data => data.entityTypeName === "Exclusion"), 1)[0]);
    this.auditEntities.push(this.auditEntities.splice(this.auditEntities.findIndex(data => data.entityTypeName === "Contra"), 1)[0]);
    this.auditEntities.unshift(this.auditEntities.splice(this.auditEntities.findIndex(data => data.entityTypeName === "Member Validation"), 1)[0]);
   }

  getCoderAuditDetail(entity: RiskEntity): AuditDetail[] {
    const filteredAuditDetail: AuditDetail[] = [];
    const attributes: DynamicEntityAttribute[] = entity.attributes;
    const diagnoses: RiskEntity[] = entity.diagnoses;

    this.coderSourceTypes.forEach(source => {
      const coderInfo = this.getCoderInfo(source);
      const coderAttributes = this.getAttributes(attributes, source);
      const coderDiagnoses = this.getDiagnosis(diagnoses, source);

      if (StringHelper.isAvailable(coderInfo.coderName)) {
        const coderDetail = new AuditDetail({
          coderClass: coderInfo.coderClass,
          coderName: coderInfo.coderName,
          attributes: coderAttributes,
          diagnoses: ArrayHelper.isAvailable(coderDiagnoses) ? coderDiagnoses : [],
        });

        filteredAuditDetail.push(coderDetail);
      }
    });

    return filteredAuditDetail;
   }

  trackByIndex(index, item) {
    return index;
  }

  private getAttributes(attributes: DynamicEntityAttribute[], source: string): DynamicEntityAttribute[] {
    const auditDetailAttributes = attributes.filter(attribute => attribute.sourceTypeName === source);
    return auditDetailAttributes;
  }

  private getDiagnosis(diagnoses: RiskEntity[], source: string): DynamicEntityAttribute[] {
    const diagnosisAttributes: any[] = [];

    diagnoses.forEach(diagnosis => {
      const attributes: DynamicEntityAttribute[] = this.getAttributes(diagnosis.attributes, source);
      if (ArrayHelper.isAvailable(attributes)) {
        diagnosisAttributes.push(attributes);
      }
    });
    return diagnosisAttributes;
  }

  private getCoderInfo(source: string): any {
    const coderInfo = {
      coderName: "",
      coderClass: "",
    };

    switch (source) {
      case "Eve":
        coderInfo.coderName = "Eve";
        coderInfo.coderClass = "audit-log--coder-detail__eve";
        break;
      case "Abstractor":
        coderInfo.coderName = this.abstractionName;
        coderInfo.coderClass = "audit-log--coder-detail__abstractor";
        break;
      case "Overreader":
        coderInfo.coderName = this.overreaderName;
        coderInfo.coderClass = "audit-log--coder-detail__overreader";
        break;
      case "Overreader2":
        coderInfo.coderName = this.overreader2Name;
        coderInfo.coderClass = "audit-log--coder-detail__overreader2";
        break;
      default:
        break;
    }
    return coderInfo;
  }
}
