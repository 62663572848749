<div class="card-holder">
  <app-risk-dx-admin-card-header *ngIf="!addNewCoder" [isValidated]="icdGroup.isValidated" (onResetAll)="onResetAll()" (onInvalid)="onInvalid()" [icdGroup]="icdGroup"></app-risk-dx-admin-card-header>
  <app-risk-dx-admin-grid [(diagnoses)]="icdGroup.diagnoses" (onValid)="onValid($event)" (onResetAll)="onResetAll($event)" (onInvalid)="onInvalid($event)" *ngIf="!addNewCoder"  [icdGroup]="icdGroup" [disabled]="shouldDisableControl"
   [addNewDx]="addNewDx"
   (removeDx)="removeDx()"
   [riskChaseState]="riskChaseState"
   (saveNewDiagnosis)="saveNewDiagnosis($event)"
   (removeDiagnosis)="removeDiagnosis($event)"
   ></app-risk-dx-admin-grid>

   <app-risk-dx-admin-grid  *ngIf="addNewCoder"
   [addNewDx]="addNewCoder"
   (removeDx)="removeDx()"
   [riskChaseState]="riskChaseState"
   (saveNewDiagnosis)="saveNewDiagnosis($event)"
   (removeDiagnosis)="removeDiagnosis($event)"
   ></app-risk-dx-admin-grid>
  <app-risk-dx-admin-validation-buttons *ngIf="!addNewCoder"
                                  [disabled]="shouldDisableControl"
                                  (addNewDx)="addNewDiagnosis()"
                                  ></app-risk-dx-admin-validation-buttons>
</div>
