import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { UserService } from "../../../../core/user/user.service";
import { FormService } from "../../../../dynamic-forms/form.service";
import { Textbox } from "../../../../dynamic-forms/inputs/textbox/textbox.model";
import { GridPipeName } from "../../../../shared/grid/grid-pipe.enum";
import { GridColumnDefinition } from "../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../shared/grid/models/grid-configuration.model";
import { ValidFileExtension } from "../../../../shared/upload/valid-file-extension";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { BASE_API_URL } from "./../../../../core/environment.tokens";
import { ProjectDocumentType } from "./project-document-type.enum";
import { ProjectFileService } from "./project-file.service";
import { ProjectFile } from "./project-file/project-file.model";

export interface IOnUpload {
  formData: FormData;
  usePresignedURLs: boolean;
  presignedURLList: string[];
  originalFilenameList: string[];

  success(): void;

  fail(): void;
}

export interface IUploadObject {
  presignedURL: string;
  fileName: string;
}

@Component({
  selector: "project-files",
  templateUrl: "./project-files.component.html",
  styleUrls: ["./project-files.component.scss"],
  providers: [ProjectFileService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectFilesComponent implements OnInit {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly service: ProjectFileService,
    private readonly formService: FormService,
    private userService: UserService,
    private readonly changeDetector: ChangeDetectorRef,
    private messagingService: MessagingService,
    private readonly route: ActivatedRoute,
    private http: HttpClient
  ) {
    this.userId = this.userService.getUserToken().userId;

    this.gridConfigurationModel.columns = [
      new GridColumnDefinition({ field: "projectdocumentid", header: "Project File ID", show: false }),
      new GridColumnDefinition({ field: "projectid", header: "Project ID", show: false }),
      new GridColumnDefinition({ field: "filename", header: "File Name" }),
      new GridColumnDefinition({ field: "description", header: "Description" }),
      new GridColumnDefinition({ field: "username", header: "Uploaded by" }),
      new GridColumnDefinition({ field: "lastmodified", header: "Upload Date", pipeName: GridPipeName.Date, format: "short", timeZone: "local" }),
    ];
    this.gridConfigurationModel.pageSize = 25;
    this.gridConfigurationModel.showActionColumn = true;
  }
  projectFilesFormGroup: FormGroup;
  uploadDescription: Textbox;
  projectFiles: ProjectFile[] = [];
  gridConfigurationModel = new GridConfiguration();
  selectedFileList: any[] = [];
  projectId: number;
  projectName: string;
  private userId: number;
  readonly projectDocumentTypeId = ProjectDocumentType.Project;
  ivaWorkpaperProjectId: number;
  isUploadStarted = false;

  private usePresignedURLs: boolean;
  private presignedURLArray = new Array();

  ngOnInit() {
    this.route.paramMap.subscribe(params => this.projectId = +params.get("projectId"));

    this.uploadDescription = new Textbox({
      key: "uploadDescription",
      label: "Upload Description:",
      placeholder: "Upload Description",
    });

    this.projectFilesFormGroup = this.formService.createFormGroup([
      this.uploadDescription,
    ]);

    this.service.checkProjectPermission(this.projectId).subscribe();

    this.service.getProjectList(this.projectId)
      .subscribe(result => {
        this.projectName = result[0].name;
        this.changeDetector.markForCheck();
      });

    this.service.getIVAWorkpaperProject().subscribe(response => {
      this.ivaWorkpaperProjectId = Number(response);
    });

    this.loadGrid();
    this.usePresignedURLs = this.userService.getUserToken().isPreSignedUrlUploadEnabled;
  }

  loadGrid() {
    this.service
      .getProjectFiles(this.projectId, this.projectDocumentTypeId)
      .subscribe(items => {
        this.projectFiles = items;
        this.changeDetector.markForCheck();
      });
  }

  trackByIndex(index, item) {
    return index;
  }

  async onFileSelect(event) {
    this.presignedURLArray = [];
    this.selectedFileList = [];
    for (const file of event.files) {
      if (ValidFileExtension.isValidExtension(file.name)) {
        this.selectedFileList.push(file);
      }
    }

    if (this.usePresignedURLs) {
      const obj = {
        name: event.currentFiles[0].name,
        type: event.currentFiles[0].type,
        fileType: "ProjectFile",
        folderName: this.projectId,
      };
      await this.http.post(`${this.baseApiUrl}presignedurl`, JSON.stringify(obj), { headers: new HttpHeaders({ "Content-Type": "application/json" }) })
        .toPromise().then(async (x: IUploadObject) => {
          this.presignedURLArray.push(x.presignedURL);
        });
    }
  }

  onFileRemove(fileUpload): void {
    this.selectedFileList = [];
    this.projectFilesFormGroup.get("uploadDescription").reset();
    fileUpload.clear();
  }

  uploadFiles(file, j): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        this.http.put(this.presignedURLArray[j], file).toPromise().then(() => {
          resolve(true);
        });
      } catch (e) {
        reject(e);
      }
    });
  }

  async uploadDocument(fileUpload): Promise<void> {
    this.isUploadStarted = true;
    const formData = new FormData();
    let isValid = true;
    formData.append("ProjectId", this.projectId.toString());
    formData.append("FileDescription", this.projectFilesFormGroup.get("uploadDescription").value);
    this.selectedFileList.forEach(file => {
      if (this.ivaWorkpaperProjectId === this.projectId) {
        isValid = this.validateIVAWorkpaperFileName(file, formData);
      }
    });
    if (isValid) {
      if (this.usePresignedURLs) {
        formData.append("OFN0", this.selectedFileList[0].name);
        formData.append("NumFiles", "1");
        formData.append("Size", this.selectedFileList[0].size);
        const filePromises = this.selectedFileList.map((file, index) => {
          return this.uploadFiles(file, index);
        });
        await Promise.all(filePromises).catch(res => {
          this.isUploadStarted = false;
          return false;
        });
      }

      this.service.uploadpresigned(formData).subscribe(files => {
        if (ArrayHelper.isAvailable(files)) {
          const file = files[0];
          if (file.uploaded) {
            this.messagingService.showToast("File(s) uploaded successfully.", SeverityType.SUCCESS);
            fileUpload.clear();
            this.projectFilesFormGroup.reset();
            this.loadGrid();
            this.changeDetector.markForCheck();
          } else {
            this.messagingService.showToast(file.message, SeverityType.ERROR);
          }
        } else {
          this.messagingService.showToast("Error while uploading File(s), please try again.", SeverityType.ERROR);
        }
      });
    } else {
      this.messagingService.showMessage("ChaseID does not match an existing IVA chase.", SeverityType.ERROR);
    }
    this.clearPreSignedUploadObjects();
  }

  private clearPreSignedUploadObjects(): void {
    this.isUploadStarted = false;
    this.presignedURLArray = [];
  }

  onViewFile(rowData): void {
    this.service.downloadFile2(rowData.projectdocumentid, this.userId, "projects", this.projectDocumentTypeId);
  }

  onDeleteFile(rowData): void {
    const projectFileDeleteRequest = {
      Filename: rowData.filename,
      ProjectId: this.projectId,
      ProjectDocumentId: rowData.projectdocumentid,
    };
    this.service.deleteProjectFile(projectFileDeleteRequest).subscribe(response => {
      if (response) {
        this.messagingService.showToast(`${projectFileDeleteRequest.Filename} has been deleted successfully.`, SeverityType.SUCCESS);
        this.loadGrid();
      } else {
        this.messagingService.showToast(`There was a problem deleting ${projectFileDeleteRequest.Filename}.`, SeverityType.ERROR);
      }
    });
  }

  validateIVAWorkpaperFileName(file: any, formData: FormData): boolean {
    let isValid = true;
    const lastIndex = file.name.lastIndexOf("_");
    if (lastIndex === -1) {
      return false;
    }
    const chaseStr = file.name.slice(lastIndex + 1);
    const chaseId = chaseStr?.slice(0, chaseStr.lastIndexOf("."));
    if (!NumberHelper.isAvailable(Number(chaseId))) {
      isValid = false;
    } else {
      formData.append("ChaseId", chaseId);
    }
    return isValid;
  }

}
