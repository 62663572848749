import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { LookupModel } from "../../../../shared/models/lookup-model";
import { FileDownloadRequest } from "./file-download-request.model";
import { ProjectDocumentType } from "./project-document-type.enum";
import { ProjectFileResponse } from "./project-file/project-file-response.model";
import { ProjectFile } from "./project-file/project-file.model";

@Injectable()
export class ProjectFileService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }


  getProjectFiles(projectId: number, projectDocumentTypeId: number | null): Observable<ProjectFile[]> {
    const url = `${this.baseApiUrl}project/files?projectId=${projectId}&projectDocumentTypeId=${projectDocumentTypeId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "ProjectFile"))
    );
  }

  getDataExtractFiles(projectId: number): Observable<ProjectFile[]> {
    const url = `${this.baseApiUrl}project/dataextract/files?projectId=${projectId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "ProjectFile"))
    );
  }

  upload(formData: FormData): Observable<ProjectFileResponse[]> {
    const url = `${this.baseApiUrl}project`;

    return this.http.post(url, formData)
      .pipe(
        map(this.automapper.curryMany("default", "ProjectFileResponse"))
      );
  }

  uploadpresigned(formData: FormData): Observable<ProjectFileResponse[]> {
    const url = `${this.baseApiUrl}project/uploadpresgined`;

    return this.http.post(url, formData)
      .pipe(
        map(this.automapper.curryMany("default", "ProjectFileResponse"))
      );
  }

  checkProjectPermission(projectId: number): Observable<number> {
    const url = `${this.baseApiUrl}project/projectpermission?projectid=${projectId}`;

    return this.http.get(url, { observe: "response" })
      .pipe(
        map((response: HttpResponse<any>): number => {
          if (response.ok) {
            return 1;
          } else {

            return 0;
          }
        })
      );
  }

  getProjectList(projectId: number): Observable<LookupModel[]> {
    // OBSOLETE
    // TODO: Delete this method and use "app/platform/modules/retrieval/retrieval-page/retrieval-page.service.ts"
    const url = `${this.baseApiUrl}project/lookup?projectId=${projectId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "LookupModel"))
    );
  }

  deleteProjectFile(projectFileDeleteRequest): Observable<object>  {
    const url = `${this.baseApiUrl}project/deleteprojectfile`;
    return this.http.post(url, projectFileDeleteRequest);
  }

  downloadFile2(projectDocumentId: string, userId: number, fileType: string, projectDocumentTypeId: ProjectDocumentType, fileName: string = "") {
    this.http.get(
      `${this.baseApiUrl}file/download?fileId=${projectDocumentId}&fileType=${fileType}&userId=${userId}&projectDocumentTypeId=${projectDocumentTypeId}&fileName=${fileName}`,
      { responseType: "blob" as "json", observe: "response" as "body" }).subscribe(
        (response: any) => {
          const downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(response.body);
          const disposition = response.headers.get("content-disposition");
          if (disposition && disposition.indexOf("attachment") !== -1) {
            let dispositionFileName = fileName;

            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              dispositionFileName = matches[1].replace(/['"]/g, "");
            }
            downloadLink.download = dispositionFileName;
          }
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      );
  }

  deleteProjectChartFile(projectFileDeleteRequest): Observable<object> {
    const url = `${this.baseApiUrl}project/delete/chart`;
    return this.http.post(url, projectFileDeleteRequest);
  }

  getIVAWorkpaperProject(): Observable<string> {
    const url = `${this.baseApiUrl}project/getivaworkpaperprojectid`;
    return this.http.get(url).pipe(map((response: any) => response as string));
  }

  downloadFile(request: FileDownloadRequest): any {
    const url = `${this.baseApiUrl}post/file/download`;
    const httpOptions = {
      responseType  : "arraybuffer" as "json",
    };

    return this.http.post<any>(url, request, httpOptions);
  }

  printFile(data: any, fileName: string): void {
    const blob = new Blob([data], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;

    a.download = fileName;
    a.click();
    a.remove();
  }
}
