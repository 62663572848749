import { IAutoMapper } from "../../core/automapper/interfaces";
import { BooleanHelper } from "../../utilities/contracts/boolean-helper";
import { StringHelper } from "../../utilities/contracts/string-helper";

export const mapSelectableInput = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "SelectableInput")
    .forMember("text", o => o.text || o.Text)
    .forMember("value", o => o.value || o.Value)
    .forMember("extra", o => o.extra || o.Extra || o);

  automapper
    .createMap("string", "SelectableInput")
    .forMember("text", o => o)
    .forMember("value", o => o);

  automapper
    .createMap("ProjectList", "SelectableInput")
    .forMember("text", o => o.projectName)
    .forMember("value", o => o.projectId);

  automapper
    .createMap("NumeratorListItem", "SelectableInput")
    .forMember("text", o => o.numeratorName)
    .forMember("value", o => o.numeratorId)
    .forMember("extra", o => ({
      code: o.numeratorCode,
    }));

  automapper
    .createMap("DisplayNumeratorListItem", "SelectableInput")
    .forMember("text", o => o.displayNumeratorCode)
    .forMember("value", o => o.numeratorId);

  automapper
    .createMap("LookupModel", "SelectableInput")
    .forMember("text", o => o.description)
    .forMember("value", o => o.id)
    .forMember("extra", o => ({
      projectTypeId: o.projectTypeId,
      projectRetrivalTypeId: o.projectRetrivalTypeId,
      clientId: o.clientId,
    }));

  automapper
    .createMap("ClinicalMeasureListItem", "SelectableInput")
    .forMember("text", o => o.measureCode)
    .forMember("value", o => o.measureId);

  automapper
    .createMap("UserModel", "SelectableInput")
    .forMember("text", o => `${o.firstName} ${o.lastName}`.trim())
    .forMember("value", o => o.userId);

  automapper
    .createMap("DropDownListItem", "SelectableInput")
    .forMember("text", o => o.name)
    .forMember("value", o => o.id);

  automapper
    .createMap("ReportingStatusModel", "SelectableInput")
    .forMember("text", o => o.reportingStatusName)
    .forMember("value", o => o.reportingStatusName);

  automapper
    .createMap("ClientOrgModel", "SelectableInput")
    .forMember("text", o => o.name)
    .forMember("value", o => o.clientId);

  automapper
    .createMap("PendCode", "SelectableInput")
    .forMember("text", o => o.displayName)
    .forMember("value", o => o.pendTypeId)
    .forMember("extra", o => ({
      isThirdParty: o.isThirdParty,
      owner: o.defaultOwner,
      status: o.defaultStatus,
      isAutoclose: o.isAutoclose,
      isClinical: o.isClinical,
      pendTypeCategoryId: o.pendTypeCategoryId,
    }));

  automapper
    .createMap("PendStatus", "SelectableInput")
    .forMember("text", o => o.description)
    .forMember("value", o => o.pendStatusId)
    .forMember("disabled", o => o.description === "New");

  automapper
    .createMap("PendUser", "SelectableInput")
    .forMember("text", o => o.loginName)
    .forMember("value", o => o.userID);

  automapper
    .createMap("ProjectTemplateModel", "SelectableInput")
    .forMember("text", o => o.name)
    .forMember("value", o => o.templateId);

  automapper
    .createMap("States", "SelectableInput")
    .forMember("text", o => o.label)
    .forMember("value", o => o.value);

  automapper
    .createMap("DocumentRequirement", "SelectableInput")
    .forMember("text", o => o.documentName)
    .forMember("value", o => o.documentName);

  automapper
    .createMap("ProjectType", "SelectableInput")
    .forMember("text", o => o.projectTypeName)
    .forMember("value", o => o.projectTypeId);

  automapper
    .createMap("hccCodes", "SelectableInput")
    .forMember("text", o => o.displayName)
    .forMember("value", o => o.modelId);

  automapper
    .createMap("Icd", "SelectableInput")
    .forMember("text", o => `${o.icdCode} - ${o.name}`)
    .forMember("value", o => o.icdCode);

  automapper
    .createMap("Provider", "SelectableInput")
    .forMember("text", o => `${o.providerName} (${StringHelper.clean(o.providerSpecialty, "Unknown")}) - NPI: ${StringHelper.clean(o.nationalProviderId, "None")}`)
    .forMember("value", o => o.providerSourceAliasId)
    .forMember("extra", o => o);

  automapper
    .createMap("PursuitType", "SelectableInput")
    .forMember("text", o => o.name)
    .forMember("value", o => o.code);

  automapper
    .createMap("ListItem", "SelectableInput")
    .forMember("text", o => o.value)
    .forMember("value", o => o.key);

  automapper
    .createMap("TemplateName", "SelectableInput")
    .forMember("text", o => o.name)
    .forMember("value", o => o.code);

  automapper
    .createMap("ExtractType", "SelectableInput")
    .forMember("text", o => o.name)
    .forMember("value", o => o.extractTypeId);

  automapper
    .createMap("InvoiceType", "SelectableInput")
    .forMember("text", o => o.name)
    .forMember("value", o => o.vendorInvoiceTypeId);

  automapper
    .createMap("Vendors", "SelectableInput")
    .forMember("text", o => o.name)
    .forMember("value", o => o.vendorId);

  automapper
    .createMap("CallResultType", "SelectableInput")
    .forMember("text", o => o.contactResultTypeName)
    .forMember("value", o => o.contactResultTypeId);

  automapper
    .createMap("GridColumnDefinition", "SelectableInput")
    .forMember("text", o => o.header)
    .forMember("value", o => o.field);

  automapper
    .createMap("PendSeverity", "SelectableInput")
    .forMember("text", o => o.name)
    .forMember("value", o => o.severityId);

  automapper
    .createMap("ChaseProduct", "SelectableInput")
    .forMember("text", o => o.name)
    .forMember("value", o => o.value);

  automapper
    .createMap("HccModel", "SelectableInput")
    .forMember("text", o => o.categoryCode)
    .forMember("value", o => o.categoryCode);

  automapper
    .createMap("ChaseReportingStatusModel", "SelectableInput")
    .forMember("text", o => o.reportingStatusName)
    .forMember("value", o => o.reportingStatusName.replace(",", "|"));

  automapper
    .createMap("Diagnosis", "SelectableInput")
    .forMember("text", o => `${o.icdCode} ${o.name}`)
    .forMember("value", o => o.icdCode);

  automapper
    .createMap("UserItemModel", "SelectableInput")
    .forMember("text", o => `${o.firstName} ${o.lastName}`.trim())
    .forMember("value", o => o.userId)
    .forMember("extra", o => o.organizationName);

  automapper
    .createMap("TagItem", "SelectableInput")
    .forMember("text", o => o.tagText)
    .forMember("value", o => o.tagId)
    .forMember("extra", o => ({
      color: o.color,
      tagSourceId: o.tagSourceId,
      tagId: o.tagId,
      organizationId: o.organizationId,
      isSelected: false,
    }));

  automapper
    .createMap("RoleModel", "SelectableInput")
    .forMember("text", o => o.roleName)
    .forMember("value", o => o.roleId)
    .forMember("extra", o => ({
      groupName: o.groupName,
      roleTypeName: o.roleTypeName,
    }));

  automapper
    .createMap("BulkAction", "SelectableInput")
    .forMember("text", o => o.name)
    .forMember("value", o => o.name)
    .forMember("disabled", o => !o.alwaysEnabled)
    .forMember("extra", o => ({

      showBulkAction: o.showBulkAction,
      hasIcon: o.hasIcon,
      iconName: o.iconName,
    }));

  automapper
    .createMap("ThumbnailFilter", "SelectableInput")
    .forMember("text", o => o.displayValue)
    .forMember("value", o => o.id);

  automapper
    .createMap("RequestType", "SelectableInput")
    .forMember("text", o => o.requestTypeName)
    .forMember("value", o => o.requestTypeId);

  automapper
    .createMap("RequestFileType", "SelectableInput")
    .forMember("text", o => o.requestFileTypeDesc)
    .forMember("value", o => o.auditRequestFileTypeId)
    .forMember("extra", o => ({
      requestTypeId: o.requestTypeId,
    }));

  automapper
    .createMap("ThumbnailCheckBox", "SelectableInput")
    .forMember("text", o => "")
    .forMember("value", o => o.documentPageId)
    .forMember("extra", o => ({
      documentQueueId: o.documentQueueId,
      pageNumber: o.pageNumber,
      documentPageId: o.documentPageId,
    }));

  automapper
    .createMap("DxCode", "SelectableInput")
    .forMember("text", o => o.text)
    .forMember("value", o => o.value)
    .forMember("extra", o => ({
      serviceOrg: o.serviceOrgId,
      isActive: o.isActive,
      itemId: o.itemId,
      isSubmittable : o.isSubmittable,
    }));

  automapper
    .createMap("EventLake", "SelectableInput")
    .forMember("text", o => `${o.documentQueueId} : ${o.originalDocumentName}`)
    .forMember("value", o => o.documentQueueId);
};

export class SelectableInput {
  text: string;
  value: string | number;
  disabled: boolean;
  items?: SelectableInput[];
  extra: any;

  constructor(options: {
    text: string;
    value: string | number;
    disabled?: boolean;
    items?: SelectableInput[];
    extra?: any;
  }) {
    this.text = StringHelper.clean(options.text);
    this.value = options.value == null ? this.text : options.value;
    this.disabled = BooleanHelper.tryGet(options.disabled, false);
    this.items = options.items || [];
    this.extra = options.extra || {};
  }

  get label(): string {
    return this.text;
  }
}
