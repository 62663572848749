import { RiskDxActionType } from "../risk-dx-action-type.enum";
import { RiskDxValidationDiagnosis } from "./risk-dx-validation-diagnosis.model";

export class RiskDxValidation {
  chaseId: number;
  dxActionRequests: RiskDxValidationDiagnosis[];
  riskDxActionType: RiskDxActionType;

  constructor(chaseId: number, dxActionRequests: RiskDxValidationDiagnosis[]) {
    this.chaseId = chaseId;
    this.dxActionRequests = dxActionRequests;
  }
}
