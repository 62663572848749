import { Location } from "@angular/common";
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentFactoryResolver, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { distinctUntilKeyChanged, filter, map } from "rxjs/operators";
import { SubSink } from "subsink";
import { AuthService } from "../../../../../auth/auth.service";
import { UserToken } from "../../../../../auth/user-token.model";
import { LocalService } from "../../../../../core/storage/local.service";
import { UserService } from "../../../../../core/user/user.service";
import { DocumentPageViewerComponent } from "../../../../../shared/document/document-page-viewer/document-page-viewer.component";
import { HighlightAnnotationService } from "../../../../../shared/highlight-annotation/highlight-annotation.service";
import { ListItem } from "../../../../../shared/list/list-item";
import { MenuService } from "../../../../../shared/menu/menu.service";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { ActionButton } from "../../../../../zdevcontrols/action-button/action-button.model";
import { DevControllerService } from "../../../../../zdevcontrols/dev-controller/dev-controller.service";
import { WorkflowStatusDb } from "../../../../api/workflow/workflow-status-db.enum";
import { ChaseDetailForAuditItem } from "../../../audit/chase-detail-for-audit-item.model";
import { FunctionalRole } from "../../../dashboard/retrieval/functional-role.enum";
import { CodingReviewMode } from "../../../project/project-config/coding-review-mode.enum";
import { ProjectType } from "../../../project/project-type.enum";
import { ChartService } from "../../chase-detail/chase-detail-chart/chart.service";
import { OverreadFeedbackStatus } from "../../chase-detail/chase-detail-chart/overread-feedback/overread-feedback-status.enum";
import { OverreadFeedbackService } from "../../chase-detail/chase-detail-chart/overread-feedback/overread-feedback.service";
import { RiskState } from "../../chase-detail/chase-detail-chart/risk/risk-state.model";
import { RiskService } from "../../chase-detail/chase-detail-chart/risk/risk.service";
import { ChaseDetailState } from "../../chase-detail/chase-detail-state.model";
import { ChaseDetailStateService } from "../../chase-detail/chase-detail-state.service";
import { ChaseDetailService } from "../../chase-detail/chase-detail.service";
import { ChartFilters } from "../chart-filters.model";
import { DocumentViewerSessionService } from "../document-viewer-session.service";
import { LOCALSTORAGE_ENCOUNTERAFTERSCREENSYNC, LOCALSTORAGE_ISWINDOWOPEN, LOCALSTORAGE_PAGEAFTERSCREENSYNC, LOCALSTORAGE_RESTRICTENCOUNTERPAGEJUMP } from "./local-storage-keys";
import { RiskDxComponent } from "./risk-dx/risk-dx.component";
import { ClinicalTermsService } from "./risk/risk-encounter/eve-clinical-terms/clinical-terms.service";
import { ChaseDetailV2ChartRiskComponent } from "./risk/risk.component";
// TODO: Refactor this enum to be used across all the v2 Risk pages and keep just "encounter"
enum Tab {
  Member = "member",
  Encounter = "encounter",
  Encounters = "encounters",
  Submit = "submit",
  DxReview = "dx-review",
}

interface IBaseComponent {
  chaseId: number;
  selectedSubMenu: EventEmitter<string>;
}

@Component({
  selector: "member-chase-detail-v2-chart",
  templateUrl: "./chase-detail-v2-chart.component.html",
  styleUrls: ["./chase-detail-v2-chart.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseDetailV2ChartComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild("riskTabMenu", { static: false, read: ViewContainerRef }) dynamicContainer: ViewContainerRef;

  @Input() chaseDetailsForAudit: ChaseDetailForAuditItem;
  private pdfScreenTab = "chasedetailWindow";
  private sink = new SubSink();
  private localStorageSelectedDiagnosis = "localStorageSelectedDiagnosis";
  private localStorageEncounterStartDate = "localStorageEncounterStartDate";
  private user: UserToken;
  chaseDetails: ListItem[] = [];
  fromPage: string;
  formGroup = new FormGroup({});
  isChartMaxed = false;
  chaseId: number;
  auditMode = false;
  chaseDetailState: ChaseDetailState;
  isFormReloading = false;
  isManagerReview = false;
  isFormReloadingComplete = false;
  hasNoDocPages = false;
  isExpandedViewFromUrl = false;
  chaseStatusToDisplayDe = "";
  @ViewChild("containerBody", { static: true }) containerBody: ElementRef;
  selectedPageNumber: number;
  isPopOutBtnEnabled = true;
  isSplitScreenEnabled = false;
  mainTab: any = null;
  duplicateTab: boolean = null;
  localStorageDiagnosisIndex = "selectedDiagnosisIndex";
  localStorageDiagnosisSelection = "diagnosisSelection";
  localStorageDocumentIds = "documentStorageIds";
  localStorageEncounterId = "selectedEncounterId";
  localStorageIsStorageCheckRequired = "isStorageCheckRequired";
  localStorageKey = "popOutAction";
  localStoragePageNumber = "pageNumber";
  localStoragePageReload = "isReloadPage";
  localStorageSelectedChaseId = "selectedChaseId";
  localStorageSkipDefaultView = "skipDefaultView";
  localStorageOriginalView = "originalView";
  localStorageOpenSecondTab = "openPreviously";
  localStorageCurrentUser =  "localStorageCurrentUser";
  winRef: any;
  isSplitScreen = false;
  isSplitScreenMainTab = false;
  selectedTabText = Tab.Member;
  selectedFilterTexts: string[] = [];
  chartFilters: ChartFilters;
  isDiagnosisSelected = false;
  isEncounterSelected = false;
  allEvidencesDocumentPageIds: number[] = [];
  isTermsActive = false;
  termsDocumentsId: number[] = [];
  filteredDocumentIds: number[] = [];
  ocrDataAvailable = false;
  showHighlightIcon = null;
  isOverreadQuery = false;
  isStatusNotManagerReview = false;
  chaseOverreadDetail: any;
  get isCodingReviewModeDX(): boolean {
    return this.chaseDetailState?.projectConfiguration?.codingReviewMode === CodingReviewMode.DIAGNOSIS ||
           this.chaseDetailState?.projectConfiguration?.codingReviewMode === CodingReviewMode.DIAGNOSIS_V2_2;
}

  get isOverreadFeedbackEnabled(): boolean {
    return this.chaseDetailState?.chaseOverreadFeedback;
  }

  get isStatusOverreadFeedback(): boolean {
    return this.chaseDetailState?.reportingStatusName === "Overread Feedback";
  }

  get isRisk(): boolean {
    return this.projectType === ProjectType.RISK;
  }

  constructor(
    private localService: LocalService,
    private overreadFeedbackService: OverreadFeedbackService,
    private readonly authService: AuthService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly chartService: ChartService,
    private readonly chaseDetailService: ChaseDetailService,
    private readonly chaseDetailStateService: ChaseDetailStateService,
    private readonly devControllerService: DevControllerService,
    private readonly riskService: RiskService,
    private readonly router: Router,
    private route: ActivatedRoute,
    private clinicalTermsService: ClinicalTermsService,
    public menuService: MenuService,
    private highlightAnnotationService: HighlightAnnotationService,
    private readonly documentViewerSessionService: DocumentViewerSessionService,
    private readonly location: Location,
    private userService: UserService,
    private readonly cfr: ComponentFactoryResolver
  ) {
    if (this.localService.get(this.localStorageKey, null) === "false") {
      this.isPopOutBtnEnabled = false;
    }
  }

  get isReloadPage(): boolean {
    return  this.localService.get(this.localStoragePageReload, null) === "1";
  }

  ngOnInit(): void {

    if (this.isReloadPage) {
      if (this.router?.url.indexOf("submit") > -1) {
        this.selectedTabText = Tab.Submit;
      } else if (this.router?.url.indexOf("encounter") > -1) {
        this.selectedTabText = Tab.Encounter;
      } else if (this.router?.url.indexOf("dx-review") > -1) {
        this.selectedTabText = Tab.DxReview;
      } else {
        this.selectedTabText = Tab.Member;
      }
    }

    if (this.localService.get(this.localStorageKey, null) === "false") {
      this.isPopOutBtnEnabled = false;
    }
    this.containerBody?.nativeElement?.offsetParent?.classList.add("container-body-wrap");
    this.user = this.userService.getUserToken();
    this.sink.add(
      this.route.parent.paramMap.subscribe(params => {
        this.isExpandedViewFromUrl = this.route.snapshot.url[0]?.path === "expanded";
        this.fromPage = params.get("fromPage");

        if ((NumberHelper.isGreaterThan(+params.get("chaseGd"), 0)) && (this.chaseId !== +params.get("chaseGd"))) {
          this.chaseId = +params.get("chaseGd");
        } else if ((NumberHelper.isGreaterThan(this.chaseDetailsForAudit.chaseId, 0))) {
          this.chaseId = this.chaseDetailsForAudit.chaseId;
          this.auditMode = true;
        } else {
          this.chaseId = 0;
        }
        if (this.router.url.includes("auditquery")) {
          this.auditMode = false;
        }
        this.riskService.setData(new RiskState({
          chaseId: this.chaseId,
          auditMode: this.auditMode,
        }));

        DocumentPageViewerComponent.documentId.next(this.chaseId);

        if ((NumberHelper.isGreaterThan(this.chaseId, 0))) {
          this.getForm();
        }
      }),
      this.chaseDetailService.chaseDetailsChangeObserver.subscribe(chaseDetails => {
        this.chaseDetails = chaseDetails;
        // show/hide split screen functionality based on configration

        const popOutStatus = this.chaseDetails.find(item => item.key === "IsOrganizationIdsEnabledForSplitScreen")?.value;
        this.isSplitScreenEnabled = popOutStatus === "True";
        if (this.isSplitScreenEnabled) {
          setTimeout(() => {
            // hide the pdf expand icon
            const expandIcon: any = document.getElementById("iconMaximize");
            if (expandIcon) {
              expandIcon.style.setProperty("display", "none");
            }
          },         0);
        }
        // TODOD: Remove after V2 screen is for all measures.
        this.isSplitScreen = true;

        this.riskService.setData({ assignedToCurrentUser: this.assignedToCurrentUser });
      }),
      this.chaseDetailStateService.state
        .pipe(
          filter(state => NumberHelper.isAvailable(state.chaseId)),
          map(state => {
            this.chaseDetailState = state;
            const isStatusOverreadFeedbackOrClient =
              this.chaseDetailState.reportingStatusName === "Overread Feedback" ||
              this.chaseDetailState.reportingStatusName === "Client Overread" ||
              this.chaseDetailState.reportingStatusName === "Downloaded" ||
              this.chaseDetailState.reportingStatusName === "Available for Download" ||
              this.chaseDetailState.hasPend;
            if (!this.isOverreadQuery && this.chaseDetailState.chaseOverreadFeedback && isStatusOverreadFeedbackOrClient) {
              this.overreadfeedbackQuery();
              this.isOverreadQuery = true;
            }
          })
        )
        .subscribe(_ => this.changeDetector.markForCheck()),
      this.chaseDetailStateService.isDataEntryClicked$.subscribe(res => {
        if (res) {
          this.isDiagnosisSelected = !res;
        }
      }),
      this.chaseDetailService.ocrDataAvailableChange.subscribe(res => {
        this.ocrDataAvailable = res;
        this.changeDetector.markForCheck();
      }),
      this.chartService.refreshCodingForm
        .subscribe(isRefreshCodingForm => {
          if (isRefreshCodingForm) {
            this.refreshCodingForm();
            this.changeDetector.markForCheck();
          }
        }),
      this.chaseDetailStateService.encounterDetails.subscribe(data => {
        this.isDiagnosisSelected = data?.isDiagnosSelected;
        if (this.isDiagnosisSelected) {
          this.selectedFilterTexts = [];
          this.isEncounterSelected = false;
          this.getFilteredDocumentIds();
        }
      }),
      this.clinicalTermsService.pagesDocumentId$.subscribe(documentsIds => {
        this.isTermsActive = ArrayHelper.isAvailable(documentsIds);
        this.termsDocumentsId = documentsIds;
        this.getFilteredDocumentIds();
      }),
      this.chaseDetailStateService.isEncounterSelected$.subscribe(data => {
        if (data) {
          this.setSelectedTabText();
          this.isEncounterSelected = data?.enc !== null ? data?.enc : this.isEncounterSelected;
          this.isDiagnosisSelected = false;
          if (this.selectedTabText === Tab.Encounters) {
            this.selectedFilterTexts = ["DOS", "Provider"];
            this.getFilteredDocumentIds();
          }
        }
      }),
      this.chaseDetailStateService.isDataEntryClicked$
        .pipe(map(_ => {
          this.selectedFilterTexts = [];
          this.getFilteredDocumentIds();
        }))
        .subscribe()
    );

    this.devControllerService.push([
      new ActionButton({
        name: "Refresh Form",
        action: () => {
          this.getForm();
        },
      }),
      new ActionButton({
        name: "Enable All",
        action: () => {
          this.formGroup.enable();
          this.changeDetector.markForCheck();
        },
      }),
    ]);
    setTimeout(() => {
      if (this.isPopOutBtnEnabled && this.localService.get(LOCALSTORAGE_ISWINDOWOPEN.key, null) === "1") {
        this.maintainStorage();
        this.localService.put(this.localStorageSelectedChaseId, this.router.url);
      }
    },         1000);

    // manage the condition for not call the localstorage event
    if (this.localService.get(this.localStorageKey, null)) {
      this.isPopOutBtnEnabled = (this.localService.get(this.localStorageKey, null) === "true");
    }
    if (this.isPopOutBtnEnabled && this.localService.get(LOCALSTORAGE_ISWINDOWOPEN.key, null) === "1") {
      this.localService.put(this.localStorageIsStorageCheckRequired, "false");
      setTimeout(() => {
        this.localService.delete(this.localStorageIsStorageCheckRequired);
      },         3000);
    }
    this.handlePopupEvent();
    this.getChaseFilters();
  }


  ngOnDestroy() {
    if (this.duplicateTab) {
      this.localService.delete(LOCALSTORAGE_ISWINDOWOPEN.key);
    }
    this.mainTab = null;
    this.duplicateTab = null;
    this.localService.delete(this.localStorageKey);
    this.localService.delete(this.localStoragePageReload);
    this.localService.delete(this.localStoragePageNumber);
    this.localService.delete(this.localStorageDiagnosisSelection);
    this.localService.delete(this.localStorageDocumentIds);
    this.localService.delete(this.localStorageDiagnosisIndex);
    this.localService.delete(this.localStorageEncounterId);
    this.localService.delete(this.localStorageEncounterStartDate);
    this.localService.delete(this.localStorageSelectedDiagnosis);
    this.sink.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.sink.add(
      this.highlightAnnotationService.shouldReload$.subscribe(data => {
        if (data === 1) {
          this.localService.put(this.localStorageOpenSecondTab, this.isSplitScreenMainTab);
        }
        if (data === 0 && this.localService.get(this.localStorageOpenSecondTab, null)) {
          this.localService.delete(this.localStorageKey);
          this.localService.delete(LOCALSTORAGE_ISWINDOWOPEN.key);
          this.localService.delete(this.localStorageOpenSecondTab);
          this.localService.delete(this.localStoragePageNumber);
          this.localService.delete(this.localStoragePageReload);
          this.localService.delete(this.localStorageCurrentUser);
          this.isPopOutBtnEnabled = true;
          setTimeout(() => {
            this.openURL();
          },         2000);
        }
      }),
      this.chaseDetailStateService.state
        .pipe(
          filter(state => StringHelper.isAvailable(state?.projectConfiguration?.codingReviewMode)),
          map(state => state?.projectConfiguration),
          distinctUntilKeyChanged("codingReviewMode")
        )
        .subscribe(() => this.componentResolver(this.isCodingReviewModeDX))
    );
  }

  private overreadfeedbackQuery(): void {
    this.overreadFeedbackService.getOverreadFeedbackDetail(this.chaseId).subscribe(overreadDetail => {
      if (ArrayHelper.isAvailable(overreadDetail)) {
      this.chaseOverreadDetail = overreadDetail;
      const isManagerOrClinicalLead =  this.user.functionalRoleIds.some(roleId => [FunctionalRole.PROJECTADMINISTRATOR, FunctionalRole.CLINICALLEAD].includes(roleId));
      const isStatusManger = overreadDetail.some(x => x.status  === OverreadFeedbackStatus.MANAGER);
      const isStatusAbstractorReview = overreadDetail.some(x => x.status  === OverreadFeedbackStatus.ABSTRACTOR);
      if (isManagerOrClinicalLead && isStatusManger) {
          this.isManagerReview = true;
          this.changeDetector.markForCheck();
      } else if (overreadDetail[0].overreaderUserId === this.user.userId && isStatusAbstractorReview) {
        this.isStatusNotManagerReview = false;
      } else if (overreadDetail[0].abstractorUserId === this.user.userId || overreadDetail[0].overreaderUserId === this.user.userId) {
        this.isStatusNotManagerReview = true;
      }
    }
    });
  }

  private componentResolver(isRiskDxProject = false): void {
    this.dynamicContainer.clear();
    const encounterBasedComponent = ChaseDetailV2ChartRiskComponent;
    const dxBasedComponent = RiskDxComponent;
    const componentToRender = isRiskDxProject ? dxBasedComponent : encounterBasedComponent;
    const componentFactory = this.cfr.resolveComponentFactory<Partial<IBaseComponent>>(componentToRender);
    const componentInstance = this.dynamicContainer.createComponent(componentFactory);
    componentInstance.instance.chaseId = this.chaseId;
    componentInstance.instance.selectedSubMenu.subscribe((res: string) => this.onTabSelected(res));
  }

  private setSelectedTabText(): void {
    if (this.pathCurrentRoute === Tab.Encounter) {
      this.selectedTabText = Tab.Encounters;
      return;
    }

    if (this.pathCurrentRoute === Tab.Submit) {
      this.selectedTabText = Tab.Submit;
      return;
    }
  }

  get assignedToId(): number {
    const assignedToIdItem = this.chaseDetails.find(item => item.key === "Assigned To Id");
    if (assignedToIdItem == null) {
      return null;
    }

    return +assignedToIdItem.value;
  }

  get assignedToCurrentUser(): boolean {
    if (this.assignedToId == null) {
      return true;
    }

    return this.assignedToId === this.authService.userId;
  }

  getForm(): void {
    // TODO: We need to refactor this section. Will cause problems once we start using chase detail state.
    if (NumberHelper.isGreaterThan(this.chaseId, 0)) {
      if (this.projectType == null) {
        setTimeout(() => this.getForm(), 50);
        return;
      }
    }
  }

  get measureCode(): string {
    return this.chaseDetailState.measureCode;
  }

  get projectType(): ProjectType {
    return this.chaseDetailState?.projectTypeId;
  }

  get status(): string {
    const statusItem = this.chaseDetails.find(item => item.key === "Workflow Status");
    return statusItem ? statusItem.value : "";
  }

  getPopOutPDFViewerStyle() {
    return this.mainTab ? {} : (!this.isPopOutBtnEnabled ? { right: "0rem" } : "");
  }

  getPopOutStyle() {
    return this.mainTab ? {} : (!this.isPopOutBtnEnabled ? { display: "none" } : "");
  }

  @HostListener("window:popstate", ["$event"]) onClickBack(
    event: PopStateEvent
  ) {
    event.preventDefault();
    this.location.back();
  }

  handlePopupEvent(): void {
    window.addEventListener("storage", event => this.storageHandler(event));

    window.addEventListener("beforeunload", () => {
      const tabstage = this.mainTab ? "1" : "0";
      this.localService.put(this.localStoragePageReload, tabstage);
      this.localService.put(LOCALSTORAGE_RESTRICTENCOUNTERPAGEJUMP.key, "1");
      this.riskService.data.subscribe(res => {
        this.localService.put(LOCALSTORAGE_ENCOUNTERAFTERSCREENSYNC.key, res?.selectedEncounterIndex);
      });
      // keep the flag, when reload happen on main tab
      if (tabstage === "0") {
        this.localService.delete(LOCALSTORAGE_ISWINDOWOPEN.key);
      }
    });
    window.addEventListener("unload", () => {
      if (this.localService.get(this.localStorageKey, null) === "false" &&
        this.localService.get(this.localStorageIsStorageCheckRequired, null) === null) {
        if (this.localService.get(this.localStorageSkipDefaultView, null) == null) {
          this.localService.put(this.localStorageOriginalView, "true");
          window.close();
        }
        this.localService.delete(this.localStorageSkipDefaultView);
      }
    });
  }

  private storageHandler(event) {
    try {
      if (event.storageArea !== localStorage) {
      }
      if (this.localService.get(this.localStorageKey, null) === null) {
        return;
      }
      if (this.localService.get(this.localStorageIsStorageCheckRequired, null) !== null && this.localService.get(this.localStorageKey, null) === "false") {
        return;
      }
      this.handleThumbnailPageSelection(event);

      for (const key in localStorage) {
        if (localStorage.hasOwnProperty(key)) {
          switch (key) {
            case this.localStorageKey:
              this.isPopOutBtnEnabled = (this.localService.get(key, null) === "true");
              this.duplicateTabAction();
              break;
            case this.localStoragePageReload:
              if (localStorage.hasOwnProperty(event.key)) {

                if (this.mainTab == null) {
                  this.mainTab = this.localService.get(key, null) === "1";
                  if (this.mainTab) {
                    this.duplicateTab = false;
                  } else {
                    this.localService.put(LOCALSTORAGE_ISWINDOWOPEN.key, "1");
                    this.duplicateTab = true;
                  }
                }
              }
              break;
            case this.localStorageOriginalView:
              this.localService.delete(this.localStorageOriginalView);
              this.closeURL();
              break;
            case this.localStorageSelectedChaseId:
              if (window.name === this.pdfScreenTab && window.location.href !== window.location.origin + this.localService.get(this.localStorageSelectedChaseId, null)) {
                window.location.href = window.location.origin + this.localService.get(this.localStorageSelectedChaseId, null);
                this.localService.delete(this.localStorageSelectedChaseId);
                this.localService.put(this.localStorageSkipDefaultView, "1");
              }
              break;
              case this.localStorageCurrentUser:
                const currentUser = Number(this.localService.get(this.localStorageCurrentUser, 0));
                this.showHighlightIcon = currentUser === this.authService.userId;
                this.changeDetector.markForCheck();
                break;
            default:
              break;
          }
        }
      }
    } catch { }
  }

  private handleThumbnailPageSelection(event: any) {
    if (event === null || event.key === undefined) { return; }
    switch (event.key) {
      case this.localStorageOriginalView:
        this.localService.delete(this.localStorageOriginalView);
        this.closeURL();
        break;
      case this.localStoragePageNumber:
      case this.localStorageDiagnosisSelection:
        this.selectedPageNumber = Number(this.localService.get(event.key, null));
        if (this.selectedPageNumber !== 0) {
          const selectedPage: any = document.getElementById("CurrentPageNumber");
          selectedPage.value = this.selectedPageNumber;
          selectedPage.click();
          this.localService.put(LOCALSTORAGE_PAGEAFTERSCREENSYNC.key, this.selectedPageNumber);
          setTimeout(() => {
            this.localService.delete(this.localStoragePageNumber);
            this.localService.delete(this.localStorageDiagnosisSelection);
          },         1000);
        }
        break;
      default:
        break;
    }
  }

  private duplicateTabAction() {
    if ((this.duplicateTab == null || this.duplicateTab)) {
      // hide user error notification
      const messageNotification: any = document.getElementsByTagName("app-messages")[0];
      if (messageNotification) {
        messageNotification.style.setProperty("display", "none");
      }
      // hide the data entry view if 2 tab as pdf viewer load
      const pdfSectionFullView: any = document.getElementById("chart_container");
      pdfSectionFullView.style.setProperty("right", "0rem");
      // remove nevigation
      document.getElementsByTagName("platform-navigation")[0].querySelector("div").style.display = "none";
      // hide the upper bar
      document.getElementsByTagName("member-chase-detail-v2")[0].querySelector("div").style.display = "none";
      // cover the bottom area
      const bottomArea: any = document.getElementsByClassName("chase-detail-chart-container")[0];
      bottomArea.style.setProperty("height", "calc(100vh - 4px)");
      // pdf left portion adjustment
      const pdfLeftArea: any = document.getElementsByTagName("app-document-page-thumbnails")[0];
      // pdfLeftArea.style.setProperty("height", "calc(100vh - 4px)"); // if show thumbnail in second tab
      if (pdfLeftArea) {
        pdfLeftArea.style.setProperty("display", "none");
      }

      const chaseDetailV2: any = document.getElementsByClassName("chase-detail-v2")[0];
      chaseDetailV2.style.display = "block";

      if (!this.isSplitScreenMainTab) {
        const header: any = document.getElementsByClassName("chase-detail-v2__header")[0];
        header.style.display = "none";

        const footer: any = document.getElementsByClassName("chase-detail-v2__footer")[0];
        footer.style.display = "none";

        const rightpane: any = document.getElementsByClassName("chase-detail-v2-chart__coding")[0];
        rightpane.style.display = "none";

        const chasedetailv2body: any = document.getElementsByClassName("chase-detail-v2__body")[0];
        chasedetailv2body.style.height = "100%";

        const chasedetailv2chartdocument: any = document.getElementsByClassName("chase-detail-v2-chart__document")[0];
        chasedetailv2chartdocument.style.width = "100%";

        const chartHeader: any = document.getElementsByTagName("app-chase-detail-v2-chart-viewer-header")[0];
        chartHeader.style.display = "none";

        const documentThumbnails: any = document.getElementsByTagName("app-chase-document-page-thumbnails")[0];
        documentThumbnails.style.display = "none";
      }
      // pdf left portion adjustment
      const pdfLeftArea1: any = document.getElementsByTagName("cdk-virtual-scroll-viewport")[0];
      if (pdfLeftArea1) {
        pdfLeftArea1.style.setProperty("height", "calc(100vh - 44px)");
      }
      // Pdf section till bottom
      const pdfSection: any = document.getElementsByClassName("document")[0];
      pdfSection.style.setProperty("height", "calc(100vh - 44px)");

    } else if (!this.duplicateTab) {
      // hide the data entry view if 2 tab as pdf viewer load
      const pdfSectionGridView: any = document.getElementById("chart_container");
      pdfSectionGridView.style.right = null;
      // keep nevigation
      document.getElementsByTagName("platform-navigation")[0].querySelector("div").style.display = "flex";
      // keep the upper bar
      document.getElementsByTagName("member-chase-detail-v2")[0].querySelector("div").style.display = "block";

      this.isSplitScreenMainTab = true;

      // main tab remove the scroll bar
      const bottomArea: any = document.getElementsByClassName("chase-detail-chart-container")[0];
      bottomArea.style.setProperty("height", "calc(100vh - 132px)");

      const footer: any = document.getElementsByClassName("chase-detail-v2__footer")[0];

      footer.style.display = "block";

      const rightpane: any = document.getElementsByClassName("chase-detail-v2-chart__coding")[0];
      rightpane.style.display = "block";


      const header: any = document.getElementsByClassName("chase-detail-v2__header")[0];
      header.style.display = "block";

      const chartHeader: any = document.getElementsByTagName("app-chase-detail-v2-chart-viewer-header")[0];
      chartHeader.style.display = "block";

      const chasedetailv2chartdocument: any = document.getElementsByClassName("chase-detail-v2-chart__document")[0];
      chasedetailv2chartdocument.style.width = "70%";
    }
  }

  private makeOriginalView() {
    this.localService.delete(LOCALSTORAGE_ISWINDOWOPEN.key);
    this.localService.delete(this.localStoragePageNumber);
    this.localService.delete(this.localStoragePageReload);
    this.isPopOutBtnEnabled = true;
    this.localService.delete(this.localStorageKey);
    const pdfThumbnailsListView: any = document.getElementsByClassName("thumbnail__singleExpand")[0].children[0];
    if (pdfThumbnailsListView) {
      pdfThumbnailsListView.style.pointerEvents = "auto";
      pdfThumbnailsListView.click();
    }
  }

  closeURL() {
    try {
      this.makeOriginalView();
      this.winRef.close();
      window.location.reload();
    } catch {
      window.close();
      window.location.reload();
    }
  }

  openURL() {
    this.maintainStorage();
    this.openOnce(window.location.origin + this.router.url, this.pdfScreenTab);
  }

  private maintainStorage() {
    this.localService.put(this.localStorageKey, "false");
    this.localService.put(LOCALSTORAGE_ISWINDOWOPEN.key, "1");
    this.localService.put(this.localStoragePageReload, "0");
    this.mainTab = true;
    this.duplicateTab = false;
  }

  get isOcrDataAvailable(): boolean {
    return this.ocrDataAvailable;
  }

  private openOnce(url, target) {
    // open a blank "target" window
    // or get the reference to the existing "target" window
    if (window.name === target) {
      window.name = "";
    }
    this.winRef = window.open("", target, "");
    // if the "target" window was just opened, change its url
    if (this.winRef.location?.href !== url) {
      this.winRef.location.href = url;
    }
    return this.winRef;
  }

  refreshCodingForm(): void {
    this.isFormReloading = true;
    this.getForm();
    this.isFormReloadingComplete = false;
    this.changeDetector.markForCheck();
  }

  hasNoDocumentPages(event: boolean): void {
    this.hasNoDocPages = event;
  }

  isOpenSplitScreenUrl(event: boolean): void {
    this.getFilteredDocumentIds();
    this.openURL();
  }

  get pathCurrentRoute(): string {
    const pathUrl = window.location.href;
    return pathUrl.substring(pathUrl.lastIndexOf("/") + 1).trim().toLowerCase();
  }

  getChaseFilters(): void {
    this.chaseDetailService.getChaseFilters(this.chaseId)
      .subscribe(data => {
        this.chartFilters = data;
        this.getFilteredDocumentIds();
      });
  }

  getFilteredDocumentIds() {
    if (this.chartFilters !== undefined) {
      let filters: any;
      let isNoFilterText = false;
      this.filteredDocumentIds = [];

      filters = [];
      switch (this.selectedTabText) {
        case Tab.Member:

          if (this.selectedFilterTexts.length === 0) {
            isNoFilterText = true;
            this.selectedFilterTexts = ["Member Name", "DOB", "Gender"];
          }

          this.selectedFilterTexts.forEach(element => {

            switch (element) {
              case "Member Name": {
                filters.push(this.chartFilters?.memberFilters?.filter(x => x.filterTypeName === "MemberName"));
                break;
              }
              case "DOB": {
                filters.push(this.chartFilters?.memberFilters?.filter(x => x.filterTypeName === "MemberDOB"));
                break;
              }
              case "Gender": {
                filters.push(this.chartFilters?.memberFilters?.filter(x => x.filterTypeName === "MemberGender"));
                break;
              }
              default:
                break;
            }

          });
          break;
        case Tab.Encounters:
          if (this.isDiagnosisSelected) {

            if (this.selectedFilterTexts.length === 0) {
              isNoFilterText = true;
              this.selectedFilterTexts = ["Diagnosis", "Signs", "Symptoms", "Medications"];
            }

            this.selectedFilterTexts.forEach(element => {

              switch (element) {
                case "Diagnosis": {
                  filters.push(this.chartFilters?.diagnosisFilters?.filter(x => x.filterTypeName === "Diagnosis"));
                  break;
                }
                case "Signs": {
                  filters.push(this.chartFilters?.diagnosisFilters?.filter(x => x.filterTypeName === "Signs"));
                  break;
                }
                case "Symptoms": {
                  filters.push(this.chartFilters?.diagnosisFilters?.filter(x => x.filterTypeName === "Symptoms"));
                  break;
                }
                case "Medications": {
                  filters.push(this.chartFilters?.diagnosisFilters?.filter(x => x.filterTypeName === "Medications"));
                  break;
                }
                default:
                  break;
              }

            });

          } else {

            if (this.selectedFilterTexts.length === 0) {
              isNoFilterText = true;
              this.selectedFilterTexts = ["DOS", "Provider"];
            }

            this.selectedFilterTexts.forEach(element => {

              switch (element) {
                case "DOS": {
                  filters.push(this.chartFilters?.encounterFilters?.filter(x => x.filterTypeName === "DateOfService"));
                  break;
                }
                case "Provider": {
                  filters.push(this.chartFilters?.encounterFilters?.filter(x => x.filterTypeName === "Provider"));
                  break;
                }
                default:
                  break;
              }

            });

          }
          break;
        case Tab.DxReview:
          if (this.selectedFilterTexts.length === 0) {
            isNoFilterText = true;
            this.selectedFilterTexts = ["Diagnosis", "Signs", "Symptoms", "Medications", "DOS", "Provider"];
          }

          this.selectedFilterTexts.forEach(element => {

            switch (element) {
              case "Diagnosis": {
                filters.push(this.chartFilters?.diagnosisFilters?.filter(x => x.filterTypeName === "Diagnosis"));
                break;
              }
              case "Signs": {
                filters.push(this.chartFilters?.diagnosisFilters?.filter(x => x.filterTypeName === "Signs"));
                break;
              }
              case "Symptoms": {
                filters.push(this.chartFilters?.diagnosisFilters?.filter(x => x.filterTypeName === "Symptoms"));
                break;
              }
              case "Medications": {
                filters.push(this.chartFilters?.diagnosisFilters?.filter(x => x.filterTypeName === "Medications"));
                break;
              }
              case "DOS": {
                filters.push(this.chartFilters?.encounterFilters?.filter(x => x.filterTypeName === "DateOfService"));
                break;
              }
              case "Provider": {
                filters.push(this.chartFilters?.encounterFilters?.filter(x => x.filterTypeName === "Provider"));
                break;
              }
              default:
                break;
            }
          });
          break;
        default:
          break;
      }

      filters = filters?.filter(item => item !== undefined);

      filters?.forEach(element => {
        element.forEach(el => {
          this.filteredDocumentIds = [...this.filteredDocumentIds, ...el.documentPageIds];
        });
      });

      if (this.isTermsActive) {
        this.isTermsActive = false;
        this.filteredDocumentIds = this.termsDocumentsId;
      }

      this.filteredDocumentIds = [...new Set(this.filteredDocumentIds)];
      if (isNoFilterText) {
        this.allEvidencesDocumentPageIds = this.filteredDocumentIds;
        this.documentViewerSessionService.updateDocumentPageIds(this.allEvidencesDocumentPageIds);
      }
    }
  }

  get documentIds(): number[] {
    return this.filteredDocumentIds;
  }

  onTabSelected(value: string) {
   this.selectedTabText = Tab[value];
   this.selectedFilterTexts = [];
   if (this.selectedTabText === Tab.Encounters) {
      this.isEncounterSelected = true;
      this.isDiagnosisSelected = false;
    }
   this.getFilteredDocumentIds();
  }

  selectedFilter(value: any) {
    if (ArrayHelper.isAvailable(value)) {
      this.selectedFilterTexts = value;
      this.getFilteredDocumentIds();
    }
  }

  toggleCodingData(event: boolean): void {
    const status = event ? WorkflowStatusDb[WorkflowStatusDb.Abstraction] : WorkflowStatusDb[WorkflowStatusDb.Overread];
    this.isFormReloading = true;
    this.chaseStatusToDisplayDe = status;
    if (this.isRisk) {
      this.riskService.loadDataByStatus(this.chaseId, status, true, () => {
        this.chaseDetailStateService.setOverreadToggleStatus(status);
      });
    }
  }
}
