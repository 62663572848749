<div class="section file-upload mapping-document">
  <div class="iva-attributeName">{{documentType}}</div>
    <div class="fileUpload">
      <app-file-upload #fileUpload
                       chooseLabel="Choose"
                       uploadLabel="Upload"
                       [multiple]="false"
                       [accept]="fileTypes"
                       [showCancelButton]="false"
                       (onUpload)="uploadDocument($event)"
                       [sendThroughPresignUrl]="false">
      </app-file-upload>
    </div>
            <div class="file-details" *ngIf="hasFile">
              <div class="detail-inline">
           <div>{{projectMappingDocumentAttribute1?.fileName}}</div>
           <div class="upload-details">uploaded by {{projectMappingDocumentAttribute1?.userName}} at {{projectMappingDocumentAttribute1?.uploadDateTime | date:'MM/dd/yyyy hh:mm:ss a'}}</div>
          </div>
            <div class="setAction">
              <div (click)="popover.toggle($event)" class="fas fa-ellipsis-v ui-clickable ui-button-icon-left">
                <app-popover #popover>                
                  <div class="action-template-container">
                    <app-button text="View File" (click)="downloadIVAMappingFile()"></app-button>
                    <app-button text="Delete File" (click)="deleteIVAMappingFile()"></app-button>
                  </div>
              </app-popover>

            </div>
          </div>
      </div>
