import { HttpClient } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { timer } from "rxjs";
import { takeWhile } from "rxjs/operators";
import { SubSink } from "subsink";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../dynamic-forms/form.service";
import { Autocomplete } from "../../../../dynamic-forms/inputs/autocomplete/autocomplete.model";
import { Dropdown } from "../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { UploadNewComponent } from "../../../../shared/upload/upload-new.component";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { VALIDATION_REASON_CODE_REQUIRED } from "../../member/chase-detail/chase-detail-chart/attributes";
import { ProjectConfigurationService } from "../../project/project-config/project-config.service";
import { ProjectFileService } from "../../project/project-files/project-file.service";
import { ProjectType } from "../../project/project-type.enum";
import { RetrievalPageService } from "../../retrieval/retrieval-page/retrieval-page.service";
import { DataLoadState } from "../data-load-state.model";
import { DataLoadService } from "../data-load.service";

@Component({
  selector: "app-data-load-upload",
  templateUrl: "./upload.component.html",
  styleUrls: ["./upload.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataLoadUploadComponent implements OnInit {
  @ViewChildren("appFileUpload") filesToUpload: QueryList<UploadNewComponent>;
  private sink = new SubSink();
  dataLoadId: number;
  dataLoadState: DataLoadState;
  enableLoadButton = false;
  isConfirmDeleteVisible = false;
  isDocumentUploadVisible = false;
  isProcessingVisible = false;
  isValidationComplete = false;
  requiredFiles: any[] = [];
  showLoadTypeSection = false;
  validationGridData: any[] = [];
  projectConfigurationVrcRequired = "";

  dataLoadFormGroup: FormGroup;
  loadTypesInput: Dropdown;
  projectsInput: Dropdown;
  projectIdInput: Autocomplete;
  hasError = false;
  uploadedfileName = "";

  constructor(
    private changeDetector: ChangeDetectorRef,
    private dataLoadService: DataLoadService,
    private formService: FormService,
    private messagingService: MessagingService,
    private projectConfigurationService: ProjectConfigurationService,
    private projectFileService: ProjectFileService,
    private retrievalPageService: RetrievalPageService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.sink.add(
      this.dataLoadService.state.subscribe(state => {
        this.dataLoadState = state;
        this.changeDetector.markForCheck();
      })
    );

    this.createDataLoadForm();
    this.getProjects();
  }

  get enableProcessButton(): boolean {
    const requiredFiles = this.requiredFiles.filter(file => !file.fileTypeIsOptional);
    return requiredFiles.every(file => file.isFileUploaded);
  }

  get processButtonText(): string {
    return this.dataLoadState.isDelete ? "DELETE" : "VALIDATE";
  }

  get processHeaderText(): string {
    if (this.dataLoadState.isAdminRefresh) {
      return "REFRESH";
    } else if (this.dataLoadState.isDelete) {
      return "DELETE";
    } else {
      return "VALIDATION";
    }
  }

  get selectedProjectId(): number {
    return NumberHelper.isGreaterThan(this.dataLoadFormGroup.get(this.projectsInput.key).value, 0) ?
    Number(this.dataLoadFormGroup.get(this.projectsInput.key).value) : Number(this.dataLoadFormGroup.get(this.projectIdInput.key).value.value);
  }

  get selectedLoadType(): string {
    return this.dataLoadFormGroup.get(this.loadTypesInput.key).value;
  }

  get showDeleteSection(): boolean {
    return StringHelper.isAvailable(this.dataLoadState.loadType) ? this.dataLoadState.isDelete : false;
  }

  get showUploadSection(): boolean {
    return StringHelper.isAvailable(this.dataLoadState.loadType) ? !this.dataLoadState.isDelete : false;
  }

  get isVrcRequired(): boolean {
    return this.projectConfigurationVrcRequired === "1";
  }

  get isRisk(): boolean {
    return this.dataLoadState.projectType === "RISK";
  }

  get showDiagnosisFileNotRequired(): boolean {
    return !this.isVrcRequired && this.isRisk;
  }

  checkProgress(projectId, dataLoadId): void {
    timer(0, 2000).pipe(
      takeWhile(() => !this.isValidationComplete)
    ).subscribe(() => {
      this.isProcessDone(projectId, dataLoadId);
    });
  }

  createDataLoadForm(): void {
    this.projectsInput =
      new Dropdown({
        key: "projects",
        label: "Select Project",
        placeholder: "Select project...",
      });

    this.loadTypesInput =
      new Dropdown({
        key: "loadType",
        label: "Select Load Type",
        placeholder: "Select load type...",
      });

    this.projectIdInput =
      new Autocomplete({
        key: "projectId",
        label: "Project Id",
        placeholder: "Select...",
      });

    this.dataLoadFormGroup = this.formService.createFormGroup([this.projectsInput, this.loadTypesInput, this.projectIdInput]);
  }

  deleteData(): void {
    this.isConfirmDeleteVisible = false;
    this.dataLoadId = NumberHelper.randomWholeNumber(1000000000, 2000000000);
    this.dataLoadService.setState({
      dataLoadId: this.dataLoadId,
    });
    this.isProcessingVisible = true;
    this.dataLoadService
      .deleteData(this.selectedProjectId, this.dataLoadId)
      .subscribe({
        next: () => {
          this.dataLoadService.setState({
            displaySummary: false,
            isLoadProcess: false,
          });
          this.isProcessingVisible = false;
          this.messagingService.showToast("Project delete completed.", SeverityType.SUCCESS);
        },
        error: () => {
          this.dataLoadService.setState({
            displaySummary: false,
            isLoadProcess: false,
          });
          this.isProcessingVisible = false;
          this.messagingService.showToast("Project delete failed.", SeverityType.ERROR);
        },
      });
  }

  getDataLoadTypes(): void {
    this.dataLoadService
      .getListOfDataLoadTypes(this.selectedProjectId)
      .subscribe(options => {
        this.loadTypesInput = new Dropdown({ ...this.loadTypesInput, options } as any);
        this.showLoadTypeSection = true;
        this.changeDetector.markForCheck();
      });
  }

  getDownloadInstruction(): void {
    const instructionFileType = this.getInstructionFileType();
    this.projectFileService.downloadFile2(null, null, instructionFileType, null);
  }

  getListOfRequiredFiles(): void {
    this.dataLoadService
      .getListOfFilesToLoad(this.selectedProjectId)
      .subscribe(items => {
        this.requiredFiles = items;
        this.requiredFiles.map(x => {
          x.hasError = false;
          x.errorMessage = "";
          if (x.projectTypeName === "IVA" && x.fileTypeName === "Claim") {
            x.fileFormat = `File Name Format: ClientName_Claim_Info_File_[HIOS]_Return_To_Reveleer_[Project ID]_[Date Delivered to Reveleer - YYYYMMDDhhmm]`;
          } else if ((x.projectTypeName === "HEDIS" || x.projectTypeName === "RISK") && x.fileTypeName === "Chase") {
            x.fileFormat = `File Name Format: C_InsurerHealthPlan_[ProjectID]_[YYYYMMDDhhmm]`;
          } else if (x.projectTypeName === "HEDIS" && x.fileTypeName === "Attribute") {
            x.fileFormat = `File Name Format: A_InsurerHealthPlan_[ProjectID]_[YYYYMMDDhhmm]`;
          } else if (x.projectTypeName === "RISK" && x.fileTypeName === "Diagnosis") {
            x.fileFormat = `File Name Format: Reveleer_Diagnosis_Detail_File_[ProjectID]_[YYYYMMDDhhmm]`;
          } else if (x.projectTypeName === "HEDIS" && x.fileTypeName === "Compliance") {
            x.fileFormat = `File Name Format: COMP_InsurerHealthPlan_[ProjectID]_[YYYYMMDDhhmm]`;
          } else {
            x.fileFormat = "";
          }
          return x;
          });
        this.changeDetector.markForCheck();
      });
  }

  getProjectDetail(projectId: number): void {
    this.retrievalPageService
      .getProjectList(projectId)
      .subscribe(result => {
        this.dataLoadService.setState({
          clientName: result[0].clientName,
          projectName: result[0].name,
          projectType: result[0].projectTypeName,
        });
      });
  }

  getProjects(): void {
    this.dataLoadService
      .getListOfProjects()
      .subscribe(result => {
        this.projectsInput = new Dropdown({ ...this.projectsInput, options: [...result] } as any);
        const pOptions = [...new Set(result)];
        this.setProjectIdInput({ ...pOptions } as any);
      });
  }

  setProjectIdInput(value: any): void {
    const pOptions = Object.keys(value).map(key => ({...value[key]}));
    pOptions.map(x => { x.text = (x.value).toString(); x.label = (x.value).toString(); return x; });
    this.projectIdInput = new Autocomplete({ ...this.projectIdInput, options: pOptions } as any);
    this.formService.updateDom.next();
    this.changeDetector.markForCheck();
  }

  onLoadTypeChange(event): void {
    this.dataLoadService.setState({
      loadType: this.selectedLoadType,
    });
  }

  onProjectChange(event, isProjectIdSelected): void {
    this.resetFileUpload();
    this.getDataLoadTypes();
    this.getListOfRequiredFiles();
    this.getProjectDetail(this.selectedProjectId);
    this.getProjectConfiguration(this.selectedProjectId, VALIDATION_REASON_CODE_REQUIRED.attributeId);
    if (isProjectIdSelected) {
      this.dataLoadFormGroup.get(this.projectsInput.key).setValue(event.value);
    } else if (!isProjectIdSelected) {
      const options = new SelectableInput({value: event.value, text: (event.value).toString()});
      this.dataLoadFormGroup.get(this.projectIdInput.key).setValue(options);
    }
    this.dataLoadService.setState({
      displaySummary: false,
      projectId: this.selectedProjectId,
    });
  }

  resetFileUpload(): void {
    this.dataLoadService.setState({
      displaySummary: false,
    });
    this.filesToUpload.forEach(fileToUpload => {
      fileToUpload.clearUpload();
    });
    this.requiredFiles = this.requiredFiles.map(file => ({ ...file, isFileUploaded: false }));
    this.changeDetector.markForCheck();
  }

  startDataValidation(): void {
    if (this.dataLoadState.isDelete) {
      this.isConfirmDeleteVisible = true;
    } else {
      this.dataLoadId = NumberHelper.randomWholeNumber(1000000000, 2000000000);
      this.dataLoadService.setState({
        dataLoadId: this.dataLoadId,
      });
      this.isValidationComplete = false; // reset report

      this.validateData(this.selectedProjectId, this.dataLoadId, this.selectedLoadType);
    }
  }

  getUploadData(fileData: any): KeyValuePairs<string> {
    return [
      { key: "ProjectId", value: this.selectedProjectId.toString()},
      { key: "FileType", value: fileData.fileTypeName},
      { key: "FileTypeId", value: fileData.fileTypeId},
    ];
  }

  uploadDocument(event: any, index: number, uploadedFile: any, fileData: any): void {
    const hasValidationError = this.validateUploadedFile(uploadedFile, fileData);
    if (!hasValidationError) {
        const obj = {
        name: event.formData.get("Document").name,
        type: event.formData.get("Document").type,
        fileType: `DataLoad-${fileData.fileTypeName}`,
        folderName: fileData.projectId,
      };

        this.dataLoadService.getPresignedURL(JSON.stringify(obj))
      .subscribe(data => {
        if (data) {
          this.uploadDocuments(data, event, index, fileData);
        } else {
          this.requiredFiles[index] = { ...this.requiredFiles[index], isFileUploaded: false };
          this.messagingService.showToast("Error generating pre-signed URL while uploading, please try again.", SeverityType.ERROR);
        }
        this.changeDetector.markForCheck();
      });
    }
  }

  async uploadDocuments(response, event, index, fileData) {
    this.uploadFile(response.presignedURL, event.formData.get("Document"))
      .then(sucess => {
        const obj = {
          fileLocation: response.fileName,
          fileTypeId: event.formData.get("FileTypeId"),
          projectId: event.formData.get("ProjectId"),
        };
        this.dataLoadService.saveUpload(JSON.stringify(obj))
        .subscribe(data => {
          if (data) {
            this.requiredFiles[index] = { ...this.requiredFiles[index], isFileUploaded: true };
            this.messagingService.showToast("File Uploaded successfully.", SeverityType.SUCCESS);
          } else {
            this.requiredFiles[index] = { ...this.requiredFiles[index], isFileUploaded: false };
            this.messagingService.showToast("Error saving upload details in DB, please try again.", SeverityType.ERROR);
          }
          this.changeDetector.markForCheck();
        });
      })
      .catch(error => {
            this.requiredFiles[index] = { ...this.requiredFiles[index], isFileUploaded: false };
            this.messagingService.showToast("Error uploading file using pre-signed URL, please try again.", SeverityType.ERROR);
      });
  }

  uploadFile(url, file): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        this.http.put(url, file).toPromise().then(() => {
          resolve(true);
        });
      } catch (e) {
        reject(e);
      }
    });
  }


  validateData(projectId: number, dataLoadId: number, dataLoadType: string): void {
    this.isProcessingVisible = true;
    this.dataLoadService.setState({
      displaySummary: this.isValidationComplete,
    });
    this.dataLoadService
      .validateData(projectId, dataLoadId, dataLoadType)
      .subscribe(
        result => {
          if (NumberHelper.isGreaterThan(result, 0)) {
            this.checkProgress(projectId, dataLoadId);
          }
        },
        err => {
          this.isValidationComplete = true;
          this.isProcessingVisible = false;
        });
  }

  private getInstructionFileType(): string {
    switch (this.dataLoadState.projectType) {
      case ProjectType[ProjectType.HEDIS]:
        return "instructionsHEDIS";
      case ProjectType[ProjectType.RISK]:
        return "instructionsRISK";
      case ProjectType[ProjectType.IVA]:
        return "instructionsIVA";
      default:
        return "instructionsHEDIS";
    }
  }

  private isProcessDone(projectId: number, dataLoadId: number): void {
    this.dataLoadService.isProcessDone(projectId, dataLoadId)
      .subscribe(result => {
        this.isValidationComplete = result;
        if (this.isValidationComplete) {
          this.isProcessingVisible = false;
          this.dataLoadService.setState({
            displaySummary: this.isValidationComplete,
          });
        }
        this.changeDetector.markForCheck();
      });
  }

  private getProjectConfiguration(projectId: number, attributeId: number): void {
    this.projectConfigurationService.getProjectAttribute(projectId, attributeId)
      .subscribe(config => {
        this.projectConfigurationVrcRequired = config.attributeValue;
      });
  }

  trackByIndex(index, item) {
    return index;
  }

  validateUploadedFile(uploadedFile: any, fileData: any): boolean {
    const isvalidate = this.checkFileNameValidation(fileData);
    this.hasError = false;
    if (isvalidate) {
      uploadedFile.primeUpload.files.map(file => {
        if (!file.name.includes(`_${this.selectedProjectId}_`) && !file.name.includes(`${this.selectedProjectId}_`)) {
          fileData.hasError = true;
          this.hasError = true;
          this.uploadedfileName = file.name;
          fileData.errorMessage = `The Project ID of the attached file "${this.uploadedfileName}" does not match the Project ID (${this.selectedProjectId}) of the selected project. Please review the selected project or update the file name prior to continuing data load.`;
        } else {
          fileData.hasError = false;
          fileData.errorMessage = "";
        }
        return file;
      });
    }
    return this.hasError;
  }

  private checkFileNameValidation(fileData: any): boolean {
    return (fileData.projectTypeName === "IVA" && fileData.fileTypeName === "Claim") || fileData.projectTypeName === "HEDIS"
    || fileData.projectTypeName === "RISK";
  }

  fileUploadSpacing(fileData: any): string {
    const defaultClass = "required-files upload__spacing";
    return StringHelper.isAvailable(fileData.fileFormat) ? `${defaultClass} fileFormat` : defaultClass;
  }

  deleteFile(): void {
    this.dataLoadService
      .deleteFile(this.selectedProjectId)
      .subscribe({
        next: () => {
          this.messagingService.showToast("Reset files completed", SeverityType.SUCCESS);
        },
        error: () => {
          this.messagingService.showToast("An unexpected error has occurred", SeverityType.ERROR);
        },
      });
  }


}
